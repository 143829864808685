import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Api from '../api';
const initialStatus = {
  loading: false,
  errorMsg: '',
  isSuccessful: true,
};
const initialStatus2 = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};
const useGoogleStore = create(
  devtools((set) => ({
    googleYearlyAnlaytics: [],
    topPerformanceSeo: initialStatus2,
    topPerformanceAds: initialStatus2,
    accounts: [],
    ga4Accounts: [],
    ga4SeoData: initialStatus2,
    ga4PpcData: initialStatus2,
    googleSearchConsole: initialStatus2,
    adsClients: [],
    campaignsReport: initialStatus2,
    campaignsYearlyReport: [],
    fetchTopPerformanceSeo: async (data) => {
      try {
        set(() => ({ topPerformanceSeo: initialStatus2 }));
        const response = await Api.Analytics.getTopPerformance(data);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set((state) => ({ ...state, topPerformanceSeo: resData }));
          throw 'An error occurred (GA4). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ topPerformanceSeo: resData }));
          return { status: true };
        }
      } catch (error) {
        set(() => ({ topPerformanceSeo: initialStatus }));
        throw error;
      }
    },
    fetchTopPerformanceAds: async (data) => {
      try {
        set(() => ({ topPerformanceAds: initialStatus2 }));
        const response = await Api.Analytics.getTopPerformance(data);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ topPerformanceAds: resData }));
          throw 'An error occurred (GA4). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ topPerformanceAds: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchAccounts: async (account_email, refreshCache=false) => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.Analytics.getAnalyticAccounts(account_email, refreshCache);
      set(() => ({ accounts: response, loading: false }));
      return { status: true };
    },
    fetchGA4Accounts: async (account_email, account_id) => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.Analytics.getGA4Accounts(
        account_email,
        account_id
      );
      set(() => ({ ga4Accounts: response || [], loading: false }));
      return { status: true };
    },
    fetchGA4SeoData: async (data) => {
      try {
        set(() => ({ ga4SeoData: initialStatus2 }));
        const response = await Api.Analytics.getGA4AnalyticsReport(data);
        if (response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.message,
          };
          set(() => ({ ga4SeoData: resData }));
          throw 'An error occurred (GA4). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ ga4SeoData: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchGsearchData: async (data) => {
      try {
        set(() => ({ googleSearchConsole: initialStatus2 }));
        const response = await Api.Analytics.getGoogleSearchReport(data);
        
        if (response[0]?.status === 500 || response[0]?.error) {
          const resData = {
            ...response,
            loading: false,
            error: response[0]?.message
          }
          set(() => ({ googleSearchConsole: resData }));
          throw 'An error occurred (GA4). Please attempt a different date or reach out to support for assistance.';
        } else {
          const resData = { ...response, loading: false };
          set(() => ({ googleSearchConsole: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    updateAnalyticsAcc: async (data, id) => {
      set(() => ({ loading: true, errorMessage: '' }));
      await Api.Client.update(data, id);
      set(() => ({ loading: false }));
    },
    fetchAdsClients: async () => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.Analytics.getAdsClients();
      set(() => ({ adsClients: response, loading: false }));
      return { status: true };
    },
    fetchCampainsReport: async (data) => {
      set(() => ({ campaignsReport: initialStatus2 }));
      const response = await Api.Analytics.getCampaignsReport(data);
      if (response[0]?.error) {
        const resData = {
          ...response,
          loading: false,
          error: response[0]?.error,
        };
        set(() => ({ campaignsReport: resData }));
      } else {
        const resData = { ...response, loading: false, error: '' };
        set(() => ({
          campaignsReport: resData,
        }));
      }
    },
    fetchCampainsYearlyReport: async (data) => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.Analytics.getCampaignsReport(data);
      if (response[0]?.error) {
        set(() => ({ campaignsYearlyReport: [], loading: false }));
      } else {
        set(() => ({ campaignsYearlyReport: response, loading: false }));
      }
    },
    resetGoogleStore: (types) => {
      if (!Array.isArray(types)) {
        console.error('resetStore Google expects an array of types.');
        return;
      }
      set((state) =>
        types.reduce((acc, type) => {
          // Ensure the type exists in the state before trying to reset it
          if (state[type]) {
            acc[type] = { ...initialStatus }; // Spread to avoid mutation of initialStatus
          }
          return acc;
        }, {})
      );
    },
  }))
);

export default useGoogleStore;
