import { useEffect, useState } from 'react';

import Icon from '../../assets/icons/SvgComponent';

import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Textarea,
  Select,
  SelectItem,
} from '@tremor/react';
import Toggle from '../ToggleV1';
import ReactSelect from '../ReactSelect';
import {
  getMetricSourceOptions,
  getStatusMetric,
  metricSourceOptions,
} from './constants';

interface IProps {
  selectedItem?: any;
  handleSaveItem: (data: any) => void;
  handleDeleteItem: (data: any) => void;
  onClose?: any;
}

const WidgetSettings = ({
  selectedItem,
  handleSaveItem,
  handleDeleteItem,
  onClose,
}: IProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const [title, setTitle] = useState('');
  const [toolTip, setToolTip] = useState('');
  const [chartType, setChartType] = useState('');
  const [property, setProperty] = useState('');
  const [metrics, setMetrics] = useState('');
  const [trendLine, setTrendLine] = useState(true);
  const [integrationIcon, setIntegrationIcon] = useState(true);
  const [dataLabels, setDataLabels] = useState(true);
  const [themeStyle, setThemeStyle] = useState('');
  const [themeColor, setThemeColor] = useState('');

  const [selectedSourceMetric, setSelectedSourceMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Source',
    value: '',
  });
  const [selectedMetric, setSelectedMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Metric',
    value: '',
  });

  useEffect(() => {
    if (selectedItem) {
      console.log(selectedItem, 'selectedItem');
      // source
      setSelectedSourceMetric({
        label: metricSourceOptions.filter(
          (s) => s.value === selectedItem?.data_config?.source
        )[0]?.label,
        value: selectedItem?.data_config?.source,
      });
      setSelectedMetric({
        label: getStatusMetric(selectedItem?.data_config?.metrics),
        value: selectedItem?.data_config?.metrics,
      });

      setTitle(selectedItem?.title);
      setToolTip(selectedItem?.toolTip);

      // if(!selectedSourceMetric.value && !selectedMetric.value) {
      //   setIsDisabled(true)
      // } else {
      setIsDisabled(false);
      // }
      // setProperty(selectedItem?.data_config?.property)
      setMetrics(selectedItem?.data_config?.metrics);

      setChartType(selectedItem?.display_config?.chartType);

      setTrendLine(selectedItem?.display_config?.showTrendline);
      setIntegrationIcon(selectedItem?.display_config?.showIntegrationIcon);
      setDataLabels(selectedItem?.display_config?.showDataLabels);

      setThemeStyle(selectedItem?.display_config?.themeStyle);
      setThemeColor(selectedItem?.display_config?.themeColor);
    }
  }, [selectedItem]);

  const handleSave = () => {
    if (!selectedSourceMetric?.value && !selectedMetric?.value) {
      setError({
        status: true,
        message: 'Metrics and Source are required.',
      });
      return false;
    }

    console.log(selectedItem,'selectedItem');
    
    handleSaveItem({
      id: selectedItem?.id,
      key: selectedItem?.key,
      title,
      toolTip,
      data_config: {
        property, // error on update - remove soon
        source: selectedSourceMetric?.value,
        metrics: selectedMetric?.value,
      },
      display_config: {
        chartType,
        range: 'lastYear',
        showTrendline: trendLine,
        showIntegrationIcon: integrationIcon,
        showDataLabels: dataLabels,
        themeColor: themeColor,
        themeStyle: themeStyle,
      },
    });
  };

  const handleDelete = () => {
    handleDeleteItem(selectedItem);
  };

  return (
    <div className="h-screen w-screen flex justify-end">
      <div
        className="bg-[#fff] dark:bg-gray-900 relative w-80 p-4 sm:p-6  text-[#001C44] flex flex-col justify-start"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center border-b pb-3">
          <h1 className="text-base font-[700] dark:text-white flex flex-row items-center">
            <span onClick={onClose} className="inline-block cursor-pointer">
              <Icon name="arrowLeft" color="#0029FF" />
            </span>
            {` `}&nbsp; Edit Widget
          </h1>
          <div className="block sm:hidden cursor-pointer" onClick={onClose}>
            <Icon name="Xclose" />
          </div>
        </div>
        <TabGroup className="">
          <div className="flex justify-between items-center w-full items-center mb-4 mt-4">
            <TabList
              variant="solid"
              className="bg-transparent font-[600] text-[14px] text-[#001C44]"
            >
              <Tab>General</Tab>
              <Tab disabled={isDisabled}>Data</Tab>
              <Tab disabled={isDisabled}>Display</Tab>
            </TabList>
          </div>
          <TabPanels>
            <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="max-h-[80dvh] sm:max-h-[73dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      value={title || ''}
                      onChange={(e) => setTitle(e.target.value)}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="my-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Tooltip
                    </label>
                    <Textarea
                      value={toolTip || ''}
                      onChange={(e) => setToolTip(e.target.value)}
                      placeholder="sample tooltip here..."
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Chart Type
                    </label>
                    <div className="flex flex-wrap gap-4">
                      <div
                        className={`
                            cursor-pointer flex items-center flex-col justify-center w-[45%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                            ${chartType === 'line' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}
                          `}
                        onClick={() => setChartType('line')}
                      >
                        <Icon name="lineIconChart" />
                        <h4 className="text-sm font-semibold mt-2">Line</h4>
                      </div>
                      <div
                        className={`cursor-pointer flex items-center flex-col justify-center w-[45%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                          ${chartType === 'area' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}`}
                        onClick={() => setChartType('area')}
                      >
                        <Icon name="areaIconChart" />
                        <h4 className="text-sm font-semibold mt-2">Area</h4>
                      </div>
                      <div
                        className={`cursor-pointer flex items-center flex-col justify-center w-[45%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                          ${chartType === 'bar' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}`}
                        onClick={() => setChartType('bar')}
                      >
                        <Icon name="BarChart" color="#0029FF" />
                        <h4 className="text-sm font-semibold mt-2">Bar</h4>
                      </div>
                      <div
                        className={`
                            cursor-pointer flex items-center flex-col justify-center w-[45%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                            ${chartType === 'pie' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}
                          `}
                        onClick={() => setChartType('pie')}
                      >
                        <Icon name="pieIconChart" />
                        <h4 className="text-sm font-semibold mt-2">Pie</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="max-h-[80dvh] sm:max-h-[73dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  {/* <div className="relative">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Property
                      </label>
                      <input
                        type="text"
                        value={property || ''}
                        onChange={(e) => setProperty(e.target.value)}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div> */}
                  {/* <div className="my-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Metrics
                      </label>
                      <select value={themeColor} className='w-full p-2 rounded-md'>

                      </select>
                    </div> */}
                  <div className="flex flex-col w-full gap-2 items-start mt-4">
                    <label className="block text-sm font-medium text-gray-900 dark:text-white">
                      Metrics
                    </label>
                    <div className="w-full">
                      <ReactSelect
                        options={metricSourceOptions}
                        value={selectedSourceMetric}
                        // isDisabled={selectedItem?.data_config?.metrics ? true : false}
                        handleOnChange={(selectedOption: any) => {
                          setSelectedSourceMetric(selectedOption);
                          setSelectedMetric(null);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <ReactSelect
                        options={getMetricSourceOptions(
                          selectedSourceMetric?.value
                        )}
                        value={selectedMetric}
                        // isDisabled={selectedItem?.data_config?.metrics ? true : false}
                        handleOnChange={(selectedOption: any) => {
                          // console.log(selectedOption, 'selectedOption');
                          setSelectedMetric(selectedOption);
                          setIsDisabled(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="h-screen overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  <div className="flex items-center gap-4 w-full jutify-between">
                    <label className="block text-sm text-gray-900 dark:text-white">
                      Show trendline
                    </label>
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={trendLine}
                        onChange={(e) => setTrendLine(e.target.checked)}
                        // defaultChecked={trendLine}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                  <div className="flex items-center gap-4 mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white">
                      Show Integration Icon
                    </label>
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={integrationIcon}
                        onChange={(e) => setIntegrationIcon(e.target.checked)}
                        // defaultChecked={integrationIcon}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                  <div className="flex items-center gap-4 mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white">
                      Show Data Labels
                    </label>
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={dataLabels}
                        onChange={(e) => setDataLabels(e.target.checked)}
                        // defaultChecked={dataLabels}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white mb-2">
                      Theme Color
                    </label>
                    <select
                      value={themeColor}
                      onChange={(e) => setThemeColor(e.target.value)}
                      className="w-full p-2 rounded-md border-2 border-gray-300 dark:border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-700 focus:outline-none"
                    >
                      <option value="#0029FF">Cobalt</option>
                      <option value="#009EFF">Mid Blue</option>
                      <option value="#FFB545">Mango</option>
                      <option value="#01F0FF">Neon</option>
                      <option value="#001C44">Navy</option>
                      <option value="#ADF8FF">Blue Highlight</option>
                      <option value="#D0EDFF">Ice</option>
                    </select>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white mb-2">
                      Theme Style
                    </label>
                    <select
                      value={themeStyle}
                      className="w-full p-2 rounded-md"
                      onChange={(e) => setThemeStyle(e.target.value)}
                    >
                      <option value="light">Light</option>
                      <option value="dark">Dark</option>
                    </select>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        {error?.status && (
          <p className="text-sm text-red-500">{error?.message}</p>
        )}
        <div className="absolute bottom-4 flex flex-row justify-between w-72">
          <button
            className="w-2/5 mr-2 text-white bg-[#0029FF] text-[16px] font-[600] rounded-lg p-2"
            onClick={handleSave}
          >
            {/* {selectedGoal?.id ? 'Save' : 'Create Goal'} */}
            Save
          </button>
          {selectedItem?.key &&
            <button
              className="w-2/5 ml-2 text-white bg-red-700 hover:bg-red-800 text-[16px] font-[600] rounded-lg p-2"
              onClick={handleDelete}
            >
              Delete
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default WidgetSettings;
