import { AreaChart } from '@tremor/react';
import { useCallback } from 'react';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import { useThemeStore } from '../../../store/useThemeStore';

interface IProps {
  data?: any;
  category?: string[];
  displayConfig?: any;
  isLoading?: boolean;
  hasDollar?: boolean;
  hasPercent?: boolean;
  error?: boolean;
  propertyId?: string;
}

const AreaChartDisplay = ({
  data,
  category,
  displayConfig,
  isLoading,
  hasDollar,
  hasPercent,
  error,
  propertyId,
}: IProps) => {

  const { mode } = useThemeStore();

  const dataFormatter = useCallback(
    (number: number | string, hasDollar?: boolean, hasPercent?: boolean) => {
      return hasDollar
        ? formatToCurrency(number)
        : formatNumber(number, hasPercent);
    },
    []
  );

  const isLightTheme = displayConfig.themeStyle === 'light';

  return (
    <div>
      <AreaChart
        // valueFormatter={(value) => dataFormatter(value, hasDollar, hasPercent)}
        // data={staticData}
        data={data}
        index="date"
        // categories={['revenue']}
        categories={category}
        showYAxis={false}
        showLegend={false}
        showAnimation={false}
        showGradient={true}
        startEndOnly
        colors={[displayConfig?.themeColor]}
        className={
          isLightTheme
            ? '-mb-2 mt-8 h-48 bg-white'
            : '-mb-2 mt-8 h-48 bg-dark-tremor-background'
        }
      />
    </div>
  );
};

export default AreaChartDisplay;
