import ga4 from 'react-ga4'
import { Config } from '../config'
const { ENV } = Config

const TRACKING_ID = 'G-P0ERE5DFVL'
const isProduction = ENV === 'live'

export const init = () => ga4.initialize(TRACKING_ID, { 
  //testMode: !isProduction 
  testMode: false 
})

/* CUSTOM EVENTS FOR LATER 
export const sendEvent = (name: string) => ga4.event('custom_event_name', {
  app_name: "Gurulytics",
  screen_name: name,
})
*/

export const sendPageview = (path: string) => ga4.send({ 
  hitType: 'pageview', 
  page: path 
})