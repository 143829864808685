import React, { useState, useEffect, useMemo } from 'react';
import BarCharts from '../../components/Insights/BarChart/positionOpportunity';
import PieChart from '../../components/Insights/PieChart/positionOpportunity';
import 'react-datepicker/dist/react-datepicker.css';
import useToolStore from '../../store/useToolStore';
import ReactSelect from '../../components/ReactSelect';

const ContactFinder = () => {
  const { fetchContacts, fetchQuotas, loading } = useToolStore((state) => state);
  const [error, setError] = useState('');
  const [reportData, setReportData] = useState({});
  const [payload, setPayload] = useState({
    role: '',
    data: [],
    loading: true,
    keyword: '',
    database: '',
    databaseLabel: '',
    balance: '',
    resets: '',
    selectedItem: null,
    showModal: false
  });

  const { data, keyword, database, databaseLabel, balance, resets, selectedItem, showModal } = payload;
  const dbOptions = [
    { label: 'United States', value: 'US' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'Australia', value: 'AU' },
    { label: 'Canada', value: 'CA' },
    { label: 'India', value: 'IN' },
  ];

  const handleRowClick = (item, index) => {
    let merged = {...item, index: index}
    setPayload(prevPayload => ({
      ...prevPayload,
      selectedItem: merged,
      showModal: true
    }));
  };

  const handleCloseModal = () => {
    setPayload(prevPayload => ({
      ...prevPayload,
      selectedItem: null, 
      showModal: false
    }));
  };

  const SideModal = ({ item, onClose }) => {
    if (!item) return null;
    item.contacts.sort((a, b) => {
      if (a.position && !b.position) return -1;
      if (!a.position && b.position) return 1;
      return 0;
    })
    return (
      <>
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={onClose}
        />
        <div className="fixed right-0 top-6 h-full w-100 bg-white dark:bg-gray-800 shadow-lg z-50 p-4 overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Contact Details</h2>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          </div>
          
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold">Page Title</h3>
              <p>{item.title}</p>
            </div>

            <div>
              <h3 className="font-semibold">Position</h3>
              <p>{item.index}</p>
            </div>

            <div>
              <h3 className="font-semibold">Domain</h3>
              <p>{item.displayLink}</p>
            </div>

            <div>
              <h3 className="font-semibold">URL</h3>
              <a href={item.link} target="_blank" className="text-blue-600 hover:underline">{item.link}</a>
            </div>

            <div>
              <h3 className="font-semibold">Contacts ({item.contacts?.length || 0})</h3>
              <div className="space-y-2">
                {item.contacts?.map((contact, index) => (
                  <div key={index} className="p-2 bg-gray-50 dark:bg-gray-700 rounded">
                    <p><strong>Email:</strong> {contact.email}</p>
                    <p><strong>Name:</strong> {contact.firstName ? contact.firstName : 'Unknown'} {contact.lastName ? contact.lastName : ''}</p>
                    <p><strong>Position:</strong> {contact.position ? contact.position : 'Unknown'}</p>
                    <p><strong>Hubspot:</strong> {contact.isInCRM?.isInCRM ? <a target="_blank" href={'https://app.hubspot.com/contacts/1590785/record/0-1/'+contact.isInCRM.details.id} className="px-2 py-1 text-s text-white bg-green-500 rounded hover:bg-green-600">Yes</a> : <span className="px-2 py-1 text-s text-white bg-blue-500 rounded hover:bg-blue-600">New</span>}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleFetchingPositions = () => {
    if(keyword == ''){
      setError('keyword cannot be empty');
      return;
    }
    if(database == ''){
      setError('Please select a region to analyze');
      return;
    }

    setError('');
    setPayload(prevPayload => ({
      ...prevPayload,
      loading: true,
    }));

    
    fetchContacts(payload)
    .then(data => {
      if(data.success === false){
        setError(data.message);
        setPayload(prevPayload => ({
          ...prevPayload,
          loading: false,
        }));
      }else{
        console.log('OUR DATA: ', data.data)
        var limited = data.data.items.slice(30)
        setPayload(prevPayload => ({
          ...prevPayload,
          data: limited,
          balance: data.data.balance.data.balance,
          resets: data.data.balance.data.limit_resets_in,
          loading: false,
        }));
      }
    });
    
/*
   
    var data = {
    "balance": {
        "success": true,
        "data": {
            "balance": "2299.00",
            "teamwork": true,
            "unique_recipients_used": 0,
            "limit_resets_in": 22,
            "expires_in": 142
        }
    },
    "items": [
        {
            "kind": "customsearch#result",
            "title": "Looking for electrician who isn't completely unethical and is ...",
            "htmlTitle": "Looking for <b>electrician</b> who isn&#39;t completely unethical and is ...",
            "link": "https://www.reddit.com/r/sydney/comments/196zdns/looking_for_electrician_who_isnt_completely/",
            "displayLink": "www.reddit.com",
            "snippet": "Jan 15, 2024 ... Just Electrical Sydney. Very honest guy, good at his job and reasonably priced.",
            "htmlSnippet": "Jan 15, 2024 <b>...</b> Just Electrical <b>Sydney</b>. Very honest guy, <b>good</b> at his job and reasonably priced.",
            "formattedUrl": "https://www.reddit.com/.../sydney/.../looking_for_electrician_who_isnt_co...",
            "htmlFormattedUrl": "https://www.reddit.com/.../<b>sydney</b>/.../looking_for_<b>electrician</b>_who_isnt_co...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/196zdns",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/sydney on Reddit: Looking for electrician who isn't completely unethical and is competent",
                        "og:site_name": "Reddit",
                        "og:title": "r/sydney on Reddit: Looking for electrician who isn't completely unethical and is competent",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/madhouse15 - 51 votes and 93 comments",
                        "twitter:image": "https://share.redd.it/preview/post/196zdns",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/sydney/comments/196zdns/looking_for_electrician_who_isnt_completely/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/196zdns"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Looking to emigrate, how's best? | on ElectriciansForums",
            "htmlTitle": "Looking to emigrate, how&#39;s <b>best</b>? | on ElectriciansForums",
            "link": "https://www.electriciansforums.net/threads/looking-to-emigrate-hows-best.180668/",
            "displayLink": "www.electriciansforums.net",
            "snippet": "Nov 16, 2019 ... I recommend getting licensed and registered first, as Australian laws require you to be a licensed electrician to do electrical installation, ...",
            "htmlSnippet": "Nov 16, 2019 <b>...</b> I recommend getting licensed and registered first, as Australian laws require you to be a licensed <b>electrician</b> to do electrical installation,&nbsp;...",
            "formattedUrl": "https://www.electriciansforums.net/.../looking-to-emigrate-hows-best.18066...",
            "htmlFormattedUrl": "https://www.<b>electrician</b>sforums.net/.../looking-to-emigrate-hows-<b>best</b>.18066...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8le7fPHSMCRUB6SywUPnkUHj4UinNxkcU6mfTZwmkOE2vVdf5iykgqKmN&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "answer": [
                    {
                        "upvotecount": "0",
                        "text": "might be an idea to pm one of our aussie members. afaik, in Oz you need to be licensed. Australia House would also be a good place for advice.",
                        "url": "Nov 16, 2019",
                        "datepublished": "2019-11-16T20:10:33+0000"
                    },
                    {
                        "upvotecount": "0",
                        "text": "Have you been to either Country to look around from the point of view of living / working there as opposed to holidaying? Have you checked both Countires immigration systems to see if you and...",
                        "url": "Nov 16, 2019",
                        "datepublished": "2019-11-16T20:32:19+0000"
                    },
                    {
                        "upvotecount": "0",
                        "text": "telectrix said: might be an idea to pm one of our aussie members. afaik, in Oz you need to be licensed. Australia House would also be a good place for advice. Click to expand... Any suggestions...",
                        "url": "Nov 16, 2019",
                        "datepublished": "2019-11-16T21:31:03+0000"
                    },
                    {
                        "upvotecount": "0",
                        "text": "Xjsr92 said: Any suggestions on who'd be best to pm? [automerge]1573940037[/automerge] Not been to either yet, plans are early next year we're going to holiday both. It seems both countries...",
                        "url": "Nov 17, 2019",
                        "datepublished": "2019-11-17T09:08:19+0000"
                    },
                    {
                        "upvotecount": "0",
                        "text": "Well it depends where you are moving to. My recommendation is moving either to New South Wales or Victoria. Sydney in NSW and Melbourne in VIC are very popular as there are lots of job opportunitie...",
                        "url": "Jul 28, 2020",
                        "datepublished": "2020-07-28T07:59:38+0100"
                    },
                    {
                        "upvotecount": "0",
                        "text": "Whatever happened to the good old days. Commit a crime and get shipped to Australia? Seriously though , wish you all the best if you go for it, had few friends and family move out many moons...",
                        "url": "Jul 28, 2020",
                        "datepublished": "2020-07-28T14:57:35+0100"
                    },
                    {
                        "upvotecount": "0",
                        "text": "going just by the title, maybe \"how's best\" would be an aeroplane. swimming is so over rated.",
                        "url": "Jul 28, 2020",
                        "datepublished": "2020-07-28T17:22:56+0100"
                    }
                ],
                "person": [
                    {
                        "image": "https://electricians.s3.eu-west-1.amazonaws.com/data/avatars/m/20/20093.jpg?1589958703",
                        "jobtitle": "-",
                        "name": "telectrix",
                        "url": "https://www.electriciansforums.net/members/telectrix.20093/"
                    },
                    {
                        "image": "https://electricians.s3.eu-west-1.amazonaws.com/data/avatars/m/50/50587.jpg?1470935340",
                        "jobtitle": "-",
                        "name": "snowhead",
                        "url": "https://www.electriciansforums.net/members/snowhead.50587/"
                    },
                    {
                        "name": "Xjsr92",
                        "url": "https://www.electriciansforums.net/members/xjsr92.123932/"
                    },
                    {
                        "name": "Scornado",
                        "url": "https://www.electriciansforums.net/members/scornado.133900/"
                    },
                    {
                        "name": "Let me think",
                        "url": "https://www.electriciansforums.net/members/let-me-think.128636/"
                    }
                ],
                "interactioncounter": [
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    },
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    },
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    },
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    },
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    },
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    },
                    {
                        "userinteractioncount": "0",
                        "interactiontype": "https://schema.org/LikeAction"
                    }
                ],
                "metatags": [
                    {
                        "msapplication-tilecolor": "#000066",
                        "og:image": "https://electricians.s3.eu-west-1.amazonaws.com/data/assets/logo/ms-icon-310x310.png",
                        "theme-color": "#000066",
                        "twitter:card": "summary_large_image",
                        "og:type": "website",
                        "twitter:title": "Looking to emigrate, how's best? |  on ElectriciansForums",
                        "og:site_name": "ElectriciansForums.net Est.2006 | Free Electrical Advice Forum",
                        "apple-mobile-web-app-title": "ElectriciansForums.net Est.2006",
                        "og:title": "Looking to emigrate, how's best? |  on ElectriciansForums",
                        "msapplication-tileimage": "/ms-icon-144x144.png",
                        "og:description": "Find Looking to emigrate, how's best? Advice and Help. How-to Looking to emigrate, how's best? in the Australia advice boards on ElectriciansForums.net Est.2006 | Free Electrical Advice Forum. Looking to emigrate, how's best? on ElectriciansForums.net Electrical Advice Forum.",
                        "twitter:image": "https://electricians.s3.eu-west-1.amazonaws.com/data/assets/logo/ms-icon-310x310.png",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "twitter:description": "Find Looking to emigrate, how's best? Advice and Help. How-to Looking to emigrate, how's best? in the Australia advice boards on ElectriciansForums.net Est.2006 | Free Electrical Advice...",
                        "og:url": "https://www.electriciansforums.net/threads/looking-to-emigrate-hows-best.180668/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://electricians.s3.eu-west-1.amazonaws.com/data/assets/logo/ms-icon-310x310.png"
                    }
                ],
                "listitem": [
                    {
                        "item": "Forums",
                        "name": "Forums",
                        "position": "1"
                    },
                    {
                        "item": "Electrical Advice",
                        "name": "Electrical Advice",
                        "position": "2"
                    },
                    {
                        "item": "Electrical Advice by Country",
                        "name": "Electrical Advice by Country",
                        "position": "3"
                    },
                    {
                        "item": "Australia",
                        "name": "Australia",
                        "position": "4"
                    },
                    {
                        "item": "Forums",
                        "name": "Forums",
                        "position": "1"
                    },
                    {
                        "item": "Electrical Advice",
                        "name": "Electrical Advice",
                        "position": "2"
                    },
                    {
                        "item": "Electrical Advice by Country",
                        "name": "Electrical Advice by Country",
                        "position": "3"
                    },
                    {
                        "item": "Australia",
                        "name": "Australia",
                        "position": "4"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "info@electriciansforums.net",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrical Apprenticeship : r/sydney",
            "htmlTitle": "Electrical Apprenticeship : r/<b>sydney</b>",
            "link": "https://www.reddit.com/r/sydney/comments/xzi77u/electrical_apprenticeship/",
            "displayLink": "www.reddit.com",
            "snippet": "Oct 9, 2022 ... A really good area of the trade. Once you're finished you will come out with a cert3 for electrical and a cert 4 in rail signalling. And ...",
            "htmlSnippet": "Oct 9, 2022 <b>...</b> A really <b>good</b> area of the trade. Once you&#39;re finished you will come out with a cert3 for electrical and a cert 4 in rail signalling. And&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/sydney/comments/.../electrical_apprenticeship/",
            "htmlFormattedUrl": "https://www.reddit.com/r/<b>sydney</b>/comments/.../electrical_apprenticeship/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/xzi77u",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/sydney on Reddit: Electrical Apprenticeship",
                        "og:site_name": "Reddit",
                        "og:title": "r/sydney on Reddit: Electrical Apprenticeship",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/maffewdtd - 6 votes and 13 comments",
                        "twitter:image": "https://share.redd.it/preview/post/xzi77u",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/sydney/comments/xzi77u/electrical_apprenticeship/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/xzi77u"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Which state is the best one to immigrate to in Australia for electrical ...",
            "htmlTitle": "Which state is the <b>best</b> one to immigrate to in Australia for electrical ...",
            "link": "https://www.quora.com/Which-state-is-the-best-one-to-immigrate-to-in-Australia-for-electrical-engineers",
            "displayLink": "www.quora.com",
            "snippet": "Jul 15, 2017 ... The state of Victoria 🏛️ in Australia is often considered a favorable destination for civil engineer immigrants. Melbourne, the capital city of ...",
            "htmlSnippet": "Jul 15, 2017 <b>...</b> The state of Victoria 🏛️   in Australia is often considered a favorable destination for civil engineer immigrants. Melbourne, the capital city of&nbsp;...",
            "formattedUrl": "https://www.quora.com/Which-state-is-the-best-one-to-immigrate-to-in-Aus...",
            "htmlFormattedUrl": "https://www.quora.com/Which-state-is-the-<b>best</b>-one-to-immigrate-to-in-Aus...",
            "pagemap": {
                "metatags": [
                    {
                        "al:android:url": "intent://www.quora.com/Which-state-is-the-best-one-to-immigrate-to-in-Australia-for-electrical-engineers#Intent;scheme=qhttp;package=com.quora.android;S.market_referrer=launch_url=https%3A%2F%2Fwww.quora.com%2FWhich-state-is-the-best-one-to-immigrate-to-in-Australia-for-electrical-engineers&logging_data=uid%3DNone;end",
                        "theme-color": "#b92b27",
                        "viewport": "initial-scale=1, maximum-scale=1, user-scalable=no, width=device-width, minimum-scale=1, viewport-fit=cover",
                        "al:android:package": "com.quora.android",
                        "fb:pages": "255232486973",
                        "twitter:widgets:theme": "light"
                    }
                ],
                "QAPage": [
                    {}
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "prizes@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "press@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "feedback@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "challenges@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "moderation@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "adam@quora.com",
                    "firstName": "Adam",
                    "lastName": "Bouras",
                    "companyName": "Quora",
                    "sourcePage": "https://www.linkedin.com/in/bourasm/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "bugs@quora.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Which sector to choose : r/AusElectricians",
            "htmlTitle": "Which sector to choose : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/16sp0qn/which_sector_to_choose/",
            "displayLink": "www.reddit.com",
            "snippet": "Sep 26, 2023 ... Community Info Section. r/AusElectricians. Join. Australian Electricians ... Good Electrician Pre-apprenticeships Providers? 8 upvotes · 8 ...",
            "htmlSnippet": "Sep 26, 2023 <b>...</b> Community Info Section. r/AusElectricians. Join. Australian <b>Electricians</b> ... <b>Good Electrician</b> Pre-apprenticeships Providers? 8 upvotes · 8&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/AusElectricians/.../which_sector_to_choose/",
            "htmlFormattedUrl": "https://www.reddit.com/r/Aus<b>Electrician</b>s/.../which_sector_to_choose/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/16sp0qn",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Which sector to choose",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Which sector to choose",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Material-Pop-4522 - No votes and 17 comments",
                        "twitter:image": "https://share.redd.it/preview/post/16sp0qn",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/16sp0qn/which_sector_to_choose/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/16sp0qn"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best place in Oz for electricians to work - British Expats",
            "htmlTitle": "<b>Best</b> place in Oz for <b>electricians</b> to work - British Expats",
            "link": "https://britishexpats.com/forum/australia-54/best-place-oz-electricians-work-670360/",
            "displayLink": "britishexpats.com",
            "snippet": "May 30, 2010 ... ... like the idea of Perth or around Melbourne or around Sydney. Husband is an electrician and we are particularly thinking of his earning.",
            "htmlSnippet": "May 30, 2010 <b>...</b> ... like the idea of Perth or around Melbourne or around <b>Sydney</b>. Husband is an <b>electrician</b> and we are particularly thinking of his earning.",
            "formattedUrl": "https://britishexpats.com/forum/.../best-place-oz-electricians-work-670360/",
            "htmlFormattedUrl": "https://britishexpats.com/forum/.../<b>best</b>-place-oz-<b>electrician</b>s-work-670360/",
            "pagemap": {
                "metatags": [
                    {
                        "theme-color": "#194081",
                        "og:type": "website",
                        "og:site_name": "British Expats",
                        "handheldfriendly": "true",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no",
                        "og:title": "Best place in Oz for electricians to work - British Expats",
                        "og:url": "https://britishexpats.com/forum/australia-54/best-place-oz-electricians-work-670360/"
                    }
                ],
                "listitem": [
                    {
                        "item": "Living & Moving Abroad",
                        "name": "Living & Moving Abroad",
                        "position": "2"
                    },
                    {
                        "item": "Australia",
                        "name": "Australia",
                        "position": "3"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "1079556.1069563511@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "1079714.1069582007@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "1080309.1069622995@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "1098173072@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "member13275@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "891556.1059514609@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "906795.1060360232@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "434543.1033856070@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "0993.1089659762@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "1157854256@britishexpats.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Should I become an electrician or an auto electrician? What's the ...",
            "htmlTitle": "Should I become an <b>electrician</b> or an auto <b>electrician</b>? What&#39;s the ...",
            "link": "https://www.reddit.com/r/AusElectricians/comments/17zm0j1/should_i_become_an_electrician_or_an_auto/",
            "displayLink": "www.reddit.com",
            "snippet": "Nov 20, 2023 ... Hey mate, Aussie Chinese here living in Sydney I got married when I was 30 and started my electrical apprenticeship when I was 32 with my first ...",
            "htmlSnippet": "Nov 20, 2023 <b>...</b> Hey mate, Aussie Chinese here living in <b>Sydney</b> I got married when I was 30 and started my electrical apprenticeship when I was 32 with my first&nbsp;...",
            "formattedUrl": "https://www.reddit.com/.../AusElectricians/.../should_i_become_an_electrici...",
            "htmlFormattedUrl": "https://www.reddit.com/.../Aus<b>Electrician</b>s/.../should_i_become_an_<b>electrici</b>...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/17zm0j1",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Should I become an electrician or an auto electrician? What’s the difference?",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Should I become an electrician or an auto electrician? What’s the difference?",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Afrovenger - 12 votes and 87 comments",
                        "twitter:image": "https://share.redd.it/preview/post/17zm0j1",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/17zm0j1/should_i_become_an_electrician_or_an_auto/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/17zm0j1"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Sydney North Shore Electrician - Best Local 24/7 Electricians",
            "htmlTitle": "<b>Sydney</b> North Shore <b>Electrician</b> - <b>Best</b> Local 24/7 <b>Electricians</b>",
            "link": "https://sydneynorthshoreelectrician.com.au/",
            "displayLink": "sydneynorthshoreelectrician.com.au",
            "snippet": "Sydney North Shore Electrician provides all electrical services residential and commercial. Powerpoints, lights and more. Call us on 02 8378 2828.",
            "htmlSnippet": "<b>Sydney</b> North Shore <b>Electrician</b> provides all electrical services residential and commercial. Powerpoints, lights and more. Call us on 02 8378 2828.",
            "formattedUrl": "https://sydneynorthshoreelectrician.com.au/",
            "htmlFormattedUrl": "https://<b>sydney</b>northshore<b>electrician</b>.com.au/",
            "pagemap": {
                "metatags": [
                    {
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Sydney North Shore Electrician",
                        "article:modified_time": "2022-08-16T01:09:25+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:title": "Sydney North Shore Electrician - Best Local 24/7 Electricians",
                        "og:locale": "en_US",
                        "twitter:label1": "Est. reading time",
                        "og:url": "https://sydneynorthshoreelectrician.com.au/",
                        "msapplication-tileimage": "https://sydneynorthshoreelectrician.com.au/wp-content/uploads/2019/06/sydney-north-shore-electrician-favicon.png",
                        "og:description": "Sydney North Shore Electrician provides all electrical services residential and commercial. Powerpoints, lights and more. Call us on 02 8378 2828.",
                        "twitter:data1": "1 minute"
                    }
                ],
                "webpage": [
                    {
                        "name": "Home",
                        "url": "Home"
                    }
                ],
                "wpheader": [
                    {
                        "headline": "https://sydneynorthshoreelectrician.com.au/"
                    }
                ],
                "sitenavigationelement": [
                    {
                        "name": "Home",
                        "url": "Home"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Sparkies that earn >150k : r/AusElectricians",
            "htmlTitle": "Sparkies that earn &gt;150k : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/15erobz/sparkies_that_earn_150k/",
            "displayLink": "www.reddit.com",
            "snippet": "Jul 31, 2023 ... ... Sydney Trains currently has applications open for 2024 apprentice intake, including Signal Electrical. It's a good ... electrician. While ...",
            "htmlSnippet": "Jul 31, 2023 <b>...</b> ... <b>Sydney</b> Trains currently has applications open for 2024 apprentice intake, including Signal Electrical. It&#39;s a <b>good</b> ... <b>electrician</b>. While&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/AusElectricians/.../sparkies_that_earn_150k/",
            "htmlFormattedUrl": "https://www.reddit.com/r/Aus<b>Electrician</b>s/.../sparkies_that_earn_150k/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/15erobz",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Sparkies that earn >150k",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Sparkies that earn >150k",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/[Deleted Account] - 20 votes and 104 comments",
                        "twitter:image": "https://share.redd.it/preview/post/15erobz",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/15erobz/sparkies_that_earn_150k/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/15erobz"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "15 Best Electrician Website Design Examples in Australia",
            "htmlTitle": "15 <b>Best Electrician</b> Website Design Examples in Australia",
            "link": "https://kcwebdesign.com.au/best-electrician-website-design/",
            "displayLink": "kcwebdesign.com.au",
            "snippet": "Jul 16, 2023 ... This blog post aims to explore the pinnacle of electrician website designs in Australia, shining a light on those that excel in providing an outstanding user ...",
            "htmlSnippet": "Jul 16, 2023 <b>...</b> This blog post aims to explore the pinnacle of <b>electrician</b> website designs in Australia, shining a light on those that excel in providing an outstanding user&nbsp;...",
            "formattedUrl": "https://kcwebdesign.com.au/best-electrician-website-design/",
            "htmlFormattedUrl": "https://kcwebdesign.com.au/<b>best</b>-<b>electrician</b>-website-design/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8GlHf1YzIrZn7I4y-epmhokxhROIAr6vkYQ2N8M01gIsusZ7hf2vChKtx&s",
                        "width": "300",
                        "height": "168"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://kcwebdesign.com.au/wp-content/uploads/2023/07/Best-Electrician-Website-Design.gif",
                        "og:image:width": "480",
                        "article:published_time": "2023-07-16T09:58:29+10:00",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "KC Web Design",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Time to read",
                        "og:image:type": "image/gif",
                        "msapplication-tileimage": "https://kcwebdesign.com.au/wp-content/uploads/2022/09/KC-Web-Design-Agency-Favicon-300x300.png",
                        "og:description": "This blog post features some of the best electrician website design examples in Australia and provides guidance for electricians seeking a website designer.",
                        "twitter:creator": "@KCWebDesignAU",
                        "article:publisher": "https://www.facebook.com/KCWebDesignAU/",
                        "og:image:secure_url": "https://kcwebdesign.com.au/wp-content/uploads/2023/07/Best-Electrician-Website-Design.gif",
                        "twitter:image": "https://kcwebdesign.com.au/wp-content/uploads/2023/07/Best-Electrician-Website-Design.gif",
                        "twitter:data1": "Kyle Crisp",
                        "twitter:data2": "19 minutes",
                        "twitter:site": "@KCWebDesignAU",
                        "article:modified_time": "2024-07-06T13:19:20+10:00",
                        "og:type": "article",
                        "article:section": "Web Design",
                        "og:image:alt": "electrician website design",
                        "twitter:title": "15 Best Electrician Website Design Examples in Australia",
                        "og:title": "15 Best Electrician Website Design Examples in Australia",
                        "og:image:height": "270",
                        "og:updated_time": "2024-07-06T13:19:20+10:00",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "twitter:description": "This blog post features some of the best electrician website design examples in Australia and provides guidance for electricians seeking a website designer.",
                        "og:locale": "en_US",
                        "og:url": "https://kcwebdesign.com.au/best-electrician-website-design/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://kcwebdesign.com.au/wp-content/uploads/2023/07/Best-Electrician-Website-Design.gif"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "kyle@kcwebdesign.com.au",
                    "position": "Web Designer",
                    "firstName": "Kyle",
                    "lastName": "Crisp",
                    "companyName": "KC Web Design",
                    "sourcePage": "https://www.linkedin.com/in/kylecrisp",
                    "isInCRM": {
                        "details": {
                            "id": "55536651",
                            "properties": {
                                "createdate": "2022-05-04T05:24:46.545Z",
                                "email": "kyle@kcwebdesign.com.au",
                                "firstname": null,
                                "hs_object_id": "55536651",
                                "lastmodifieddate": "2024-12-09T16:23:15.156Z",
                                "lastname": null
                            },
                            "createdAt": "2022-05-04T05:24:46.545Z",
                            "updatedAt": "2024-12-09T16:23:15.156Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Kari@kcwebdesign.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Paul@kcwebdesign.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "31M Electrician in Aus, looking for a career change. But have no ...",
            "htmlTitle": "31M <b>Electrician</b> in Aus, looking for a career change. But have no ...",
            "link": "https://www.reddit.com/r/AusElectricians/comments/1bznwld/31m_electrician_in_aus_looking_for_a_career/",
            "displayLink": "www.reddit.com",
            "snippet": "Apr 10, 2024 ... Sydney trains Best work life balance, good money. Upvote 1. Downvote ... Best Electrician Programs Ontario · Best Countries for ...",
            "htmlSnippet": "Apr 10, 2024 <b>...</b> <b>Sydney</b> trains <b>Best</b> work life balance, <b>good</b> money. Upvote 1. Downvote ... <b>Best Electrician</b> Programs Ontario &middot; <b>Best</b> Countries for&nbsp;...",
            "formattedUrl": "https://www.reddit.com/.../AusElectricians/.../31m_electrician_in_aus_looki...",
            "htmlFormattedUrl": "https://www.reddit.com/.../Aus<b>Electrician</b>s/.../31m_<b>electrician</b>_in_aus_looki...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/1bznwld",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: 31M Electrician in Aus, looking for a career change. But have no idea what I want to do.",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: 31M Electrician in Aus, looking for a career change. But have no idea what I want to do.",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Quirky-Progress-4131 - 10 votes and 63 comments",
                        "twitter:image": "https://share.redd.it/preview/post/1bznwld",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/1bznwld/31m_electrician_in_aus_looking_for_a_career/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/1bznwld"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Good marine electrician in Sydney | Sailing Forums, page 1",
            "htmlTitle": "<b>Good</b> marine <b>electrician</b> in <b>Sydney</b> | Sailing Forums, page 1",
            "link": "https://www.seabreeze.com.au/forums/Sailing/General/Good-marine-electrician-in-Sydney?page=1",
            "displayLink": "www.seabreeze.com.au",
            "snippet": "Jan 30, 2024 ... You won't find someone better than 33 South. Sailed /know a few boats maintained by them and they all have very solid electrical work.",
            "htmlSnippet": "Jan 30, 2024 <b>...</b> You won&#39;t find someone better than 33 South. Sailed /know a few boats maintained by them and they all have very solid electrical work.",
            "formattedUrl": "https://www.seabreeze.com.au/.../Good-marine-electrician-in-Sydney?page...",
            "htmlFormattedUrl": "https://www.seabreeze.com.au/.../Good-marine-<b>electrician</b>-in-<b>Sydney</b>?page...",
            "pagemap": {
                "metatags": [
                    {
                        "msapplication-tilecolor": "#2d89ef",
                        "fb:app_id": "189117861135618",
                        "theme-color": "#ffffff",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "og:site_name": "seabreeze.com.au",
                        "viewport": "width=device-width, initial-scale=1.0, user-scalable=yes",
                        "apple-mobile-web-app-capable": "yes",
                        "og:title": "Good marine electrician in Sydney | Sailing Forums, page 1",
                        "msapplication-tileimage": "/mstile-144x144.png",
                        "og:country-name": "Australia"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "events@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "spartan@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "kitesurfing-digest@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "admin@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "standuppaddle-digest@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "laurie@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sales@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "domains@seabreeze.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Career as an Electrician : r/AusElectricians",
            "htmlTitle": "Career as an <b>Electrician</b> : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/1c9du34/career_as_an_electrician/",
            "displayLink": "www.reddit.com",
            "snippet": "Apr 21, 2024 ... Is it worth moving whole family to Australia as a fully qualified electrician? ... Best Electrician Programs Ontario · Best Trade Schools for ...",
            "htmlSnippet": "Apr 21, 2024 <b>...</b> Is it worth moving whole family to Australia as a fully qualified <b>electrician</b>? ... <b>Best Electrician</b> Programs Ontario &middot; <b>Best</b> Trade Schools for&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/AusElectricians/.../career_as_an_electrician/",
            "htmlFormattedUrl": "https://www.reddit.com/r/Aus<b>Electrician</b>s/.../career_as_an_<b>electrician</b>/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/1c9du34",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Career as an Electrician",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Career as an Electrician",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Euphoric_Bobcat_ - 3 votes and 28 comments",
                        "twitter:image": "https://share.redd.it/preview/post/1c9du34",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/1c9du34/career_as_an_electrician/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/1c9du34"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Gordon Powers: Electrician Sydney – Licensed Electrical Contractors",
            "htmlTitle": "Gordon Powers: <b>Electrician Sydney</b> – Licensed Electrical Contractors",
            "link": "https://gordonpowers.com.au/",
            "displayLink": "gordonpowers.com.au",
            "snippet": "Gordon Powers is a leading electrical services company in Sydney and one of the best-rated electricians. We've been providing exceptional electrical services ...",
            "htmlSnippet": "Gordon Powers is a leading electrical services company in <b>Sydney</b> and one of the <b>best</b>-rated <b>electricians</b>. We&#39;ve been providing exceptional electrical services&nbsp;...",
            "formattedUrl": "https://gordonpowers.com.au/",
            "htmlFormattedUrl": "https://gordonpowers.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4_sR5Y8uffOto375M2j6wRMu_dI6VEtyhLH9iPkKQKWg0nJt6aKPJ_3gw&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "ahrefs-site-verification": "5feed6e9e58935850552e06aac3ba27010bef4b6efb6c179f21bab4b9b7dedfc",
                        "viewport": "width=device-width, initial-scale=1",
                        "msapplication-tileimage": "https://gordonpowers.com.au/wp-content/uploads/2019/01/favicon.png"
                    }
                ],
                "webpage": [
                    {
                        "name": "Home",
                        "url": "Home"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://gordonpowers.com.au/wp-content/uploads/2021/07/covid-safe-logo.png"
                    }
                ],
                "wpheader": [
                    {
                        "headline": "https://gordonpowers.com.au/"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "hello@gordonpowers.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "23598951",
                            "properties": {
                                "createdate": "2020-01-07T21:36:01.561Z",
                                "email": "hello@gordonpowers.com.au",
                                "firstname": "Hello",
                                "hs_object_id": "23598951",
                                "lastmodifieddate": "2024-12-09T14:32:25.016Z",
                                "lastname": null
                            },
                            "createdAt": "2020-01-07T21:36:01.561Z",
                            "updatedAt": "2024-12-09T14:32:25.016Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@gordonpowers.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "charlie@gordonpowers.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Pay rates for electricians : r/sydney",
            "htmlTitle": "Pay rates for <b>electricians</b> : r/<b>sydney</b>",
            "link": "https://www.reddit.com/r/sydney/comments/s64xpm/pay_rates_for_electricians/",
            "displayLink": "www.reddit.com",
            "snippet": "Jan 17, 2022 ... I know that industrial and commercial sites pay best, but I am just curious what you guys from Australia get paid and where you work. (If you've ...",
            "htmlSnippet": "Jan 17, 2022 <b>...</b> I know that industrial and commercial sites pay <b>best</b>, but I am just curious what you guys from Australia get paid and where you work. (If you&#39;ve&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/sydney/comments/.../pay_rates_for_electricians/",
            "htmlFormattedUrl": "https://www.reddit.com/r/<b>sydney</b>/comments/.../pay_rates_for_<b>electrician</b>s/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/s64xpm",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/sydney on Reddit: Pay rates for electricians",
                        "og:site_name": "Reddit",
                        "og:title": "r/sydney on Reddit: Pay rates for electricians",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/GroovyFloozie_ - No votes and 8 comments",
                        "twitter:image": "https://share.redd.it/preview/post/s64xpm",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/sydney/comments/s64xpm/pay_rates_for_electricians/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/s64xpm"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "The Local Electrician: Electrician Sydney | Electrical Services",
            "htmlTitle": "The Local <b>Electrician</b>: <b>Electrician Sydney</b> | Electrical Services",
            "link": "https://www.thelocalelectrician.com.au/",
            "displayLink": "www.thelocalelectrician.com.au",
            "snippet": "The Local Electrician Sydney is open 24/7. We are emergency and level 2 electrician specialists and offer up to 15% off your first electrical service!",
            "htmlSnippet": "The Local <b>Electrician Sydney</b> is open 24/7. We are emergency and level 2 <b>electrician</b> specialists and offer up to 15% off your first electrical service!",
            "formattedUrl": "https://www.thelocalelectrician.com.au/",
            "htmlFormattedUrl": "https://www.thelocal<b>electrician</b>.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI_lALqGqfMTONaMyet90xawpCyE7l1wtxrzxpqBP9eKai9FW7qOXH_nI&s",
                        "width": "363",
                        "height": "139"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "The Local Electrician",
                        "msvalidate.01": "e9447f4b999f44459370fedef6f03454",
                        "og:title": "Electrician Sydney | Electrical Services | The Local Electrician",
                        "twitter:label1": "Est. reading time",
                        "msapplication-tileimage": "https://www.thelocalelectrician.com.au/wordpress/wp-content/uploads/cropped-TLE-favicon-270x270.png",
                        "og:description": "The Local Electrician Sydney is open 24/7. We are emergency and level 2 electrician specialists and offer up to 15% off your first electrical service!",
                        "twitter:data1": "8 minutes",
                        "article:modified_time": "2023-01-16T02:31:25+00:00",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
                        "og:locale": "en_US",
                        "og:url": "https://www.thelocalelectrician.com.au/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.thelocalelectrician.com.au/wordpress/wp-content/uploads/15percent-off.png.webp"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@thelocalelectrician.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "61202341659",
                            "properties": {
                                "createdate": "2024-09-24T04:43:30.919Z",
                                "email": "info@thelocalelectrician.com.au",
                                "firstname": null,
                                "hs_object_id": "61202341659",
                                "lastmodifieddate": "2024-12-09T20:04:18.472Z",
                                "lastname": null
                            },
                            "createdAt": "2024-09-24T04:43:30.919Z",
                            "updatedAt": "2024-12-09T20:04:18.472Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "marcus@thelocalelectrician.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Help in finding a 1st year electrical apprenticeship : r/AusElectricians",
            "htmlTitle": "Help in finding a 1st year electrical apprenticeship : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/142bbwm/help_in_finding_a_1st_year_electrical/",
            "displayLink": "www.reddit.com",
            "snippet": "Jun 6, 2023 ... Community Info Section. r/AusElectricians. Join. Australian Electricians. Aussie Sparkies (Australian Electricians). ... Best Electrician Programs ...",
            "htmlSnippet": "Jun 6, 2023 <b>...</b> Community Info Section. r/AusElectricians. Join. Australian <b>Electricians</b>. Aussie Sparkies (Australian <b>Electricians</b>). ... <b>Best Electrician</b> Programs&nbsp;...",
            "formattedUrl": "https://www.reddit.com/.../AusElectricians/.../help_in_finding_a_1st_year_e...",
            "htmlFormattedUrl": "https://www.reddit.com/.../Aus<b>Electrician</b>s/.../help_in_finding_a_1st_year_e...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/142bbwm",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Help in finding a 1st year electrical apprenticeship",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Help in finding a 1st year electrical apprenticeship",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Suspicious-Ad-7233 - 3 votes and 44 comments",
                        "twitter:image": "https://share.redd.it/preview/post/142bbwm",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/142bbwm/help_in_finding_a_1st_year_electrical/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/142bbwm"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best Electricians in Sydney NSW (3 Free Quotes)",
            "htmlTitle": "<b>Best Electricians</b> in <b>Sydney</b> NSW (3 Free Quotes)",
            "link": "https://hipages.com.au/find/electricians/nsw/sydney",
            "displayLink": "hipages.com.au",
            "snippet": "Best Electricians in Sydney NSW · Emco Electrical Services · RLC ELECTRICAL & AIR CONDITIONING · Greater Sydney Electrical · Jn Electrical Solutions · Take ...",
            "htmlSnippet": "<b>Best Electricians</b> in <b>Sydney</b> NSW &middot; Emco Electrical Services &middot; RLC ELECTRICAL &amp; AIR CONDITIONING &middot; Greater <b>Sydney</b> Electrical &middot; Jn Electrical Solutions &middot; Take&nbsp;...",
            "formattedUrl": "https://hipages.com.au/find/electricians/nsw/sydney",
            "htmlFormattedUrl": "https://hipages.com.au/find/<b>electrician</b>s/nsw/<b>sydney</b>",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCwNFgiqFP6cD49Pl3-VXLIGQ2bEZVinO4WfnqX4SpvsGtOZYRv2MpQ-M&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "msapplication-tilecolor": "#faa25c",
                        "og:image": "https://hipages.com.au/images/fb_shared_default.jpg",
                        "copyright": "2004 Home Improvement Pages Australia",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Best Electricians in Sydney NSW (3 Free Quotes)",
                        "og:site_name": "hipages",
                        "twitter:domain": "hipages.com.au",
                        "author": "hipages Australia",
                        "og:title": "Best Electricians in Sydney NSW (3 Free Quotes)",
                        "hipages:timestamp": "1733392883280",
                        "twitter:description:": "Find top rated Electricians in Sydney NSW within minutes on hipages. Get 3 free quotes now. Compare profiles and verified recommendations.",
                        "classification": "Home Improvement",
                        "msapplication-tileimage": "assets.homeimprovementpages.com.au/images/hui/hipages-144.png",
                        "og:description": "Find top rated Electricians in Sydney NSW within minutes on hipages. Get 3 free quotes now. Compare profiles and verified recommendations.",
                        "twitter:image": "https://hipages.com.au/images/fb_shared_default.jpg",
                        "fb:app_id": "102132386588242",
                        "apple-mobile-web-app-status-bar-style": "white",
                        "twitter:site": "@hipages",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "og:url": "https://hipages.com.au/find/electricians/nsw/sydney",
                        "reply-to": "info@hipages.com.au",
                        "twitter:account_id": "52971341"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://hipages.com.au/images/fb_shared_default.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sashademelo@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "lorichacko@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "roby@hipages.com.au",
                    "position": "COO",
                    "firstName": "Roby",
                    "lastName": "Sharon-Zipser",
                    "companyName": "hipages",
                    "sourcePage": "https://www.linkedin.com/in/roby-sharon-zipser-8417401b",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "daviddeng@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "marianne@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Marketing@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "davidvitek@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "adamwoods@hipages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Wanting to move to Australia when I'm a qualified UK electrician ...",
            "htmlTitle": "Wanting to move to Australia when I&#39;m a qualified UK <b>electrician</b> ...",
            "link": "https://www.reddit.com/r/AskAnAustralian/comments/12gtkdf/wanting_to_move_to_australia_when_im_a_qualified/",
            "displayLink": "www.reddit.com",
            "snippet": "Apr 9, 2023 ... The electrical theory is the same universally but there are probably plenty of differences between our reg books. Best of luck with the ...",
            "htmlSnippet": "Apr 9, 2023 <b>...</b> The electrical theory is the same universally but there are probably plenty of differences between our reg books. <b>Best</b> of luck with the&nbsp;...",
            "formattedUrl": "https://www.reddit.com/.../wanting_to_move_to_australia_when_im_a_qua...",
            "htmlFormattedUrl": "https://www.reddit.com/.../wanting_to_move_to_australia_when_im_a_qua...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/12gtkdf",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AskAnAustralian on Reddit: Wanting to move to Australia when I’m a qualified UK electrician could use a few pointers on what to do ?",
                        "og:site_name": "Reddit",
                        "og:title": "r/AskAnAustralian on Reddit: Wanting to move to Australia when I’m a qualified UK electrician could use a few pointers on what to do ?",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Wonderful_War9310 - 6 votes and 31 comments",
                        "twitter:image": "https://share.redd.it/preview/post/12gtkdf",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AskAnAustralian/comments/12gtkdf/wanting_to_move_to_australia_when_im_a_qualified/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/12gtkdf"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "The 10 Best Electricians in Sydney, NSW - Oneflare",
            "htmlTitle": "The 10 <b>Best Electricians</b> in <b>Sydney</b>, NSW - Oneflare",
            "link": "https://www.oneflare.com.au/electrician/nsw/sydney",
            "displayLink": "www.oneflare.com.au",
            "snippet": "For the best customer experience and expert service for all electrical faults, requirements, and solutions – look no further than Marky Sparky. Read more ...",
            "htmlSnippet": "For the <b>best</b> customer experience and expert service for all electrical faults, requirements, and solutions – look no further than Marky Sparky. Read more&nbsp;...",
            "formattedUrl": "https://www.oneflare.com.au/electrician/nsw/sydney",
            "htmlFormattedUrl": "https://www.oneflare.com.au/<b>electrician</b>/nsw/<b>sydney</b>",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyKlPzQSeNgb_otQJOhrJSkVCGOQ7nYvZ0SsNkH-SmzWR4XtValm2lh78&s",
                        "width": "311",
                        "height": "162"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://cdn.oneflare.com/static/site/home/facebook/logo-icon-large.png",
                        "next-head-count": "26",
                        "viewport": "width=device-width",
                        "og:title": "The 10 Best Electricians in Sydney, NSW - Oneflare",
                        "og:url": "https://www.oneflare.com.au/electrician/nsw/sydney",
                        "og:description": "Here is a list of Sydney's best Electricians as rated by other customers. Get up to 3 free quotes for Sydney Electricians!"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.oneflare.com/static/site/home/facebook/logo-icon-large.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "located@oneflare.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@oneflare.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "media@oneflare.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "greg@oneflare.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "billy@oneflare.com.au",
                    "firstName": "Billy",
                    "lastName": "Tucker",
                    "sourcePage": "https://www.linkedin.com/in/billytucker100",
                    "twitter": "billytucker100",
                    "isInCRM": {
                        "details": {
                            "id": "1449402",
                            "properties": {
                                "createdate": "2017-12-05T02:30:59.108Z",
                                "email": "billy@oneflare.com.au",
                                "firstname": null,
                                "hs_object_id": "1449402",
                                "lastmodifieddate": "2024-12-09T13:35:02.337Z",
                                "lastname": null
                            },
                            "createdAt": "2017-12-05T02:30:59.108Z",
                            "updatedAt": "2024-12-09T13:35:02.337Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "katie@oneflare.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "brett@oneflare.com.au",
                    "firstName": "Brett",
                    "lastName": "Kaplan",
                    "sourcePage": "https://www.linkedin.com/in/brett-kaplan-19651422",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "chobe@oneflare.com.au",
                    "position": "Head of Creative",
                    "firstName": "Chobe",
                    "lastName": "Keedle",
                    "sourcePage": "https://www.linkedin.com/in/chobe-keedle-035a9793",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "nathanael@oneflare.com.au",
                    "position": "Head of Product",
                    "firstName": "Nathanael",
                    "lastName": "van der Kolk",
                    "sourcePage": "https://www.linkedin.com/in/nathanael-van-der-kolk-31359420/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "ap@oneflare.com.au",
                    "position": "Senior Support Advisor/Training Consultant",
                    "firstName": "Prashansa",
                    "lastName": "Adhikary",
                    "sourcePage": "https://www.linkedin.com/in/prashansaadhikary",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Tool requirements : r/AusElectricians",
            "htmlTitle": "Tool requirements : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/um4gaw/tool_requirements/",
            "displayLink": "www.reddit.com",
            "snippet": "May 10, 2022 ... Community Info Section. r/AusElectricians. Join. Australian Electricians. Aussie Sparkies ... Best Electrician Tools. See more. Top Posts ...",
            "htmlSnippet": "May 10, 2022 <b>...</b> Community Info Section. r/AusElectricians. Join. Australian <b>Electricians</b>. Aussie Sparkies ... <b>Best Electrician</b> Tools. See more. <b>Top</b> Posts&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/AusElectricians/comments/.../tool_requirements/",
            "htmlFormattedUrl": "https://www.reddit.com/r/Aus<b>Electrician</b>s/comments/.../tool_requirements/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/um4gaw",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Tool requirements",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Tool requirements",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Ok_Discount_6241 - 4 votes and 15 comments",
                        "twitter:image": "https://share.redd.it/preview/post/um4gaw",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/um4gaw/tool_requirements/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/um4gaw"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best Rated Electricians Sydney | Electrician Reviews NSW",
            "htmlTitle": "<b>Best</b> Rated <b>Electricians Sydney</b> | <b>Electrician</b> Reviews NSW",
            "link": "https://mcintoshelectrical.com.au/about-us/customer-reviews/",
            "displayLink": "mcintoshelectrical.com.au",
            "snippet": "We're proud of our reputation for high-quality workmanship and exceptional service, including the outstanding electrician reviews we have across Sydney.",
            "htmlSnippet": "We&#39;re proud of our reputation for high-quality workmanship and exceptional service, including the outstanding <b>electrician</b> reviews we have across <b>Sydney</b>.",
            "formattedUrl": "https://mcintoshelectrical.com.au/about-us/customer-reviews/",
            "htmlFormattedUrl": "https://mcintoshelectrical.com.au/about-us/customer-reviews/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyVG9QZpGB_5brAwbPZdB4PSgXktgl926D4mghSGuavOaLFCknhffVXjI&s",
                        "width": "303",
                        "height": "166"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "McIntosh Electrical",
                        "handheldfriendly": "True",
                        "og:title": "Best Rated Electricians Sydney | Electrician Reviews NSW",
                        "msapplication-tileimage": "https://mcintoshelectrical.com.au/wp-content/uploads/2022/03/cropped-favicon-270x270.png",
                        "og:description": "Looking for best rated electricians in Sydney providing professional electrical services and installations. Call today at 1300 627 727.",
                        "facebook-domain-verification": "rleqflfvbhviat64qtmt2kwjwlzugd",
                        "article:modified_time": "2023-09-06T23:35:35+00:00",
                        "viewport": "width=device-width, initial-scale=1, maximum-scale=1",
                        "mobileoptimized": "320",
                        "og:locale": "en_US",
                        "og:url": "https://mcintoshelectrical.com.au/about-us/customer-reviews/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://mcintoshelectrical.com.au/wp-content/uploads/2022/03/fast-service.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@mcintoshelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "jenny@mcintoshelectrical.com.au",
                    "position": "Director",
                    "firstName": "Jenny",
                    "lastName": "McIntosh",
                    "companyName": "McIntosh Electrical Pty Ltd",
                    "sourcePage": "https://www.linkedin.com/in/jenny-mcintosh-01069236/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "brent@mcintoshelectrical.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "71556951",
                            "properties": {
                                "createdate": "2024-02-08T02:11:29.147Z",
                                "email": "brent@mcintoshelectrical.com.au",
                                "firstname": "BRENT",
                                "hs_object_id": "71556951",
                                "lastmodifieddate": "2024-12-09T17:57:33.776Z",
                                "lastname": "MCINTOSH"
                            },
                            "createdAt": "2024-02-08T02:11:29.147Z",
                            "updatedAt": "2024-12-09T17:57:33.776Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Mature age apprenticeship : r/AusElectricians",
            "htmlTitle": "Mature age apprenticeship : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/147lody/mature_age_apprenticeship/",
            "displayLink": "www.reddit.com",
            "snippet": "Jun 12, 2023 ... ... electrical wholesalers will be your best bet. Upvote 2. Downvote ... Australian Electricians. Aussie Sparkies (Australian Electricians) ...",
            "htmlSnippet": "Jun 12, 2023 <b>...</b> ... electrical wholesalers will be your <b>best</b> bet. Upvote 2. Downvote ... Australian <b>Electricians</b>. Aussie Sparkies (Australian <b>Electricians</b>)&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/AusElectricians/.../mature_age_apprenticeship/",
            "htmlFormattedUrl": "https://www.reddit.com/r/Aus<b>Electrician</b>s/.../mature_age_apprenticeship/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/147lody",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Mature age apprenticeship",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Mature age apprenticeship",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Scramapple - 12 votes and 46 comments",
                        "twitter:image": "https://share.redd.it/preview/post/147lody",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/147lody/mature_age_apprenticeship/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/147lody"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Top Electrical and Electronic Engineering Universities in Australia ...",
            "htmlTitle": "<b>Top</b> Electrical and Electronic Engineering Universities in Australia ...",
            "link": "https://www.usnews.com/education/best-global-universities/australia/electrical-electronic-engineering",
            "displayLink": "www.usnews.com",
            "snippet": "Here are the best global universities for electrical and electronic engineering in Australia · University of Technology Sydney · University of Sydney ...",
            "htmlSnippet": "Here are the <b>best</b> global universities for electrical and electronic engineering in Australia &middot; University of Technology <b>Sydney</b> &middot; University of <b>Sydney</b>&nbsp;...",
            "formattedUrl": "https://www.usnews.com/education/best.../electrical-electronic-engineering",
            "htmlFormattedUrl": "https://www.usnews.com/education/<b>best</b>.../electrical-electronic-engineering",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy7LJ0B5Khp6zCaGMQfluvoa7RlIMPU-kTkrjucHaEPBktNELm7rammyg&s",
                        "width": "311",
                        "height": "162"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://media.beam.usnews.com/25/bd/c4fa18ca4abdbe0d8f48b21d1274/191007-globalstudents-stock.png",
                        "twitter:title": "Top Electrical and Electronic Engineering Universities in Australia | US News Best Global Universities",
                        "twitter:card": "summary_large_image",
                        "og:title": "Top Electrical and Electronic Engineering Universities in Australia | US News Best Global Universities",
                        "optimizely": "Optimizely is supported by this application but is not enabled for this page.",
                        "fb:pages": "5834919267",
                        "og:description": "See the U.S. News rankings for Electrical and Electronic Engineering among the top universities in Australia. Compare the academic programs at the world's best universities.",
                        "twitter:image": "https://media.beam.usnews.com/25/bd/c4fa18ca4abdbe0d8f48b21d1274/191007-globalstudents-stock.png",
                        "referrer": "origin",
                        "site": "bestglobaluniversities",
                        "fb:app_id": "5834919267",
                        "twitter:site": "@USNews",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
                        "zone": "rankings/australia/electricalelectronicengineering",
                        "twitter:description": "See the U.S. News rankings for Electrical and Electronic Engineering among the top universities in Australia. Compare the academic programs at the world's best universities."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://media.beam.usnews.com/25/bd/c4fa18ca4abdbe0d8f48b21d1274/191007-globalstudents-stock.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "webmaster@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "permissions@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyrightagent@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mutualfunds@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "stocks@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "financialadvisors@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "forbetter@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "admissionsplaybook@usnews.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "dhaynie@usnews.com",
                    "position": "Assistant Managing Editor, Cities and States",
                    "firstName": "Devon",
                    "lastName": "Haynie",
                    "companyName": "U.S. News",
                    "sourcePage": "https://www.linkedin.com/in/devon-haynie-27587b10",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Which type of Electrician earns the most in order? : r/AusElectricians",
            "htmlTitle": "Which type of <b>Electrician</b> earns the most in order? : r/AusElectricians",
            "link": "https://www.reddit.com/r/AusElectricians/comments/1fnfyoh/which_type_of_electrician_earns_the_most_in_order/",
            "displayLink": "www.reddit.com",
            "snippet": "Sep 23, 2024 ... The electrical trade is fucking massive, but also insanely competitive. Yeah, the money is around, but no one is going to pay a hack top dollar.",
            "htmlSnippet": "Sep 23, 2024 <b>...</b> The electrical trade is fucking massive, but also insanely competitive. Yeah, the money is around, but no one is going to pay a hack <b>top</b> dollar.",
            "formattedUrl": "https://www.reddit.com/.../AusElectricians/.../which_type_of_electrician_ea...",
            "htmlFormattedUrl": "https://www.reddit.com/.../Aus<b>Electrician</b>s/.../which_type_of_<b>electrician</b>_ea...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/1fnfyoh",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Which type of Electrician earns the most in order?",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Which type of Electrician earns the most in order?",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/Sxspect6 - No votes and 23 comments",
                        "twitter:image": "https://share.redd.it/preview/post/1fnfyoh",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/1fnfyoh/which_type_of_electrician_earns_the_most_in_order/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/1fnfyoh"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "about mark best",
            "htmlTitle": "about mark <b>best</b>",
            "link": "https://markbest.com.au/bio",
            "displayLink": "markbest.com.au",
            "snippet": "Best began his working life as an electrician in the gold mines of Western Australia before finding his true calling in the kitchen. He commenced a culinary ...",
            "htmlSnippet": "<b>Best</b> began his working life as an <b>electrician</b> in the gold mines of Western Australia before finding his true calling in the kitchen. He commenced a culinary&nbsp;...",
            "formattedUrl": "https://markbest.com.au/bio",
            "htmlFormattedUrl": "https://mark<b>best</b>.com.au/bio",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoo9fIjrcMbdonKAyRioMNo6w7JzC7xfCTBiysGuU6IQ-uAf3hQ-Dwwxw&s",
                        "width": "203",
                        "height": "249"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "http://static1.squarespace.com/static/58f6d7a6ff7c5042d67453e5/t/66f2631f19d6441960daf5c4/1727161119866/MARK_ROB_PALMER.jpg?format=1500w",
                        "og:type": "website",
                        "og:image:width": "1500",
                        "twitter:title": "ABOUT — about mark best",
                        "twitter:card": "summary",
                        "og:site_name": "about mark best",
                        "twitter:url": "https://markbest.com.au/bio",
                        "og:title": "ABOUT — about mark best",
                        "og:image:height": "1842",
                        "og:description": "information about Mark Best&nbsp;",
                        "twitter:image": "http://static1.squarespace.com/static/58f6d7a6ff7c5042d67453e5/t/66f2631f19d6441960daf5c4/1727161119866/MARK_ROB_PALMER.jpg?format=1500w",
                        "viewport": "width=device-width,initial-scale=1",
                        "twitter:description": "information about Mark Best&nbsp;",
                        "og:url": "https://markbest.com.au/bio"
                    }
                ],
                "cse_image": [
                    {
                        "src": "http://static1.squarespace.com/static/58f6d7a6ff7c5042d67453e5/t/66f2631f19d6441960daf5c4/1727161119866/MARK_ROB_PALMER.jpg?format=1500w"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Being a sparky? : r/sydney",
            "htmlTitle": "Being a sparky? : r/<b>sydney</b>",
            "link": "https://www.reddit.com/r/sydney/comments/51odf5/being_a_sparky/",
            "displayLink": "www.reddit.com",
            "snippet": "Sep 8, 2016 ... Australian Electricians are also regarded as the best trained in the world so getting a job overseas is very possible. If you have trouble ...",
            "htmlSnippet": "Sep 8, 2016 <b>...</b> Australian <b>Electricians</b> are also regarded as the <b>best</b> trained in the world so getting a job overseas is very possible. If you have trouble&nbsp;...",
            "formattedUrl": "https://www.reddit.com/r/sydney/comments/51odf5/being_a_sparky/",
            "htmlFormattedUrl": "https://www.reddit.com/r/<b>sydney</b>/comments/51odf5/being_a_sparky/",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/51odf5",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/sydney on Reddit: Being a sparky?",
                        "og:site_name": "Reddit",
                        "og:title": "r/sydney on Reddit: Being a sparky?",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/naniwanabu - 32 votes and 54 comments",
                        "twitter:image": "https://share.redd.it/preview/post/51odf5",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/sydney/comments/51odf5/being_a_sparky/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/51odf5"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best Electricians in Monterey | Fast & Trusted Service",
            "htmlTitle": "<b>Best Electricians</b> in Monterey | Fast &amp; Trusted Service",
            "link": "https://www.adselectrical.com.au/service-area/electrician-monterey",
            "displayLink": "www.adselectrical.com.au",
            "snippet": "We specialize in a range of services, including lighting installations, switchboard upgrades, electric car charger setups, and emergency electrical repairs.",
            "htmlSnippet": "We specialize in a range of services, including lighting installations, switchboard upgrades, electric car charger setups, and emergency electrical repairs.",
            "formattedUrl": "https://www.adselectrical.com.au/service-area/electrician-monterey",
            "htmlFormattedUrl": "https://www.adselectrical.com.au/service-area/<b>electrician</b>-monterey",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTox5CDmkhL8xly1QCijUaGpXOxZsDeZJaY_wCEkNJb4tWVWUWQXvyxCtc&s",
                        "width": "285",
                        "height": "177"
                    }
                ],
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.adselectrical.com.au/_next/image?url=%2Fassets%2Fservice-location.png&w=828&q=75"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Best ways to become licensed with out being an apprentice : r ...",
            "htmlTitle": "<b>Best</b> ways to become licensed with out being an apprentice : r ...",
            "link": "https://www.reddit.com/r/AusElectricians/comments/1aj137l/best_ways_to_become_licensed_with_out_being_an/",
            "displayLink": "www.reddit.com",
            "snippet": "Feb 4, 2024 ... In NSW, you have 4 options to become an electrician; however the ... I would definitely recommend Ultimo TAFE for the best Electrical trades ...",
            "htmlSnippet": "Feb 4, 2024 <b>...</b> In NSW, you have 4 options to become an <b>electrician</b>; however the ... I would definitely recommend Ultimo TAFE for the <b>best</b> Electrical trades&nbsp;...",
            "formattedUrl": "https://www.reddit.com/.../AusElectricians/.../best_ways_to_become_license...",
            "htmlFormattedUrl": "https://www.reddit.com/.../Aus<b>Electrician</b>s/.../<b>best</b>_ways_to_become_license...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://share.redd.it/preview/post/1aj137l",
                        "theme-color": "#000000",
                        "og:image:width": "1200",
                        "og:type": "website",
                        "og:image:alt": "An image containing a preview of the post",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "r/AusElectricians on Reddit: Best ways to become licensed with out being an apprentice",
                        "og:site_name": "Reddit",
                        "og:title": "r/AusElectricians on Reddit: Best ways to become licensed with out being an apprentice",
                        "og:image:height": "630",
                        "msapplication-navbutton-color": "#000000",
                        "og:description": "Posted by u/No_Level_5825 - No votes and 95 comments",
                        "twitter:image": "https://share.redd.it/preview/post/1aj137l",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "twitter:site": "@reddit",
                        "viewport": "width=device-width, initial-scale=1, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "og:ttl": "600",
                        "og:url": "https://www.reddit.com/r/AusElectricians/comments/1aj137l/best_ways_to_become_licensed_with_out_being_an/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://share.redd.it/preview/post/1aj137l"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Here@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "licensing@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "security@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "912@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertising@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mobilesupport@reddit.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best Electrical Company | MrSwitch Sydney",
            "htmlTitle": "<b>Best</b> Electrical Company | MrSwitch <b>Sydney</b>",
            "link": "https://www.mrswitch.com.au/",
            "displayLink": "www.mrswitch.com.au",
            "snippet": "We have a range of professional licensed electricians in Sydney providing quality electrical work to Sydney residents and Sydney Businesses.",
            "htmlSnippet": "We have a range of professional licensed <b>electricians</b> in <b>Sydney</b> providing quality electrical work to <b>Sydney</b> residents and <b>Sydney</b> Businesses.",
            "formattedUrl": "https://www.mrswitch.com.au/",
            "htmlFormattedUrl": "https://www.mrswitch.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsL_fZA0-XNSwXjWdBMiKDY6H1kzRSxIv217xGL12EVM0uWeLF5TXUZpw&s",
                        "width": "215",
                        "height": "159"
                    }
                ],
                "aggregaterating": [
                    {
                        "reviewcount": "226",
                        "ratingvalue": "5",
                        "name": "Mr Switch Electrical",
                        "worstrating": "0",
                        "bestrating": "5"
                    }
                ],
                "postaladdress": [
                    {
                        "addresslocality": "Sydney",
                        "postalcode": "2000",
                        "addressregion": "NSW",
                        "streetaddress": "75 King St"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Best Electrical Company | MrSwitch Sydney",
                        "og:site_name": "Mr Switch -",
                        "viewport": "width=device-width",
                        "twitter:description": "Visit our website to learn more about the top electrical company's services, as well as its main features and advantages.",
                        "og:title": "Best Electrical Company | MrSwitch Sydney",
                        "og:locale": "en_US",
                        "norton-safeweb-site-verification": "hrf27sfu2l0-0993jp7bw8pthrrph1r2qo913o9d5i4-5azx9xg7lbxhxw-mbkhe-i9vgkp6ijamd343yi1sm2zrhryp1nd3yw2caeal4rcvm2g2dtv8jend78zvmn6s",
                        "og:url": "https://www.mrswitch.com.au/",
                        "og:description": "Visit our website to learn more about the top electrical company's services, as well as its main features and advantages."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.mrswitch.com.au/home/wp-content/uploads/2014/01/logo.png"
                    }
                ],
                "localbusiness": [
                    {
                        "name": "Mr Switch Electrician Sydney",
                        "telephone": "1300 128 432",
                        "email": "info@mrswitch.com.au",
                        "url": "https://www.mrswitch.com.au/"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "info@mrswitch.com.au",
                    "position": "Electrical Contractors",
                    "firstName": "Mr Switch",
                    "lastName": "Electrical",
                    "companyName": "Mr Switch Electrical",
                    "sourcePage": "https://au.linkedin.com/in/mr-switch-electrical-36176784/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Marketing@mrswitch.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "annie@mrswitch.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "anthony@mrswitch.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "83451",
                            "properties": {
                                "createdate": "2017-04-24T05:01:08.638Z",
                                "email": "anthony@mrswitch.com.au",
                                "firstname": "Anthony",
                                "hs_object_id": "83451",
                                "lastmodifieddate": "2024-12-09T13:27:48.682Z",
                                "lastname": null
                            },
                            "createdAt": "2017-04-24T05:01:08.638Z",
                            "updatedAt": "2024-12-09T13:27:48.682Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "accounts@mrswitch.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "688852",
                            "properties": {
                                "createdate": "2017-09-06T05:42:26.157Z",
                                "email": "accounts@mrswitch.com.au",
                                "firstname": "Dan",
                                "hs_object_id": "688852",
                                "lastmodifieddate": "2024-12-09T13:32:31.503Z",
                                "lastname": "Connors Finance Controller"
                            },
                            "createdAt": "2017-09-06T05:42:26.157Z",
                            "updatedAt": "2024-12-09T13:32:31.503Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Digital Multimeters | Sydney Tools",
            "htmlTitle": "Digital Multimeters | <b>Sydney</b> Tools",
            "link": "https://sydneytools.co.nz/category/electrical/multimeters",
            "displayLink": "sydneytools.co.nz",
            "snippet": "A multimeter is an essential bit of kit in every electrician's arsenal. And for reliable and accurate readings – you want the best kit ...",
            "htmlSnippet": "A multimeter is an essential bit of kit in every <b>electrician&#39;s</b> arsenal. And for reliable and accurate readings – you want the <b>best</b> kit&nbsp;...",
            "formattedUrl": "https://sydneytools.co.nz/category/electrical/multimeters",
            "htmlFormattedUrl": "https://<b>sydney</b>tools.co.nz/category/electrical/multimeters",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYqEfYDnZdjyd8fIUy5Dnq8FiLyD6GCnyjinCTe5KWL5SmSSZxdIANLOF9&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "msapplication-tilecolor": "#00aba9",
                        "theme-color": "#cb0000",
                        "og:site_name": "Sydney Tools",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_NZ"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://sydneytools.co.nz/assets/images/products/1/2/7/6/12766/CD558A667C220B829AB315EB4FC1B40679EF3458C40B8F98E9F338E36FD9AC1B/512x512/CD558A667C220B829AB315EB4FC1B40679EF3458C40B8F98E9F338E36FD9AC1B.jpeg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "kevins@sydneytools.co.nz",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrician in Sydney – Electrician in Sydney",
            "htmlTitle": "<b>Electrician</b> in <b>Sydney</b> – <b>Electrician</b> in <b>Sydney</b>",
            "link": "https://www.sydneyelectrician247.com/",
            "displayLink": "www.sydneyelectrician247.com",
            "snippet": "We are one of the top electrical installation companies in Sydney that offers a variety of electrical services, including installation, maintenance, and repairs ...",
            "htmlSnippet": "We are one of the <b>top</b> electrical installation companies in <b>Sydney</b> that offers a variety of electrical services, including installation, maintenance, and repairs&nbsp;...",
            "formattedUrl": "https://www.sydneyelectrician247.com/",
            "htmlFormattedUrl": "https://www.<b>sydneyelectrician</b>247.com/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSktQZE7yH2eQ8Oube4E5TGCwoD3ZtqmSX1Zann73MC8MzaDKw5eJk-WUQ&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1",
                        "msapplication-tileimage": "https://www.sydneyelectrician247.com/wp-content/uploads/2023/02/cropped-Mask-group-270x270.png"
                    }
                ],
                "creativework": [
                    {
                        "text": "Electrician in Sydney Whether it’s a commercial, residential, or industrial emergency, we have set up a division to handle it. Having electricity is essential in a city that operates 24 hours..."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.sydneyelectrician247.com/wp-content/uploads/2023/02/Electrical-Service.png"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Best Electrician in Sydney Inner City | Service Heroes",
            "htmlTitle": "<b>Best Electrician</b> in <b>Sydney</b> Inner City | Service Heroes",
            "link": "https://www.serviceheroes.com.au/electrician/sydney-inner-city-electricians/",
            "displayLink": "www.serviceheroes.com.au",
            "snippet": "Service Heroes is one of the best electricians in Sydney's inner city. We are here to assist you with all your household and commercial electrical needs!",
            "htmlSnippet": "Service Heroes is one of the <b>best electricians</b> in <b>Sydney&#39;s</b> inner city. We are here to assist you with all your household and commercial electrical needs!",
            "formattedUrl": "https://www.serviceheroes.com.au/electrician/sydney-inner-city-electricians/",
            "htmlFormattedUrl": "https://www.serviceheroes.com.au/<b>electrician</b>/<b>sydney</b>-inner-city-<b>electrician</b>s/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmAuABD4jv8QmcOa9GTYXxSxu4jYALAG6Z4-fDD3Cq-4xK4ns0YRuB_zfP&s",
                        "width": "296",
                        "height": "170"
                    }
                ],
                "metatags": [
                    {
                        "p:domain_verify": "baf12575a1d14b50f7c9a820b2947fc0",
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Service Heroes",
                        "viewport": "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
                        "og:title": "Best Electrician in Sydney Inner City | Service Heroes",
                        "og:locale": "en_US",
                        "og:url": "https://www.serviceheroes.com.au/electrician/sydney-inner-city-electricians/",
                        "msapplication-tileimage": "https://cdn.serviceheroes.com.au/wp-content/uploads/2021/11/cropped-fav-300x300.png",
                        "theme-url": "https://www.serviceheroes.com.au/wp-content/themes/serviceheroes",
                        "og:description": "Service Heroes is one of the best electricians in Sydney's inner city. We are here to assist you with all your household and commercial electrical needs!"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.serviceheroes.com.au/wp-content/themes/serviceheroes/assets/images/logo.svg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "info@serviceheroes.com.au",
                    "firstName": "Niv",
                    "lastName": "Sharon",
                    "sourcePage": "https://www.linkedin.com/in/10101975/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "hiring@serviceheroes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "annie@serviceheroes.com.au",
                    "position": "Co-Founder",
                    "firstName": "Annie",
                    "lastName": "Bedrosian",
                    "sourcePage": "https://www.linkedin.com/in/annie-bedrosian-77785215/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "alex@serviceheroes.com.au",
                    "position": "Warehouse Manager",
                    "firstName": "Alex",
                    "lastName": "Bedrosian",
                    "sourcePage": "https://www.linkedin.com/in/alex-bedrosian-b94a85184",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "filip@serviceheroes.com.au",
                    "position": "Business Development Manager",
                    "firstName": "Filip",
                    "lastName": "Cernok",
                    "sourcePage": "https://www.linkedin.com/in/filip-cernok-9208a5112",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Joanne@serviceheroes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "anthony@serviceheroes.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "688801",
                            "properties": {
                                "createdate": "2017-09-06T05:42:25.482Z",
                                "email": "anthony@serviceheroes.com.au",
                                "firstname": "Anthony",
                                "hs_object_id": "688801",
                                "lastmodifieddate": "2024-12-09T13:32:25.837Z",
                                "lastname": null
                            },
                            "createdAt": "2017-09-06T05:42:25.482Z",
                            "updatedAt": "2024-12-09T13:32:25.837Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "accounts@serviceheroes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Hashtags for #sydneyelectrician to grow your Instagram, TikTok ...",
            "htmlTitle": "Hashtags for #<b>sydneyelectrician</b> to grow your Instagram, TikTok ...",
            "link": "https://best-hashtags.com/hashtag/sydneyelectrician/",
            "displayLink": "best-hashtags.com",
            "snippet": "... electrician #plumbing #pipesupportsystems #building #strutchannel #electricians #plumber #sydney ... Best #sydneyelectrician hashtags. Grow your instagram using ...",
            "htmlSnippet": "... <b>electrician</b> #plumbing #pipesupportsystems #building #strutchannel #<b>electricians</b> #plumber #<b>sydney</b> ... <b>Best</b> #<b>sydneyelectrician</b> hashtags. Grow your instagram using&nbsp;...",
            "formattedUrl": "https://best-hashtags.com/hashtag/sydneyelectrician/",
            "htmlFormattedUrl": "https://<b>best</b>-hashtags.com/hashtag/<b>sydneyelectrician</b>/",
            "pagemap": {
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1.0"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "dobeid@best-hashtags.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "35 BEST local Electricians in Sydney, NSW | Yellow Pages®",
            "htmlTitle": "35 <b>BEST</b> local <b>Electricians</b> in <b>Sydney</b>, NSW | Yellow Pages®",
            "link": "https://www.yellowpages.com.au/find/electricians-electrical-contractors/sydney-nsw-2000",
            "displayLink": "www.yellowpages.com.au",
            "snippet": "Williams Electrical has over 15 years experience in electrical services and offers expert advice, personalised, friendly service, and competitive quotes.",
            "htmlSnippet": "Williams Electrical has over 15 years experience in electrical services and offers expert advice, personalised, friendly service, and competitive quotes.",
            "formattedUrl": "https://www.yellowpages.com.au/find/electricians.../sydney-nsw-2000",
            "htmlFormattedUrl": "https://www.yellowpages.com.au/find/<b>electrician</b>s.../<b>sydney</b>-nsw-2000",
            "pagemap": {
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no",
                        "format-detection": "telephone=no"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "quotes@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "kerin.heatley@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "herman.cordellierwever@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "keith.edwards@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "melanie.cox@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "j.r@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "david.peirce@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "amber.rae@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "maria.mccarthy@yellowpages.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Sydney's Best Industrial Electricians | Robycs Technology",
            "htmlTitle": "<b>Sydney&#39;s Best</b> Industrial <b>Electricians</b> | Robycs Technology",
            "link": "https://robycstechnology.com.au/industrial-electrician-sydney/",
            "displayLink": "robycstechnology.com.au",
            "snippet": "Sydney's Industrial Electrican Specialists. Robycs Technology has been serving Sydney businesses as a leading Siemens Automation Industrial Electrical ...",
            "htmlSnippet": "<b>Sydney&#39;s</b> Industrial Electrican Specialists. Robycs Technology has been serving <b>Sydney</b> businesses as a leading Siemens Automation Industrial Electrical&nbsp;...",
            "formattedUrl": "https://robycstechnology.com.au/industrial-electrician-sydney/",
            "htmlFormattedUrl": "https://robycstechnology.com.au/industrial-<b>electrician</b>-<b>sydney</b>/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCsc8tmAEU7ffzcutQ20wsFrGHkHwJmTJyqxPV0JushzzwKCPJnw1H9lF3&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "imageobject": [
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/2019/11/RT-Rect-Compress-Copy.png",
                        "url": "https://robycstechnology.com.au/"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/futuro-next-icons-043-tools.jpg",
                        "url": "https://robycstechnology.com.au/industrial-electrician-sydney/electrical-maintenance/"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/futuro-next-icons-043-instrument.jpg",
                        "url": "https://robycstechnology.com.au/plc-programming/"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/futuro-next-icons-087-box.jpg",
                        "url": "https://robycstechnology.com.au/industrial-electrician-sydney/engineering-consultants/"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/bb-plugin/cache/Automation-Sydney-circle-3b77ff85682ae0cba73612df373fd7c8-5edda3e6d3187.jpg"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/RT-Rect-Compress-Copy-Copy.png"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/JS-Automation.png",
                        "url": "https://www.jsautomation.com.au/"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/KUKA-System-Partner-600x138.jpg"
                    },
                    {
                        "image": "https://robycstechnology.com.au/wp-content/uploads/6-D0013d-APS-SIEMENS-System-Integrator-Program-Logo-600x188.png"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://robycstechnology.com.au/wp-content/uploads/PLC-Programming-scaled.jpg",
                        "og:type": "activity",
                        "twitter:card": "summary",
                        "twitter:title": "Sydney's Best Industrial Electricians | Robycs Technology",
                        "og:site_name": "Robycs Technology | Full Service Automation Solutions, Robotic Automation and Industrial Electrician Support",
                        "og:title": "Sydney's Best Industrial Electricians | Robycs Technology",
                        "msapplication-tileimage": "https://robycstechnology.com.au/wp-content/uploads/logo-8.png",
                        "og:description": "Full support for all Industrial Electrician services in Sydney. Maintenance, MCC design, installation, electrical engineering for manufacturing, processing",
                        "og:image:secure_url": "https://robycstechnology.com.au/wp-content/uploads/PLC-Programming-scaled.jpg",
                        "twitter:image": "https://robycstechnology.com.au/wp-content/uploads/PLC-Programming-scaled.jpg",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Full support for all Industrial Electrician services in Sydney. Maintenance, MCC design, installation, electrical engineering for manufacturing, processing",
                        "og:locale": "en_US",
                        "og:url": "https://robycstechnology.com.au/industrial-electrician-sydney/"
                    }
                ],
                "creativework": [
                    {
                        "text": "Sydney's Best Industrial Electricians Expert support for your business. We fix the systems other technicians can't. Call Now For Priority Service 1300 525 447 Industrial Electrical Maintenance..."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://robycstechnology.com.au/wp-content/uploads/PLC-Programming-scaled.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "info@robycstechnology.com.au",
                    "position": "CEO/Director",
                    "firstName": "Raymond",
                    "lastName": "Sanchez",
                    "companyName": "Robycs Technology",
                    "sourcePage": "https://www.linkedin.com/in/raymond-sanchez-1a8a3827/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "buddy.n@robycstechnology.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "ray@robycstechnology.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "47397064",
                            "properties": {
                                "createdate": "2021-11-08T02:11:28.945Z",
                                "email": "ray@robycstechnology.com.au",
                                "firstname": "Raymond",
                                "hs_object_id": "47397064",
                                "lastmodifieddate": "2024-12-09T16:01:36.028Z",
                                "lastname": "Sanchez"
                            },
                            "createdAt": "2021-11-08T02:11:28.945Z",
                            "updatedAt": "2024-12-09T16:01:36.028Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "raymond.z@robycstechnology.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Top Companies Hiring for Electrician Jobs in Australia | Glassdoor",
            "htmlTitle": "<b>Top</b> Companies Hiring for <b>Electrician</b> Jobs in Australia | Glassdoor",
            "link": "https://www.glassdoor.com/Explore/top-electrician-companies-australia_IO.4,15_IL.26,35_IN16.htm",
            "displayLink": "www.glassdoor.com",
            "snippet": "Top Companies in Australia Hiring for Electrician Jobs · Filter Companies · Rio Tinto · BHP · Hays · Downer · Woolworths Group · SelfEmployed.com · Sydney ...",
            "htmlSnippet": "<b>Top</b> Companies in Australia Hiring for <b>Electrician</b> Jobs &middot; Filter Companies &middot; Rio Tinto &middot; BHP &middot; Hays &middot; Downer &middot; Woolworths Group &middot; SelfEmployed.com &middot; <b>Sydney</b>&nbsp;...",
            "formattedUrl": "https://www.glassdoor.com/.../top-electrician-companies-australia_IO.4,15_...",
            "htmlFormattedUrl": "https://www.glassdoor.com/.../top-<b>electrician</b>-companies-australia_IO.4,15_...",
            "pagemap": {
                "metatags": [
                    {
                        "referrer": "origin",
                        "og:image": "https://www.glassdoor.com/explore/static/images/brand-campaign-image.png",
                        "theme-color": "#0caa41",
                        "og:type": "website",
                        "apple-mobile-web-app-status-bar-style": "#0caa41",
                        "og:site_name": "Glassdoor",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "apple-mobile-web-app-capable": "yes",
                        "og:title": "Top Companies Hiring for Electrician Jobs in Australia",
                        "bingbot": "nocache",
                        "msapplication-navbutton-color": "#0caa41",
                        "og:description": "Browse the top companies hiring for Electrician jobs in Australia. Salaries, reviews, and more - all posted by employees working at the top companies hiring for Electrician jobs in Australia. Apply to jobs near you."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.glassdoor.com/explore/static/images/brand-campaign-image.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "testimonials@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "design-publication@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "bestplacestowork@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "blog@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "domains@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "feedback@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "pr@glassdoor.com",
                    "position": "Real Estate Specialist",
                    "firstName": "Roxana",
                    "lastName": "Pelin",
                    "companyName": "Glassdoor",
                    "sourcePage": "https://www.linkedin.com/in/roxana-pelin-25a12852",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "KAhlering@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "feedback-de@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "research@glassdoor.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "BEST Electrician Near You - Sydney Electricians Near Me ...",
            "htmlTitle": "<b>BEST Electrician</b> Near You - <b>Sydney Electricians</b> Near Me ...",
            "link": "https://www.networkplumbing.com.au/sydney-electricians-near-you",
            "displayLink": "www.networkplumbing.com.au",
            "snippet": "Top-rated Sydney electricians delivering a full range of services, from wiring and panel upgrades to solar installations and more.",
            "htmlSnippet": "<b>Top</b>-rated <b>Sydney electricians</b> delivering a full range of services, from wiring and panel upgrades to solar installations and more.",
            "formattedUrl": "https://www.networkplumbing.com.au/sydney-electricians-near-you",
            "htmlFormattedUrl": "https://www.networkplumbing.com.au/<b>sydney</b>-<b>electrician</b>s-near-you",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuWOITmd5Ru6tJtZd4lQouROCVzylaRmbfS3NJqTcYAtZT1ITSxi_esiCC&s",
                        "width": "349",
                        "height": "144"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://irp.cdn-website.com/9e44b5d8/dms3rep/multi/Network-Logo_with_no_outline+%281%29.svg",
                        "og:type": "website",
                        "twitter:card": "summary",
                        "twitter:title": "BEST Electrician Near You - Sydney Electricians Near Me - Electrical Services in Sydney - Call Now for Quality Service",
                        "viewport": "width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, viewport-fit=cover",
                        "apple-mobile-web-app-capable": "yes",
                        "twitter:description": "Top-rated Sydney electricians delivering a full range of services, from wiring and panel upgrades to solar installations and more. Rely on Network Plumbing’s expert team for dependable repairs, ongoing maintenance, and advanced electrical solutions across Sydney and nearby suburbs. Get in touch with us today!",
                        "og:title": "BEST Electrician Near You - Sydney Electricians Near Me - Electrical Services in Sydney - Call Now for Quality Service",
                        "og:description": "Top-rated Sydney electricians delivering a full range of services, from wiring and panel upgrades to solar installations and more. Rely on Network Plumbing’s expert team for dependable repairs, ongoing maintenance, and advanced electrical solutions across Sydney and nearby suburbs. Get in touch with us today!",
                        "twitter:image": "https://irp.cdn-website.com/9e44b5d8/dms3rep/multi/Network-Logo_with_no_outline+%281%29.svg"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://irp.cdn-website.com/9e44b5d8/dms3rep/multi/Network-Logo_with_no_outline+%281%29.svg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "vicky@networkplumbing.com.au",
                    "position": "Manager",
                    "firstName": "Vicky",
                    "lastName": "Irwin",
                    "companyName": "Network Plumbing",
                    "sourcePage": "https://www.linkedin.com/in/vicky-irwin-56a61712",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "sandy@networkplumbing.com.au",
                    "position": "Dispatch Coordinator",
                    "firstName": "Sandy",
                    "lastName": "Dempster",
                    "companyName": "Network Plumbing",
                    "sourcePage": "https://www.linkedin.com/in/sandy-dempster-52a43a46",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "rod@networkplumbing.com.au",
                    "position": "Warehouse Manager",
                    "firstName": "Rod",
                    "lastName": "Hanley",
                    "companyName": "Network Plumbing",
                    "sourcePage": "https://www.linkedin.com/in/rod-hanley-a91b054a",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "scott@networkplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "hannah@networkplumbing.com.au",
                    "firstName": "Hannah",
                    "lastName": "Krix",
                    "companyName": "Network Plumbing",
                    "sourcePage": "https://www.linkedin.com/in/hannah-krix-457b0546",
                    "isInCRM": {
                        "details": {
                            "id": "25773401",
                            "properties": {
                                "createdate": "2020-04-28T00:56:49.530Z",
                                "email": "hannah@networkplumbing.com.au",
                                "firstname": "Hannah",
                                "hs_object_id": "25773401",
                                "lastmodifieddate": "2024-12-09T14:46:05.620Z",
                                "lastname": "Krix"
                            },
                            "createdAt": "2020-04-28T00:56:49.530Z",
                            "updatedAt": "2024-12-09T14:46:05.620Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "service@networkplumbing.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "23344901",
                            "properties": {
                                "createdate": "2019-12-17T05:17:34.542Z",
                                "email": "service@networkplumbing.com.au",
                                "firstname": null,
                                "hs_object_id": "23344901",
                                "lastmodifieddate": "2024-12-09T14:31:54.906Z",
                                "lastname": null
                            },
                            "createdAt": "2019-12-17T05:17:34.542Z",
                            "updatedAt": "2024-12-09T14:31:54.906Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "richard@networkplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "bruce@networkplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "rachelz@networkplumbing.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "34923151",
                            "properties": {
                                "createdate": "2021-02-16T04:51:44.183Z",
                                "email": "rachelz@networkplumbing.com.au",
                                "firstname": "rachel",
                                "hs_object_id": "34923151",
                                "lastmodifieddate": "2024-12-09T15:15:25.198Z",
                                "lastname": "Zanejic"
                            },
                            "createdAt": "2021-02-16T04:51:44.183Z",
                            "updatedAt": "2024-12-09T15:15:25.198Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "kent@networkplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "What Are the Best Qualities of a Mobile Auto Electrician?",
            "htmlTitle": "What Are the <b>Best</b> Qualities of a Mobile Auto <b>Electrician</b>?",
            "link": "https://www.sydneyroadside.com/what-are-the-best-qualities-of-a-mobile-auto-electrician/",
            "displayLink": "www.sydneyroadside.com",
            "snippet": "Jul 25, 2023 ... A mobile auto electrician in Sydney is expected to be in charge of assessing every electrical and electronic spare parts and gadgets that are connected to a ...",
            "htmlSnippet": "Jul 25, 2023 <b>...</b> A mobile auto <b>electrician</b> in <b>Sydney</b> is expected to be in charge of assessing every electrical and electronic spare parts and gadgets that are connected to a&nbsp;...",
            "formattedUrl": "https://www.sydneyroadside.com/what-are-the-best-qualities-of-a-mobile-a...",
            "htmlFormattedUrl": "https://www.<b>sydney</b>roadside.com/what-are-the-<b>best</b>-qualities-of-a-mobile-a...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqyt1HUey0oqSHyFwLVKyOgFCx6EGGUB2c-M0UUFiK57XURDJysyGDYcY&s",
                        "width": "493",
                        "height": "54"
                    }
                ],
                "thumbnail": [
                    {
                        "width": "100",
                        "sydneyroadside": "https://www.sydneyroadside.com/wp-content/uploads/2022/03/logo.png",
                        "height": "130"
                    },
                    {
                        "src": "https://www.sydneyroadside.com/wp-content/uploads/2022/03/logo.png"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.sydneyroadside.com/wp-content/uploads/2022/03/logo.png",
                        "thumbnail": "https://www.sydneyroadside.com/wp-content/uploads/2022/03/logo.png",
                        "og:type": "article",
                        "article:published_time": "2023-07-25T12:20:50+00:00",
                        "og:image:width": "675",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Sydney Roadside Rescue",
                        "author": "Rikyleo79",
                        "og:title": "What Are the Best Qualities of a Mobile Auto Electrician?",
                        "og:image:height": "375",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Est. reading time",
                        "og:image:type": "image/png",
                        "msapplication-tileimage": "https://www.sydneyroadside.com/wp-content/uploads/2022/03/favicon.png",
                        "og:description": "To excel an auto electrician needs to have certain qualities and you must hire the professional who has all these qualities and attributes to ensure you get the best service.",
                        "twitter:data1": "Rikyleo79",
                        "twitter:data2": "3 minutes",
                        "viewport": "width=device-width",
                        "og:locale": "en_US",
                        "og:url": "https://www.sydneyroadside.com/what-are-the-best-qualities-of-a-mobile-auto-electrician/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.sydneyroadside.com/wp-content/uploads/2022/03/logo.png"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "HJ Recruitment: Best Blue Collar Recruitment Agency Australia",
            "htmlTitle": "HJ Recruitment: <b>Best</b> Blue Collar Recruitment Agency Australia",
            "link": "https://www.hjrecruiting.com/",
            "displayLink": "www.hjrecruiting.com",
            "snippet": "Welcome to HJ Recruitment - Your trusted partner in HVAC and Electrical Recruitment. Bridging skilled professionals with standout employers nationwide.",
            "htmlSnippet": "Welcome to HJ Recruitment - Your trusted partner in HVAC and Electrical Recruitment. Bridging skilled professionals with standout employers nationwide.",
            "formattedUrl": "https://www.hjrecruiting.com/",
            "htmlFormattedUrl": "https://www.hjrecruiting.com/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4eyB5E4xYcOqUCCXKtT2GBo_G_JDCxw-L7EdUCRwk1wzPdMoc5Wx7_zNk&s",
                        "width": "163",
                        "height": "310"
                    }
                ],
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.prod.website-files.com/64fc48f31a4d9631cd0b7df0/65211b360bb7ead8147295bf_Artboard%201-cropped.svg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "harvey@hjrecruiting.com",
                    "position": "Chief Executive Officer",
                    "firstName": "Harvey",
                    "lastName": "Jutton",
                    "companyName": "HJ Recruitment",
                    "sourcePage": "https://www.linkedin.com/in/harvey-jutton-a840ab268/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "theresa@hjrecruiting.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Tradesmen around the world: Which country pays the most ...",
            "htmlTitle": "Tradesmen around the world: Which country pays the most ...",
            "link": "https://www.buildingtalk.com/tradesmen-around-the-world-which-country-pays-the-most/",
            "displayLink": "www.buildingtalk.com",
            "snippet": "Aug 23, 2017 ... Some of the best cities in America for plumbers are as follows: ... In Australia, electricians earn on average $81,536 (£48,631) or ...",
            "htmlSnippet": "Aug 23, 2017 <b>...</b> Some of the <b>best</b> cities in America for plumbers are as follows: ... In Australia, <b>electricians</b> earn on average $81,536 (£48,631) or&nbsp;...",
            "formattedUrl": "https://www.buildingtalk.com/tradesmen-around-the-world-which-country-...",
            "htmlFormattedUrl": "https://www.buildingtalk.com/tradesmen-around-the-world-which-country-...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyhmyg0ZG0YiREefLWoAwtM3pCHiFTsg4yS2_VhE1LEBqkPp70z8WaRA&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "article",
                        "article:published_time": "2017-08-23T10:15:37Z",
                        "twitter:card": "summary",
                        "twitter:title": "Tradesmen around the world: Which country pays the most? | Buildingtalk | Construction news and building products for specifiers",
                        "og:site_name": "Buildingtalk | Construction news and building products for specifiers |",
                        "twitter:domain": "www.buildingtalk.com",
                        "og:title": "Tradesmen around the world: Which country pays the most? | Buildingtalk | Construction news and building products for specifiers",
                        "category_0": "582",
                        "google": "nositelinkssearchbox",
                        "msapplication-tileimage": "https://www.buildingtalk.com/wp-content/uploads/cropped-btalk-icon-1-270x270.png",
                        "article:modified_time": "2017-09-13T11:10:11Z",
                        "category_3": "591",
                        "viewport": "user-scalable=yes, width=device-width, initial-scale=1.0, minimum-scale=1",
                        "category_2": "11",
                        "supplier": "null",
                        "category_1": "132",
                        "og:url": "https://www.buildingtalk.com/tradesmen-around-the-world-which-country-pays-the-most/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.buildingtalk.com/wp-content/uploads/Image1114.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "news@buildingtalk.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "antonyneedham@buildingtalk.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "About Us. Hills District Electrician: The Best Local Electricians Sydney",
            "htmlTitle": "About Us. Hills District <b>Electrician</b>: The <b>Best</b> Local <b>Electricians Sydney</b>",
            "link": "https://hillsdistrictelectrician.com.au/about-us",
            "displayLink": "hillsdistrictelectrician.com.au",
            "snippet": "We focus on delivering a personalised service throughout the Hills District of Sydney. Providing a trusted local service to the community and exceed customer ...",
            "htmlSnippet": "We focus on delivering a personalised service throughout the Hills District of <b>Sydney</b>. Providing a trusted local service to the community and exceed customer&nbsp;...",
            "formattedUrl": "https://hillsdistrictelectrician.com.au/about-us",
            "htmlFormattedUrl": "https://hillsdistrict<b>electrician</b>.com.au/about-us",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNlbbEfo8SynFRXcaQ4Jb0E-lEyYgUO8Dmfjp00qG5jx2_JEegbiuWxwte&s",
                        "width": "198",
                        "height": "254"
                    }
                ],
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://hillsdistrictelectrician.com.au/images/uploaded/Hill-District-Electrician-AboutUs.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "hello@hillsdistrictelectrician.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "What's your best Van storage... - Voltex Electrical Australia | Facebook",
            "htmlTitle": "What&#39;s your <b>best</b> Van storage... - Voltex Electrical Australia | Facebook",
            "link": "https://www.facebook.com/VoltexElectrical/posts/whats-your-best-van-storage-hack-share-an-image-of-your-simple-innovations-that-/1271518106357367/",
            "displayLink": "www.facebook.com",
            "snippet": "Jun 13, 2019 ... What's your best Van storage hack? Share an image of your simple innovations that have saved you hours!",
            "htmlSnippet": "Jun 13, 2019 <b>...</b> What&#39;s your <b>best</b> Van storage hack? Share an image of your simple innovations that have saved you hours!",
            "formattedUrl": "https://www.facebook.com/.../posts/...best...-/1271518106357367/",
            "htmlFormattedUrl": "https://www.facebook.com/.../posts/...<b>best</b>...-/1271518106357367/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWstJIZOumk2mpdBNJCGPmtKthbMdMMoUpxZipf6VyjGxfZEcs4kA5b2EH&s",
                        "width": "276",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://lookaside.fbsbx.com/lookaside/crawler/media/?media_id=1271518096357368",
                        "og:type": "video.other",
                        "og:image:alt": "Voltex Electrical Australia",
                        "twitter:card": "summary",
                        "twitter:title": "Voltex Electrical Australia",
                        "theme-color": "#FFFFFF",
                        "og:title": "Voltex Electrical Australia",
                        "google": "notranslate",
                        "og:description": "What's your best Van storage hack? Share an image of your simple innovations that have saved you hours! 😃",
                        "twitter:image": "https://lookaside.fbsbx.com/lookaside/crawler/media/?media_id=1271518096357368",
                        "referrer": "origin-when-crossorigin",
                        "twitter:image:alt": "Voltex Electrical Australia",
                        "twitter:site": "@facebookapp",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "What's your best Van storage hack? Share an image of your simple innovations that have saved you hours! 😃",
                        "og:locale": "en_US",
                        "fb-version": "438.0.0.0.29:2691",
                        "og:url": "https://www.facebook.com/VoltexElectrical/posts/whats-your-best-van-storage-hack-share-an-image-of-your-simple-innovations-that-/1271518106357367/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://lookaside.fbsbx.com/lookaside/crawler/media/?media_id=1271518096357368"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Electrical Brands We Work With - Electrician To The Rescue",
            "htmlTitle": "Electrical Brands We Work With - <b>Electrician</b> To The Rescue",
            "link": "https://www.electriciantotherescue.com.au/electrical-brands/",
            "displayLink": "www.electriciantotherescue.com.au",
            "snippet": "HPM is committed to bringing Australia modern and innovative electrical products that are not only safe and reliable, but will last years to come at the best ...",
            "htmlSnippet": "HPM is committed to bringing Australia modern and innovative electrical products that are not only safe and reliable, but will last years to come at the <b>best</b>&nbsp;...",
            "formattedUrl": "https://www.electriciantotherescue.com.au/electrical-brands/",
            "htmlFormattedUrl": "https://www.<b>electrician</b>totherescue.com.au/electrical-brands/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAYzcTnhJGf8ZRhn90zJofwIPtzpvm9Pvtw3ZwjVbUGci2jKztsNJ0-sEV&s",
                        "width": "224",
                        "height": "225"
                    }
                ],
                "imageobject": [
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2019/06/electrician-sydney-electrician-to-the-rescue-logo.png",
                        "url": "https://www.electriciantotherescue.com.au/"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2024/08/highly-rated-on-google.png",
                        "url": "https://www.electriciantotherescue.com.au/"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/04/electricians-support-cystic-fibrosis-nsw.png"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/05/Clipsal-Logo.jpg"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/05/HPM-Logo.jpg"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/05/Philips-Logo.jpg"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/05/Osram-Logo.jpg"
                    },
                    {
                        "image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/02/payment-methods-icons.png"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/04/electricians-support-cystic-fibrosis-nsw.png",
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Electrician To The Rescue",
                        "og:title": "Electrical Brands We Work With - Electrician To The Rescue",
                        "twitter:label1": "Est. reading time",
                        "msapplication-tileimage": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/03/cropped-favicon-270x270.jpg",
                        "og:description": "Brands we work with The best electricians only use the best electrical brands Here at Electrician To The Rescue, we believe in quality! Quality electrical service and quality electrical products. Which is why we use only the highest-grade certified components and products in our electrical services, when repairing and installing electrical products in your home.…",
                        "twitter:data1": "4 minutes",
                        "article:modified_time": "2020-05-13T05:27:11+00:00",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "og:locale": "en_US",
                        "og:url": "https://www.electriciantotherescue.com.au/electrical-brands/"
                    }
                ],
                "webpage": [
                    {
                        "maincontentofpage": "Brands we work with The best electricians only use the best electrical brands Here at Electrician To The Rescue, we believe in quality! Quality electrical service and quality electrical products...."
                    }
                ],
                "creativework": [
                    {
                        "text": "Brands we work with The best electricians only use the best electrical brands Here at Electrician To The Rescue, we believe in quality! Quality electrical service and quality electrical products...."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.electriciantotherescue.com.au/wp-content/uploads/2020/04/electricians-support-cystic-fibrosis-nsw.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobs@electriciantotherescue.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "23598901",
                            "properties": {
                                "createdate": "2020-01-07T21:34:56.641Z",
                                "email": "jobs@electriciantotherescue.com.au",
                                "firstname": "Jobs",
                                "hs_object_id": "23598901",
                                "lastmodifieddate": "2024-12-09T14:32:25.016Z",
                                "lastname": null
                            },
                            "createdAt": "2020-01-07T21:34:56.641Z",
                            "updatedAt": "2024-12-09T14:32:25.016Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@electriciantotherescue.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "61214568349",
                            "properties": {
                                "createdate": "2024-09-24T04:24:08.516Z",
                                "email": "info@electriciantotherescue.com.au",
                                "firstname": null,
                                "hs_object_id": "61214568349",
                                "lastmodifieddate": "2024-12-09T20:04:18.471Z",
                                "lastname": null
                            },
                            "createdAt": "2024-09-24T04:24:08.516Z",
                            "updatedAt": "2024-12-09T20:04:18.471Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "talebs@electriciantotherescue.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Reasons to move to Australia as an Electrician - Visa Go Australia",
            "htmlTitle": "Reasons to move to Australia as an <b>Electrician</b> - Visa Go Australia",
            "link": "https://visa-go.com/blog/2022/07/21/reasons-to-move-to-australia-as-an-electrician/",
            "displayLink": "visa-go.com",
            "snippet": "Jul 21, 2022 ... Australia offers opportunities for electricians to earn good wages, and in turn, an amazing lifestyle! Higher wages can offer the freedom to work less hours.",
            "htmlSnippet": "Jul 21, 2022 <b>...</b> Australia offers opportunities for <b>electricians</b> to earn <b>good</b> wages, and in turn, an amazing lifestyle! Higher wages can offer the freedom to work less hours.",
            "formattedUrl": "https://visa-go.com/blog/.../reasons-to-move-to-australia-as-an-electrician/",
            "htmlFormattedUrl": "https://visa-go.com/blog/.../reasons-to-move-to-australia-as-an-<b>electrician</b>/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0CjHcMekWptKmpjvW-rEBd_M74pIv3bWG4BnUcrOIgKv1tHQBX32bkUQ&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "imageobject": [
                    {
                        "width": "999",
                        "url": "https://i0.wp.com/visa-go.com/wp-content/uploads/2019/07/electrician.jpg?fit=999%2C667&ssl=1",
                        "height": "667"
                    },
                    {
                        "url": "https://visa-go.com/wp-content/uploads/2019/08/logo-new.png"
                    },
                    {
                        "thumbnailurl": "https://i0.wp.com/visa-go.com/wp-content/uploads/2019/06/mma.png?fit=300%2C200&ssl=1"
                    },
                    {
                        "thumbnailurl": "https://i0.wp.com/visa-go.com/wp-content/uploads/2019/06/mia.png?fit=300%2C200&ssl=1"
                    },
                    {
                        "thumbnailurl": "https://i0.wp.com/visa-go.com/wp-content/uploads/2024/07/MARA-DARREN.png?fit=300%2C200&ssl=1"
                    },
                    {
                        "thumbnailurl": "https://i0.wp.com/visa-go.com/wp-content/uploads/2024/07/MARA-KIMBERLEY.png?fit=300%2C200&ssl=1"
                    },
                    {
                        "width": "1200",
                        "url": "https://i0.wp.com/visa-go.com/wp-content/uploads/2024/09/shrawan-choudhary-c2kxNwDcPZo-unsplash-scaled.jpg?fit=1200%2C675&ssl=1",
                        "height": "675"
                    },
                    {
                        "url": "https://visa-go.com/wp-content/uploads/2019/08/logo-new.png"
                    },
                    {
                        "width": "1000",
                        "url": "https://i0.wp.com/visa-go.com/wp-content/uploads/2020/07/beach-girls-scaled-e1597242910777.jpg?fit=1000%2C700&ssl=1",
                        "height": "700"
                    },
                    {
                        "url": "https://visa-go.com/wp-content/uploads/2019/08/logo-new.png"
                    }
                ],
                "person": [
                    {
                        "name": "Kimberley Chatt"
                    },
                    {
                        "name": "Kimberley Chatt"
                    },
                    {
                        "name": "Kimberley Chatt"
                    }
                ],
                "organization": [
                    {
                        "name": "Kimberley Chatt"
                    },
                    {
                        "name": "Kimberley Chatt"
                    },
                    {
                        "name": "Kimberley Chatt"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://visa-go.com/wp-content/uploads/2019/07/electrician.jpg",
                        "og:type": "article",
                        "article:published_time": "2022-07-21T09:52:50+00:00",
                        "og:image:width": "999",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Visa Go Australia",
                        "author": "Kimberley Chatt",
                        "og:title": "Reasons to move to Australia as an Electrician - Visa Go Australia",
                        "og:image:height": "667",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Estimated reading time",
                        "og:image:type": "image/jpeg",
                        "og:description": "Move to Australia as an Electrician. Discover reasons to emigrate to Australia and work as an Electrician. Visas and jobs for Electricians Oz",
                        "twitter:creator": "@VisaGoAustralia",
                        "article:publisher": "https://www.facebook.com/VisaGoAustralia",
                        "twitter:data1": "Kimberley Chatt",
                        "twitter:data2": "3 minutes",
                        "twitter:site": "@VisaGoAustralia",
                        "article:modified_time": "2022-07-21T09:57:24+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_GB",
                        "og:url": "https://visa-go.com/blog/2022/07/21/reasons-to-move-to-australia-as-an-electrician/"
                    }
                ],
                "creativework": [
                    {
                        "text": "24 Canning Street Edinburgh EH3 8EG UNITED KINGDOM",
                        "headline": "Address"
                    },
                    {
                        "text": "info@visagoaustralia.com",
                        "headline": "Email"
                    },
                    {
                        "text": "55 Grantham Street Wembley 6014 WA AUSTRALIA",
                        "headline": "Address"
                    },
                    {
                        "text": "info@visagoaustralia.com",
                        "headline": "Email"
                    },
                    {
                        "text": "Used for the like, share, comment, and reaction icons Visa Go Australia 4 months ago It's a busy time in our offices as states reopen their sponsorship programs (visa subclasses 190 and 491)...."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://visa-go.com/wp-content/uploads/2019/07/electrician.jpg"
                    }
                ],
                "blogposting": [
                    {
                        "datemodified": "2022-07-21 10:57:24",
                        "name": "Reasons to move to Australia as an Electrician",
                        "text": "There are many reasons to move to Australia as an electrician. Keep reading to find out why! Electricians earn big money in Australia Electricians have high earning potential in Australia....",
                        "headline": "Reasons to move to Australia as an Electrician",
                        "datepublished": "2022-07-21T10:52:50+01:00",
                        "mainentityofpage": "Reasons to move to Australia as an Electrician"
                    },
                    {
                        "datemodified": "2024-09-06 04:29:04",
                        "name": "Setting up your finances in Australia",
                        "headline": "Setting up your finances in Australia",
                        "datepublished": "2024-09-06T04:29:04+01:00",
                        "mainentityofpage": "Setting up your finances in Australia"
                    },
                    {
                        "datemodified": "2024-09-06 04:34:46",
                        "name": "New Working Holiday visa arrangements for UK passport holders to commence",
                        "headline": "New Working Holiday visa arrangements for UK passport holders to commence",
                        "datepublished": "2024-06-17T03:39:59+01:00",
                        "mainentityofpage": "New Working Holiday visa arrangements for UK passport holders to commence"
                    }
                ],
                "sitenavigationelement": [
                    {
                        "url": "Professionals"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "deanne@visa-go.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "darren@visa-go.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@visa-go.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrician Sydney | Calibre Connect",
            "htmlTitle": "<b>Electrician Sydney</b> | Calibre Connect",
            "link": "https://calibreconnect.com.au/electrician-sydney/",
            "displayLink": "calibreconnect.com.au",
            "snippet": "We are a family-owned business committed to providing the best electrical services in Sydney and have a name and reputation you can trust.",
            "htmlSnippet": "We are a family-owned business committed to providing the <b>best</b> electrical services in <b>Sydney</b> and have a name and reputation you can trust.",
            "formattedUrl": "https://calibreconnect.com.au/electrician-sydney/",
            "htmlFormattedUrl": "https://calibreconnect.com.au/<b>electrician</b>-<b>sydney</b>/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr2vaQCTANWNl3x17gNN4_cT8EU39eRKtf2FQd8Jis-NfC1WMDEpNTQck&s",
                        "width": "334",
                        "height": "151"
                    }
                ],
                "product": [
                    {
                        "name": "Calibre Connect"
                    }
                ],
                "aggregaterating": [
                    {
                        "ratingvalue": "4.8",
                        "reviewcount": "45"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://calibreconnect.com.au/wp-content/uploads/local-electrician-sydney-1.jpg",
                        "og:type": "article",
                        "article:section": "Local Areas",
                        "og:image:width": "1024",
                        "og:image:alt": "local-electrician-sydney",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Electrician Sydney | 🥇Calibre Connect",
                        "og:site_name": "🥇Calibre Connect",
                        "og:title": "Electrician Sydney | 🥇Calibre Connect",
                        "og:image:height": "462",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Time to read",
                        "og:image:type": "image/jpeg",
                        "og:updated_time": "2024-11-04T17:13:53+11:00",
                        "msapplication-tileimage": "https://calibreconnect.com.au/wp-content/uploads/cropped-android-chrome-512x512-1-270x270.png",
                        "og:description": "Local Sydney Electrician On-Call and Ready to Go! Fast, Friendly and Professional Electrician Sydney Servicing All Areas. Fully Licensed Master Electricians.",
                        "og:image:secure_url": "https://calibreconnect.com.au/wp-content/uploads/local-electrician-sydney-1.jpg",
                        "twitter:image": "https://calibreconnect.com.au/wp-content/uploads/local-electrician-sydney-1.jpg",
                        "twitter:data1": "Alex Tiongco",
                        "twitter:data2": "8 minutes",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Local Sydney Electrician On-Call and Ready to Go! Fast, Friendly and Professional Electrician Sydney Servicing All Areas. Fully Licensed Master Electricians.",
                        "og:locale": "en_US",
                        "og:url": "https://calibreconnect.com.au/electrician-sydney/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://calibreconnect.com.au/wp-content/uploads/local-electrician-sydney-1.jpg"
                    }
                ],
                "wpheader": [
                    {
                        "headline": "🥇Calibre Connect"
                    }
                ],
                "hproduct": [
                    {
                        "fn": "Calibre Connect"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "connect@calibreconnect.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@calibreconnect.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "marc@calibreconnect.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "glen@calibreconnect.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Hello Electrical | Sydney electrician",
            "htmlTitle": "Hello Electrical | <b>Sydney electrician</b>",
            "link": "https://www.helloelectrical.com.au/",
            "displayLink": "www.helloelectrical.com.au",
            "snippet": "Residential electrician. Hello Electrical offers top-notch residential electrical services in Sydney. Our adept team of skilled and licensed electricians ...",
            "htmlSnippet": "Residential <b>electrician</b>. Hello Electrical offers <b>top</b>-notch residential electrical services in <b>Sydney</b>. Our adept team of skilled and licensed <b>electricians</b>&nbsp;...",
            "formattedUrl": "https://www.helloelectrical.com.au/",
            "htmlFormattedUrl": "https://www.helloelectrical.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk607P62i45hEZ9mLIx0RcSvsiKRSKKngSxNuezXObnLb8bthy9IgKqusl&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://cdn.prod.website-files.com/6454a44e6c1a4a8369dc2418/6459ec8578e77e573e2ccfc1_HelloElectrical_Office_Warehouse.jpg",
                        "twitter:title": "Hello Electrical | Sydney electrician",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:title": "Hello Electrical | Sydney electrician",
                        "twitter:image": "https://cdn.prod.website-files.com/6454a44e6c1a4a8369dc2418/6459ec8578e77e573e2ccfc1_HelloElectrical_Office_Warehouse.jpg"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.prod.website-files.com/6454a44e6c1a4a8369dc2418/6459ec8578e77e573e2ccfc1_HelloElectrical_Office_Warehouse.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@helloelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Sydney Electrician Jobs | Ryelec | Careers",
            "htmlTitle": "<b>Sydney Electrician</b> Jobs | Ryelec | Careers",
            "link": "https://ryelec.com.au/contact/careers/",
            "displayLink": "ryelec.com.au",
            "snippet": "Instead of saying “it's too hard,” we say, “let's find the best way to achieve this.” We have an ever-growing team, as the home and commercial automation ...",
            "htmlSnippet": "Instead of saying “it&#39;s too hard,” we say, “let&#39;s find the <b>best</b> way to achieve this.” We have an ever-growing team, as the home and commercial automation&nbsp;...",
            "formattedUrl": "https://ryelec.com.au/contact/careers/",
            "htmlFormattedUrl": "https://ryelec.com.au/contact/careers/",
            "pagemap": {
                "metatags": [
                    {
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Ryelec",
                        "og:title": "Sydney Electrician Jobs | Ryelec | Careers",
                        "apple-mobile-web-app-title": "Ryelec - Automation & Energy",
                        "twitter:label1": "Est. reading time",
                        "msapplication-tileimage": "https://ryelec.com.au/wp-content/uploads/2021/12/cropped-favicon-270x270.png",
                        "og:description": "Sydney electrician jobs. Systems integrator jobs. Program smart homes & venues. Solar, Audio & Security. Mix up your electrical career",
                        "article:publisher": "https://www.facebook.com/ryelec",
                        "twitter:data1": "3 minutes",
                        "facebook-domain-verification": "oi7ek84qnhs6in8cyy5xeuyr71xp3y",
                        "article:modified_time": "2023-01-16T02:54:00+00:00",
                        "viewport": "width=device-width, initial-scale=1, shrink-to-fit=no",
                        "apple-mobile-web-app-capable": "yes",
                        "og:locale": "en_US",
                        "mobile-web-app-capable": "yes",
                        "og:url": "https://ryelec.com.au/contact/careers/"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "tom@ryelec.com.au",
                    "position": "Director",
                    "firstName": "Tom",
                    "lastName": "Ryan",
                    "companyName": "Ryelec Automation & Electrical",
                    "sourcePage": "https://www.linkedin.com/in/tom-ryan-92ab01a8",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@ryelec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "ashleigh@ryelec.com.au",
                    "position": "Marketing Manager",
                    "firstName": "Ashleigh",
                    "lastName": "Field",
                    "companyName": "Ryelec Automation & Electrical",
                    "sourcePage": "https://www.linkedin.com/in/ashleigh-field-a5364b142",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrical Hazard Resistant Boots - Blundstone Australia",
            "htmlTitle": "Electrical Hazard Resistant Boots - Blundstone Australia",
            "link": "https://www.blundstone.com.au/work-boots/electrical-hazard-resistant-boots",
            "displayLink": "www.blundstone.com.au",
            "snippet": "Where there is a risk that you may come in contact with a live electrical circuit, these boots will provide the best possible protection against electrical ...",
            "htmlSnippet": "Where there is a risk that you may come in contact with a live electrical circuit, these boots will provide the <b>best</b> possible protection against electrical&nbsp;...",
            "formattedUrl": "https://www.blundstone.com.au/work-boots/electrical-hazard-resistant-boots",
            "htmlFormattedUrl": "https://www.blundstone.com.au/work-boots/electrical-hazard-resistant-boots",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSASGYmFTeTZ0rB7wUYVS4t-o0xRluC-7rrpKYp4NG5VoVOTTT3nOurAQ&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "msapplication-tilecolor": "#2b5797",
                        "og:image": "/static/frontend/Blundstone/default/default/images/logo-fb.jpg",
                        "og:image:width": "600",
                        "theme-color": "#002d72",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=5",
                        "og:image:height": "314",
                        "title": "Electrical Hazard Resistant Boots - Blundstone Australia",
                        "format-detection": "telephone=no",
                        "facebook-domain-verification": "4emhtnf5livn9kcfqo5js2ryys36ew"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.blundstone.com.au/media/attribute/swatch/b/l/bld_fuel_oil_resistant_b_cmyk.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "rclose@blundstone.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@blundstone.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "dwilkes@blundstone.com.au",
                    "position": "Joint Chief Executive Officer",
                    "firstName": "Darryl",
                    "lastName": "Wilkes",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/darryl-wilkes-a5023738",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "lknight@blundstone.com.au",
                    "position": "Australasian Marketing Coordinator",
                    "firstName": "Lee-Anne",
                    "lastName": "Knight",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/lee-anne-knight-6ab77653",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "sgunn@blundstone.com.au",
                    "position": "Chairman",
                    "firstName": "Steve",
                    "lastName": "Gunn",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/steve-gunn-142aa223",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "jcarfora@blundstone.com.au",
                    "position": "Global Range Manager",
                    "firstName": "Joe",
                    "lastName": "Carfora",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/joe-carfora-a7b8b998",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "jphillis@blundstone.com.au",
                    "position": "Area Manager",
                    "firstName": "John",
                    "lastName": "Phillis",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/john-phillis-4b034948",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "cburgess@blundstone.com.au",
                    "position": "Sales Manager",
                    "firstName": "Chris",
                    "lastName": "Burgess",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/chris-burgess-88a76012",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mvince@blundstone.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "kwhite@blundstone.com.au",
                    "position": "Australian Sales Manager",
                    "firstName": "Kylie",
                    "lastName": "White",
                    "companyName": "Blundstone Australia",
                    "sourcePage": "https://www.linkedin.com/in/kylie-white-6b932b197",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "How to tell if Electrical wiring in your house is not up to the mark?",
            "htmlTitle": "How to tell if Electrical wiring in your house is not up to the mark?",
            "link": "https://caslec.com.au/news/tell-electrical-wiring-house-not-mark/",
            "displayLink": "caslec.com.au",
            "snippet": "Jan 29, 2020 ... ... Electricians in Sydney · Level 2 Electricians · Service Areas · Careers · News · Contact Us ... best way forward. Instead, look at going for an ...",
            "htmlSnippet": "Jan 29, 2020 <b>...</b> ... <b>Electricians</b> in <b>Sydney</b> &middot; Level 2 <b>Electricians</b> &middot; Service Areas &middot; Careers &middot; News &middot; Contact Us ... <b>best</b> way forward. Instead, look at going for an&nbsp;...",
            "formattedUrl": "https://caslec.com.au/news/tell-electrical-wiring-house-not-mark/",
            "htmlFormattedUrl": "https://caslec.com.au/news/tell-electrical-wiring-house-not-mark/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR4h4fDT7uyHj1KYtnBUl7hLHXXftgSE5fX1IkuefLl2Ey5sVjlZtwqWIP&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://caslec.com.au/site/uploads/2020/01/Bad-House-Wiring.jpg",
                        "og:type": "article",
                        "article:published_time": "2020-01-29T00:58:46+00:00",
                        "og:image:width": "717",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Caslec Electrical Contractors",
                        "msvalidate.01": "0DC468887A6365333F69309A9A726C4C",
                        "author": "Caslec Industries",
                        "og:title": "How to tell if Electrical wiring in your house is not up to the mark?",
                        "og:image:height": "488",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Est. reading time",
                        "og:image:type": "image/jpeg",
                        "og:description": "Have you been smelling odors like plastic burning in your house? This article explains how to protect your house from bad wiring.",
                        "twitter:data1": "Caslec Industries",
                        "twitter:data2": "5 minutes",
                        "article:modified_time": "2020-01-29T01:03:13+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_US",
                        "og:url": "https://caslec.com.au/news/tell-electrical-wiring-house-not-mark/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://caslec.com.au/site/uploads/2020/01/GettyImages-1075628018-1024x683.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "service@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "Brendan@caslec.com.au",
                    "position": "Operations Manager",
                    "firstName": "Brendan",
                    "lastName": "Cassidy",
                    "companyName": "Caslec Electrical",
                    "sourcePage": "https://www.linkedin.com/in/brendan-cassidy-37a79935",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Jodi@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "brian@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "adam@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "accounts@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "installs@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jancu.aucamp@caslec.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Top 10 Local Electricians in Sydney | Airtasker AU",
            "htmlTitle": "<b>Top</b> 10 Local <b>Electricians</b> in <b>Sydney</b> | Airtasker AU",
            "link": "https://www.airtasker.com/au/services/electrician/sydney/",
            "displayLink": "www.airtasker.com",
            "snippet": "Find trusted local electricians in Sydney. Fill a short form and get free quotes from local electricians in Sydney. 4.3 Excellent rating - 4.3/5 (10900+ ...",
            "htmlSnippet": "Find trusted local <b>electricians</b> in <b>Sydney</b>. Fill a short form and get free quotes from local <b>electricians</b> in <b>Sydney</b>. 4.3 Excellent rating - 4.3/5 (10900+&nbsp;...",
            "formattedUrl": "https://www.airtasker.com/au/services/electrician/sydney/",
            "htmlFormattedUrl": "https://www.airtasker.com/au/services/<b>electrician</b>/<b>sydney</b>/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHCMACndltEt_c5-j38KgXPSZFThoSROigCe14VvwJwAQpqUoaqcgxbaFz&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://airtasker-seo-assets-prod.s3.amazonaws.com/en_AU/1689336443742-electrician-near-me.jpg",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Airtasker",
                        "msvalidate.01": "8A0B1CCF080C941CE2564028526876E2",
                        "og:title": "Top 10 Local Electricians in Sydney | Airtasker AU",
                        "web-arch": "seo-web",
                        "color-scheme": "only light",
                        "og:description": "Find 366 local licensed Electricians in Sydney. Get FREE quotes in minutes from reviewed, rated & trusted electricians for electrical services on Airtasker.",
                        "dcterms.modified": "2024-12-05T00:00:00.126Z",
                        "viewport": "width=device-width, initial-scale=1",
                        "apple-mobile-web-app-capable": "yes",
                        "og:url": "https://www.airtasker.com/au/services/electrician/sydney/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://airtasker-seo-assets-prod.s3.amazonaws.com/en_AU/1689336443742-electrician-near-me.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "located@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "ie@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "design@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "ert@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "m@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "ign@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "t@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "thegoodguysinstallations@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "Tasks@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "peopleops@airtasker.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Olympic Electrical: Trusted Electrician in Sydney",
            "htmlTitle": "Olympic Electrical: Trusted <b>Electrician</b> in <b>Sydney</b>",
            "link": "https://www.olympicelectrical.com.au/",
            "displayLink": "www.olympicelectrical.com.au",
            "snippet": "Olympic Electrical is a top-rated electrician in Sydney. We are your trusted local electricians who specialise in residential and commercial installations.",
            "htmlSnippet": "Olympic Electrical is a <b>top</b>-rated <b>electrician</b> in <b>Sydney</b>. We are your trusted local <b>electricians</b> who specialise in residential and commercial installations.",
            "formattedUrl": "https://www.olympicelectrical.com.au/",
            "htmlFormattedUrl": "https://www.olympicelectrical.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRKHUdgBIedc057HFs3KqyYU7RlcnGBAGFXe3Xxz59b0lBtBENlcbEHgE4&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.olympicelectrical.com.au/wp-content/uploads/2023/08/medal-2.png",
                        "cdp-version": "1.4.0",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Trusted Electrician in Sydney: Olympic Electrical",
                        "theme-color": "#f5f5f5",
                        "og:site_name": "Olympic Electrical",
                        "skype_toolbar": "SKYPE_TOOLBAR_PARSER_COMPATIBLE",
                        "og:title": "Trusted Electrician in Sydney: Olympic Electrical",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Time to read",
                        "og:updated_time": "2024-07-24T09:42:06+00:00",
                        "msapplication-tileimage": "https://www.olympicelectrical.com.au/wp-content/uploads/2020/09/icon.jpg",
                        "og:description": "Olympic Electrical is a top-rated electrician in Sydney. We are your trusted local electricians who specialise in residential and commercial installations.",
                        "twitter:data1": "olympicelectrical",
                        "twitter:data2": "24 minutes",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Olympic Electrical is a top-rated electrician in Sydney. We are your trusted local electricians who specialise in residential and commercial installations.",
                        "og:locale": "en_US",
                        "og:url": "https://www.olympicelectrical.com.au/"
                    }
                ],
                "webpage": [
                    {
                        "maincontentofpage": "Top Rated Electrician in Sydney SYDNEY’S GOLD MEDAL SPARKY SERVICE GET YOUR FREE QUOTETop Rated Electrician in SydneySYDNEY’S GOLD MEDAL SPARKY SERVICE GET YOUR FREE QUOTE OUR PROFESSIONALSWhy..."
                    }
                ],
                "cse_image": [
                    {
                        "src": "x-raw-image:///153936a9f2ec01b019a4626d8d594748fdad986dc78b23228bd65704c7bb2ae3"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "spyro@olympicelectrical.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "61442092816",
                            "properties": {
                                "createdate": "2024-09-25T00:15:35.479Z",
                                "email": "spyro@olympicelectrical.com.au",
                                "firstname": null,
                                "hs_object_id": "61442092816",
                                "lastmodifieddate": "2024-12-09T20:04:24.736Z",
                                "lastname": null
                            },
                            "createdAt": "2024-09-25T00:15:35.479Z",
                            "updatedAt": "2024-12-09T20:04:24.736Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "QS World University Rankings for Engineering - Electrical and ...",
            "htmlTitle": "QS World University Rankings for Engineering - Electrical and ...",
            "link": "https://www.topuniversities.com/university-subject-rankings/electrical-electronic-engineering",
            "displayLink": "www.topuniversities.com",
            "snippet": "Find out which universities are the best in the world for Engineering - Electrical and Electronic ... Australia, New Zealand, Brazil, Peru, Mexico, Argentina ...",
            "htmlSnippet": "Find out which universities are the <b>best</b> in the world for Engineering - Electrical and Electronic ... Australia, New Zealand, Brazil, Peru, Mexico, Argentina&nbsp;...",
            "formattedUrl": "https://www.topuniversities.com/university.../electrical-electronic-engineeri...",
            "htmlFormattedUrl": "https://www.topuniversities.com/university.../electrical-electronic-engineeri...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4B7LbNkFUvF7Pz9tZHvDWb6VsDtAHz4_ibzzQY76Ovo9iFtQXNQskn5c&s",
                        "width": "329",
                        "height": "153"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.topuniversities.com/sites/default/files/rankings/Subject_Rankings_2024.png",
                        "og:type": "website",
                        "og:image:width": "700",
                        "og:image:alt": "QS World University Rankings for Electrical and Electronic Engineering",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "QS World University Rankings for Electrical and Electronic Engineering 2024",
                        "og:site_name": "Top Universities",
                        "og:image:url": "https://www.topuniversities.com/sites/default/files/rankings/Subject_Rankings_2024.png",
                        "handheldfriendly": "true",
                        "og:title": "QS World University Rankings for Electrical and Electronic Engineering 2024",
                        "og:image:height": "325",
                        "og:image:type": "'image/jpeg'",
                        "og:description": "Discover the world's top universities for Electrical and Electronic Engineering. Explore the QS World University Rankings by Subject in various disciplines.",
                        "og:image:secure_url": "https://www.topuniversities.com/sites/default/files/rankings/Subject_Rankings_2024.png",
                        "twitter:image": "https://www.topuniversities.com/sites/default/files/rankings/OG%20img%20%20CRE%203693%20sub%20rank.png",
                        "facebook-domain-verification": "jyed73etkbrc34h8gwtc8axnj2g5v3",
                        "referrer": "unsafe-url",
                        "twitter:image:alt": "QS World University Rankings for Electrical and Electronic Engineering",
                        "twitter:site": "@TopUnis",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "twitter:description": "Discover the world's top universities for Electrical and Electronic Engineering. Explore the QS World University Rankings by Subject 2024 in various disciplines.",
                        "mobileoptimized": "width",
                        "og:url": "https://www.topuniversities.com/university-subject-rankings/electrical-electronic-engineering"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.topuniversities.com/sites/default/files/rankings/Subject_Rankings_2024.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "noreply@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "noreply-topuniversities@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "le_bohec@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "philippe_lebohec@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "kim@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "adalberto_iriarte@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "kllanos@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "maria_gonzalez@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "zain_nabi@topuniversities.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrical Contractors Sydney | Local Electrician Near Me",
            "htmlTitle": "Electrical Contractors <b>Sydney</b> | Local <b>Electrician</b> Near Me",
            "link": "https://easyfixelectrics.com.au/",
            "displayLink": "easyfixelectrics.com.au",
            "snippet": "Need a Local Electrician Near you? Easyfix Electrics is a leading CFSP fire safety practitioner and Electrical Contractor in Sydney. Call us on 02 9666 ...",
            "htmlSnippet": "Need a Local <b>Electrician</b> Near you? Easyfix Electrics is a leading CFSP fire safety practitioner and Electrical Contractor in <b>Sydney</b>. Call us on 02 9666&nbsp;...",
            "formattedUrl": "https://easyfixelectrics.com.au/",
            "htmlFormattedUrl": "https://easyfixelectrics.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8FU0vO41L-yuPE702DHTc7xjZfN3hhyrIUOKkRDl9IOpEHgo0bLCyhj1X&s",
                        "width": "315",
                        "height": "160"
                    }
                ],
                "thumbnail": [
                    {
                        "src": "https://www.easyfixelectrics.com.au/wp-content/uploads/2021/05/Logo@1X.png"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "http://[website_url]/wp-content/uploads/2021/05/Image.png",
                        "thumbnail": "https://www.easyfixelectrics.com.au/wp-content/uploads/2021/05/Logo@1X.png",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Easyfix Electrics",
                        "og:title": "Electrical Contractors Sydney | Local Electrician Near Me",
                        "twitter:label1": "Est. reading time",
                        "msapplication-tileimage": "https://easyfixelectrics.com.au/wp-content/uploads/2021/06/fevicon.ico",
                        "og:description": "Need a Local Electrician Near you? Easyfix Electrics is a leading CFSP fire safety practitioner and Electrical Contractor in Sydney. Call us on 02 9666 8008!",
                        "twitter:data1": "16 minutes",
                        "article:modified_time": "2024-10-07T05:44:07+00:00",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
                        "og:locale": "en_US",
                        "og:url": "https://easyfixelectrics.com.au/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.easyfixelectrics.com.au/wp-content/uploads/2021/05/Logo@1X.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "service@easyfixelectrics.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "edmond@easyfixelectrics.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "maree@easyfixelectrics.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "5 Best electrician jobs in sydney nova, scotia (Hiring Now ...",
            "htmlTitle": "5 <b>Best electrician</b> jobs in <b>sydney</b> nova, scotia (Hiring Now ...",
            "link": "https://www.workopolis.com/search?q=electrician&l=sydney+nova%2C+scotia",
            "displayLink": "www.workopolis.com",
            "snippet": "5 electrician jobs available in sydney nova, scotia. See salaries, compare reviews, easily apply, and get hired. New electrician careers in sydney nova, ...",
            "htmlSnippet": "5 <b>electrician</b> jobs available in <b>sydney</b> nova, scotia. See salaries, compare reviews, easily apply, and get hired. New <b>electrician</b> careers in <b>sydney</b> nova,&nbsp;...",
            "formattedUrl": "https://www.workopolis.com/search?q=electrician&l=sydney+nova%2C...",
            "htmlFormattedUrl": "https://www.workopolis.com/search?q=<b>electrician</b>&amp;l=<b>sydney</b>+nova%2C...",
            "pagemap": {
                "metatags": [
                    {
                        "next-head-count": "7",
                        "viewport": "initial-scale=1.0, width=device-width"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "laperle@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "erickl@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "elaperle@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "ericklaperle@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "laperlee@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "erick_laperle@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "erick.l@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "e.laperle@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "er.harris@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "e.weichel@workopolis.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "What Are the Best Electrician Courses in Sydney?",
            "htmlTitle": "What Are the <b>Best Electrician</b> Courses in <b>Sydney</b>?",
            "link": "https://craftyconnect.com.au/electrical_electronicservices/what-are-the-best-electrician-courses-in-sydney/",
            "displayLink": "craftyconnect.com.au",
            "snippet": "May 2, 2024 ... Working towards becoming an electrician in Sydney means focusing on electrician skills development and electrician training. The training covers ...",
            "htmlSnippet": "May 2, 2024 <b>...</b> Working towards becoming an <b>electrician</b> in <b>Sydney</b> means focusing on <b>electrician</b> skills development and <b>electrician</b> training. The training covers&nbsp;...",
            "formattedUrl": "https://craftyconnect.com.au/.../what-are-the-best-electrician-courses-in-syd...",
            "htmlFormattedUrl": "https://craftyconnect.com.au/.../what-are-the-<b>best</b>-<b>electrician</b>-courses-in-<b>syd</b>...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTo9toZQqskyrwAl9FyROVsa3_-Vq8EayklPKmFnIJKxLMJN5NTnooGxo&s",
                        "width": "297",
                        "height": "170"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://craftyconnect.com.au/wp-content/uploads/2024/05/Electrician-Training-1024x585.jpg",
                        "og:type": "article",
                        "article:published_time": "2024-05-02T10:38:00+00:00",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Crafty Connect",
                        "author": "Crafty Connect",
                        "og:title": "What Are the Best Electrician Courses in Sydney?",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Est. reading time",
                        "msapplication-tileimage": "https://craftyconnect.com.au/wp-content/uploads/2024/02/cropped-FAVICON-2024-270x270.png",
                        "og:description": "Explore the top-rated Electrician Training courses in Sydney and kickstart your career with accredited certifications and hands-on apprenticeships.",
                        "article:publisher": "https://www.facebook.com/craftyconnectau",
                        "twitter:data1": "Crafty Connect",
                        "twitter:data2": "11 minutes",
                        "article:modified_time": "2024-04-25T13:18:43+00:00",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=5.0",
                        "og:locale": "en_US",
                        "og:url": "https://craftyconnect.com.au/electrical_electronicservices/what-are-the-best-electrician-courses-in-sydney/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://craftyconnect.com.au/wp-content/uploads/2024/05/Electrician-Training-1024x585.jpg"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Top Rated Electrician Sydney | Great Reviews & All Work Guaranteed",
            "htmlTitle": "<b>Top</b> Rated <b>Electrician Sydney</b> | Great Reviews &amp; All Work Guaranteed",
            "link": "https://www.edycoelectricalgroup.com.au/",
            "displayLink": "www.edycoelectricalgroup.com.au",
            "snippet": "Offering professional and affordable electrician services throughout Sydney. Free quotes, best value pricing, guaranteed warranty on all work performed.",
            "htmlSnippet": "Offering professional and affordable <b>electrician</b> services throughout <b>Sydney</b>. Free quotes, <b>best</b> value pricing, guaranteed warranty on all work performed.",
            "formattedUrl": "https://www.edycoelectricalgroup.com.au/",
            "htmlFormattedUrl": "https://www.edycoelectricalgroup.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBJ_0CSc7flGXL0uZ6Qt-y_jIXZkIrqFPOi7WpwFVGpW2rDHVVgXJigg&s",
                        "width": "444",
                        "height": "113"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Edyco Electrical Group | Residential and Commercial Sydney",
                        "article:modified_time": "2024-08-22T10:36:27+00:00",
                        "viewport": "width=device-width, initial-scale=1, maximum-scale=1",
                        "og:title": "Top Rated Electrician Sydney | Great Reviews & All Work Guaranteed",
                        "og:locale": "en_US",
                        "twitter:label1": "Est. reading time",
                        "og:url": "https://www.edycoelectricalgroup.com.au/",
                        "msapplication-tileimage": "https://www.edycoelectricalgroup.com.au/wp-content/uploads/2024/03/cropped-Main-Logo-2-270x270.png",
                        "og:description": "Get a Free Quote from the most trusted Sydney Electrician. We will beat any competitor quotes. 24/7 Sydney wide service & fast response times",
                        "twitter:data1": "13 minutes"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn-ilbaiaf.nitrocdn.com/SkKvVrTUmkqxoRVABVapOmluNyralIPC/assets/images/optimized/rev-69bbb8e/www.edycoelectricalgroup.com.au/wp-content/uploads/2024/03/Main-Logo-white-2.png"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "The 4 Best Ceiling Fans for a Bedroom – As Rated by a REAL ...",
            "htmlTitle": "The 4 <b>Best</b> Ceiling Fans for a Bedroom – As Rated by a REAL ...",
            "link": "https://www.plateauelectrical.com.au/blog/best-ceiling-fan-for-bedroom",
            "displayLink": "www.plateauelectrical.com.au",
            "snippet": "Apr 15, 2024 ... Residential Electrician Sydney · Electrician Inner West · Electrician ... The 4 Best Ceiling Fans for a Bedroom – As Rated by a REAL Electrician.",
            "htmlSnippet": "Apr 15, 2024 <b>...</b> Residential <b>Electrician Sydney</b> &middot; <b>Electrician</b> Inner West &middot; <b>Electrician</b> ... The 4 <b>Best</b> Ceiling Fans for a Bedroom – As Rated by a REAL <b>Electrician</b>.",
            "formattedUrl": "https://www.plateauelectrical.com.au/blog/best-ceiling-fan-for-bedroom",
            "htmlFormattedUrl": "https://www.plateauelectrical.com.au/blog/<b>best</b>-ceiling-fan-for-bedroom",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2AGM7yqJcA0LQ-H2CL22GUcTyjK8k-J1oH2w_aw5GqRc9JuQmyUk0wm4w&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://cdn.prod.website-files.com/6614be9f925aa98bbe09456f/6619b7f63ba423892e3f4909_VoltexFan.jpeg",
                        "twitter:title": "The 4 Best Ceiling Fans for a Bedroom – As Rated by a REAL Electrician | Plateau Electrical | Blog",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Our 4 favourite (and quiet) ceiling fans for Bedrooms",
                        "og:title": "The 4 Best Ceiling Fans for a Bedroom – As Rated by a REAL Electrician | Plateau Electrical | Blog",
                        "og:description": "Our 4 favourite (and quiet) ceiling fans for Bedrooms",
                        "twitter:image": "https://cdn.prod.website-files.com/6614be9f925aa98bbe09456f/6619b7f63ba423892e3f4909_VoltexFan.jpeg"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.prod.website-files.com/6614be9f925aa98bbe09456f/6619b7f63ba423892e3f4909_VoltexFan.jpeg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@plateauelectrical.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "20960251",
                            "properties": {
                                "createdate": "2019-08-09T23:48:20.741Z",
                                "email": "info@plateauelectrical.com.au",
                                "firstname": "Nicholas",
                                "hs_object_id": "20960251",
                                "lastmodifieddate": "2024-12-09T13:59:17.154Z",
                                "lastname": "Paton"
                            },
                            "createdAt": "2019-08-09T23:48:20.741Z",
                            "updatedAt": "2024-12-09T13:59:17.154Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Commercial Electrician Sydney - Titanium Electrical Group",
            "htmlTitle": "Commercial Electrician Sydney - Titanium Electrical Group",
            "link": "https://titaniumelectrical.net.au/commercial/",
            "displayLink": "titaniumelectrical.net.au",
            "snippet": "We're committed to finding the best and most cost effective solutions for our clients. We also bring a strong work ethic to every client project because ...",
            "htmlSnippet": "We&#39;re committed to finding the <b>best</b> and most cost effective solutions for our clients. We also bring a strong work ethic to every client project because&nbsp;...",
            "formattedUrl": "https://titaniumelectrical.net.au/commercial/",
            "htmlFormattedUrl": "https://titaniumelectrical.net.au/commercial/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp9z5ruuIkvJJd05OiWKFxAtl4jIRRByruhLKOV8oKUzAMqARuBGq0TEY&s",
                        "width": "319",
                        "height": "158"
                    }
                ],
                "metatags": [
                    {
                        "msapplication-tilecolor": "#b91d47",
                        "msapplication-config": "/wp-content/uploads/fbrfg/browserconfig.xml?v=2",
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "theme-color": "#ffffff",
                        "og:site_name": "Titanium Electrical Group | Sydney Electrician",
                        "article:modified_time": "2023-09-28T01:10:01+00:00",
                        "viewport": "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1",
                        "og:title": "Titanium Electrical Group | Commercial Electrician Sydney",
                        "og:locale": "en_US",
                        "og:url": "https://titaniumelectrical.net.au/commercial/",
                        "og:description": "We’re committed to finding the best and most cost effective solutions for our clients. We also bring a strong work ethic to every client project because we’re very conscious of time and budget constraints."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://titaniumelectrical.net.au/wp-content/uploads/2023/09/img-5.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "office@titaniumelectrical.net.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Discover the best Commercial Electrician in North Shore Guide",
            "htmlTitle": "Discover the <b>best</b> Commercial <b>Electrician</b> in North Shore Guide",
            "link": "https://www.leendertselectrical.com.au/2024/11/04/your-guide-to-finding-the-best-commercial-electrician-in-north-shore/",
            "displayLink": "www.leendertselectrical.com.au",
            "snippet": "Nov 4, 2024 ... Engaging a qualified commercial electrician in the North Shore ensures your electrical systems are safe, efficient, and up to Australian ...",
            "htmlSnippet": "Nov 4, 2024 <b>...</b> Engaging a qualified commercial <b>electrician</b> in the North Shore ensures your electrical systems are safe, efficient, and up to Australian&nbsp;...",
            "formattedUrl": "https://www.leendertselectrical.com.au/.../your-guide-to-finding-the-best-co...",
            "htmlFormattedUrl": "https://www.leendertselectrical.com.au/.../your-guide-to-finding-the-<b>best</b>-co...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXWYaxrh6d8rr2u4vVCK8nuWMAfkAaRDesB0fX2kv9t6x_pX45A3hnRRs&s",
                        "width": "362",
                        "height": "139"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.leendertselectrical.com.au/wp-content/uploads/sites/2/2020/09/Leenderts-BC-Personal-Back-e1600509542483.jpg",
                        "og:type": "article",
                        "og:image:width": "531",
                        "article:published_time": "2024-11-04T09:48:03+00:00",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Discover the best Commercial Electrician in North Shore Guide",
                        "og:site_name": "Leenderts Electrical - Your local electrician",
                        "og:title": "Discover the best Commercial Electrician in North Shore Guide",
                        "og:image:height": "205",
                        "msapplication-tileimage": "https://i0.wp.com/www.leendertselectrical.com.au/wp-content/uploads/sites/2/2020/09/cropped-Leenderts-BC-Generic-Back-e1600509754856.jpg?fit=270%2C270&ssl=1",
                        "og:description": "Discover tips and criteria for choosing the top commercial electrician in North Shore. Ensure quality service for your business's electrical needs!",
                        "og:image:secure_url": "https://www.leendertselectrical.com.au/wp-content/uploads/sites/2/2020/09/Leenderts-BC-Personal-Back-e1600509542483.jpg",
                        "article:publisher": "https://www.facebook.com/leendertselec",
                        "twitter:image": "https://www.leendertselectrical.com.au/wp-content/uploads/sites/2/2020/09/Leenderts-BC-Personal-Back-e1600509542483.jpg",
                        "article:modified_time": "2024-11-04T09:48:05+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Discover tips and criteria for choosing the top commercial electrician in North Shore. Ensure quality service for your business's electrical needs!",
                        "og:locale": "en_US",
                        "og:url": "https://www.leendertselectrical.com.au/2024/11/04/your-guide-to-finding-the-best-commercial-electrician-in-north-shore/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.leendertselectrical.com.au/wp-content/uploads/sites/2/2020/09/Leenderts-BC-Personal-Back-e1600509542483.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@leendertselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "How to Choose an Electrician in Sydney - Mr Sparky Electrical ...",
            "htmlTitle": "How to Choose an <b>Electrician</b> in <b>Sydney</b> - Mr Sparky Electrical ...",
            "link": "https://www.mrsparky.com.au/how-to-choose-an-electrician-in-sydney/",
            "displayLink": "www.mrsparky.com.au",
            "snippet": "Nov 17, 2023 ... Find the best electrician in Sydney with this guide. Essential tips for&eliable and qualified professional to ensure your electrical safety.",
            "htmlSnippet": "Nov 17, 2023 <b>...</b> Find the <b>best electrician</b> in <b>Sydney</b> with this guide. Essential tips for&amp;eliable and qualified professional to ensure your electrical safety.",
            "formattedUrl": "https://www.mrsparky.com.au/how-to-choose-an-electrician-in-sydney/",
            "htmlFormattedUrl": "https://www.mrsparky.com.au/how-to-choose-an-<b>electrician</b>-in-<b>sydney</b>/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfHVZNY1-p4rnMvZe20hAM11tFt9MTdhHQ0qYDh3ftYLT2EgFvPLV6DGaS&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.mrsparky.com.au/wp-content/uploads/2023/11/shutterstock_2046901838.jpg",
                        "og:type": "article",
                        "article:published_time": "2023-11-17T04:34:57+00:00",
                        "og:image:width": "1000",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Mr Sparky Electrical Services",
                        "author": "Steven",
                        "og:title": "How to Choose an Electrician in Sydney",
                        "og:image:height": "667",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Est. reading time",
                        "og:image:type": "image/jpeg",
                        "msapplication-tileimage": "https://www.mrsparky.com.au/wp-content/uploads/2023/06/favicon-mr-sparky-1.png",
                        "og:description": "Find the best electrician in Sydney with this guide. Essential tips for&eliable and qualified professional to ensure your electrical safety.",
                        "article:publisher": "https://www.facebook.com/MrSparky.com.au/",
                        "twitter:data1": "Steven",
                        "twitter:data2": "2 minutes",
                        "article:modified_time": "2023-11-19T22:45:27+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_US",
                        "og:url": "https://www.mrsparky.com.au/how-to-choose-an-electrician-in-sydney/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.mrsparky.com.au/wp-content/uploads/2023/11/shutterstock_2046901838.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "steven.sukar@mrsparky.com.au",
                    "position": "CEO",
                    "firstName": "Steven",
                    "lastName": "Sukar",
                    "companyName": "Mr Sparky Electrical Services Pty Ltd",
                    "sourcePage": "https://www.linkedin.com/in/steven-sukar-957b891b",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "enquiry@mrsparky.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@mrsparky.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "info@mrsparky.com.au",
                    "position": "Human Resource and Payroll Manager",
                    "firstName": "Erin",
                    "lastName": "Greenhalgh",
                    "companyName": "Mr Sparky Electrical Services Pty Ltd",
                    "sourcePage": "https://www.linkedin.com/in/erin-greenhalgh-3843563b",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sales@mrsparky.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "steven@mrsparky.com.au",
                    "position": "CEO",
                    "firstName": "Steven",
                    "lastName": "Sukar",
                    "companyName": "Mr Sparky Electrical Services Pty Ltd",
                    "sourcePage": "https://www.linkedin.com/in/steven-sukar-957b891b",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "nawal@mrsparky.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "gerin@mrsparky.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best Electrician St Ives|M.E.Ward Electrical Services",
            "htmlTitle": "<b>Best Electrician</b> St Ives|M.E.Ward Electrical Services",
            "link": "https://meward.com.au/electrician-st-ives/",
            "displayLink": "meward.com.au",
            "snippet": "Hiring a domestic electrician is hassle-free in Australia. Simply give us a call or fill out an online form to reach a member of our team. Straight away, we'll ...",
            "htmlSnippet": "Hiring a domestic <b>electrician</b> is hassle-free in Australia. Simply give us a call or fill out an online form to reach a member of our team. Straight away, we&#39;ll&nbsp;...",
            "formattedUrl": "https://meward.com.au/electrician-st-ives/",
            "htmlFormattedUrl": "https://meward.com.au/<b>electrician</b>-st-ives/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgUFEMf6LHoMPLI_2-Zl8MMLJZvAicyfgE56o1EYlNU-q28I_LEHQoRg&s",
                        "width": "427",
                        "height": "118"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://meward.com.au/wp-content/uploads/2022/10/ME-WARD.png",
                        "og:type": "article",
                        "article:published_time": "2022-10-12T07:37:26+00:00",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Best Electrician St Ives|M.E.Ward Electrical Services",
                        "og:site_name": "M.E. Ward Electrical Services - Domestic and Commercial Electricians Sydney",
                        "og:title": "Best Electrician St Ives|M.E.Ward Electrical Services",
                        "msapplication-tileimage": "https://meward.com.au/wp-content/uploads/2022/10/ME-Ward-Favicon-1-300x300.png",
                        "og:description": "Highest Level of Service for Customers in St Ives and Surrounding. 50 Years of Experience. Call (02) 9875 5533 Today!",
                        "og:image:secure_url": "https://meward.com.au/wp-content/uploads/2022/10/ME-WARD.png",
                        "twitter:image": "https://meward.com.au/wp-content/uploads/2022/10/ME-WARD.png",
                        "article:modified_time": "2024-11-20T12:14:17+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Highest Level of Service for Customers in St Ives and Surrounding. 50 Years of Experience. Call (02) 9875 5533 Today!",
                        "og:locale": "en_US",
                        "og:url": "https://meward.com.au/electrician-st-ives/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://meward.com.au/wp-content/uploads/2022/10/ME-WARD.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sales@meward.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "vicki@meward.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "murray@meward.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jill@meward.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Surry Hills Electrical",
            "htmlTitle": "Surry Hills Electrical",
            "link": "https://www.surryhillselectrical.com/",
            "displayLink": "www.surryhillselectrical.com",
            "snippet": "I will make sure you have the best customer service. I strive to be the best electrician in sydney and provide a 1 year guarantee on all labour. I never ...",
            "htmlSnippet": "I will make sure you have the <b>best</b> customer service. I strive to be the <b>best electrician</b> in <b>sydney</b> and provide a 1 year guarantee on all labour. I never&nbsp;...",
            "formattedUrl": "https://www.surryhillselectrical.com/",
            "htmlFormattedUrl": "https://www.surryhillselectrical.com/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0xdL7kzihv7Vrqza8ZtjUMZl0CC8qBlqNw70vB3wRYXtnmRucBeLSJLA&s",
                        "width": "279",
                        "height": "181"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "website",
                        "twitter:title": "Surry Hills Electrical",
                        "twitter:card": "summary",
                        "og:site_name": "Surry Hills Electrical",
                        "viewport": "initial-scale=1",
                        "twitter:url": "https://www.surryhillselectrical.com",
                        "twitter:description": "Surry Hills electrical is a reliable choice for the best electricians. Surry Hills electrical work Sydney wide. We offer the best service, and can cater to all your electrical needs. Lighting repairs, lighting installation.",
                        "og:title": "Surry Hills Electrical",
                        "og:url": "https://www.surryhillselectrical.com",
                        "og:description": "Surry Hills electrical is a reliable choice for the best electricians. Surry Hills electrical work Sydney wide. We offer the best service, and can cater to all your electrical needs. Lighting repairs, lighting installation."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://images.squarespace-cdn.com/content/v1/56ec101db09f95da37e77918/1458317460088-Z27X14MAB5X7CAC0DBOG/SQS_DK_carlos_0226-e.jpg?format=2500w"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "Best Electricians in Sydney - Our Services - David Jones - The ...",
            "htmlTitle": "<b>Best Electricians</b> in <b>Sydney</b> - Our Services - David Jones - The ...",
            "link": "https://djelectrician.com.au/electrician-services/",
            "displayLink": "djelectrician.com.au",
            "snippet": "At David Jones - The Electrician, we do domestic & commercial electrical services including CCTV, Alarms, LED Lighting & many more!",
            "htmlSnippet": "At David Jones - The <b>Electrician</b>, we do domestic &amp; commercial electrical services including CCTV, Alarms, LED Lighting &amp; many more!",
            "formattedUrl": "https://djelectrician.com.au/electrician-services/",
            "htmlFormattedUrl": "https://dj<b>electrician</b>.com.au/<b>electrician</b>-services/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyzeWXxdH1Fan4JLDOL4-X3K7QkMVAudNqan7nahMRH7N-oPb1qj4Ri_3x&s",
                        "width": "311",
                        "height": "162"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://djelectricians.com.au/wp-content/themes/djelectricians/images/fb-logo.jpg",
                        "og:type": "article",
                        "twitter:card": "summary",
                        "twitter:title": "Sydney Electrical Services & Contractors - DJ Electricians",
                        "article:published_time": "2017-03-24T11:57:31+00:00",
                        "og:site_name": "Sydney Electrical Services & Contractors - DJ Electricians",
                        "og:title": "Sydney Electrical Services & Contractors - DJ Electricians",
                        "og:description": "For high quality electrical services in Sydney, contact David Jones - The Electrician. Offers award winning service at affordable rates, all work guaranteed!",
                        "twitter:image": "https://djelectricians.com.au/wp-content/themes/djelectricians/images/fb-logo.jpg",
                        "article:modified_time": "2020-02-24T23:56:16+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "For high quality electrical services in Sydney, contact David Jones - The Electrician. Offers award winning service at affordable rates, all work guaranteed!",
                        "og:locale": "en_US",
                        "og:url": "https://djelectrician.com.au/electrician-services/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://djelectricians.com.au/wp-content/themes/djelectricians/images/fb-logo.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "david@djelectrician.com.au",
                    "position": "Founder & Managing Director",
                    "firstName": "David",
                    "lastName": "Jones",
                    "companyName": "David Jones Electricians",
                    "sourcePage": "https://www.linkedin.com/in/davidchristopherjones",
                    "isInCRM": {
                        "details": {
                            "id": "30496451",
                            "properties": {
                                "createdate": "2020-01-07T21:38:30.546Z",
                                "email": "david@djelectrician.com.au",
                                "firstname": "David",
                                "hs_object_id": "30496451",
                                "lastmodifieddate": "2024-12-09T14:58:04.218Z",
                                "lastname": null
                            },
                            "createdAt": "2020-01-07T21:38:30.546Z",
                            "updatedAt": "2024-12-09T14:58:04.218Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@djelectrician.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "finance@djelectrician.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "THE BEST 10 Electricians in SYDNEY, NS - Last Updated ...",
            "htmlTitle": "THE <b>BEST</b> 10 <b>Electricians</b> in <b>SYDNEY</b>, NS - Last Updated ...",
            "link": "https://www.yelp.com/search?cflt=electricians&find_loc=Sydney%2C+NS",
            "displayLink": "www.yelp.com",
            "snippet": "Best Electricians Sydney, NS - Aether Electric Randall Macdonald, Cantwell Electric, D Finlay Electrical, Rybran Electrical, JMD Electric, ...",
            "htmlSnippet": "<b>Best Electricians Sydney</b>, NS - Aether Electric Randall Macdonald, Cantwell Electric, D Finlay Electrical, Rybran Electrical, JMD Electric,&nbsp;...",
            "formattedUrl": "https://www.yelp.com/search?cflt=electricians&find_loc=Sydney%2C...",
            "htmlFormattedUrl": "https://www.yelp.com/search?cflt=<b>electrician</b>s&amp;find_loc=<b>Sydney</b>%2C...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM3vsgBMjQpn46PQXgM0PA2-sLvD4dfdYDRy-JG7iVQOlX9SbRA6sHqn0&s",
                        "width": "300",
                        "height": "168"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_large_assets/a8d26394324f/assets/img/seo_metadata/yelp_og_image.png",
                        "og:type": "website",
                        "og:image:width": "1920",
                        "og:site_name": "Yelp",
                        "al:ios:app_name": "Yelp",
                        "og:title": "THE BEST 10 Electricians in SYDNEY, NS - Last Updated December 2024 - Yelp",
                        "og:image:height": "1080",
                        "bingbot": "noarchive",
                        "al:ios:url": "https://www.yelp.ca/search?cflt=electricians&find_loc=Sydney%2C+NS&utm_campaign=search&utm_medium=organic&utm_source=apple",
                        "og:description": "Best Electricians  Sydney, NS - Aether Electric Randall Macdonald, Cantwell Electric, D Finlay Electrical, Rybran Electrical, JMD Electric, W McNeil Electrical Contracting, J.M.K. Electric, Mackenzie Electrical Service, A and E Electric, River Ryan Heat Pumps & Electricial, Power On Electrical Service, Brake Electric, Mallard Electric, Michael Macdonald Electrical, Ideal Electric, Alliance Electrical Contracting",
                        "al:ios:app_store_id": "284910350",
                        "fb:app_id": "97534753161",
                        "viewport": "initial-scale=1",
                        "traceparent": "00-c8bc34dbc9234ac3812ab366cf42cde5-085471d80f134042-01",
                        "og:ttl": "2419200",
                        "og:url": "https://m.yelp.ca/search?cflt=electricians&find_loc=Sydney%2C+NS",
                        "format-detection": "telephone=no"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_large_assets/a8d26394324f/assets/img/seo_metadata/yelp_og_image.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "read@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "YES@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "trademarks@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "look@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "store@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Systems@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "yelp@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jallen8j@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "egreene8m@yelp.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "New Build Electrician (Sydney) Construction Electrical Contractor",
            "htmlTitle": "New Build <b>Electrician</b> (<b>Sydney</b>) Construction Electrical Contractor",
            "link": "https://www.amberelectrical.com.au/new-construction",
            "displayLink": "www.amberelectrical.com.au",
            "snippet": "Amber Electrical Service's highly experienced and reliable team of electricians has provided the best service to our customers across Western Sydney since 2001.",
            "htmlSnippet": "Amber Electrical Service&#39;s highly experienced and reliable team of <b>electricians</b> has provided the <b>best</b> service to our customers across Western <b>Sydney</b> since 2001.",
            "formattedUrl": "https://www.amberelectrical.com.au/new-construction",
            "htmlFormattedUrl": "https://www.amberelectrical.com.au/new-construction",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQywzQfjkA9G8AFzyA9bcgq2B8yHNISQNHoHpSdToH527UZ7lp9x6wctFw&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.amberelectrical.com.au/favicon.png",
                        "og:type": "website",
                        "og:image:width": "256",
                        "twitter:card": "summary",
                        "twitter:title": "New Build Electrician (Sydney) Construction Electrical Contractor",
                        "og:title": "New Build Electrician (Sydney) Construction Electrical Contractor",
                        "og:image:height": "256",
                        "msapplication-tileimage": "/favicon.png",
                        "og:description": "Our new build electricians and experienced construction electrical contractors are trusted across Sydney by builders and homeowners since 2001!",
                        "og:image:secure_url": "https://www.amberelectrical.com.au/favicon.png",
                        "twitter:image": "https://www.amberelectrical.com.au/favicon.png",
                        "viewport": "width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no",
                        "twitter:description": "Our new build electricians and experienced construction electrical contractors are trusted across Sy",
                        "og:url": "https://www.amberelectrical.com.au/new-construction"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.amberelectrical.com.au/favicon.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@amberelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "mark@amberelectrical.com.au",
                    "position": "Director",
                    "firstName": "Mark",
                    "lastName": "Cossor",
                    "companyName": "Amber Electrical Services",
                    "sourcePage": "https://www.linkedin.com/in/mark-cossor",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "mark.cossor@amberelectrical.com.au",
                    "position": "Director",
                    "firstName": "Mark",
                    "lastName": "Cossor",
                    "companyName": "Amber Electrical Services",
                    "sourcePage": "https://www.linkedin.com/in/mark-cossor",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "scott.jones@amberelectrical.com.au",
                    "position": "Director",
                    "firstName": "Scott",
                    "lastName": "Jones",
                    "companyName": "Amber Electrical Services",
                    "sourcePage": "https://www.linkedin.com/in/scott-jones-sydney/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "michelle@amberelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Quick and reliable electricians in Sydney",
            "htmlTitle": "Quick and reliable <b>electricians</b> in <b>Sydney</b>",
            "link": "https://www.faradaygroup.com.au/",
            "displayLink": "www.faradaygroup.com.au",
            "snippet": "Electrician) and Pauline (Office Manager). Our team provide top quality electrical services all over Sydney. For over 60 years the Faraday Group has ...",
            "htmlSnippet": "<b>Electrician</b>) and Pauline (Office Manager). Our team provide <b>top</b> quality electrical services all over <b>Sydney</b>. For over 60 years the Faraday Group has&nbsp;...",
            "formattedUrl": "https://www.faradaygroup.com.au/",
            "htmlFormattedUrl": "https://www.faradaygroup.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxEgYcxLLk37JrbDLOmIDgEba6ZL4G0PL67o3sPFGc5v7eZ65iml66-98&s",
                        "width": "302",
                        "height": "167"
                    }
                ],
                "service": [
                    {
                        "servicetype": "Professional and Reliable Residential and Level 2 ASP Commercial Electricians in Sydney",
                        "description": "Looking for Residential, Commercial or Level 2 ASP Commercial Electricians in Sydney? Trust Our Reliable Electrical Contractors with Over 60 Years of Experience, Delivering 5-Star Quality and Service."
                    },
                    {
                        "servicetype": "Professional and Reliable Residential and Level 2 ASP Commercial Electricians in Sydney",
                        "description": "Looking for Residential, Commercial or Level 2 ASP Commercial Electricians in Sydney? Trust Our Reliable Electrical Contractors with Over 60 Years of Experience, Delivering 5-Star Quality and Service."
                    }
                ],
                "postaladdress": [
                    {
                        "postalcode": "NSW 2112",
                        "addressregion": "Denistone East",
                        "streetaddress": "1 Doig Ave,",
                        "telephone": "Telephone No.02 9809 5299"
                    },
                    {
                        "postalcode": "NSW 2112",
                        "addressregion": "Denistone East",
                        "streetaddress": "1 Doig Ave,",
                        "telephone": "Telephone No.02 9809 5299"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.faradaygroup.com.au/wp-content/uploads/Faraday-Group-New-Logo2.jpg",
                        "og:type": "website",
                        "og:image:width": "470",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Faraday Group",
                        "og:title": "Quick and reliable electricians in Sydney",
                        "og:image:height": "260",
                        "og:image:type": "image/jpeg",
                        "msapplication-tileimage": "https://www.faradaygroup.com.au/wp-content/uploads/cropped-Favicon@3x-270x270.png",
                        "og:description": "Need a residential or commercial electrician in Sydney? Call Faraday Group for repairs, installations, and maintenance on 02 9809 5299",
                        "article:publisher": "https://www.facebook.com/FaradayGroup/",
                        "article:modified_time": "2024-11-27T06:25:38+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_US",
                        "google-adsense-platform-domain": "sitekit.withgoogle.com",
                        "google-adsense-platform-account": "ca-host-pub-2644536267352236",
                        "og:url": "https://www.faradaygroup.com.au/"
                    }
                ],
                "state": [
                    {
                        "name": "Sydney"
                    },
                    {
                        "name": "Sydney"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.faradaygroup.com.au/wp-content/uploads/Faraday-Group-New-Logo2.jpg"
                    }
                ],
                "hatomfeed": [
                    {}
                ],
                "localbusiness": [
                    {
                        "name": "Faraday Group"
                    },
                    {
                        "name": "Faraday Group"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "john@faradaygroup.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "pauline@faradaygroup.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mail@faradaygroup.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mark@faradaygroup.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Local Electrician Fairfield NSW 2165 - HP Electrical Contractor",
            "htmlTitle": "Local <b>Electrician</b> Fairfield NSW 2165 - HP Electrical Contractor",
            "link": "https://hpelectricalcontractor.com.au/electrician-fairfield/",
            "displayLink": "hpelectricalcontractor.com.au",
            "snippet": "Best Electrician Fairfield. Certified Level 2 and Master Electrician ... Level 2 electrician servicing Fairfield, Parramatta, Liverpool & Western Sydney.",
            "htmlSnippet": "<b>Best Electrician</b> Fairfield. Certified Level 2 and Master <b>Electrician</b> ... Level 2 <b>electrician</b> servicing Fairfield, Parramatta, Liverpool &amp; Western <b>Sydney</b>.",
            "formattedUrl": "https://hpelectricalcontractor.com.au/electrician-fairfield/",
            "htmlFormattedUrl": "https://hpelectricalcontractor.com.au/<b>electrician</b>-fairfield/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWqf26EutVyHwYFQFokr8q_oF-YtMr1pPL4i2ITzp1Ylm22H8ruMrR8V4u&s",
                        "width": "254",
                        "height": "199"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://hpelectricalcontractor.com.au/wp-content/uploads/2019/04/Best-Electrician-Fairfield-1.jpg",
                        "og:image:width": "500",
                        "article:published_time": "2019-06-22T06:58:10+00:00",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "HP Electrical Contractor",
                        "twitter:label1": "Time to read",
                        "og:image:type": "image/jpeg",
                        "msapplication-tileimage": "https://hpelectricalcontractor.com.au/wp-content/uploads/2018/10/Level-2-Electrician-Western-Sydney.jpg",
                        "og:description": "Fairfield East, we are your local Fairfield residential electrician. There is no job too big or small, also available 24/7 for electrical emergencies.",
                        "og:image:secure_url": "https://hpelectricalcontractor.com.au/wp-content/uploads/2019/04/Best-Electrician-Fairfield-1.jpg",
                        "twitter:image": "https://hpelectricalcontractor.com.au/wp-content/uploads/2019/04/Best-Electrician-Fairfield-1.jpg",
                        "twitter:data1": "4 minutes",
                        "article:modified_time": "2024-04-01T07:27:46+00:00",
                        "og:type": "article",
                        "article:section": "24 Hr Emergency Electrician",
                        "og:image:alt": "Best Electrician Fairfield",
                        "twitter:title": "Local Electrician Fairfield NSW 2165 - HP Electrical Contractor",
                        "og:title": "Local Electrician Fairfield NSW 2165 - HP Electrical Contractor",
                        "og:image:height": "392",
                        "og:updated_time": "2024-04-01T07:27:46+00:00",
                        "article:tag": "Electrician Sydney",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Fairfield East, we are your local Fairfield residential electrician. There is no job too big or small, also available 24/7 for electrical emergencies.",
                        "og:locale": "en_US",
                        "og:url": "https://hpelectricalcontractor.com.au/electrician-fairfield/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://hpelectricalcontractor.com.au/wp-content/uploads/2019/04/Best-Electrician-Fairfield-1.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@hpelectricalcontractor.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "recommend a fair electrician in Sydney - Home",
            "htmlTitle": "recommend a fair <b>electrician</b> in <b>Sydney</b> - Home",
            "link": "https://forums.whirlpool.net.au/archive/2526521",
            "displayLink": "forums.whirlpool.net.au",
            "snippet": "May 2, 2016 ... Small jobs will often have a premium attached as it needs to be worth the trades time to do such a small job. Best you can do as said is get a ...",
            "htmlSnippet": "May 2, 2016 <b>...</b> Small jobs will often have a premium attached as it needs to be worth the trades time to do such a small job. <b>Best</b> you can do as said is get a&nbsp;...",
            "formattedUrl": "https://forums.whirlpool.net.au/archive/2526521",
            "htmlFormattedUrl": "https://forums.whirlpool.net.au/archive/2526521",
            "pagemap": {
                "metatags": [
                    {
                        "application-name": "Whirlpool",
                        "msapplication-tilecolor": "#3A437B",
                        "og:type": "article",
                        "og:site_name": "Whirlpool.net.au",
                        "handheldfriendly": "True",
                        "viewport": "width=device-width, initial-scale=1",
                        "apple-mobile-web-app-title": "Whirlpool",
                        "og:locale": "en_AU",
                        "msapplication-tileimage": "https://whirlpool.net.au/skin/image/whirlpool-2015-icon-152.png",
                        "format-detection": "telephone=no"
                    }
                ],
                "listitem": [
                    {
                        "item": "Forums",
                        "name": "Forums",
                        "position": "1"
                    },
                    {
                        "item": "Home",
                        "name": "Home",
                        "position": "2"
                    },
                    {
                        "item": "https://forums.whirlpool.net.au/thread/9kmvrpn9",
                        "name": "recommend a fair electrician in Sydney",
                        "position": "3"
                    },
                    {
                        "item": "Forums",
                        "name": "Forums",
                        "position": "1"
                    },
                    {
                        "item": "Home",
                        "name": "Home",
                        "position": "2"
                    },
                    {
                        "item": "https://forums.whirlpool.net.au/thread/9kmvrpn9",
                        "name": "recommend a fair electrician in Sydney",
                        "position": "3"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "legal@whirlpool.net.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "simon@whirlpool.net.au",
                    "position": "Owner Operator",
                    "firstName": "Simon",
                    "lastName": "Wright",
                    "companyName": "Whirlpool.net.au",
                    "sourcePage": "https://www.linkedin.com/in/simondotau",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "phil@whirlpool.net.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "survey@whirlpool.net.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "dns@whirlpool.net.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "bc@whirlpool.net.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Best Sydney Electrician | NSW - Trusted Tradie",
            "htmlTitle": "<b>Best Sydney Electrician</b> | NSW - Trusted Tradie",
            "link": "https://www.trustedtradie.com.au/nsw-best-electrician/sydney-electrical-contractors",
            "displayLink": "www.trustedtradie.com.au",
            "snippet": "Jun 23, 2016 ... We provide services created to carry out the electrical needs of the business owners and residents in the Sydney NSW area and local nearby suburbs.",
            "htmlSnippet": "Jun 23, 2016 <b>...</b> We provide services created to carry out the electrical needs of the business owners and residents in the <b>Sydney</b> NSW area and local nearby suburbs.",
            "formattedUrl": "https://www.trustedtradie.com.au/...best-electrician/sydney-electrical-contra...",
            "htmlFormattedUrl": "https://www.trustedtradie.com.au/...<b>best</b>-<b>electrician</b>/<b>sydney</b>-electrical-contra...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA1toF-0AHqkTHOAGq5cv5N2gM33kSOx-PuSGygfQAF8qHQa2IGCu8ZjY&s",
                        "width": "199",
                        "height": "253"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.trustedtradie.com.au/wp-content/uploads/2016/09/electrician-236x300.jpg",
                        "og:type": "article",
                        "twitter:card": "summary",
                        "og:site_name": "Trusted Tradie",
                        "og:title": "Best Sydney Electrician | NSW - Trusted Tradie",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Est. reading time",
                        "og:description": "Looking for the best electricians in Sydney NSW? Look no further TrustedTradie finds the best and most highly rated Sydney electricians around for you.",
                        "twitter:data1": "bentrueman",
                        "twitter:data2": "9 minutes",
                        "article:modified_time": "2018-06-07T05:32:56+00:00",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "og:locale": "en_US",
                        "og:url": "https://www.trustedtradie.com.au/nsw-best-electrician/sydney-electrical-contractors"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.trustedtradie.com.au/wp-content/uploads/2016/09/electrician-236x300.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "ben@trustedtradie.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Delta Construction Partners, Inc. on LinkedIn: #electricalforeman ...",
            "htmlTitle": "Delta Construction Partners, Inc. on LinkedIn: #electricalforeman ...",
            "link": "https://www.linkedin.com/posts/delta-construction-partners-inc-_electricalforeman-electricalsuperintendent-activity-7244353844548108289-yLZ2",
            "displayLink": "www.linkedin.com",
            "snippet": "Sep 24, 2024 ... Leading an exceptional team of electricians, servicing homes and businesses across Southern Sydney. ... Best Electrician in Bristol & Bath. https ...",
            "htmlSnippet": "Sep 24, 2024 <b>...</b> Leading an exceptional team of <b>electricians</b>, servicing homes and businesses across Southern <b>Sydney</b>. ... <b>Best Electrician</b> in Bristol &amp; Bath. https&nbsp;...",
            "formattedUrl": "https://www.linkedin.com/.../delta-construction-partners-inc-_electricalfore...",
            "htmlFormattedUrl": "https://www.linkedin.com/.../delta-construction-partners-inc-_electricalfore...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwtIm-7TURz9KXLokESp2Vgtp9VEyTc_6uWLiX5pnYbjIEDMpLcyEmlYkz&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://media.licdn.com/dms/image/v2/D4E22AQEtqq9YZw_Sww/feedshare-shrink_800/feedshare-shrink_800/0/1727188549166?e=2147483647&v=beta&t=NeizzN2SGrfO-nBf_CrcP3krVHKBYievhVxHzf-hXS8",
                        "twitter:title": "Delta Construction Partners, Inc. on LinkedIn: #electricalforeman #electricalsuperintendent #electricaljobs…",
                        "twitter:card": "summary_large_image",
                        "og:type": "article",
                        "al:ios:app_name": "LinkedIn",
                        "twitter:url": "https://www.linkedin.com/posts/delta-construction-partners-inc-_electricalforeman-electricalsuperintendent-activity-7244353844548108289-yLZ2",
                        "og:title": "Delta Construction Partners, Inc. on LinkedIn: #electricalforeman #electricalsuperintendent #electricaljobs…",
                        "al:android:package": "com.linkedin.android",
                        "pagekey": "p_public_post",
                        "locale": "en_US",
                        "al:ios:url": "https://www.linkedin.com/posts/delta-construction-partners-inc-_electricalforeman-electricalsuperintendent-activity-7244353844548108289-yLZ2",
                        "og:description": "HOT JOB – Electrical Foreman / Superintendent in Naples, FL\n\nCONTACT\nKendall Magnan\n727-513-8832\nKendall@DeltaConstructionPartners.com\n\n➡️ Explore other job…",
                        "al:ios:app_store_id": "288429040",
                        "twitter:image": "https://media.licdn.com/dms/image/v2/D4E22AQEtqq9YZw_Sww/feedshare-shrink_800/feedshare-shrink_800/0/1727188549166?e=2147483647&v=beta&t=NeizzN2SGrfO-nBf_CrcP3krVHKBYievhVxHzf-hXS8",
                        "al:android:url": "https://www.linkedin.com/posts/delta-construction-partners-inc-_electricalforeman-electricalsuperintendent-activity-7244353844548108289-yLZ2",
                        "lnkd:url": "https://www.linkedin.com/feed/update/urn:li:activity:7244353844548108289",
                        "twitter:site": "@linkedin",
                        "viewport": "width=device-width, initial-scale=1.0",
                        "twitter:description": "HOT JOB – Electrical Foreman / Superintendent in Naples, FL\n\nCONTACT\nKendall Magnan\n727-513-8832\nKendall@DeltaConstructionPartners.com\n\n➡️ Explore other job…",
                        "og:url": "https://www.linkedin.com/posts/delta-construction-partners-inc-_electricalforeman-electricalsuperintendent-activity-7244353844548108289-yLZ2",
                        "al:android:app_name": "LinkedIn"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://media.licdn.com/dms/image/v2/D4E22AQEtqq9YZw_Sww/feedshare-shrink_800/feedshare-shrink_800/0/1727188549166?e=2147483647&v=beta&t=NeizzN2SGrfO-nBf_CrcP3krVHKBYievhVxHzf-hXS8"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "press@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "lcsfeedback@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "pulse-support@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Accommodations@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "rhoffman@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "invitations@linkedin.com",
                    "firstName": "Hubert",
                    "lastName": "PATURAL",
                    "sourcePage": "https://www.linkedin.com/in/hubert-patural-de-chabet-193b5227",
                    "isInCRM": {
                        "details": {
                            "id": "67062001",
                            "properties": {
                                "createdate": "2023-09-02T10:23:32.546Z",
                                "email": "invitations@linkedin.com",
                                "firstname": "Sue",
                                "hs_object_id": "67062001",
                                "lastmodifieddate": "2024-12-09T17:38:31.030Z",
                                "lastname": "Lu Silva via LinkedIn"
                            },
                            "createdAt": "2023-09-02T10:23:32.546Z",
                            "updatedAt": "2024-12-09T17:38:31.030Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "updates@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Connections@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyrightagent@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@linkedin.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Local Residential & Commercial Electrician|Northern Beaches",
            "htmlTitle": "Local Residential &amp; Commercial <b>Electrician</b>|Northern Beaches",
            "link": "https://mattyjameselectrical.com.au/",
            "displayLink": "mattyjameselectrical.com.au",
            "snippet": "best ... electrical services. Some of the primary ... electrical services to the residents of Sydney for over 25 years.",
            "htmlSnippet": "<b>best</b> ... electrical services. Some of the primary ... electrical services to the residents of <b>Sydney</b> for over 25 years.",
            "formattedUrl": "https://mattyjameselectrical.com.au/",
            "htmlFormattedUrl": "https://mattyjameselectrical.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtEGveAfRNiyhytTsfnvbAT9Zy6gaEihE6HngDFZR_iisSU9lveSc1drU&s",
                        "width": "295",
                        "height": "171"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://mattyjameselectrical.com.au/wp-content/uploads/2022/11/2-1-e1669150108522.png",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Local Residential & Commercial Electrician|Northern Beaches",
                        "og:site_name": "Matty James Electrical - Trusted electrical company in the Northern Beaches",
                        "og:title": "Local Residential & Commercial Electrician|Northern Beaches",
                        "msapplication-tileimage": "https://mattyjameselectrical.com.au/wp-content/uploads/2022/11/MJ-Electrical-Favicon-300x300.png",
                        "og:description": "Need an experienced and reliable electrician? Look no further! We have been providing high-quality residential and commercial electrical services for 25 years.",
                        "og:image:secure_url": "https://mattyjameselectrical.com.au/wp-content/uploads/2022/11/2-1-e1669150108522.png",
                        "twitter:image": "https://mattyjameselectrical.com.au/wp-content/uploads/2022/11/2-1-e1669150108522.png",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Need an experienced and reliable electrician? Look no further! We have been providing high-quality residential and commercial electrical services for 25 years.",
                        "og:locale": "en_US",
                        "og:url": "https://mattyjameselectrical.com.au/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://mattyjameselectrical.com.au/wp-content/uploads/2022/11/2-1-e1669150108522.png"
                    }
                ]
            },
            "contacts": []
        },
        {
            "kind": "customsearch#result",
            "title": "2023 Excellence Award Winners - MEA - Master Electricians Australia",
            "htmlTitle": "2023 Excellence Award Winners - MEA - Master <b>Electricians</b> Australia",
            "link": "https://www.masterelectricians.com.au/2023-excellence-award-winners/",
            "displayLink": "www.masterelectricians.com.au",
            "snippet": "Apr 3, 2024 ... Across the nation, we shone a spotlight on the best in each state category, leading up to a momentous live-streamed national awards ceremony. It ...",
            "htmlSnippet": "Apr 3, 2024 <b>...</b> Across the nation, we shone a spotlight on the <b>best</b> in each state category, leading up to a momentous live-streamed national awards ceremony. It&nbsp;...",
            "formattedUrl": "https://www.masterelectricians.com.au/2023-excellence-award-winners/",
            "htmlFormattedUrl": "https://www.master<b>electrician</b>s.com.au/2023-excellence-award-winners/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhyY1_XbKLE-EzxtyOTXykEywpG_rwme1vV-_BYfy3-gtRdtByBEbro9g&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "application-name": "MEA",
                        "og:image": "https://dev.masterelectricians.com.au/wp-content/uploads/2024/01/SA-Proj-less-1m-Electric-Barossa-TAFE-SlideB-HERO-1-e1691457807793-300x300-1.png",
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "og:image:width": "7930",
                        "theme-color": "#fff",
                        "og:site_name": "MEA - Master Electricians Australia",
                        "tec-api-origin": "https://www.masterelectricians.com.au",
                        "og:title": "2023 Excellence Award Winners",
                        "apple-mobile-web-app-title": "MEA",
                        "twitter:label1": "Est. reading time",
                        "og:image:height": "2091",
                        "og:image:type": "image/png",
                        "msapplication-tileimage": "https://www.masterelectricians.com.au/wp-content/uploads/2023/09/cropped-MEA_logo-32x32-1.png",
                        "og:description": "2023 Winners \r\n\r\nELECTRICAL INDUSTRY    \r\n\r\nJoin us in celebrating the outstanding winners of the Electrical Industry Excellence Awards 2023! Across the nation, we shone a spotlight on the best in each state category, leading up to a momentous live-streamed national awards ceremony. It was an unforgettable night, filled with laughter and",
                        "twitter:data1": "48 minutes",
                        "article:modified_time": "2024-04-03T05:08:05+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "apple-mobile-web-app-capable": "yes",
                        "og:locale": "en_US",
                        "mobile-web-app-capable": "yes",
                        "og:url": "https://www.masterelectricians.com.au/2023-excellence-award-winners/",
                        "tec-api-version": "v1"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://dev.masterelectricians.com.au/wp-content/uploads/2024/01/SA-Proj-less-1m-Electric-Barossa-TAFE-SlideB-HERO-1-e1691457807793-300x300-1.png"
                    }
                ],
                "hatomfeed": [
                    {}
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "technical@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mcooper@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "training@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "raining@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "ining@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "safety@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "dgordon@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sbrne@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "ntran@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "ecampbell@masterelectricians.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "What Electrical Conduit To Use Outdoors? | Finnley Electrical",
            "htmlTitle": "What Electrical Conduit To Use Outdoors? | Finnley Electrical",
            "link": "https://www.finnleyelectrical.com.au/what-electrical-conduit-to-use-outdoors/",
            "displayLink": "www.finnleyelectrical.com.au",
            "snippet": "Using proper outdoor electrical conduit ensures the safety of the electrical ... Level 2 Electrician Sydney · Powerpoint ... Back to top. Page load link.",
            "htmlSnippet": "Using proper outdoor electrical conduit ensures the safety of the electrical ... Level 2 <b>Electrician Sydney</b> &middot; Powerpoint ... Back to <b>top</b>. Page load link.",
            "formattedUrl": "https://www.finnleyelectrical.com.au/what-electrical-conduit-to-use-outdoors/",
            "htmlFormattedUrl": "https://www.finnleyelectrical.com.au/what-electrical-conduit-to-use-outdoors/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRr96oiCEiNE2sfEnE-K4x--RK--zvIU35973fj0-s7YQUBn1UtBEiJ178&s",
                        "width": "306",
                        "height": "165"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.finnleyelectrical.com.au/wp-content/uploads/2023/04/Outdoor-Electrical-Conduit.jpg",
                        "og:type": "article",
                        "article:published_time": "2023-04-07T04:02:29+00:00",
                        "og:image:width": "1200",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Finnley Electrical",
                        "author": "Greg",
                        "og:title": "What Electrical Conduit To Use Outdoors? | Finnley Electrical",
                        "og:image:height": "647",
                        "twitter:label1": "Written by",
                        "twitter:label2": "Est. reading time",
                        "og:image:type": "image/jpeg",
                        "og:description": "Choosing the right electrical conduit for outdoor use is crucial for ensuring the safety and longevity of your electrical installations.",
                        "twitter:data1": "Greg",
                        "twitter:data2": "14 minutes",
                        "article:modified_time": "2023-09-07T00:49:47+00:00",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_US",
                        "og:url": "https://www.finnleyelectrical.com.au/what-electrical-conduit-to-use-outdoors/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.finnleyelectrical.com.au/wp-content/uploads/2023/04/Outdoor-Electrical-Conduit.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "admin@finnleyelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Insulated Linesman Pliers | Sydney Tools",
            "htmlTitle": "Insulated Linesman Pliers | <b>Sydney</b> Tools",
            "link": "https://sydneytools.com.au/category/hand-tools/linesman-pliers",
            "displayLink": "sydneytools.com.au",
            "snippet": "A must-have in every electrician's arsenal, linesman pliers do all the work when it comes to handling wires - cutting, bending, ...",
            "htmlSnippet": "A must-have in every <b>electrician&#39;s</b> arsenal, linesman pliers do all the work when it comes to handling wires - cutting, bending,&nbsp;...",
            "formattedUrl": "https://sydneytools.com.au/category/hand-tools/linesman-pliers",
            "htmlFormattedUrl": "https://<b>sydney</b>tools.com.au/category/hand-tools/linesman-pliers",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi733Y2N3iUp8wgKd6wooDD8R1zMBDpoBqKfjoKlkJoqfynafQdW7iZgs&s",
                        "width": "225",
                        "height": "225"
                    }
                ],
                "metatags": [
                    {
                        "msapplication-tilecolor": "#00aba9",
                        "theme-color": "#cb0000",
                        "og:site_name": "Sydney Tools",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_AU"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://sydneytools.com.au/assets/images/products/1/7/1/3/17134/3D5E3F3A8F8EF0EAC53D59CDCE09126B8CDE55FCEE70C952F3169F5CF01E151A/512x512/3D5E3F3A8F8EF0EAC53D59CDCE09126B8CDE55FCEE70C952F3169F5CF01E151A.jpeg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "nathanboss@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "chad@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "dispatchtarenpoint@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "jobs@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "nt@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "oint@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "ONinfo@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "returns@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "import@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "used@sydneytools.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "School of Electrical and Computer Engineering - Faculty of ...",
            "htmlTitle": "School of Electrical and Computer Engineering - Faculty of ...",
            "link": "https://www.sydney.edu.au/engineering/schools/school-of-electrical-and-computer-engineering.html",
            "displayLink": "www.sydney.edu.au",
            "snippet": "Whether you're a prospective student, industry contact or academic, find out how to best connect with us or make an enquiry. Careers for Sydney Horizon ...",
            "htmlSnippet": "Whether you&#39;re a prospective student, industry contact or academic, find out how to <b>best</b> connect with us or make an enquiry. Careers for <b>Sydney</b> Horizon&nbsp;...",
            "formattedUrl": "https://www.sydney.edu.au/.../school-of-electrical-and-computer-engineerin...",
            "htmlFormattedUrl": "https://www.<b>sydney</b>.edu.au/.../school-of-electrical-and-computer-engineerin...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg5XbO1WdepeIti6O6f8v39K3VanI6AnuE_TcCwNZ6tVHA3xiBsal2PfY&s=0",
                        "width": "149",
                        "height": "63"
                    }
                ],
                "metatags": [
                    {
                        "dc.modified": "23/09/2024",
                        "og:image": "https://www.sydney.edu.au/content/dam/corporate/images/faculty-of-engineering-and-information-technologies/research/energy-resources-environment/wind-farm-water.jpeg",
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "School of Electrical and Computer Engineering",
                        "og:site_name": "The University of Sydney",
                        "languages": "en-US=English",
                        "og:title": "School of Electrical and Computer Engineering",
                        "exf-header-data-path": "/content/experience-fragments/corporate/header/corporate-local-site/jcr:content/root/exf-header.engineering.model.json",
                        "og:description": "We undertake research across a range of electrical engineering and information disciplines, emphasising future energy networks, wireless communications, computer, software, photonics and the Internet of Things.",
                        "og:image:secure_url": "https://www.sydney.edu.au/content/dam/corporate/images/faculty-of-engineering-and-information-technologies/research/energy-resources-environment/wind-farm-water.jpeg",
                        "twitter:image": "https://www.sydney.edu.au/content/dam/corporate/images/faculty-of-engineering-and-information-technologies/research/energy-resources-environment/wind-farm-water.jpeg",
                        "dc.created": "2024-09-23",
                        "fb:app_id": "2237644793183672",
                        "twitter:site": "@Sydney_Uni",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "We undertake research across a range of electrical engineering and information disciplines, emphasising future energy networks, wireless communications, computer, software, photonics and the Internet of Things.",
                        "fb:admins": "5755052206",
                        "alexaverifyid": "Q2-0ySF2g20GteljJn9AeJRV01k",
                        "og:url": "https://www.sydney.edu.au/engineering/schools/school-of-electrical-and-computer-engineering.html"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.sydney.edu.au/content/dam/corporate/images/faculty-of-engineering-and-information-technologies/research/energy-resources-environment/wind-farm-water.jpeg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "colombina.schaeffer@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "graduate.studies@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "io.mail@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "research.training@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "recruitment@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "karanjeet.sandhu@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "ashlie.hartigan@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "patricia.martin@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "mark.krockenberger@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "derek.spielman@sydney.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Jim's Electrical - Jim's Electricians provide great prices, best service ...",
            "htmlTitle": "Jim&#39;s Electrical - Jim&#39;s <b>Electricians</b> provide great prices, <b>best</b> service ...",
            "link": "https://www.jimselectrical.com.au/",
            "displayLink": "www.jimselectrical.com.au",
            "snippet": "Jim's Electrical team of Electricians provide free quotes, and electrical services at very competitive rates. Jim's Electrical provide Sydney Electricians, ...",
            "htmlSnippet": "Jim&#39;s Electrical team of <b>Electricians</b> provide free quotes, and electrical services at very competitive rates. Jim&#39;s Electrical provide <b>Sydney Electricians</b>,&nbsp;...",
            "formattedUrl": "https://www.jimselectrical.com.au/",
            "htmlFormattedUrl": "https://www.jimselectrical.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdoVHNYMyMV8M3ehv7rIiCyOv6DXM3GsRwx1Uk78ChtYiMIIUyGKIvxqA&s",
                        "width": "265",
                        "height": "190"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.jimselectrical.com.au/images/navigation/jims-electrical-logo-electrician.jpg",
                        "theme-color": "#004e96",
                        "og:type": "website",
                        "og:site_name": "Jim's Electrical",
                        "og:title": "Jim's Electrical - Jim's Electricians provide great prices, best service, call today | Sydney Electrician, Brisbane Electrician, Melbourne Electrician, Adelaide Electrician, Perth Electrician",
                        "rating": "General",
                        "classification": "Business",
                        "distribution": "Global",
                        "og:description": "Jim's Electrical team of Electricians provide free quotes, and electrical services at very competitive rates. Jim's Electrical provide Sydney Electricians, Adelaide Electricians, Perth Electricians, Brisbane Electricians, and Melbourne Electricians.",
                        "facebook-domain-verification": "8x81121v5yzg12v9g1pqcpil5h1mmv",
                        "viewport": "width=device-width, initial-scale=1",
                        "og:locale": "en_AU",
                        "og:url": "https://www.jimselectrical.com.au/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.jimselectrical.com.au/images/navigation/jims-electrical-logo-electrician.jpg"
                    }
                ],
                "sitenavigationelement": [
                    {
                        "name": "Adelaide Electrician",
                        "url": "Adelaide Electrician"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "office@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "james.wrest@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "daniel.tabikh@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "shaun.mealy@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "cameron.scott@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "admin@jimselectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrician Sydney - Silver Water Plumbing",
            "htmlTitle": "<b>Electrician Sydney</b> - Silver Water Plumbing",
            "link": "https://silverwaterplumbing.com.au/electrician-sydney/",
            "displayLink": "silverwaterplumbing.com.au",
            "snippet": "As one of the best electricians in Sydney, we know that having an efficient and reliable emergency electrician service is very essential. With our variety of ...",
            "htmlSnippet": "As one of the <b>best electricians</b> in <b>Sydney</b>, we know that having an efficient and reliable emergency <b>electrician</b> service is very essential. With our variety of&nbsp;...",
            "formattedUrl": "https://silverwaterplumbing.com.au/electrician-sydney/",
            "htmlFormattedUrl": "https://silverwaterplumbing.com.au/<b>electrician</b>-<b>sydney</b>/",
            "pagemap": {
                "product": [
                    {
                        "name": "Silver Water Plumbing"
                    }
                ],
                "aggregaterating": [
                    {
                        "ratingvalue": "4.9",
                        "reviewcount": "700+"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://silverwaterplumbing.com.au/wp-content/uploads/2023/08/electrician-sydney.jpg",
                        "og:type": "article",
                        "og:image:width": "892",
                        "og:image:alt": "electrician-sydney",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Electrician Sydney - Silver Water Plumbing",
                        "og:site_name": "Silver Water Plumbing",
                        "og:title": "Electrician Sydney - Silver Water Plumbing",
                        "og:image:height": "270",
                        "twitter:label1": "Time to read",
                        "og:image:type": "image/jpeg",
                        "og:updated_time": "2023-11-06T21:32:02+11:00",
                        "msapplication-tileimage": "https://silverwaterplumbing.com.au/wp-content/uploads/2023/01/favicon.ico",
                        "og:description": "Need an Electrician in Sydney? Same Day Service -- $0* Call Out Fee, Fully Licensed & Insured -- Call Silver Water Plumbing Today on (02) 9099 5759!",
                        "og:image:secure_url": "https://silverwaterplumbing.com.au/wp-content/uploads/2023/08/electrician-sydney.jpg",
                        "twitter:image": "https://silverwaterplumbing.com.au/wp-content/uploads/2023/08/electrician-sydney.jpg",
                        "twitter:data1": "12 minutes",
                        "viewport": "width=device-width, initial-scale=1, minimum-scale=1",
                        "twitter:description": "Need an Electrician in Sydney? Same Day Service -- $0* Call Out Fee, Fully Licensed & Insured -- Call Silver Water Plumbing Today on (02) 9099 5759!",
                        "og:locale": "en_US",
                        "og:url": "https://silverwaterplumbing.com.au/electrician-sydney/"
                    }
                ],
                "hproduct": [
                    {
                        "fn": "Silver Water Plumbing"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@silverwaterplumbing.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "805201",
                            "properties": {
                                "createdate": "2017-09-23T06:30:39.558Z",
                                "email": "info@silverwaterplumbing.com.au",
                                "firstname": "Kamran",
                                "hs_object_id": "805201",
                                "lastmodifieddate": "2024-12-09T13:32:55.613Z",
                                "lastname": "Khan"
                            },
                            "createdAt": "2017-09-23T06:30:39.558Z",
                            "updatedAt": "2024-12-09T13:32:55.613Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "director@silverwaterplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "workorders@silverwaterplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "kamran@silverwaterplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "korcher@silverwaterplumbing.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Home Charging | Tesla Support Australia",
            "htmlTitle": "Home Charging | Tesla Support Australia",
            "link": "https://www.tesla.com/en_au/support/charging/home-charging",
            "displayLink": "www.tesla.com",
            "snippet": "Tesla trains Certified Installers who specialise in electric vehicle charging. These installers can help select the best charging option for your home, provide ...",
            "htmlSnippet": "Tesla trains Certified Installers who specialise in electric vehicle charging. These installers can help select the <b>best</b> charging option for your home, provide&nbsp;...",
            "formattedUrl": "https://www.tesla.com/en_au/support/charging/home-charging",
            "htmlFormattedUrl": "https://www.tesla.com/en_au/support/charging/home-charging",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://digitalassets.tesla.com/tesla-contents/image/upload/f_auto,q_auto/tesla-logo-thumbnail.jpg",
                        "twitter:card": "summary_large_image",
                        "theme-color": "#000000",
                        "og:site_name": "Tesla",
                        "twitter:url": "https://www.tesla.com/en_au/support/charging/home-charging",
                        "msapplication-tileimage": "/themes/custom/tesla_frontend/assets/favicons/mstile-144x144.png",
                        "og:description": "Charging your electric vehicle at home is convenient and cost-effective. Find ways to order, install and experience Tesla home charging.",
                        "twitter:creator": "@tesla",
                        "og:image:secure_url": "https://digitalassets.tesla.com/tesla-contents/image/upload/f_auto,q_auto/tesla-logo-thumbnail.jpg",
                        "twitter:image": "https://digitalassets.tesla.com/tesla-contents/image/upload/f_auto,q_auto/tesla-logo-thumbnail.jpg",
                        "twitter:image:alt": "Tesla logo in gray",
                        "twitter:site": "@tesla",
                        "msapplication-tilecolor": "#cc0000",
                        "og:image:alt": "Tesla logo in gray",
                        "twitter:title": "Home Charging | Tesla Support Australia",
                        "og:image:url": "https://digitalassets.tesla.com/tesla-contents/image/upload/f_auto,q_auto/tesla-logo-thumbnail.jpg",
                        "handheldfriendly": "true",
                        "image:alt_text": "Tesla logo in gray",
                        "og:title": "Home Charging | Tesla Support Australia",
                        "apple-mobile-web-app-status-bar-style": "black",
                        "viewport": "width=device-width, minimum-scale=1.0, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover",
                        "twitter:description": "Charging your electric vehicle at home is convenient and cost-effective. Find ways to order, install and experience Tesla home charging.",
                        "apple-mobile-web-app-capable": "yes",
                        "mobileoptimized": "width",
                        "og:locale": "en_AU",
                        "og:url": "https://www.tesla.com/en_au/support/charging/home-charging"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://digitalassets.tesla.com/tesla-contents/image/upload/f_auto,q_auto/tesla-logo-thumbnail.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "pacpress@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "AAbulkhanou@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "JAguigui@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "upress@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "hina-press@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "predobjednat@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Fremont_Service@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "LasVegas_Service@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Rocklin_Service@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Stuttgart_Sales@tesla.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Contact Us - LME Electrical Services Northern Beaches, Sydney",
            "htmlTitle": "Contact Us - LME Electrical Services Northern Beaches, <b>Sydney</b>",
            "link": "https://www.lmeelectrical.com.au/contact/",
            "displayLink": "www.lmeelectrical.com.au",
            "snippet": "We offer the best electrical services all throughout Sydney but primarily service Upper North Shore, Manly, Mosman, Cremorne, Seaforth, Lane Cove, Balgowlah ...",
            "htmlSnippet": "We offer the <b>best</b> electrical services all throughout <b>Sydney</b> but primarily service Upper North Shore, Manly, Mosman, Cremorne, Seaforth, Lane Cove, Balgowlah&nbsp;...",
            "formattedUrl": "https://www.lmeelectrical.com.au/contact/",
            "htmlFormattedUrl": "https://www.lmeelectrical.com.au/contact/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRf0PtTJcGGjtbH-X6RU_tDFLuhPWE1Vzp0iurNu_TrFgX9h4KgYw8wcwE&s",
                        "width": "163",
                        "height": "82"
                    }
                ],
                "organization": [
                    {
                        "url": "https://www.lmeelectrical.com.au/"
                    },
                    {
                        "url": "https://www.lmeelectrical.com.au/"
                    }
                ],
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1"
                    }
                ],
                "creativework": [
                    {
                        "text": "Company Name : LME Electrical Contractors Pty Ltd ABN : 97 622 768 334 Company Electrical Contractors Licence No. : 319585C Public Liability Policy No. : BP4029913/MSA Workers Compensation..."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.lmeelectrical.com.au/wp-content/uploads/2015/10/logo.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "luke@lmeelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "isaac@lmeelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Emergency Electrician Sydney | Mr Emergency | 30 Minute Service*",
            "htmlTitle": "Emergency <b>Electrician Sydney</b> | Mr Emergency | 30 Minute Service*",
            "link": "https://www.mremergency.com.au/service-areas/nsw/electrician-sydney/",
            "displayLink": "www.mremergency.com.au",
            "snippet": "Mr Emergency Electrical Sydney. Need a local Sydney electrician? Mr Emergency has years of electrical experience and is the top choice for a local electrician ...",
            "htmlSnippet": "Mr Emergency Electrical <b>Sydney</b>. Need a local <b>Sydney electrician</b>? Mr Emergency has years of electrical experience and is the <b>top</b> choice for a local <b>electrician</b>&nbsp;...",
            "formattedUrl": "https://www.mremergency.com.au/service-areas/nsw/electrician-sydney/",
            "htmlFormattedUrl": "https://www.mremergency.com.au/service-areas/nsw/<b>electrician</b>-<b>sydney</b>/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGdN-CCCfylPr8q-At70HlAk9FhonYKEoFsyRuPJRqp14dY7N-It55NKI&s",
                        "width": "457",
                        "height": "110"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "article",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Mr Emergency",
                        "og:title": "Electrician Sydney",
                        "apple-mobile-web-app-title": "Mr Emergency - Plumbing, Electrical & Air Conditioning | Available Now",
                        "msapplication-tileimage": "https://www.mremergency.com.au/wp-content/uploads/2020/03/cropped-logo-header-img-1-270x270.png",
                        "og:description": "Looking for an emergency electrician near you? Call Mr Emergency for electrical services in Sydney, 24/7. We'll be there in 30 minutes*.",
                        "article:modified_time": "2024-11-01T03:51:05+00:00",
                        "viewport": "width=device-width, initial-scale=1, shrink-to-fit=no",
                        "apple-mobile-web-app-capable": "yes",
                        "og:locale": "en_US",
                        "mobile-web-app-capable": "yes",
                        "og:url": "https://www.mremergency.com.au/service-areas/nsw/electrician-sydney/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.mremergency.com.au/wp-content/uploads/2020/03/header-bg-2.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "bookings@mremergency.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@mremergency.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sales@mremergency.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "david@mremergency.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "How to become an Electrician - Salary, Qualifications, Skills ...",
            "htmlTitle": "How to become an <b>Electrician</b> - Salary, Qualifications, Skills ...",
            "link": "https://www.seek.com.au/career-advice/role/electrician",
            "displayLink": "www.seek.com.au",
            "snippet": "Being an electrician in Australia is a dynamic blend of adventure ... The good thingsBeing an electrician is rewarding because it combines hands-on ...",
            "htmlSnippet": "Being an <b>electrician</b> in Australia is a dynamic blend of adventure ... The <b>good</b> thingsBeing an <b>electrician</b> is rewarding because it combines hands-on&nbsp;...",
            "formattedUrl": "https://www.seek.com.au/career-advice/role/electrician",
            "htmlFormattedUrl": "https://www.seek.com.au/career-advice/role/<b>electrician</b>",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWeznv3sx0B0-tb7T1XVucitaYY6eJedMjUjmO48NHYCvbV2FO2JsDBRUc&s",
                        "width": "299",
                        "height": "168"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://cdn.seeklearning.com.au/media/images/career-guide/module/electrician-module.png",
                        "theme-color": "#fff",
                        "twitter:title": "How to become an Electrician - Salary, Qualifications, Skills & Reviews  – SEEK",
                        "twitter:card": "summary",
                        "og:type": "website",
                        "og:site_name": "SEEK",
                        "og:title": "How to become an Electrician - Salary, Qualifications, Skills & Reviews  – SEEK",
                        "og:description": "Thinking of becoming an Electrician? Learn more about the role including reviews from current Electricians tasks and duties, how much Electricians earn in your state, the skills employers are looking for and career pathways.",
                        "twitter:image": "https://cdn.seeklearning.com.au/media/images/career-guide/module/electrician-module.png",
                        "referrer": "no-referrer-when-downgrade",
                        "twitter:site": "@seekjobs",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Thinking of becoming an Electrician? Learn more about the role including reviews from current Electricians tasks and duties, how much Electricians earn in your state, the skills employers are looking for and career pathways.",
                        "og:locale": "en_GB",
                        "og:url": "https://www.seek.com.au/career-advice/role/electrician"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.seeklearning.com.au/media/images/career-guide/module/electrician-module.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "smacartney@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "userhelp@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "designresearch@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "located@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "cwilson@seek.com.au",
                    "firstName": "Chris",
                    "lastName": "Wilson",
                    "sourcePage": "https://www.linkedin.com/in/hootsuitechris/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "kchapman@seek.com.au",
                    "position": "Fraud & Compliance Analyst",
                    "firstName": "Kerry",
                    "lastName": "Chapman",
                    "sourcePage": "https://www.linkedin.com/in/kerry-chapman-69402939",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "nbrolan@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sgray@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "dgill@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jcohen@seek.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "25 Best Electricians in Sydney NSW with Reviews",
            "htmlTitle": "25 <b>Best Electricians</b> in <b>Sydney</b> NSW with Reviews",
            "link": "https://www.wordofmouth.com.au/nsw/sydney/electrician",
            "displayLink": "www.wordofmouth.com.au",
            "snippet": "Spyros Electrical ... “Very happy excellent work done by Spyros. Efficient, knowledgeable. And very honest Highly recommend for any type of electrical work. ”…",
            "htmlSnippet": "Spyros Electrical ... “Very happy excellent work done by Spyros. Efficient, knowledgeable. And very honest Highly recommend for any type of electrical work. ”…",
            "formattedUrl": "https://www.wordofmouth.com.au/nsw/sydney/electrician",
            "htmlFormattedUrl": "https://www.wordofmouth.com.au/nsw/<b>sydney</b>/<b>electrician</b>",
            "pagemap": {
                "metatags": [
                    {
                        "viewport": "width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=2",
                        "csrf-token": "u7hfkLqCnQ59V6yB1rM38n4nBAi+aEqVGkZg5l6xmImJ31VT9pJUOixnsAKK8OqY1R+6+mNq3tLNJDVFWTGoxQ==",
                        "csrf-param": "authenticity_token"
                    }
                ],
                "listitem": [
                    {
                        "item": "NSW",
                        "name": "NSW",
                        "position": "1"
                    },
                    {
                        "item": "Sydney",
                        "name": "Sydney",
                        "position": "2"
                    },
                    {
                        "name": "Electrician",
                        "position": "3"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "hcp@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "joshua.labrooy@wordofmouth.com.au",
                    "position": "Business Solutions Manager",
                    "firstName": "Joshua",
                    "lastName": "L.",
                    "companyName": "Word of Mouth Pty Ltd",
                    "sourcePage": "https://www.linkedin.com/in/joshualabrooy/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "belinda.noel@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "belindan@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "noel.belinda@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "noelb@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "nbelinda@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "noel-belinda@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "bindy@wordofmouth.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Sydney Power Group - 24/7 Emergency Electricians - Asp Level 1, 2 ...",
            "htmlTitle": "<b>Sydney</b> Power Group - 24/7 Emergency <b>Electricians</b> - Asp Level 1, 2 ...",
            "link": "https://sydpower.com.au/",
            "displayLink": "sydpower.com.au",
            "snippet": "This specialty work is only handled by level 2 electricians like the experts only have on our team. When you ask, “Who is the best electrician near me?” you ...",
            "htmlSnippet": "This specialty work is only handled by level 2 <b>electricians</b> like the experts only have on our team. When you ask, “Who is the <b>best electrician</b> near me?” you&nbsp;...",
            "formattedUrl": "https://sydpower.com.au/",
            "htmlFormattedUrl": "https://sydpower.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjrIBT1pu8Qsa1MCNvnQpI5gyRr_F41m7sW2ccJTNo0CxgV0s3MboGTuk&s",
                        "width": "209",
                        "height": "242"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://sydpower.com.au/wp-content/uploads/2022/07/spg-team-photo.jpg",
                        "og:type": "website",
                        "og:image:width": "700",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Sydney Power Group",
                        "og:site_name": "Sydney Power Group",
                        "og:title": "Sydney Power Group",
                        "og:image:height": "486",
                        "og:image:type": "image/jpeg",
                        "og:description": "SPG provides local level 2 electrician services to both residential and commercial customers across the whole metropolitan area.",
                        "article:publisher": "https://www.facebook.com/sydneypowergroup",
                        "twitter:image": "https://sydpower.com.au/wp-content/uploads/2022/07/spg-team-photo.jpg",
                        "article:modified_time": "2024-07-02T02:33:10+00:00",
                        "viewport": "width=device-width",
                        "twitter:description": "SPG provides local level 2 electrician services to both residential and commercial customers across the whole metropolitan area.",
                        "og:locale": "en_US",
                        "og:url": "https://sydpower.com.au/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://sydpower.com.au/wp-content/uploads/2022/07/about-spg3.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@sydpower.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "66142451",
                            "properties": {
                                "createdate": "2023-05-29T05:35:41.955Z",
                                "email": "info@sydpower.com.au",
                                "firstname": "Peter",
                                "hs_object_id": "66142451",
                                "lastmodifieddate": "2024-12-09T17:25:36.435Z",
                                "lastname": "Ngo"
                            },
                            "createdAt": "2023-05-29T05:35:41.955Z",
                            "updatedAt": "2024-12-09T17:25:36.435Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Sydney Electrical Service reviews | ProductReview.com.au",
            "htmlTitle": "<b>Sydney</b> Electrical Service reviews | ProductReview.com.au",
            "link": "https://www.productreview.com.au/listings/sydney-electrical-service",
            "displayLink": "www.productreview.com.au",
            "snippet": "Sydneys Best Emergency Electrician. Exceptional service. You can not meet a more professional electrician. In our situation, we had issues with our power at our ...",
            "htmlSnippet": "Sydneys <b>Best</b> Emergency <b>Electrician</b>. Exceptional service. You can not meet a more professional <b>electrician</b>. In our situation, we had issues with our power at our&nbsp;...",
            "formattedUrl": "https://www.productreview.com.au/listings/sydney-electrical-service",
            "htmlFormattedUrl": "https://www.productreview.com.au/listings/<b>sydney</b>-electrical-service",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjWYskBxVMIx_VUvr1vS_UA2VSqcz6Y23K_IMRIqTULAwIa-QGTMofkrg&s",
                        "width": "310",
                        "height": "163"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://cdn.productreview.com.au/resize/listing-picture/d2d52dc3-25aa-374f-ad25-dbabf1428810?height=630&v=2&width=1200",
                        "theme-color": "#80ba27",
                        "og:type": "website",
                        "fb:app_id": "199019123456013",
                        "og:site_name": "ProductReview.com.au",
                        "viewport": "width=device-width, minimum-scale=1.0, initial-scale=1, viewport-fit=cover",
                        "og:title": "Sydney Electrical Service reviews",
                        "og:url": "https://www.productreview.com.au/listings/sydney-electrical-service",
                        "og:description": "Sydney Electrical Service (Electrician): 4 out of 5 stars from 7 genuine reviews on Australia's largest opinion site ProductReview.com.au."
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cdn.productreview.com.au/resize/listing-picture/d2d52dc3-25aa-374f-ad25-dbabf1428810?height=630&v=2&width=1200"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "bm-support@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "advertise@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "affiliate@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Promotions@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "nathan@productreview.com.au",
                    "position": "Head of Data & Content",
                    "firstName": "Nathan",
                    "lastName": "Stacey",
                    "companyName": "ProductReview.com.au",
                    "sourcePage": "https://www.linkedin.com/in/nathan-stacey-a31207196",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "newsletter@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "confirm@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "contact@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "verify@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Josh@productreview.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Sydney Marine Electrical",
            "htmlTitle": "<b>Sydney</b> Marine Electrical",
            "link": "http://www.sydneymarineelectrical.com.au/",
            "displayLink": "www.sydneymarineelectrical.com.au",
            "snippet": "Sydney Marine Electrical offers electrical repairs, servicing, maintenance and installation for all marine electrics and electronics.",
            "htmlSnippet": "<b>Sydney</b> Marine Electrical offers electrical repairs, servicing, maintenance and installation for all marine electrics and electronics.",
            "formattedUrl": "http://www.sydneymarineelectrical.com.au/",
            "htmlFormattedUrl": "http://www.<b>sydney</b>marineelectrical.com.au/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWylyX347jBCXCd8-JQB6jDwIVfXusc9Rf-REB-Wu359FTbY9bNT0pDcc&s",
                        "width": "332",
                        "height": "152"
                    }
                ],
                "metatags": [
                    {
                        "og:type": "website",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Sydney Marine Electrical - Sydney Marine Electrical",
                        "og:site_name": "Sydney Marine Electrical",
                        "viewport": "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
                        "twitter:description": "Sydney Marine Electrical offers electrical repairs, servicing, maintenance and installation for all marine electrics and electronics.",
                        "og:title": "Sydney Marine Electrical - Sydney Marine Electrical",
                        "og:locale": "en_US",
                        "og:url": "http://www.sydneymarineelectrical.com.au/",
                        "msapplication-tileimage": "http://www.sydneymarineelectrical.com.au/wp-content/uploads/2017/06/cropped-favicon-270x270.png",
                        "og:description": "Sydney Marine Electrical offers electrical repairs, servicing, maintenance and installation for all marine electrics and electronics."
                    }
                ],
                "cse_image": [
                    {
                        "src": "http://www.sydneymarineelectrical.com.au/wp-content/uploads/2013/08/slider10.jpg"
                    }
                ],
                "hatomfeed": [
                    {}
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@sydneymarineelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "wade@sydneymarineelectrical.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Licensed Electrician in Sydney | Electrical Services - Glenco",
            "htmlTitle": "Licensed <b>Electrician</b> in <b>Sydney</b> | Electrical Services - Glenco",
            "link": "https://glenco.com.au/service_category/electrical/",
            "displayLink": "glenco.com.au",
            "snippet": "Glenco is your local Electrician in Sydney. Our electrician's extensive experience means we are well versed in the electrical service codes and regulations.",
            "htmlSnippet": "Glenco is your local <b>Electrician</b> in <b>Sydney</b>. Our <b>electrician&#39;s</b> extensive experience means we are well versed in the electrical service codes and regulations.",
            "formattedUrl": "https://glenco.com.au/service_category/electrical/",
            "htmlFormattedUrl": "https://glenco.com.au/service_category/electrical/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRAUvA-KeB6JTRH-X58bGUlG9NAIFhPQ7D0pt8xBEO7DiqqPCkcSPgA78&s=0",
                        "width": "150",
                        "height": "79"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://glenco.com.au/wp-content/uploads/meta-logo.jpg",
                        "og:type": "article",
                        "og:image:width": "1200",
                        "og:image:alt": "Glenco Logo",
                        "twitter:card": "summary_large_image",
                        "twitter:title": "Licensed Electrician in Sydney | Electrical Services - Glenco",
                        "og:site_name": "Glenco",
                        "og:title": "Licensed Electrician in Sydney | Electrical Services - Glenco",
                        "og:image:height": "630",
                        "twitter:label1": "Services",
                        "og:image:type": "image/jpeg",
                        "msapplication-tileimage": "https://glenco.com.au/wp-content/uploads/fav-icon.png",
                        "og:description": "Glenco is your local Electrician in Sydney. Our electrician's extensive experience means we are well versed in the electrical service codes and regulations.",
                        "article:publisher": "https://www.facebook.com/glencosydney/",
                        "og:image:secure_url": "https://glenco.com.au/wp-content/uploads/meta-logo.jpg",
                        "twitter:image": "https://glenco.com.au/wp-content/uploads/meta-logo.jpg",
                        "twitter:data1": "12",
                        "viewport": "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0",
                        "twitter:description": "Glenco is your local Electrician in Sydney. Our electrician's extensive experience means we are well versed in the electrical service codes and regulations.",
                        "og:locale": "en_US",
                        "og:url": "https://glenco.com.au/service_category/electrical/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://glenco.com.au/wp-content/uploads/meta-logo.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "rvice@glenco.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "service@glenco.com.au",
                    "firstName": "Nicole",
                    "lastName": "Brooke",
                    "sourcePage": "https://www.linkedin.com/in/nicole-brooke-1183346a",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "glenn@glenco.com.au",
                    "firstName": "Glenn",
                    "lastName": "Rosen",
                    "sourcePage": "https://www.linkedin.com/in/glenn-rosen-6b131716/",
                    "isInCRM": {
                        "details": {
                            "id": "2647501",
                            "properties": {
                                "createdate": "2018-05-22T04:28:56.552Z",
                                "email": "glenn@glenco.com.au",
                                "firstname": "Glenn",
                                "hs_object_id": "2647501",
                                "lastmodifieddate": "2024-12-09T13:40:46.571Z",
                                "lastname": "Rosen"
                            },
                            "createdAt": "2018-05-22T04:28:56.552Z",
                            "updatedAt": "2024-12-09T13:40:46.571Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "Brad@glenco.com.au",
                    "position": "Director/Owner",
                    "firstName": "Brad",
                    "lastName": "Rosen",
                    "sourcePage": "https://www.linkedin.com/in/rosenbrad",
                    "isInCRM": {
                        "details": {
                            "id": "696601",
                            "properties": {
                                "createdate": "2017-09-07T03:17:49.435Z",
                                "email": "brad@glenco.com.au",
                                "firstname": "Brad",
                                "hs_object_id": "696601",
                                "lastmodifieddate": "2024-12-09T13:32:31.502Z",
                                "lastname": "Rosen"
                            },
                            "createdAt": "2017-09-07T03:17:49.435Z",
                            "updatedAt": "2024-12-09T13:32:31.502Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "marny@glenco.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "accounts@glenco.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "2603051",
                            "properties": {
                                "createdate": "2018-05-11T06:48:00.746Z",
                                "email": "accounts@glenco.com.au",
                                "firstname": "Accounts",
                                "hs_object_id": "2603051",
                                "lastmodifieddate": "2024-12-09T13:40:34.496Z",
                                "lastname": "- Glenco"
                            },
                            "createdAt": "2018-05-11T06:48:00.746Z",
                            "updatedAt": "2024-12-09T13:40:34.496Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "tealerosen@glenco.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "HR@glenco.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Matt@glenco.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "lara@glenco.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "578551",
                            "properties": {
                                "createdate": "2017-08-11T07:07:23.568Z",
                                "email": "lara@glenco.com.au",
                                "firstname": "Lara",
                                "hs_object_id": "578551",
                                "lastmodifieddate": "2024-12-09T13:31:42.375Z",
                                "lastname": ""
                            },
                            "createdAt": "2017-08-11T07:07:23.568Z",
                            "updatedAt": "2024-12-09T13:31:42.375Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "The Sorry State Of Electrician Training In Australia",
            "htmlTitle": "The Sorry State Of <b>Electrician</b> Training In Australia",
            "link": "https://www.solarquotes.com.au/blog/electrician-training/",
            "displayLink": "www.solarquotes.com.au",
            "snippet": "Jan 25, 2024 ... “Oh, we've been teaching this for a couple of years now, good pickup!” said the instructor. It was painful knowing that fact. The only saving ...",
            "htmlSnippet": "Jan 25, 2024 <b>...</b> “Oh, we&#39;ve been teaching this for a couple of years now, <b>good</b> pickup!” said the instructor. It was painful knowing that fact. The only saving&nbsp;...",
            "formattedUrl": "https://www.solarquotes.com.au/blog/electrician-training/",
            "htmlFormattedUrl": "https://www.solarquotes.com.au/blog/<b>electrician</b>-training/",
            "pagemap": {
                "hcard": [
                    {
                        "fn": "Nathan holt",
                        "photo": "https://secure.gravatar.com/avatar/91eca22e32913df79824dcf7840ca7a6?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Tristan",
                        "nickname": "Tristan",
                        "photo": "https://secure.gravatar.com/avatar/0908d276092b5b9371e45ccdb0a5c94d?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Murray",
                        "nickname": "Murray",
                        "photo": "https://secure.gravatar.com/avatar/687aadba13573c86b41528c533d07fe2?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Tony",
                        "nickname": "Tony",
                        "photo": "https://secure.gravatar.com/avatar/50161aa629d4a33f32c28b556678b17a?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Ralph Blundell",
                        "photo": "https://secure.gravatar.com/avatar/511d380f07c417b0babf3d1904e23e10?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Rod Smith",
                        "photo": "https://secure.gravatar.com/avatar/02fc3c9327eae61477bba593c4bde2a6?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "dave buckingham",
                        "photo": "https://secure.gravatar.com/avatar/1bde7484ed955dc2ca81919b3d7ce52b?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Anthony Bennett",
                        "photo": "https://secure.gravatar.com/avatar/8d69822a88f5710aefc94766e4625993?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "William",
                        "nickname": "William",
                        "photo": "https://secure.gravatar.com/avatar/cd2f091658b4e2660ec5cf842c4eb0d0?s=48&d=monsterid&r=r"
                    },
                    {
                        "fn": "Derek McKinnon",
                        "photo": "https://secure.gravatar.com/avatar/780af993d876cb019c79e5802430a58e?s=48&d=monsterid&r=r"
                    }
                ],
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_0ZFmwwgArPxwYL5-h2_qbITimbDiIdHpCove_SsTDG24sloPEG4AzxU&s",
                        "width": "310",
                        "height": "163"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://www.solarquotes.com.au/blog/wp-content/uploads/2024/01/screw-types.jpg",
                        "og:type": "article",
                        "article:published_time": "2024-01-24T22:00:15+00:00",
                        "og:image:width": "1200",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Solar Quotes Blog",
                        "author": "Anthony Bennett",
                        "og:title": "The Sorry State Of Electrician Training In Australia",
                        "og:image:height": "630",
                        "og:image:type": "image/jpeg",
                        "og:description": "In the middle of a skilled labour crisis, it's equal parts sad and infuriating to report that our training system is failing us.",
                        "twitter:creator": "@solar_quotes",
                        "article:publisher": "https://www.facebook.com/SolarQuotes/",
                        "twitter:site": "@solar_quotes",
                        "article:modified_time": "2024-01-24T23:57:39+00:00",
                        "viewport": "width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no",
                        "og:locale": "en_US",
                        "og:url": "https://www.solarquotes.com.au/blog/electrician-training/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://www.solarquotes.com.au/blog/wp-content/uploads/2024/01/screw-types.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "team@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "nick@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Calculators@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "finnpeacock@solarquotes.com.au",
                    "position": "Founder",
                    "firstName": "Finn",
                    "lastName": "Peacock",
                    "companyName": "SolarQuotes",
                    "sourcePage": "https://www.linkedin.com/in/finnpeacock/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "reviews@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "manager@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "ronald@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "finnp@solarquotes.com.au",
                    "position": "Founder",
                    "firstName": "Finn",
                    "lastName": "Peacock",
                    "companyName": "SolarQuotes",
                    "sourcePage": "https://www.linkedin.com/in/finnpeacock/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "chantel@solarquotes.com.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Electrical Courses | TAFE NSW",
            "htmlTitle": "Electrical Courses | TAFE NSW",
            "link": "https://www.tafensw.edu.au/course-areas/electrotechnology/electrical",
            "displayLink": "www.tafensw.edu.au",
            "snippet": "Who is an electrical course good for? Electrical tradespeople need more than just technical skill and the right training to succeed. It's a complex field with ...",
            "htmlSnippet": "Who is an electrical course <b>good</b> for? Electrical tradespeople need more than just technical skill and the right training to succeed. It&#39;s a complex field with&nbsp;...",
            "formattedUrl": "https://www.tafensw.edu.au/course-areas/electrotechnology/electrical",
            "htmlFormattedUrl": "https://www.tafensw.edu.au/course-areas/electrotechnology/electrical",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPoWDaV6F7xUzFmWmqd_MGFLFTNu6SCUsHIb1vvBElH9Vcd9nIUqhxiHg&s",
                        "width": "275",
                        "height": "183"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://cd.tafensw.edu.au/api/-/media/Images/Course-Areas/Electrotechnology/Electrotech.jpg?rev=2a8ce855af5e4ed9a1c227c6582688ef&sc_lang=en",
                        "twitter:title": "Electrical Courses | TAFE NSW",
                        "twitter:image:alt": "A workshop scene with an electrotechnology teacher guiding four students as they collaborate on a hands-on electrical project.",
                        "og:image:alt": "A workshop scene with an electrotechnology teacher guiding four students as they collaborate on a hands-on electrical project.",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "Learn new skills with the mix of theoretical and practical work with TAFE NSW Electrical Engineering Courses. Start your study with TAFE NSW today!",
                        "og:title": "Electrical Courses | TAFE NSW",
                        "og:description": "Learn new skills with the mix of theoretical and practical work with TAFE NSW Electrical Engineering Courses. Start your study with TAFE NSW today!",
                        "twitter:image": "https://cd.tafensw.edu.au/api/-/media/Images/Course-Areas/Electrotechnology/Electrotech.jpg?rev=2a8ce855af5e4ed9a1c227c6582688ef&sc_lang=en",
                        "og:image:secure_url": "https://cd.tafensw.edu.au/api/-/media/Images/Course-Areas/Electrotechnology/Electrotech.jpg?rev=2a8ce855af5e4ed9a1c227c6582688ef&sc_lang=en"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://cd.tafensw.edu.au/api/-/media/Images/Course-Areas/Electrotechnology/Electrotech.jpg?rev=2a8ce855af5e4ed9a1c227c6582688ef&sc_lang=en"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "NSI.BusinessServices@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "issnsit@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "swsi.isuenquiry@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "nsi.study@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "notVerified",
                    "email": "si.complaints@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Sydney.degree@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sydneycoffeeacademy@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "nsi.newapprenticeships@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "nsi.disabilities@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "telc@tafensw.edu.au",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "The 4 Best Electricians in Sydney, NS",
            "htmlTitle": "The 4 <b>Best Electricians</b> in <b>Sydney</b>, NS",
            "link": "https://reviewsonmywebsite.com/ca/local-businesses/electricians/ns/sydney",
            "displayLink": "reviewsonmywebsite.com",
            "snippet": "The 4 Best Electricians in Sydney, NS: Mackenzie Electrical Services Ltd, 4.1 45 reviews, Sydney, NS, Canada; Rybran Electrical, 4.9 12 reviews, Sydney, NS, ...",
            "htmlSnippet": "The 4 <b>Best Electricians</b> in <b>Sydney</b>, NS: Mackenzie Electrical Services Ltd, 4.1 45 reviews, <b>Sydney</b>, NS, Canada; Rybran Electrical, 4.9 12 reviews, <b>Sydney</b>, NS,&nbsp;...",
            "formattedUrl": "https://reviewsonmywebsite.com/ca/local-businesses/electricians/ns/sydney",
            "htmlFormattedUrl": "https://reviewsonmywebsite.com/ca/local-businesses/<b>electrician</b>s/ns/<b>sydney</b>",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjBtnM3Y5TSkNR0MMNQqOz0s9Tmduby_OuA5YBzRjl_TsqXfO9nAuUoA&s",
                        "width": "322",
                        "height": "87"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://reviewsonmywebsite.com/images/og.jpg",
                        "og:type": "website",
                        "apple-mobile-web-app-status-bar-style": "white",
                        "viewport": "width=device-width, initial-scale=1.0, user-scalable=no",
                        "apple-mobile-web-app-title": "Reviews",
                        "csrf-token": "dQqaZHJtGkELwh2FZNXFoR3Mq70rR9ovdo571Rtx",
                        "og:title": "The 4 Best Electricians in Sydney, NS",
                        "google-adsense-account": "ca-pub-4640760481513580",
                        "og:url": "https://reviewsonmywebsite.com/ca/local-businesses/electricians/ns/sydney",
                        "og:description": "Browse the best Electricians businesses in Sydney, NS and share your own experience.",
                        "facebook-domain-verification": "x6eto66i6eq2641tuaug68q3ieojks"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://reviewsonmywebsite.com/images/og.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@reviewsonmywebsite.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "vitaly@reviewsonmywebsite.com",
                    "position": "Founder / CEO",
                    "firstName": "Vitaly",
                    "lastName": "Motuz",
                    "companyName": "Reviews On My Website",
                    "sourcePage": "https://www.linkedin.com/in/vitalymotuz/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Understanding DIY Electrical Work in Sydney: What You Can and ...",
            "htmlTitle": "Understanding DIY Electrical Work in <b>Sydney</b>: What You Can and ...",
            "link": "https://brightforceelectrical.com.au/blog/what-electrical-work-can-i-do-myself-in-sydney",
            "displayLink": "brightforceelectrical.com.au",
            "snippet": "May 7, 2024 ... Also, safety gear should always be used to eliminate any risks of shock or electrocution. When finding the best electricians in Sydney, always ...",
            "htmlSnippet": "May 7, 2024 <b>...</b> Also, safety gear should always be used to eliminate any risks of shock or electrocution. When finding the <b>best electricians</b> in <b>Sydney</b>, always&nbsp;...",
            "formattedUrl": "https://brightforceelectrical.com.au/.../what-electrical-work-can-i-do-myself...",
            "htmlFormattedUrl": "https://brightforceelectrical.com.au/.../what-electrical-work-can-i-do-myself...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTduntps3_dotp9K6IdSNjWcLGEavsmsOPLFmVdwYZrmuV1A6asOzmyDymi&s",
                        "width": "300",
                        "height": "168"
                    }
                ],
                "thumbnail": [
                    {
                        "src": "https://zeve.au/brightforce/uploads/2022/06/electrical-wires-being-cut.jpg"
                    }
                ],
                "review": [
                    {
                        "ratingstars": "5.0",
                        "ratingcount": "196"
                    }
                ],
                "hreviewaggregate": [
                    {
                        "count": "196"
                    }
                ],
                "aggregaterating": [
                    {
                        "ratingvalue": "4.9",
                        "reviewcount": "196"
                    }
                ],
                "postaladdress": [
                    {
                        "streetaddress": "Sydney"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://zeve.au/brightforce/uploads/2022/06/electrical-wires-being-cut.jpg",
                        "theme-color": "#1B1B1B",
                        "article:published_time": "1970-01-01T10:00:00+10:00",
                        "og:image:width": "1920",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Bright Force Electrical",
                        "og:image:type": "image/jpeg",
                        "geo.position": "-33.8697054;151.2045612",
                        "og:description": "Are you wondering what type of electrical work you can do at home? We have detailed what you can and cannot do without a licensed electrician in Sydney.",
                        "article:publisher": "https://brightforceelectrical.com.au/",
                        "twitter:image": "https://zeve.au/brightforce/uploads/2022/06/electrical-wires-being-cut.jpg",
                        "twitter:image:alt": "Electrical Wires Cut",
                        "article:modified_time": "2024-05-07T12:33:00+10:00",
                        "icbm": "-33.8697054;151.2045612",
                        "thumbnail": "https://zeve.au/brightforce/uploads/2022/06/electrical-wires-being-cut.jpg",
                        "og:type": "article",
                        "article:section": "Electrical",
                        "og:image:alt": "Electrical Wires Cut",
                        "twitter:title": "Understanding DIY Electrical Work in Sydney: What You Can and Can’t Do at Home ‐ Bright Force Electrical",
                        "handheldfriendly": "True",
                        "geo.region": "AU-NSW",
                        "author": "Bright Force Electrical",
                        "og:title": "Understanding DIY Electrical Work in Sydney: What You Can and Can’t Do at Home ‐ Bright Force Electrical",
                        "og:image:height": "1080",
                        "pinterest": "nopin",
                        "ia:markup_url": "https://brightforceelectrical.com.au/blog/what-electrical-work-can-i-do-myself-in-sydney",
                        "og:locale:alternate": "en_US",
                        "viewport": "width=device-width, initial-scale=1, shrink-to-fit=no",
                        "twitter:description": "Are you wondering what type of electrical work you can do at home? We have detailed what you can and cannot do without a licensed electrician in Sydney.",
                        "og:locale": "en_GB",
                        "og:url": "https://brightforceelectrical.com.au/blog/what-electrical-work-can-i-do-myself-in-sydney",
                        "geo.placename": "Seabridge House"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://zeve.au/brightforce/uploads/2022/06/electrical-wires-being-cut.jpg"
                    }
                ],
                "localbusiness": [
                    {
                        "openinghours": "Mo-Su 00:00-23:59",
                        "name": "Bright Force Electrical",
                        "telephone": "1300 724 942",
                        "url": "https://brightforceelectrical.com.au/",
                        "email": "info@brightforceelectrical.com.au",
                        "sameas": "https://www.facebook.com/brightforceelectrical"
                    }
                ],
                "sitenavigationelement": [
                    {
                        "name": "Home",
                        "url": "Home"
                    }
                ],
                "article": [
                    {
                        "image": "https://zeve.au/brightforce/uploads/2022/11/fixing-complex-electrical-problem-at-switchboard.jpg",
                        "headline": "How To Find A Level 2 Electrician In Sydney",
                        "url": "Read More",
                        "thumbnailurl": "https://zeve.au/brightforce/uploads/2022/11/fixing-complex-electrical-problem-at-switchboard-456x283.jpg"
                    },
                    {
                        "image": "https://zeve.au/brightforce/uploads/2024/03/wire-burning-electrical-defect.jpg",
                        "headline": "What Are Electrical Defect Notices in Sydney",
                        "url": "Read More",
                        "thumbnailurl": "https://zeve.au/brightforce/uploads/2024/03/wire-burning-electrical-defect-456x283.jpg"
                    },
                    {
                        "image": "https://zeve.au/brightforce/uploads/2022/06/commercial-electrician-setting-up-equipment.jpg",
                        "headline": "How To Choose A Commercial Electrician In Sydney",
                        "url": "Read More",
                        "thumbnailurl": "https://zeve.au/brightforce/uploads/2022/06/commercial-electrician-setting-up-equipment-456x283.jpg"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "info@brightforceelectrical.com.au",
                    "isInCRM": {
                        "details": {
                            "id": "46723601",
                            "properties": {
                                "createdate": "2021-10-25T10:28:12.005Z",
                                "email": "info@brightforceelectrical.com.au",
                                "firstname": "Daniel",
                                "hs_object_id": "46723601",
                                "lastmodifieddate": "2024-12-09T15:58:18.420Z",
                                "lastname": null
                            },
                            "createdAt": "2021-10-25T10:28:12.005Z",
                            "updatedAt": "2024-12-09T15:58:18.420Z",
                            "archived": false
                        },
                        "isInCRM": true
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "How to be an Electrical Apprentice if you Live in Australia - YouTube",
            "htmlTitle": "How to be an Electrical Apprentice if you Live in Australia - YouTube",
            "link": "https://www.youtube.com/watch?v=dmVRxp1DPII&pp=ygUII3RhZmV2aWM%3D",
            "displayLink": "www.youtube.com",
            "snippet": "Oct 18, 2022 ... ... Electrician or a tradie - If you don't know any tradies; ask your ... How Much Money Do Australian Electricians Make? Corey Bruhn•39K ...",
            "htmlSnippet": "Oct 18, 2022 <b>...</b> ... <b>Electrician</b> or a tradie - If you don&#39;t know any tradies; ask your ... How Much Money Do Australian <b>Electricians</b> Make? Corey Bruhn•39K&nbsp;...",
            "formattedUrl": "https://www.youtube.com/watch?v=dmVRxp1DPII&pp...",
            "htmlFormattedUrl": "https://www.youtube.com/watch?v=dmVRxp1DPII&amp;pp...",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWCnIFQQGby4l69pip71GuW-BauV87nRvhQKAZhuPygbSp6_ZyFDzIUmg&s",
                        "width": "299",
                        "height": "168"
                    }
                ],
                "metatags": [
                    {
                        "apple-itunes-app": "app-id=544007664, app-argument=https://m.youtube.com/watch?v=dmVRxp1DPII&pp=ygUII3RhZmV2aWM%3D&referring_app=com.apple.mobilesafari-smartbanner, affiliate-data=ct=smart_app_banner_polymer&pt=9008",
                        "theme-color": "rgba(0, 0, 0, 0)",
                        "viewport": "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no,",
                        "twitter:url": "https://www.youtube.com/watch?v=dmVRxp1DPII",
                        "og:url": "https://www.youtube.com/watch?v=dmVRxp1DPII"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://i.ytimg.com/vi/dmVRxp1DPII/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDnEr_zSCpb-PAZhdPbc5katwOMQw"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "soon@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "press@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "community@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "site@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "a@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "oma@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "service@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "admin@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "sales@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "webmaster@youtube.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Instagram video by km.electric | Sydney Electrician • Nov 21, 2024 at ...",
            "htmlTitle": "Instagram video by km.electric | <b>Sydney Electrician</b> • Nov 21, 2024 at ...",
            "link": "https://www.instagram.com/kmelectricsydney/reel/DCqk7yLy7cs/",
            "displayLink": "www.instagram.com",
            "snippet": "Nov 21, 2024 ... Photo by km.electric | Sydney Electrician in Sydney, Australia. May be ... We're happy to discuss your needs and recommend the best action.",
            "htmlSnippet": "Nov 21, 2024 <b>...</b> Photo by km.electric | <b>Sydney Electrician</b> in <b>Sydney</b>, Australia. May be ... We&#39;re happy to discuss your needs and recommend the <b>best</b> action.",
            "formattedUrl": "https://www.instagram.com/kmelectricsydney/reel/DCqk7yLy7cs/",
            "htmlFormattedUrl": "https://www.instagram.com/kmelectric<b>sydney</b>/reel/DCqk7yLy7cs/",
            "pagemap": {
                "cse_thumbnail": [
                    {
                        "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNgPBvmLToDjzmqfI8BDveZRmRi9ca0oW0y3yW7ornzQ_q8MRZeK5z6BFQ&s=0",
                        "width": "150",
                        "height": "150"
                    }
                ],
                "metatags": [
                    {
                        "og:image": "https://scontent-atl3-1.cdninstagram.com/v/t51.75761-15/467901103_18121350391403788_8498679336904947130_n.jpg?stp=cmp1_dst-jpg_s640x640&_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=HJGtfOeObrIQ7kNvgFPnMWs&_nc_zt=23&_nc_ht=scontent-atl3-1.cdninstagram.com&_nc_gid=AFAy9LjPh11U5HalMlkqje2&oh=00_AYCneIdnMu2PuSp3Oa4ETFSJpwZ_Atmr6nwl8LaTIBlMgA&oe=67464F41",
                        "theme-color": "#ffffff",
                        "twitter:card": "summary_large_image",
                        "og:site_name": "Instagram",
                        "al:android:package": "com.instagram.android",
                        "bingbot": "noarchive",
                        "medium": "image",
                        "al:ios:url": "instagram://media?id=3506777697869870892",
                        "og:description": "8 likes, 3 comments - kmelectricsydney on November 21, 2024",
                        "twitter:image": "https://scontent-atl3-1.cdninstagram.com/v/t51.75761-15/467901103_18121350391403788_8498679336904947130_n.jpg?stp=cmp1_dst-jpg_s640x640&_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=HJGtfOeObrIQ7kNvgFPnMWs&_nc_zt=23&_nc_ht=scontent-atl3-1.cdninstagram.com&_nc_gid=AFAy9LjPh11U5HalMlkqje2&oh=00_AYCneIdnMu2PuSp3Oa4ETFSJpwZ_Atmr6nwl8LaTIBlMgA&oe=67464F41",
                        "al:ios:app_store_id": "389801252",
                        "twitter:site": "@instagram",
                        "instapp:owner_user_id": "12143051787",
                        "og:type": "article",
                        "twitter:title": "km.electric | Sydney Electrician (@kmelectricsydney) • Instagram reel",
                        "al:ios:app_name": "Instagram",
                        "og:title": "km.electric | Sydney Electrician on Instagram",
                        "twitter:maxage": "86400",
                        "color-scheme": "light",
                        "al:android:url": "https://www.instagram.com/kmelectricsydney/reel/DCqk7yLy7cs/",
                        "fb:app_id": "124024574287414",
                        "apple-mobile-web-app-status-bar-style": "default",
                        "viewport": "width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover",
                        "mobile-web-app-capable": "yes",
                        "og:url": "https://www.instagram.com/kmelectricsydney/reel/DCqk7yLy7cs/",
                        "al:android:app_name": "Instagram"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://lookaside.instagram.com/seo/google_widget/crawler/?media_id=3506551615310200995"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Images@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Instagram@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "liverail.com@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "Hurleylover@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "e@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "u003E@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "us@instagram.com",
                    "position": "Co-Founder",
                    "firstName": "Urna",
                    "lastName": "Sodnomjamts",
                    "companyName": "Association of Mongol Architects",
                    "sourcePage": "https://www.linkedin.com/in/urna-sodnomjamts-9215ab149",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "view@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "multe@instagram.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "de@instagram.com",
                    "position": "Proprietário",
                    "firstName": "Sebastião",
                    "lastName": "Sebastião de Almeida Prado Neto De Almei",
                    "companyName": "Association of Mongol Architects",
                    "sourcePage": "https://www.linkedin.com/in/sebastião-sebastião-de-almeida-prado-neto-de-almei-4411b142/",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Auto Electrician Seven Hills - Seven Hills NSW 2147 - Indeed.com",
            "htmlTitle": "Auto <b>Electrician</b> Seven Hills - Seven Hills NSW 2147 - Indeed.com",
            "link": "https://au.indeed.com/viewjob?jk=b549e3ea9ba443a4",
            "displayLink": "au.indeed.com",
            "snippet": "Our people say the best thing about the job is the inclusive and supportive environment, as well as working close to home. We offer industry-leading rates ...",
            "htmlSnippet": "Our people say the <b>best</b> thing about the job is the inclusive and supportive environment, as well as working close to home. We offer industry-leading rates&nbsp;...",
            "formattedUrl": "https://au.indeed.com/viewjob?jk=b549e3ea9ba443a4",
            "htmlFormattedUrl": "https://au.indeed.com/viewjob?jk=b549e3ea9ba443a4",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://socialmediaimages.indeed.com/job/g3/b549e3ea9ba443a4.png",
                        "twitter:title": "Auto Electrician Seven Hills - Seven Hills NSW 2147 - Indeed.com",
                        "twitter:card": "summary_large_image",
                        "og:image:width": "1200",
                        "og:title": "Auto Electrician Seven Hills - Seven Hills NSW 2147 - Indeed.com",
                        "og:image:height": "630",
                        "google": "notranslate",
                        "og:description": "CDC NSW",
                        "twitter:image": "https://socialmediaimages.indeed.com/job/g3/b549e3ea9ba443a4.png",
                        "twitter:site": "@indeed",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "CDC NSW",
                        "og:url": "https://au.indeed.com/viewjob?from=social_other&jk=b549e3ea9ba443a4"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://socialmediaimages.indeed.com/job/g3/b549e3ea9ba443a4.png"
                    }
                ]
            },
            "contacts": [
                {
                    "type": "email",
                    "status": "verified",
                    "email": "1558121424-2914-1-git-send-email-chiluk+linux@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "opt-out@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "copyright@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "support@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "impressum@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "jobquality@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "email",
                    "status": "verified",
                    "email": "hostmaster@indeed.com",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "laurah@indeed.com",
                    "firstName": "Laura",
                    "lastName": "Harbison",
                    "companyName": "Indeed.com",
                    "sourcePage": "https://www.linkedin.com/in/laura-harbison-a4597855",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "agnes@indeed.com",
                    "position": "Internal Careers Specialist, Global Talent Attraction",
                    "firstName": "AGNES",
                    "lastName": "ipark",
                    "companyName": "Indeed.com",
                    "sourcePage": "https://www.linkedin.com/in/agnes-p",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                },
                {
                    "type": "prospect",
                    "status": "verified",
                    "email": "aidan@indeed.com",
                    "firstName": "Aidan",
                    "lastName": "Hanway",
                    "companyName": "Indeed.com",
                    "sourcePage": "https://www.linkedin.com/in/aidan-hanway-46b2aa84",
                    "isInCRM": {
                        "details": null,
                        "isInCRM": false
                    }
                }
            ]
        },
        {
            "kind": "customsearch#result",
            "title": "Double Award-Winner:... - Australian Scaffolds & Access | Facebook",
            "htmlTitle": "Double Award-Winner:... - Australian Scaffolds &amp; Access | Facebook",
            "link": "https://m.facebook.com/story.php/?story_fbid=881994770777428&id=100069007405841",
            "displayLink": "m.facebook.com",
            "snippet": "... NSW 2024 and the National Award for Best Scaffolding Project in Australia 2024! ... Electrician #SafetyFirst #AustralianScaffolds #TrustedSince1997 #butlin ...",
            "htmlSnippet": "... NSW 2024 and the National Award for <b>Best</b> Scaffolding Project in Australia 2024! ... <b>Electrician</b> #SafetyFirst #AustralianScaffolds #TrustedSince1997 #butlin&nbsp;...",
            "formattedUrl": "https://m.facebook.com/story.php/?story_fbid=881994770777428&id...",
            "htmlFormattedUrl": "https://m.facebook.com/story.php/?story_fbid=881994770777428&amp;id...",
            "pagemap": {
                "metatags": [
                    {
                        "og:image": "https://external-atl3-2.xx.fbcdn.net/emg1/v/t13/11560305568378648053?url=https%3a%2f%2fi.ytimg.com%2fvi%2fxnXxQR-iJ6I%2fhqdefault.jpg%3fsqp%3d-oaymwEmCOADEOgC8quKqQMa8AEB-AHUBoAC4AOKAgwIABABGGsgayhrMA8%3d%26rs%3dAOn4CLBCegrYYTqJdULjcuhENoyNIvBslg&fb_obo=1&utld=ytimg.com&stp=c0.5000x0.5000f_dst-jpg_flffffff_p600x600_q75&ccb=13-1&oh=06_Q399HGIiXz36qtI-GKHzbIJUQ2Tjo8GpCeApeGWtkXG17EM&oe=673EEA0F&_nc_sid=867500",
                        "og:type": "video.other",
                        "og:image:alt": "Australian Scaffolds & Access",
                        "twitter:card": "summary",
                        "twitter:title": "Australian Scaffolds & Access",
                        "theme-color": "#FFFFFF",
                        "og:title": "Australian Scaffolds & Access",
                        "google": "notranslate",
                        "og:description": "🏆 Double Award-Winner: Best Scaffolding Project in Australia 2024! 🏆\n\nAustralian Scaffolds is excited to announce our wins for Best Scaffolding Project in NSW 2024 and the National Award for Best...",
                        "twitter:image": "https://external-atl3-2.xx.fbcdn.net/emg1/v/t13/11560305568378648053?url=https%3A%2F%2Fi.ytimg.com%2Fvi%2FxnXxQR-iJ6I%2Fhqdefault.jpg%3Fsqp%3D-oaymwEmCOADEOgC8quKqQMa8AEB-AHUBoAC4AOKAgwIABABGGsgayhrMA8%3D%26rs%3DAOn4CLBCegrYYTqJdULjcuhENoyNIvBslg&fb_obo=1&utld=ytimg.com&stp=c0.5000x0.5000f_dst-jpg_flffffff_p600x600_q75&ccb=13-1&oh=06_Q399HGIiXz36qtI-GKHzbIJUQ2Tjo8GpCeApeGWtkXG17EM&oe=673EEA0F&_nc_sid=867500",
                        "referrer": "origin-when-crossorigin",
                        "twitter:image:alt": "Australian Scaffolds & Access",
                        "twitter:site": "@facebookapp",
                        "viewport": "width=device-width, initial-scale=1",
                        "twitter:description": "🏆 Double Award-Winner: Best Scaffolding Project in Australia 2024! 🏆\n\nAustralian Scaffolds is excited to announce our wins for Best Scaffolding Project in NSW 2024 and the National Award for Best...",
                        "og:locale": "en_US",
                        "fb-version": "435.0.0.0.77:2678",
                        "og:url": "https://www.facebook.com/AustralianScaffoldsandAccess/posts/-double-award-winner-best-scaffolding-project-in-australia-2024-australian-scaff/881994770777428/"
                    }
                ],
                "cse_image": [
                    {
                        "src": "https://external-atl3-2.xx.fbcdn.net/emg1/v/t13/11560305568378648053?url=https%3a%2f%2fi.ytimg.com%2fvi%2fxnXxQR-iJ6I%2fhqdefault.jpg%3fsqp%3d-oaymwEmCOADEOgC8quKqQMa8AEB-AHUBoAC4AOKAgwIABABGGsgayhrMA8%3d%26rs%3dAOn4CLBCegrYYTqJdULjcuhENoyNIvBslg&fb_obo=1&utld=ytimg.com&stp=c0.5000x0.5000f_dst-jpg_flffffff_p600x600_q75&ccb=13-1&oh=06_Q399HGIiXz36qtI-GKHzbIJUQ2Tjo8GpCeApeGWtkXG17EM&oe=673EEA0F&_nc_sid=867500"
                    }
                ]
            },
            "contacts": []
        }
    ]
    }

    console.log('Original data length:', data.items.length);
    var limited = data.items.slice(30)
    console.log('Limited data length:', limited.length);
    setPayload(prevPayload => ({
      ...prevPayload,
      data: limited,
      balance: data.balance.data.balance,
      resets: data.balance.data.limit_resets_in.toString(),
      loading: false
    }));
    */


  }

  const onInput = (e) => {
    setPayload(prevPayload => ({
      ...prevPayload,
      keyword: e.target.value,
    }));
  }

  useEffect(() => {
    fetchQuotas()
      .then((response) => {
        console.log('Quota data:', response);
        setPayload(prevPayload => ({
          ...prevPayload,
          balance: response.data.data.balance,
          resets: response.data.data.limit_resets_in,
          loading: false
        }));
      })
      .catch(error => {
        console.error('Error fetching quotas:', error);
        setPayload(prevPayload => ({
          ...prevPayload,
          loading: false,
          error: 'Failed to load quotas'
        }));
      });
  }, []);

  return (
    <div className="p-4 kpis">
      <h1 className="lg:text-[18px] hidden md:block font-bold font-inter text-[#001C44] dark:text-white whitespace-nowrap mb-2">
        Contact Finder
        <div className="text-xs text-gray-500"><strong>SNOV:</strong> Credits: <strong>{balance}</strong> | Resets in {resets} day(s) | <em>Approx 96 Credits per search</em></div>
        <div className="text-xs text-gray-500"><strong>GOOGLE:</strong> 10 Free Searches per Day | $5 per 10 Searches Thereafter</div>
      </h1>
      {!loading ? 
      <div className="flex items-center flex-wrap gap-2 mb-4">
        <input
          value={keyword}
          name={'keyword'}
          onChange={(e) => onInput(e)}
          placeholder="Enter keyword"
          width="w-[220px] sm:w-[300px]"
          className="block p-2 px-3 text-sm text-gray-900 border border-gray-300 rounded-lg w-[220px] sm:w-[300px] bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <div>
          <ReactSelect
            options={dbOptions}
            value={database}
            placeholder={databaseLabel ? databaseLabel : 'Select Region'}
            handleOnChange={(selectedOption: any) =>
              setPayload(prevPayload => ({
                ...prevPayload,
                database: selectedOption.value,
                databaseLabel: selectedOption.label,
              }))
            }
          />
        </div>
        <button
          className="rounded-lg text-white text-sm border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
          onClick={() => handleFetchingPositions()}
        >
          Fetch
        </button>
        <br />
      </div>
      :
      <div className="flex items-center p-4">
        <div className="flex space-x-1">
          <div className="h-2 w-2 bg-[#005C9F] rounded-full animate-bounce"></div>
          <div className="h-2 w-2 bg-[#005C9F] rounded-full animate-bounce [animation-delay:-.3s]"></div>
          <div className="h-2 w-2 bg-[#005C9F] rounded-full animate-bounce [animation-delay:-.5s]"></div>
        </div> 
        <div className="ml-4 text-sm text-gray-500">Collating Contacts and Results...</div>
      </div>
      }
      {error ? <div className="mb-2 text-red-500">{error}</div> : null}
      {!loading && data.length ? 
      <>
      
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-inter">
        <thead>
          <tr>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              #
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Page Title
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              SERP Position
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Domain
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Contacts Found
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Hubspot Found
            </th>
            <th className='font-inter font-[600] z-[9] sticky top-0 z-[11] p-2 bg-[#F3F7FA] dark:bg-gray-700 dark:text-gray-400 min-w-fit '>
              Full URL
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            console.log(item)
            return (
            <tr onClick={() => handleRowClick(item, (index + 31))} key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 w-fit max-w-[300px] z-40">
              <td className='z-10  p-2 min-w-fit'>{index + 1}</td>
              <td className='z-10  p-2 min-w-fit'>{item.title}</td>
              <td className='z-10  p-2 min-w-fit'>{index + 31}</td>
              <td className='z-10  p-2 min-w-fit'>{item.displayLink}</td>
              <td className='z-10  p-2 min-w-fit'>{item.contacts.length}</td>
              <td className='z-10  p-2 min-w-fit'>{
                item.contacts?.reduce((count, contact) => {
                  return count + (contact.isInCRM?.isInCRM ? 1 : 0)
                }, 0)
              }
              </td>
              <td className='z-10  p-2 min-w-fit'><a style={{color: '#005C9F'}} href={item.link} target="_blank">{item.link}</a></td>
            </tr>
          )})}
        </tbody>
      </table>
      </>
      : ''}
      {selectedItem ? 
        <SideModal item={selectedItem} onClose={() => setPayload(prevPayload => ({
          ...prevPayload,
          selectedItem: null,
          showModal: false,
        }))} /> 
      : null}
    </div>
  );
};

export default ContactFinder;


