import React, { useState, useEffect } from 'react';
import ReactSelect from '../../../components/ReactSelect';

// SEO APPLY CREDIT EXPORT
export const SEOApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'seo_google',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.seo_google.end_date,
    applyCreditBudget: client.seo_google.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.seo_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        SEO GOOGLE Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC APPLY CREDIT EXPORT
export const PPCApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.ppc_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'ppc_google',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.ppc_google.end_date,
    applyCreditBudget: client.ppc_google.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.ppc_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PPC GOOGLE Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SEO MICROSOFT APPLY CREDIT EXPORT
export const SEOBINGApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'seo_bing',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.seo_bing.end_date,
    applyCreditBudget: client.seo_bing.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.seo_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft SEO Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC MICROSOFT APPLY CREDIT EXPORT
export const PPCBINGApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_bing',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_bing.end_date,
    applyCreditBudget: client.social_bing.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
      Microsoft Ads Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINK BOOST APPLY CREDIT EXPORT
export const LINKBOOSTApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.link_boost.end_date !== '') {
    var timeleft = Date.parse(client.link_boost.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.link_boost.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'link_boost',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.link_boost.end_date,
    applyCreditBudget: client.link_boost.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.link_boost.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Link Booster Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// REM APPLY CREDIT EXPORT
export const REMApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.display.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'display',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.display.end_date,
    applyCreditBudget: client.display.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// META APPLY CREDIT EXPORT
export const METAApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_meta.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_meta',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.display.end_date,
    applyCreditBudget: client.display.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        META Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PINTEREST APPLY CREDIT EXPORT
export const PINTERESTApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_pinterest.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_pinterest',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_pinterest.end_date,
    applyCreditBudget: client.social_pinterest.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TIKTOK APPLY CREDIT EXPORT
export const TIKTOKApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tiktok.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_tiktok',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_tiktok.end_date,
    applyCreditBudget: client.social_tiktok.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TIKTOK Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINKEDIN APPLY CREDIT EXPORT
export const LINKEDINApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_linkedin.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_linkedin',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_linkedin.end_date,
    applyCreditBudget: client.social_linkedin.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TACTICAL APPLY CREDIT EXPORT
export const TACTICALApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tactical.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_tactical',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_tactical.end_date,
    applyCreditBudget: client.social_tactical.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// CREATIVE APPLY CREDIT EXPORT
export const CREATIVEApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_creative.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_creative',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_creative.end_date,
    applyCreditBudget: client.social_creative.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PROGRAMMATIC APPLY CREDIT EXPORT
export const PROGRAMMATICApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_programmatic.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'social_programmatic',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.social_programmatic.end_date,
    applyCreditBudget: client.social_programmatic.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV APPLY CREDIT EXPORT
export const WEBDEVApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_maintenance.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'web_maintenance',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.web_maintenance.end_date,
    applyCreditBudget: client.web_maintenance.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST APPLY CREDIT EXPORT
export const WEBHOSTApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_hosting.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'web_hosting',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.web_hosting.end_date,
    applyCreditBudget: client.web_hosting.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


// WEBLANDING APPLY CREDIT EXPORT
export const WEBLANDINGApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_landing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'web_landing',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.web_landing.end_date,
    applyCreditBudget: client.web_landing.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


// WEB PROJECT APPLY CREDIT EXPORT
export const WEBPROJECTApplyCredit = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_project.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [applyCreditValues, setValues] = useState({
    type: 'apply credit',
    service: 'web_project',
    applyCreditAmount: '',
    applyCreditReason: '',
    applyCreditBucket: '',
    applyCreditEndDate: client.web_project.end_date,
    applyCreditBudget: client.web_project.revenue,
    applyCreditTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    applyCreditAmount,
    applyCreditReason,
    applyCreditBucket,
    applyCreditEndDate,
    applyCreditBudget,
  } = applyCreditValues;

  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...applyCreditValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...applyCreditValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(applyCreditValues);
  }, [applyCreditValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Apply Credit Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to apply credit?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('applyCreditAmount')}
              value={applyCreditAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the credit? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('applyCreditReason')}
              value={applyCreditReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Which of the below is the most appropriate bucket for the credit?
          </p>
          <div>
            <ReactSelect
              handleOnChange={handleSelectChange('applyCreditBucket')}
              options={[
                {
                  value: 'Delayed start of campaign',
                  label: 'Delayed start of campaign',
                },
                {
                  value: 'Late cancellation form (Invoice already issued)',
                  label: 'Late cancellation form (Invoice already issued)',
                },
                {
                  value: 'Performance issue (Overspend, etc)',
                  label: 'Performance issue (Overspend, etc)',
                },
                {
                  value: 'Credit Settlement / Negotiation',
                  label: 'Credit Settlement / Negotiation',
                },
                { value: 'Credit Other', label: 'Credit Other' },
                { value: 'Discount', label: 'Discount' },
              ]}
              placeholder="PLEASE SELECT"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-8 mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={applyCreditBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
            onKeyDown={(e) => e.preventDefault()}
              handleOnChange={handleSelectChange('applyCreditEndDate')}
              value={applyCreditEndDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
