import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import Api from '../api';

const initialStatus = {
  loading: false,
  errorMsg: '',
  isSuccessful: true,
};
const initialStatus2 = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};

const use3rdPartyStore = create(
  devtools((set) => ({
    rankRangerData: initialStatus2,
    refdomains: initialStatus2,
    domainRating: initialStatus2,
    domainRatingHistory: initialStatus2,
    semrushData: initialStatus2,
    teamworkProjects: initialStatus2,
    CampaignsRankRanger: initialStatus2,
    ahrefsLinks: initialStatus2,
    semrushLinks: initialStatus2,
    orgDomainOverview: initialStatus2,
    topKeywords: initialStatus2,
    orgSearchPositions: initialStatus2,
    googleRanking: initialStatus2,
    urlRating: initialStatus2,
    fetchSemrushLinks: async (data) => {
      try {
        const response = await Api.ThirdParty.getSemrushBacklinksReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.message,
          };
          set(() => ({ semrushLinks: resData }));
        } else {
          const resData = { ...{ data: response }, loading: false };
          set(() => ({ semrushLinks: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchUrlRating: async (data) => {
      try {
        set(() => ({ urlRating: initialStatus2 }));
        const response = await Api.ThirdParty.getUrlRatingReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ urlRating: resData }));
        } else {
          const resData = { ...{ data: response }, loading: false };
          set(() => ({ urlRating: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchOrgSearchPositions: async (data) => {
      try {
        set(() => ({ orgSearchPositions: initialStatus2 }));
        const response = await Api.ThirdParty.getOrgSearchPositionsReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.message,
          };
          set(() => ({ orgSearchPositions: resData }));
        } else {
          const labelInfo = {
            info: response.info,
            label: response.label
          };
          const resData = { ...{ data: response.data, ...labelInfo }, loading: false };
          set(() => ({ orgSearchPositions: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchTopKeywords: async (data) => {
      try {
        set(() => ({ topKeywords: initialStatus2 }));
        const response = await Api.ThirdParty.getAhrefsTopKeywordsReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ topKeywords: resData }));
        } else {
          const labelInfo = {
            info: response.info,
            label: response.label
          };
          const resData = { ...{ data: response.data, ...labelInfo }, loading: false };
          set(() => ({ topKeywords: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchOrgDomainOverview: async (data) => {
      try {
        set(() => ({ orgDomainOverview: initialStatus2 }));
        const response = await Api.ThirdParty.getOrgDomainOverviewReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.message,
          };
          set(() => ({ orgDomainOverview: resData }));
        } else {
          const resData = { ...{ data: response }, loading: false };
          set(() => ({ orgDomainOverview: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchAhrefsLinks: async (data) => {
      try {
        const response = await Api.ThirdParty.getAhrefsBacklinksReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ ahrefsLinks: resData }));
        } else {
          const resData = { ...{ data: response }, loading: false };
          set(() => ({ ahrefsLinks: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchRankRanger: async (data) => {
      try {
        set(() => ({ rankRangerData: initialStatus2 }));
        const response = await Api.ThirdParty.getRankRangerData(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ rankRangerData: resData }));
        } else {
          const resData = { ...{ ...response }, loading: false };
          set(() => ({ rankRangerData: resData }));
          return { status: true };
        }
      } catch (error) {
        throw error;
      }
    },
    fetchDomainRating: async (data) => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.ThirdParty.getAhrefsDomainRatingReport(data);
      set(() => ({ domainRating: response, loading: false }));
    },
    fetchDomainRatingHistory: async (data) => {
      try {
        set(() => ({ domainRatingHistory: initialStatus2 }));
        const response = await Api.ThirdParty.getAhrefsDomainRatingHistoryReport(data);
        if (response[0]?.error) {
          const resData = {
            ...{ data: [] },
            loading: false,
            error: response[0]?.error,
          };
          set(() => ({ domainRatingHistory: resData }));
        } else {
          const resData = { ...{ data: response }, loading: false };
          set(() => ({ domainRatingHistory: resData }));
        }
      } catch (error) {
        throw error;
      }
    },
    fetchRefDomains: async (data) => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.ThirdParty.getAhrefsRefDomainsReport(data);
      if (response[0]?.error) {
        const resData = {
          ...{ data: [] },
          loading: false,
          error: response[0]?.error,
        };
        set(() => ({ refdomains: resData }));
      } else {
        set(() => ({ refdomains: response, loading: false }));
      }
    },
    fetchTrafficCompetition: async (data) => {
      set(() => ({ semrushData: initialStatus2 }));
      const response = await Api.ThirdParty.getSemrushCompetitorsReport(data);
      let resData;
      if (response[0]?.status === 500) {
        resData = { error: response[0]?.message, loading: false };
        set(() => ({ semrushData: resData }));
      } else if(response[0]?.error) {
        const resData = {
          ...{ data: [] },
          loading: false,
          error: response[0]?.message,
        };
        set(() => ({ semrushData: resData }));
      }else {
        resData = { ...{ data: response }, loading: false };
        set(() => ({ semrushData: resData }));
      }
    },
    fetchTeamworkProjects: async () => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.Analytics.getTeamworkProjects();
      set(() => ({ teamworkProjects: response, loading: false }));
    },
    fetchCampaignsRankRanger: async () => {
      set(() => ({ loading: true, errorMessage: '' }));
      const response = await Api.ThirdParty.getCampaignsRankRanger();
      set(() => ({ CampaignsRankRanger: response, loading: false }));
    },
    reset3rdPartyStore: (types) => {
      if (!Array.isArray(types)) {
        console.error('resetStore 3rdParty expects an array of types.');
        return;
      }
      set((state) => 
        types.reduce((acc, type) => {
          // Ensure the type exists in the state before trying to reset it
          if (state[type]) {
            acc[type] = {...initialStatus}; // Spread to avoid mutation of initialStatus
          }
          return acc;
        }, {})
      );
  }
  }))
);

export default use3rdPartyStore;
