import { create } from 'zustand';
import Api from '../api';
import { devtools } from 'zustand/middleware';
const initialStatus = {
  loading: true,
  errorMsg: '',
  isSuccessful: true,
};

const useToolStore = create(
  devtools((set, get) => ({
    positions: [],
    contacts: [],
    autoStaff:[],
    kpiStatus: initialStatus,
    selectedStaff: initialStatus,
    error: false,
    apiStatus: '',
    loading: false,
    errorMessages: '',
    fetchPositions: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Tools.getPositions(filters);
      set(() => ({ positions: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchContacts: async (filters) => {
      set(() => ({ loading: true }));
      const response = await Api.Tools.getContacts(filters);
      set(() => ({ contacts: response }));
      set(() => ({ loading: false }));
      return response;
    },
    fetchQuotas: async () => {
      const response = await Api.Tools.getQuotas();
      set(() => ({ quotas: response }));
      return response;
    }
  }))
);

export default useToolStore;
