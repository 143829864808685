import { useState, useEffect } from 'react';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';

import {
  Badge,
  Card,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@tremor/react';
import moment from 'moment';

interface IProps {
  performanceData?: any;
}
// Define the type for a comment
interface Comment {
  id: number; // Unique identifier for each comment
  date: string; // Date of the comment
  author: string; // Author of the comment
  performance: string; // Performance related to the comment
  message: string; // The comment text
}

const Comments: React.FC<IProps> = ({ performanceData }) => {
  const { 
    createPerformanceComment, 
    fetchPerformanceComments, 
    deletePerformanceComment,
    comments 
  } = usePerformanceStore((state) => state);
  
  const [commentText, setCommentText] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);

  useEffect(() => {
    const filters = {
      page: 1,
      limit: 20,
      sort: 'desc',
    };
    fetchPerformanceComments(performanceData?.client?.id, filters);
  }, [performanceData?.client?.id]);

  const data: any = comments?.data || [];
  
  const handleAddComment = () => {
    createPerformanceComment(performanceData?.client?.id, { message: commentText.trim() });
    setShowForm(false);
    setCommentText('')
  };

  const handleDeleteComment = (commentId) => {
    deletePerformanceComment(performanceData?.client?.id,commentId)
  }

  return (
    <div className="relative rounded-md h-[80%] p-2">
      <Card>
        <div className="flex justify-between items-center">
          <span className="text-gray-800 font-semibold dark:text-white ">
            Comments
          </span>
          <div className="flex items-center gap-x-2">
            <Badge
              onClick={() => setShowForm(true)}
              className=" p-1 px-2 dark:text-white cursor-pointer self-end"
            >
              <div className="flex gap-2 items-center">
                <div className="rounded-full border p-1 bg-white">
                  <Icon name="plus" />
                </div>
                <div>New Comment</div>
              </div>
            </Badge>
          </div>
        </div>
        {showForm && (
          <div className="mt-4">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddComment();
              }}
            >
              {/* <div className="flex gap-4">
                <input
                  type="text"
                  placeholder="Author"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  className="w-1/3 p-2 border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Performance"
                  value={performance}
                  onChange={(e) => setPerformance(e.target.value)}
                  className="w-1/3 p-2 border rounded-md"
                />
                <input
                  type="date"
                  placeholder="Date"
                  value={new Date().toISOString().split('T')[0]}
                  disabled
                  className="w-1/3 p-2 border rounded-md"
                />
              </div> */}
              <textarea
                className="w-full p-2 border rounded-md mt-2"
                placeholder="Type your comment here..."
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              ></textarea>
              <div className="flex justify-end mt-2">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Add Comment
                </button>
              </div>
            </form>
          </div>
        )}
        {comments?.loading ? <div className="mt-4 text-center text-gray-500">Loading comments...</div>
        : data.length === 0 ? (
          <div className="mt-4 text-center text-gray-500">No comments</div>
        ) : (
          <Table className="mt-5">
            <TableHead>
              <TableRow>
                <TableHeaderCell>Date</TableHeaderCell>
                <TableHeaderCell>Author</TableHeaderCell>
                <TableHeaderCell>Comment</TableHeaderCell>
                {/* <TableHeaderCell></TableHeaderCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{moment(item.created_at).format('llll')}</TableCell>
                  <TableCell>{item.created_by.first_name} {item.created_by.last_name}</TableCell>
                  <TableCell>{item.message}</TableCell>
                  {/* <TableCell>
                    <span 
                      onClick={() => handleDeleteComment(item._id)}
                      className='block'>
                      <Icon name="close" size={20} />
                    </span>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>
    </div>
  );
};

export default Comments;
