import use3rdPartyStore from '../../../store/use3rdPartyStore';
import useGoogleStore from '../../../store/useGoogleStore';
import OrganicSearchPositions from '../../AnalyticsSections/OrganicSearchPositions';
import ServiceNotAvailable from '../../Common/ServiceNotAvailable';
import useClientStore from '../../../store/useClientStore';
import SemrushBacklinks from '../../AnalyticsSections/SemrushBacklinks';
import {
  Card,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';
import OverallVisibility from '../../AnalyticsSections/OverallViibility';
import Ranking from '../../AnalyticsSections/Ranking';
import BackLinks from '../../AnalyticsSections/BackLinks';
import Competitors from '../../AnalyticsSections/Competitors';
import LeadingIndicators from '../../AnalyticsSections/LeadingIndicators';
import TrafficInsights from '../../AnalyticsSections/TrafficInsights';
import Icon from '../../../assets/icons/SvgComponent';
import InfoTooltip from '../../AnalyticsSections/AnalyticsInfoTooltip';
import Conversions from '../../AnalyticsSections/Conversions';
import SearchConsoleAnalysis from '../../AnalyticsSections/SearchConsoleAnalysis';
import BacklinksChart from '../../AnalyticsSections/BacklinksChart';
import Accordion from '../../Accordion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { parseDate } from '../../../utils/common';

interface IProps {
  clientLoading: boolean;
  category?: string;
}

const AnalyticsSeoGA4 = ({ clientLoading, category }: IProps) => {
  const { ga4SeoData } = useGoogleStore((state) => state);
  const { semrushData } = use3rdPartyStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);

  const location = useLocation();
  const [sliceData, setSliceData] = useState(false);

  const seoStartDate = parseDate(localStorage.getItem('seoStartDate'));
  const seoEndDate = parseDate(localStorage.getItem('seoEndDate'));
  const startDate = parseDate(localStorage.getItem('startDate'));
  const endDate = parseDate(localStorage.getItem('endDate'));

  const isSeoGa4 = location.pathname.includes('/analytics/seo-ga4');

  useEffect(() => {
    const currentMoment = moment();
    const isCycle2 = selectedClient?.cycle === 'Cycle 2';
  
    const isValidAndCurrentOrFutureMonth = (date) => {
      const momentDate = date ? moment(date) : null;
      if (!momentDate || !momentDate.isValid()) {
        return false;
      }
      if (isCycle2) {
        // For Cycle 2, check for the exact date
        return momentDate.isSameOrAfter(currentMoment, 'day');
      } else {
        // For other cycles, check for the month
        return momentDate.isSameOrAfter(currentMoment, 'month');
      }
    };
  
    const isCurrentMonth = isSeoGa4
      ? (seoEndDate && isValidAndCurrentOrFutureMonth(seoEndDate))
      : (startDate && isValidAndCurrentOrFutureMonth(startDate)) ||
        (endDate && isValidAndCurrentOrFutureMonth(endDate));

    setSliceData(isCurrentMonth);
  }, [
    seoStartDate,
    seoEndDate,
    startDate,
    endDate,
    selectedClient,
    location.pathname,
    isSeoGa4,
  ]);
  

  const isPublicDashboard = window.location.pathname ===  '/client/dashboard';
  const noServiceAvailable = ['activated', 'active'].includes(selectedClient?.seo_google?.status) ? true : false;

  const noIntegrationAvailable =
    !['activated', 'active'].includes(selectedClient?.google_analytics?.ga4?.status) &&
    !['activated', 'active'].includes(selectedClient?.google_search_console?.status) &&
    !['activated', 'active'].includes(selectedClient?.semrush?.status) &&
    !['activated', 'active'].includes(selectedClient?.ahrefs?.status) &&
    !['activated', 'active'].includes(selectedClient?.rank_ranger?.status);

  if (
    !selectedClient?.loading &&
    !ga4SeoData?.loading
  ) {
    if(isPublicDashboard && noServiceAvailable && noIntegrationAvailable) {
      return <ServiceNotAvailable title="No Dashboard Available"  serviceType="seo" hideRequestAccess={isPublicDashboard}/>;
    }
    // if(!noServiceAvailable) return <ServiceNotAvailable serviceType="seo" hideRequestAccess={isPublicDashboard}/>;
    if(noIntegrationAvailable) return <ServiceNotAvailable title="Integration Not Set Up"  serviceType="seo" hideRequestAccess={isPublicDashboard}/>;
  }

  return (
    <>
      {/* mobileView */}
      <Accordion
        title="Key Performance Indicator"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <OverallVisibility sliceData={sliceData} />
      </Accordion>
      <Accordion
        title="Leading Indicators"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <LeadingIndicators />
      </Accordion>
      <Accordion
        title="Traffic Insights"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <TrafficInsights />
        <BacklinksChart />
      </Accordion>
      <Accordion
        title="Conversions"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <Conversions />
      </Accordion>
      <Accordion
        title="Keyword Analysis"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <OrganicSearchPositions name="Organic Ranking Position" />
        <Ranking />
      </Accordion>
      <Accordion
        title="Search Console Analysis"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <SearchConsoleAnalysis />
      </Accordion>
      <Accordion
        title="Backlinks"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <BacklinksChart />
        <Card className="dark:bg-[#222732] dark:border dark:border-white px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div>
            <TabGroup>
              <div className="w-full mb-2">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex gap-2 items-center">
                    <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                      Discovered Backlinks
                    </h2>
                    <InfoTooltip
                      content={'Discovered Backlinks'}
                      position="top"
                    >
                      <Icon name="InfoCircle" size={14} />
                    </InfoTooltip>
                  </div>
                  <div>
                    <TabList
                      variant="solid"
                      defaultValue="1"
                      className="bg-[#d5efff] text-white"
                    >
                      <Tab value="1">Ahref's</Tab>
                      <Tab value="2">Semrush</Tab>
                    </TabList>
                  </div>
                </div>
              </div>
              <TabPanels>
                <TabPanel>
                  <BackLinks />
                </TabPanel>
                <TabPanel>
                  <SemrushBacklinks />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </Card>
      </Accordion>
      <Accordion
        title="Competitors"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <Competitors semrushData={semrushData} error={semrushData.error} />
      </Accordion>
      {/* mobileView */}
      <Card
        id="key-performance"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <OverallVisibility sliceData={sliceData} />
      </Card>
      <Card
        id="leading-indicators"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <LeadingIndicators sliceData={sliceData} />
      </Card>
      <Card
        id="traffic"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <TrafficInsights sliceData={sliceData} />
        <BacklinksChart />
      </Card>
      <Card
        id="conversions"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <Conversions sliceData={sliceData} />
      </Card>
      <Card
        id="keyword-analysis"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <div className="flex justify-start items-center mb-4">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
            Keyword Analysis
          </h2>
        </div>
        <OrganicSearchPositions name="Organic Ranking Position" />

        <Ranking />
      </Card>
      <Card
        id="search-console-analysis"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <SearchConsoleAnalysis sliceData={sliceData} />
      </Card>
      <Card
        id="backlinks"
        className="hidden lg:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <div className="flex justify-start items-center mb-4">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
            Backlinks
          </h2>
        </div>
        <BacklinksChart />
        <Card className="dark:bg-[#222732] dark:border dark:border-white px-1 sm:px-6">
          <div>
            <TabGroup>
              <div className="w-full mb-2">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex gap-2 items-center">
                    <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
                      Discovered Backlinks
                    </h2>
                    <InfoTooltip
                      content={'Discovered Backlinks'}
                      position="top"
                    >
                      <Icon name="InfoCircle" size={14} />
                    </InfoTooltip>
                  </div>
                  <div>
                    <TabList
                      variant="solid"
                      defaultValue="1"
                      className="bg-[#d5efff] text-white"
                    >
                      <Tab value="1">Ahref's</Tab>
                      <Tab value="2">Semrush</Tab>
                    </TabList>
                  </div>
                </div>
              </div>
              <TabPanels>
                <TabPanel>
                  <BackLinks />
                </TabPanel>
                <TabPanel>
                  <SemrushBacklinks />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </Card>
      </Card>
      <Card
        id="competitors"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <Competitors semrushData={semrushData} error={semrushData.error} />
      </Card>
    </>
  );
};

export default AnalyticsSeoGA4;
