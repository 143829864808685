import { useState } from 'react';
import { Card } from '@tremor/react';
import InfoTooltip from '../../AnalyticsSections/AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import Tooltip from '../../HelpTooltip';
import TrendTooltip from '../../AnalyticsSections/TrendTooltip';
import TrendTxt from '../../Common/TrendTxt';
import CustomModal from '../../CustomModal';
import WidgetSettings from '../WidgetSettings';
import LineChartDisplay from '../Charts/lineChartDisplay';
import PieChartDisplay from '../Charts/pieChartDisplay';
import AreaChartDisplay from '../Charts/areaChartDisplay';
import BarChartDisplay from '../Charts/barChartDisplay';
import useDashboardStore from '../../../store/useDashboardStore';
import {
  getMetricSourceIcon,
  getMetricSourceOptions,
  getStatusMetric,
} from '../constants';
import {
  formatNumber,
  formatToCurrency,
  processData,
  processDataDynamic,
} from '../../../utils/common';

interface IProps {
  layoutData: any;
  graphData?: any;
  setSelectedItem: (data: any) => void;
  setOpenWidget: (value: boolean) => void;
  viewType?: string;
  isGraphDataLoading?: boolean;
}

const GridItem = ({
  layoutData,
  setSelectedItem,
  setOpenWidget,
  viewType = 'view',
  isGraphDataLoading,
}: IProps) => {
  const { graphData, sections, dashboards } = useDashboardStore(
    (state) => state
  );

  // console.log(graphData);
  // console.log(sections);
  // console.log(layoutData);
  const activeItem = graphData ? graphData[layoutData?.id] : null;

  const processedData = layoutData?.data_config?.metrics
    ? processDataDynamic(
        activeItem?.graphData,
        layoutData?.data_config?.metrics
      )
    : undefined;

  const totalValue =
    processedData && Array.isArray(processedData) && processedData.length > 0
      ? processedData[processedData.length - 1]?.value
      : undefined;

  // console.log(processedData,'totalValue 1');
  // console.log(
  //   getStatusMetric(layoutData?.data_config?.metrics),
  //   'statusmetric'
  // );
  // console.log(layoutData.id,'-',layoutData.title,'layoutData1');
  // console.log(activeItem,'activeItem')

  const isLightTheme = layoutData?.display_config?.themeStyle === 'light';

  // console.log(layoutData);

  return (
    <>
      <Card
        className={`border-none shadow-none ring-0 lg:ring-1 lg:border ${isLightTheme ? '' : 'bg-dark-tremor-background'} dark:bg-dark-tremor-background p-[16px] relative h-[410px] sm:h-[374px]`}
      >
        <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
          <div className="flex gap-2 items-center mb-1">
            <h2
              className={`text-[14px] ${isLightTheme ? 'text-[#001C44]' : 'text-white'}  dark:text-white font-bold`}
            >
              {layoutData?.title ? layoutData?.title : ''}
            </h2>
            {/* Info param */}
            <InfoTooltip
              content={
                layoutData?.toolTip ? layoutData?.toolTip : layoutData?.title
              }
              position="top"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <div className="flex items-center">
            {layoutData?.display_config?.showIntegrationIcon && (
              <Tooltip
                content={
                  layoutData?.data_config?.property
                    ? layoutData?.data_config?.property
                    : 'N/A'
                }
                position="top"
                textAlign="text-center"
              >
                <div className="pt-1">
                  {getMetricSourceIcon(layoutData?.data_config?.source)
                    ?.value !== 'unknown' ? (
                    <Icon
                      name={
                        getMetricSourceIcon(layoutData?.data_config?.source)
                          ?.value
                      }
                      size={20}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </Tooltip>
            )}
            {viewType === 'edit' && (
              <div
                onClick={(e) => {
                  setOpenWidget(true);
                  setSelectedItem(layoutData);
                }}
                className="block ml-2 cursor-pointer z-50 w-4 h-4 flex items-center"
              >
                <Icon name="gridMenu" />
              </div>
            )}
          </div>
        </div>
        <h3
          className={`text-[30px] font-semibold leading-8  ${isLightTheme ? '' : 'text-white'} mb-2 dark:text-white`}
        >
          {isGraphDataLoading ? (
            <div className="w-[160px] h-[30px] animate-pulse bg-gray-300 rounded-lg" />
          ) : (
            <>
              {totalValue
                ? ['totalRevenue', 'organic_totalRevenue'].includes(
                    layoutData?.data_config?.metrics
                  )
                  ? formatToCurrency(totalValue)
                  : formatNumber(totalValue)
                : '0'}
            </>
          )}
        </h3>
        {layoutData?.display_config?.showTrendline && (
          <div className="flex border-b border-tremor-border dark:border-dark-tremor-border w-full mb-4">
            <>
              <div>
                {isGraphDataLoading ? (
                  <div className="w-[120px] h-[12px] animate-pulse bg-gray-300 rounded-lg my-[8px]" />
                ) : (
                  <TrendTooltip percentage={'15.26'}>
                    <TrendTxt
                      type="period"
                      title="Prev Period"
                      size="small"
                      themeStyle={layoutData?.display_config?.themeStyle}
                      value={
                        layoutData?.graphData
                          ? layoutData?.graphData?.previous_period
                          : '0'
                      }
                    />
                  </TrendTooltip>
                )}
              </div>
              <div className="ml-[6px]">
                {isGraphDataLoading ? (
                  <div className="w-[120px] h-[12px] animate-pulse bg-gray-300 rounded-lg my-[8px]" />
                ) : (
                  <TrendTooltip percentage={'3.35'}>
                    <TrendTxt
                      type="year"
                      title="Prev Year"
                      size="small"
                      value={
                        layoutData?.graphData
                          ? layoutData?.graphData?.previous_year
                          : '0'
                      }
                    />
                  </TrendTooltip>
                )}
              </div>
            </>
          </div>
        )}
        <div className="w-full">
          {isGraphDataLoading ? (
            <div className="w-full h-48 animate-pulse bg-gray-300 rounded-lg -mb-2 mt-8 " />
          ) : (
            <>
              {layoutData?.display_config?.chartType === 'area' && (
                <AreaChartDisplay
                  data={processedData || []}
                  category={[getStatusMetric(layoutData?.data_config?.metrics)]}
                  displayConfig={layoutData?.display_config}
                />
              )}
              {layoutData?.display_config?.chartType === 'line' && (
                <LineChartDisplay
                  data={processedData || []}
                  category={[getStatusMetric(layoutData?.data_config?.metrics)]}
                  displayConfig={layoutData?.display_config}
                />
              )}
              {layoutData?.display_config?.chartType === 'bar' && (
                <BarChartDisplay
                  data={processedData || []}
                  category={[getStatusMetric(layoutData?.data_config?.metrics)]}
                  displayConfig={layoutData?.display_config}
                />
              )}
              {layoutData?.display_config?.chartType === 'pie' && (
                <PieChartDisplay
                  data={processedData || []}
                  category={getStatusMetric(layoutData?.data_config?.metrics)}
                />
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default GridItem;
