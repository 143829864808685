import React, { useState, useEffect, Fragment } from 'react';
import ReactSelect from '../../../components/ReactSelect';

// SEO PAUSE EXPORT
export const SEOPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'seo_google',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.seo_google.end_date,
    pauseClientBudget: client.seo_google.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.seo_google.status === 'paused' ? (
        <Fragment>
          <h6>SEO GOOGLE Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              SEO Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                    value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// PPC PAUSE EXPORT
export const PPCPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.ppc_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'ppc_google',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.ppc_google.end_date,
    pauseClientBudget: client.ppc_google.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.ppc_google.status === 'paused' ? (
        <Fragment>
          <h6>PPC GOOGLE Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              PPC Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                    value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// SEO PAUSE EXPORT
export const SEOBINGPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'seo_bing',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.seo_google.end_date,
    pauseClientBudget: client.seo_google.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.seo_google.status === 'paused' ? (
        <Fragment>
          <h6>Microsoft SEO Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Microsoft SEO Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                    value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// PPC PAUSE EXPORT
export const PPCBINGPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_bing',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_bing.end_date,
    pauseClientBudget: client.social_bing.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.ppc_google.status === 'paused' ? (
        <Fragment>
          <h6>Microsoft Ads Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Microsoft Ads Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                    value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// LINKBOOST PAUSE EXPORT
export const LINKBOOSTPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.link_boost.end_date !== '') {
    var timeleft = Date.parse(client.link_boost.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.link_boost.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'link_boost',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.link_boost.end_date,
    pauseClientBudget: client.link_boost.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.seo_google.status === 'paused' ? (
        <Fragment>
          <h6>Link Booster Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Link Boost Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                    value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// REM PAUSE EXPORT
export const REMPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.display.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'display',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.display.end_date,
    pauseClientBudget: client.display.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.display.status === 'paused' ? (
        <Fragment>
          <h6>Remarketing Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Remarketing Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// META PAUSE EXPORT
export const METAPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_meta.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_meta',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_meta.end_date,
    pauseClientBudget: client.social_meta.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_meta.status === 'paused' ? (
        <Fragment>
          <h6>META Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              META Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// PINTEREST PAUSE EXPORT
export const PINTERESTPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_pinterest.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_pinterest',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_pinterest.end_date,
    pauseClientBudget: client.social_pinterest.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_pinterest.status === 'paused' ? (
        <Fragment>
          <h6>PINTEREST Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              PINTEREST Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// TIKTOK PAUSE EXPORT
export const TIKTOKPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tiktok.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_tiktok',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_tiktok.end_date,
    pauseClientBudget: client.social_tiktok.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_tiktok.status === 'paused' ? (
        <Fragment>
          <h6>TIKTOK Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              TIKTOK Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// LINKEDIN PAUSE EXPORT
export const LINKEDINPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_linkedin.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_linkedin',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_linkedin.end_date,
    pauseClientBudget: client.social_linkedin.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_linkedin.status === 'paused' ? (
        <Fragment>
          <h6>LINKEDIN Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              LINKEDIN Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// PROGRAMMATIC PAUSE EXPORT
export const PROGRAMMATICPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_programmatic.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_programmatic',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_programmatic.end_date,
    pauseClientBudget: client.social_programmatic.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_programmatic.status === 'paused' ? (
        <Fragment>
          <h6>PROGRAMMATIC Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              PROGRAMMATIC Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// CREATIVE PAUSE EXPORT
export const CREATIVEPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_creative.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_creative',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_creative.end_date,
    pauseClientBudget: client.social_creative.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_creative.status === 'paused' ? (
        <Fragment>
          <h6>CREATIVE Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              CREATIVE Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// TACTICAL PAUSE EXPORT
export const TACTICALPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tactical.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'social_tactical',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.social_tactical.end_date,
    pauseClientBudget: client.social_tactical.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.social_tactical.status === 'paused' ? (
        <Fragment>
          <h6>TACTICAL Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              TACTICAL Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// WEBDEV PAUSE EXPORT
export const WEBDEVPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_maintenance.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'web_maintenance',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.web_maintenance.end_date,
    pauseClientBudget: client.web_maintenance.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };
  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.web_maintenance.status === 'paused' ? (
        <Fragment>
          <h6>Web Maintenance Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Web Maintenance Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={pauseClientBudget}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// WEB HOST PAUSE EXPORT
export const WEBHOSTPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_hosting.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'web_hosting',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.web_hosting.end_date,
    pauseClientBudget: client.web_hosting.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.web_hosting.status === 'paused' ? (
        <Fragment>
          <h6>Web Hosting Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Web Hosting Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={0}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// WEB LANDING PAUSE EXPORT
export const WEBLANDINGPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_landing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'web_landing',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.web_landing.end_date,
    pauseClientBudget: client.web_landing.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.web_landing.status === 'paused' ? (
        <Fragment>
          <h6>Landing Page Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Landing Page Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={0}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

// WEB LANDING PAUSE EXPORT
export const WEBPROJECTPause = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_project.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  const [pauseValues, setValues] = useState({
    type: 'pause',
    service: 'web_project',
    pauseCategory: '',
    pauseRequestDate: '',
    pauseEffectDate: '',
    pauseRecommenceDate: '',
    pauseReason: '',
    pauseClientComments: '',
    pauseHubspotFinishDate: client.web_project.end_date,
    pauseClientBudget: client.web_project.revenue,
    pauseTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    pauseCategory,
    pauseRequestDate,
    pauseEffectDate,
    pauseRecommenceDate,
    pauseReason,
    pauseClientComments,
    pauseHubspotFinishDate,
    pauseClientBudget,
  } = pauseValues;

  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...pauseValues, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    setValues({ ...pauseValues, [name]: event.value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(pauseValues);
  }, [pauseValues]);

  return (
    <div className="change-request-processing">
      {client.web_project.status === 'paused' ? (
        <Fragment>
          <h6>Web Project Pause Amendment</h6>
          <label>
            Pause Recommence Date
            <input
              type="date"
              name="pauseRecommenceDate"
              value={pauseRecommenceDate}
            />
          </label>
          <label>
            New Recommencement Date
            <input
              type="date"
              name="pauseRecommenceDate"
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('newRecommenceDate')}
              value={pauseRecommenceDate}
            />
          </label>
        </Fragment>
      ) : (
        <Fragment>
          <div className="mb-[40px]">
            <div className="mb-6 text-[16px] text-[#005C9F]">
              Web Project Pause Details
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Pause Category
                </p>
                <div>
                  <ReactSelect
                    handleOnChange={handleSelectChange('pauseCategory')}
                    options={[
                      { value: 'Covid Pause', label: 'COVID-19' },
                      { value: 'Non Covid Pause', label: 'NON COVID-19' },
                    ]}
                    placeholder="PLEASE SELECT"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of pause request
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseRequestDate')}
                    value={pauseRequestDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Date of the pause request comes in effect?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    onInput={handleChange('pauseEffectDate')}
                    value={pauseEffectDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mb-6">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  When should invoicing recommence?
                </p>
                <div>
                  <input
                    onInput={handleChange('pauseRecommenceDate')}
                    value={pauseRecommenceDate}
                    onKeyDown={(e) => e.preventDefault()}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Service amount?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                    type="text"
                    disabled
                    value={0}
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="0"
                  />
                </div>
              </div>
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  Contract finish date?
                </p>
                <div className="text-[#7C7E7F]">
                  <input
                onKeyDown={(e) => e.preventDefault()}
                onInput={handleChange('pauseHubspotFinishDate')}
                  value={pauseHubspotFinishDate}
                    type="date"
                    className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-8">
              <div className="w-[32%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What is the reason of pause?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseReason')}
                    value={pauseReason}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
              <div className="w-[66%]">
                <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
                  What did the client say?
                </p>
                <div className="text-[#7C7E7F]">
                  <textarea
                    onInput={handleChange('pauseClientComments')}
                    value={pauseClientComments}
                    className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter a description..."
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
