import Select, { StylesConfig } from 'react-select';

type Props = {
  options?: any;
  placeholder?: string;
  value?: any;
  handleOnChange?: any;
  isDisabled?: boolean;
  wSearch?: boolean;
  customWidth?: string;
  controlBgColor?: string;
  textColor?: string; // New prop for text color
};

const ReactSelect: React.FC<Props> = ({
  options,
  placeholder,
  value,
  handleOnChange,
  isDisabled = false,
  wSearch,
  customWidth,
  controlBgColor = '#ffffff',
  textColor = '#000000', // Default text color if none provided
}) => {
  const customStyles: StylesConfig<any, boolean, any> = {
    control: (provided) => ({
      ...provided,
      minWidth: customWidth || '140px',
      borderRadius: wSearch ? '8px 0 0 8px' : '8px',
      border: '1px solid #D1D5DB',
      opacity: isDisabled ? 0.5 : 1,
      cursor: 'pointer',
      fontSize: '14px',
      backgroundColor: controlBgColor, // Apply custom background color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor, // Apply custom text color to selected option
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#6D8080' : '', // Apply custom text color to options
      fontSize: '14px',
      cursor: 'pointer',
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
  };

  return (
    <div>
      <Select
        className="my-react-select-container"
        classNamePrefix="my-react-select"
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        menuPortalTarget={document.body}
        styles={customStyles}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ReactSelect;
