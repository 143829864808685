import { useMemo } from 'react';
import { Card } from '@tremor/react';
import { formatNumber } from '../../../../utils/common';
import Tooltip from '../../../HelpTooltip';
import Icon from '../../../../assets/icons/SvgComponent';
import useClientStore from '../../../../store/useClientStore';
import useGoogleStore from '../../../../store/useGoogleStore';
import AnalyticsTable from '../../AnalyticsTable';
import MobileView from './mobileView';

const ImpressionShareOverviewPPC = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useGoogleStore((state) => state);

  const campaignResponse = campaignsReport?.campaigns;

  const impressionShareReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Campaign',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[25vw] h-fit pl-4">
                <div>Campaign</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[25vw] h-fit pl-4">
                <div>Campaign</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign' || headerId === 'Campaign') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[25vw] h-fit pl-4">
                <div>Campaign</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[200px] sm:w-[25vw] pl-4 h-fit">
            <div className="truncate w-[180px] sm:w-[25vw] overflow-hidden">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[200px] sm:w-[14vw] pl-4 h-fit">
            {formatNumber(props.getValue(), false)}
          </div>
        ),
      },
      {
        id: 'Impression Share (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression Share (%)' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Impression Share (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression Share (%)' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Impression Share (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Impression Share (%)' ||
            headerId === 'Impression Share (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Impression Share (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'content_impression_share',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[200px] sm:w-[14vw] pl-4 h-fit">
            {formatNumber(props.getValue(), true)}
          </div>
        ),
      },
      {
        id: 'Impressions Lost to Ad Rank (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (
            headerId === 'Impressions Lost to Ad Rank (%)' &&
            sortDirection === false
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit">
                <div>Impressions Lost to Ad Rank (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId === 'Impressions Lost to Ad Rank (%)' &&
            sortDirection === true
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit">
                <div>Impressions Lost to Ad Rank (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Impressions Lost to Ad Rank (%)' ||
            headerId === 'Impressions Lost to Ad Rank (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit">
                <div>Impressions Lost to Ad Rank (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'content_rank_lost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[250px] sm:w-[18vw] pl-4 h-fit">
            {formatNumber(props.getValue(), true)}
          </div>
        ),
      },
      {
        id: 'Impressions Lost to Budget (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (
            headerId === 'Impressions Lost to Budget (%)' &&
            sortDirection === false
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit pl-4">
                <div>Impressions Lost to Budget (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId === 'Impressions Lost to Budget (%)' &&
            sortDirection === true
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit pl-4">
                <div>Impressions Lost to Budget (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Impressions Lost to Budget (%)' ||
            headerId === 'Impressions Lost to Budget (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit pl-4">
                <div>Impressions Lost to Budget (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'content_budget_lost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[250px] sm:w-[18vw] pl-4 h-fit">
            {formatNumber(props.getValue(), true)}
          </div>
        ),
      },
    ];

    let loading = true;
    const data = !campaignResponse?.error && (campaignResponse || []).map((resource: any) => {
      return {
        name: resource?.campaign_name,
        cost: resource?.cost,
        content_impression_share: Number(resource?.search_impression_share),
        content_budget_lost: Number(
          resource?.search_budget_lost_impression_share
        ),
        content_rank_lost: Number(resource?.search_rank_lost_impression_share),
      };
    });

    if (campaignResponse) {
      loading = false;
    }

    return { tableHeader, data, loading };
  }, [campaignResponse]);

  return (
    <>
      <div className="block sm:hidden">
        <MobileView
          campaignsReport={campaignsReport}
          impressionShareReportData={impressionShareReportData}
        />
      </div>
      <div className="hidden sm:block">
        <div className="flex justify-between items-center">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1">
            Impression Share Overview
          </h2>
          <div className="sm:pr-4">
            <Tooltip
              content={
                selectedClient?.google_ads?.customer_id
                  ? `Customer ID: ${selectedClient?.google_ads?.customer_id}`
                  : 'N/A'
              }
              position="top"
              textAlign="text-center"
            >
              <div className="pt-1">
                <Icon name="googleAd" size={20} />
              </div>
            </Tooltip>
          </div>
        </div>

        <div className="mt-8">
          {impressionShareReportData?.data?.length === 0 &&
          !campaignsReport.loading ? (
            <Card className="card-item px-1 sm:px-6">
              <div className="flex flex-col items-center justify-center w-full my-8 h-[148px]">
                <div className="mb-8">
                  <Icon name="NoDataAdGroup" />
                </div>
                <p className="font-inter font-[600] text-[20px] text-[#001C44] dark:text-white">
                  No impression shares to report as of now.
                </p>
                <p className="text-center w-[70vw] text-[20px] text-[#001C44] dark:text-white">
                  Stay ahead with the latest insights and trends affecting your
                  impression share. Our specialized section keeps you informed
                  with valuable updates and strategies for maximizing visibility
                  and impact.
                </p>
              </div>
            </Card>
          ) : (
            <AnalyticsTable
              data={impressionShareReportData?.data}
              columns={impressionShareReportData?.tableHeader}
              loading={campaignsReport.loading}
              totalDocs={
                impressionShareReportData?.data
                  ? impressionShareReportData?.data.length
                  : 'N/A'
              }
              sortByColumnId="Impression Share (%)"
              pagination={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ImpressionShareOverviewPPC;
