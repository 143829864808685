import React, { useState, useEffect, Fragment } from 'react';

// SEO STOP WORK EXPORT
export const SEOStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_google.end_date !== '') {
    var timeleft = Date.parse(client.seo_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.seo_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'seo_google',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.seo_google.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.seo_google.revenue,
    stopWorkContractDate: client.seo_google.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        SEO GOOGLE Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
            <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC STOP WORK EXPORT
export const PPCStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.ppc_google.end_date !== '') {
    var timeleft = Date.parse(client.ppc_google.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.ppc_google.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.ppc_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'ppc_google',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.ppc_google.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.ppc_google.revenue,
    stopWorkContractDate: client.ppc_google.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PPC GOOGLE Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
            <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SEO BING STOP WORK EXPORT
export const SEOBINGStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.seo_bing.end_date !== '') {
    var timeleft = Date.parse(client.seo_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.seo_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.seo_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'seo_bing',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.seo_bing.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.seo_bing.revenue,
    stopWorkContractDate: client.seo_bing.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft SEO Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
            <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC BING STOP WORK EXPORT
export const PPCBINGStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_bing.end_date !== '') {
    var timeleft = Date.parse(client.social_bing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_bing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_bing',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_bing.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_bing.revenue,
    stopWorkContractDate: client.social_bing.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Microsoft Ads Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
            <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINK BOOST STOP WORK EXPORT
export const LINKBOOSTStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.link_boost.end_date !== '') {
    var timeleft = Date.parse(client.link_boost.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.link_boost.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.link_boost.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();

  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'link_boost',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.link_boost.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.link_boost.revenue,
    stopWorkContractDate: client.link_boost.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Link Booster Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
            <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// REM STOP WORK EXPORT
export const REMStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.display.end_date !== '') {
    var timeleft = Date.parse(client.display.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.display.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'display',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.display.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.display.revenue,
    stopWorkContractDate: client.display.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// META STOP WORK EXPORT
export const METAStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_meta.end_date !== '') {
    var timeleft = Date.parse(client.social_meta.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_meta.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_meta',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_meta.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_meta.revenue,
    stopWorkContractDate: client.social_meta.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        META Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PINTEREST STOP WORK EXPORT
export const PINTERESTStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_pinterest.end_date !== '') {
    var timeleft = Date.parse(client.social_pinterest.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_pinterest.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_pinterest',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_pinterest.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_pinterest.revenue,
    stopWorkContractDate: client.social_pinterest.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TIKTOK STOP WORK EXPORT
export const TIKTOKStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tiktok.end_date !== '') {
    var timeleft = Date.parse(client.social_tiktok.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tiktok.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_tiktok',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_tiktok.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_tiktok.revenue,
    stopWorkContractDate: client.social_tiktok.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TIKTOK Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINKEDIN STOP WORK EXPORT
export const LINKEDINStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_linkedin.end_date !== '') {
    var timeleft = Date.parse(client.social_linkedin.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_linkedin.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_linkedin',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_linkedin.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_linkedin.revenue,
    stopWorkContractDate: client.social_linkedin.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PROGRAMMATIC STOP WORK EXPORT
export const PROGRAMMATICStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_programmatic.end_date !== '') {
    var timeleft = Date.parse(client.social_programmatic.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_programmatic.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_programmatic',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_programmatic.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_programmatic.revenue,
    stopWorkContractDate: client.social_programmatic.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TACTICAL STOP WORK EXPORT
export const TACTICALStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_tactical.end_date !== '') {
    var timeleft = Date.parse(client.social_tactical.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_tactical.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_tactical',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_tactical.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_tactical.revenue,
    stopWorkContractDate: client.social_tactical.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// CREATIVE STOP WORK EXPORT
export const CREATIVEStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.social_creative.end_date !== '') {
    var timeleft = Date.parse(client.social_creative.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.social_creative.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'social_creative',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.social_creative.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.social_creative.revenue,
    stopWorkContractDate: client.social_creative.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV STOP WORK EXPORT
export const WEBDEVStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_maintenance.end_date !== '') {
    var timeleft = Date.parse(client.web_maintenance.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_maintenance.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'web_maintenance',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.web_maintenance.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.web_maintenance.revenue,
    stopWorkContractDate: client.web_maintenance.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST STOP WORK EXPORT
export const WEBHOSTStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_hosting.end_date !== '') {
    var timeleft = Date.parse(client.web_hosting.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_hosting.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'web_hosting',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.web_hosting.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.web_hosting.revenue,
    stopWorkContractDate: client.web_hosting.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


// WEBPROJECT STOP WORK EXPORT
export const WEBPROJECTStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_project.end_date !== '') {
    var timeleft = Date.parse(client.web_project.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_project.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'web_project',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.web_project.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.web_project.revenue,
    stopWorkContractDate: client.web_project.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBPROJECT STOP WORK EXPORT
export const WEBLANDINGStopWork = ({ parentCallback, client }) => {
  var finalAsClient, finalDaysLeft;
  if (client.web_landing.end_date !== '') {
    var timeleft = Date.parse(client.web_landing.end_date) - new Date().getTime();
    var daysLeft = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    var timeAsClient = new Date().getTime() - Date.parse(client.web_landing.start_date);
    var daysAsClient = Math.ceil(timeAsClient / 1000 / 60 / 60 / 24);
    if (daysLeft >= 365) {
      finalDaysLeft = (daysLeft / 365).toFixed(0) + ' Years';
    } else if (daysLeft >= 30) {
      finalDaysLeft = (daysLeft / 30).toFixed(0) + ' Months';
    } else {
      finalDaysLeft = daysLeft + ' Days';
    }
    if (daysAsClient >= 365) {
      finalAsClient = (daysAsClient / 365).toFixed(0) + ' Years';
    } else if (daysAsClient >= 30) {
      finalAsClient = (daysAsClient / 30).toFixed(0) + ' Months';
    } else {
      finalAsClient = daysAsClient + ' Days';
    }
  } else {
    finalAsClient = '';
    finalDaysLeft = '';
  }

  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [stopWorkValues, setValues] = useState({
    type: 'stop work',
    service: 'web_landing',
    stopWorkRequestDate: '',
    stopWorkStartDate: client.web_landing.start_date,
    stopWorkContractValue: '',
    stopWorkComments: '',
    stopWorkBudget: client.web_landing.revenue,
    stopWorkContractDate: client.web_landing.end_date,
    stopWorkTimeAsClient: finalAsClient,
  });

  const {
    type,
    service,
    stopWorkRequestDate,
    stopWorkStartDate,
    stopWorkContractValue,
    stopWorkComments,
    stopWorkBudget,
    stopWorkContractDate
  } = stopWorkValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...stopWorkValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(stopWorkValues);
  }, [stopWorkValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Stop Work Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Date from which work will stop
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkRequestDate')}
              value={stopWorkRequestDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="mm/dd/yyyy"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service start date?
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkStartDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the contract value?
          </p>
          <div>
            <input
              type="text"
              onInput={handleChange('stopWorkContractValue')}
              value={stopWorkContractValue}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Please provide a statement explaining why work is stopping?
          </p>
          <div>
          <textarea
              onInput={handleChange('stopWorkComments')}
              value={stopWorkComments}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              type="text"
              disabled
              value={stopWorkBudget}
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Contract finish date?
          </p>
          <div>
            <input
              onKeyDown={(e) => e.preventDefault()}
              onInput={handleChange('stopWorkStartDate')}
              value={stopWorkContractDate}
              type="date"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
