import { useState, useEffect } from 'react';
import { ComboChart } from '../../ComboChart';
import usePerformanceStore from '../../../store/usePerformanceStore';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import { Card } from '@tremor/react';
import ClientSearch from '../../PerformanceInsights/ClientSearchBar';
import Icon from '../../../assets/icons/SvgComponent';

type CustomTooltip = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};

const dataFormatter = (
  number: number | string,
  hasDollar?: boolean,
  hasPercent?: boolean
) => {
  return hasDollar
    ? formatToCurrency(number)
    : formatNumber(number, hasPercent);
};

const today = new Date();
const previousMonth = new Date(today.setMonth(today.getMonth() - 1));
const month = previousMonth.toLocaleString('default', { month: 'long' });

const PerformanceInsightsServiceChart = () => {
  const { fetchInsightsService, insightsService } = usePerformanceStore(
    (state) => state
  );
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

  useEffect(() => {
    handleFetchInsightsService();
  }, [selectedClientId]);

  const handleFetchInsightsService = () => {
    const payload = {
      clientId: selectedClientId,
    };
    fetchInsightsService(payload);
  };

  const serviceData = insightsService?.dates?.map((date, index) => {
    const {
      seo_google = 0,
      ppc_google = 0,
      social_meta = 0,
      ppc_google_average = 0,
      seo_google_average = 0,
      social_meta_average = 0,
    } = insightsService?.values[index];
    return {
      date: date,
      'Google SEO': formatNumber(seo_google),
      'Google Ads': formatNumber(ppc_google),
      Meta: formatNumber(social_meta),
      'Google SEO Average': formatNumber(seo_google_average),
      'Google Ads Average': formatNumber(ppc_google_average),
      'Meta Average': formatNumber(social_meta_average),
    };
  });

  const serviceCustomTooltip = (props: CustomTooltip) => {
    const { payload, active, label } = props;
    if (!active || !payload || payload.length === 0) return null;

    const payloadItem = payload[0];
    const googleSeoData = payloadItem.payload['Google SEO'];
    const seoAverage = payloadItem.payload['Google SEO Average'];
    const googleAdsData = payloadItem.payload['Google Ads'];
    const adsAverage = payloadItem.payload['Google Ads Average'];
    const metaData = payloadItem.payload['Meta'];
    const metaAverage = payloadItem.payload['Meta Average'];

    return (
      <div className="w-fit rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex flex-1 space-x-2.5">
          <div className="w-full">
            <p className="mb-2 pb-2 font-medium text-tremor-content-emphasis dark:text-white border-b">
              {label}
            </p>
            <div className="flex flex-col space-y-2 font-bold">
              {/* Google SEO */}
              <div className="flex items-center text-[10px]">
                <div className="w-2 h-2 rounded-full bg-[#0029FF]" />
                <div className="flex justify-between items-center w-full gap-2">
                  <div className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500] flex justify-between w-[140px]">
                    <div className="w-[120px] whitespace-nowrap">
                      Google SEO
                    </div>
                    {selectedClientId && <div>{googleSeoData || 0}%</div>}
                  </div>
                  <div className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500] flex justify-between w-[120px]">
                    <div className="w-[80px]">Average</div>
                    <div>{seoAverage || 0}%</div>
                  </div>
                </div>
              </div>

              {/* Google Ads */}
              <div className="flex items-center text-xs">
                <div className="w-2 h-2 rounded-full bg-[#01F0FF]" />
                <div className="flex justify-between items-center w-full gap-2">
                  <div className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500] flex justify-between w-[140px]">
                    <div className="w-[120px] whitespace-nowrap">
                      Google Ads
                    </div>
                    {selectedClientId && <div>{googleAdsData || 0}%</div>}
                  </div>
                  <div className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500] flex justify-between w-[120px]">
                    <div className="w-[80px]">Average</div>
                    <div>{adsAverage || 0}%</div>
                  </div>
                </div>
              </div>

              {/* Meta */}
              <div className="flex items-center text-xs">
                <div className="w-2 h-2 rounded-full bg-[#FFB545]" />
                <div className="flex justify-between items-center w-full gap-2">
                  <div className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500] flex justify-between w-[140px]">
                    <div className="w-[120px] whitespace-nowrap">Meta</div>
                    {selectedClientId && <div>{metaData || 0}%</div>}
                  </div>
                  <div className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500] flex justify-between w-[120px]">
                    <div className="w-[80px]">Average</div>
                    <div>{metaAverage || 0}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="border-t pt-4">
      <div className="font-[700] text-[18px] text-[#001C44] mb-4 dark:text-gray-300">
        Client Performance by Client
        {/* Performance by Percentage for {month} */}
      </div>
      <div className="mb-4">
        <ClientSearch
          setSelectedClientId={setSelectedClientId}
          selectedClientId={selectedClientId}
        />
      </div>
      {insightsService.loading ? (
        <div className="w-full h-[320px] flex flex-col gap-5 justify-center items-center">
          <div className="w-1/2 bg-gray-300 rounded-full overflow-hidden">
            <div className="h-2 bg-blue-500 rounded-full animate-loading-bar" />
          </div>
          <div className="font-bold animate-pulse dark:text-white">
            Loading Data
          </div>
        </div>
      ) : (
        <>
          <ComboChart
            data={serviceData}
            index="date"
            showLegend={false}
            barSeries={{
              categories: ['Google SEO', 'Google Ads', 'Meta'],
              colors: ['seo', 'ppc', 'meta'],
              yAxisWidth: 32,
              maxValue: 100,
              valueFormatter: (value) => dataFormatter(value, false, true),
            }}
            lineSeries={{
              categories: [
                'Google SEO Average',
                'Google Ads Average',
                'Meta Average',
              ],
              showYAxis: true,
              colors: ['seo', 'ppc', 'meta'],
              yAxisWidth: 0,
              maxValue: 100,
              valueFormatter: (value) => dataFormatter(value, false, true),
            }}
            customTooltip={serviceCustomTooltip}
          />
          {/* <div className="text-xs flex justify-center gap-2 mt-7">
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-[#0029FF] rounded-full" />
              <div>Google SEO</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-[#01F0FF] rounded-full" />
              <div>Google Ads</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2 h-2 bg-[#FFB545] rounded-full" />
              <div>Meta</div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default PerformanceInsightsServiceChart;
