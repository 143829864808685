import { useState, useEffect } from 'react';
import { Card, Grid, Col, LineChart } from '@tremor/react';
import ReactSelect from '../../ReactSelect';
import UserSearch from '../../PerformanceInsights/UserSearchbar';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';
import MissingIntegrationCards from '../MissingIntegration';
import { ComboChart } from '../../ComboChart';
import { formatNumber, formatToCurrency } from '../../../utils/common';
import moment from 'moment';

type CustomTooltip = {
  payload: any;
  active: boolean | undefined;
  label: any;
  hasPercent?: boolean;
};

// Define types for the individual sections (seo_google, ppc_google, social_meta)
type Status = {
  green: { average: number; total: number; user: number };
  amber: { average: number; total: number; user: number };
  red: { average: number; total: number; user: number };
};

// Define type for the user
type User = {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
  role: string;
};

// Define type for each data entry
type InsightsData = {
  seo_google: Status;
  ppc_google: Status;
  social_meta: Status;
  report_date: string;
  user: User;
};

// Define type for the main data structure
type InsightsResponse = {
  [key: number]: InsightsData; // The indexed data
  loading: boolean;
  error: string | undefined;
};

const dataFormatter = (
  number: number | string,
  hasDollar?: boolean,
  hasPercent?: boolean
) => {
  return hasDollar
    ? formatToCurrency(number)
    : formatNumber(number, hasPercent);
};

const dataLineFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();

const today = new Date();
const previousMonth = new Date(today.setMonth(today.getMonth() - 1));
const month = previousMonth.toLocaleString('default', { month: 'long' });

const PerformanceInsightsStatus = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [insightsStatusService, setInsightsStatusService] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const {
    fetchInsightsStatus,
    insightsStatus,
    fetchInsightsUserStatus,
    userInsightsStatus,
  } = usePerformanceStore((state) => state);

  const handleFetchInsightstatus = () => {
    const payload = {
      userId: selectedUserId,
      service: insightsStatusService?.value,
    };
    fetchInsightsStatus(payload);
    if (selectedUserId) {
      fetchInsightsUserStatus(payload);
    }
  };

  useEffect(() => {
    handleFetchInsightstatus();
  }, [selectedUserId, insightsStatusService]);

  const statusData = insightsStatus?.dates?.map((date, index) => {
    const { green = {}, amber = {}, red = {} } = insightsStatus.values[index];

    return {
      date,
      Green: (green['Lead Gen'] || 0) + (green['Ecommerce'] || 0),
      green: {
        'Lead Gen': green['Lead Gen'] || 0,
        Ecommerce: green['Ecommerce'] || 0,
      },
      Amber: (amber['Lead Gen'] || 0) + (amber['Ecommerce'] || 0),
      amber: {
        'Lead Gen': amber['Lead Gen'] || 0,
        Ecommerce: amber['Ecommerce'] || 0,
      },
      red: {
        'Lead Gen': red['Lead Gen'] || 0,
        Ecommerce: red['Ecommerce'] || 0,
      },
      Red: (red['Lead Gen'] || 0) + (red['Ecommerce'] || 0),
    };
  });

  const userInsightsStatusArray = Object.entries(userInsightsStatus).map(
    ([key, value]: [
      string,
      {
        report_date?: string;
        seo_google: any;
        ppc_google: any;
        social_meta: any;
      },
    ]) => {
      // Calculate totals for green, amber, red for each entry
      const seoGoogleTotals = value?.seo_google
        ? {
            green: value.seo_google.green.average || 0,
            amber: value.seo_google.amber.average || 0,
            red: value.seo_google.red.average || 0,
          }
        : { green: 0, amber: 0, red: 0 };

      const ppcGoogleTotals = value?.ppc_google
        ? {
            green: value.ppc_google.green.average || 0,
            amber: value.ppc_google.amber.average || 0,
            red: value.ppc_google.red.average || 0,
          }
        : { green: 0, amber: 0, red: 0 };

      const socialMetaTotals = value?.social_meta
        ? {
            green: value.social_meta.green.average || 0,
            amber: value.social_meta.amber.average || 0,
            red: value.social_meta.red.average || 0,
          }
        : { green: 0, amber: 0, red: 0 };

      const seoGoogleUsers = value?.seo_google
        ? {
            green: value.seo_google.green.user || 0,
            amber: value.seo_google.amber.user || 0,
            red: value.seo_google.red.user || 0,
          }
        : { green: 0, amber: 0, red: 0 };

      const ppcGoogleUsers = value?.ppc_google
        ? {
            green: value.ppc_google.green.user || 0,
            amber: value.ppc_google.amber.user || 0,
            red: value.ppc_google.red.user || 0,
          }
        : { green: 0, amber: 0, red: 0 };

      const socialMetaUsers = value?.social_meta
        ? {
            green: value.social_meta.green.user || 0,
            amber: value.social_meta.amber.user || 0,
            red: value.social_meta.red.user || 0,
          }
        : { green: 0, amber: 0, red: 0 };

      // Return the item with the users included
      return {
        date: value?.report_date
          ? moment(value.report_date).format('MMM YYYY')
          : undefined,
        seo_google: value?.seo_google,
        ppc_google: value?.ppc_google,
        social_meta: value?.social_meta,

        greenTotal:
          seoGoogleTotals.green +
          ppcGoogleTotals.green +
          socialMetaTotals.green,
        amberTotal:
          seoGoogleTotals.amber +
          ppcGoogleTotals.amber +
          socialMetaTotals.amber,
        redTotal:
          seoGoogleTotals.red + ppcGoogleTotals.red + socialMetaTotals.red,
        greenUser:
          seoGoogleUsers.green + ppcGoogleUsers.green + socialMetaUsers.green,
        amberUser:
          seoGoogleUsers.amber + ppcGoogleUsers.amber + socialMetaUsers.amber,
        redUser: seoGoogleUsers.red + ppcGoogleUsers.red + socialMetaUsers.red,
      };
    }
  );

  const statusCustomTooltip = (props: CustomTooltip) => {
    const { payload, active, label } = props;
    if (!active || !payload || payload.length === 0) return null;

    const payloadItem = payload[0];
    const amberData = payloadItem.payload.amber;
    const greenData = payloadItem.payload.green;
    const redData = payloadItem.payload.red;

    return (
      <div className="rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex space-x-2.5">
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis dark:text-white">
              {label}
            </p>
            <Grid
              numItems={2}
              numItemsSm={2}
              numItemsMd={2}
              numItemsLg={2}
              className={`gap-4`}
            >
              <Col>
                <div className="flex items-center text-[12px]">
                  <div className="w-2 h-2 rounded-full bg-[#22C55E]" />
                  <div>
                    <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                      <span className="border-b mr-3">Lead Gen</span>
                      {greenData?.['Lead Gen'] || 0}
                    </span>

                    {/* <Col>
                    <span className="ml-1 rounded  text-[12px] px-1 dark:text-white font-[500]">
                      <span className="border-b mr-3">Ecommerce</span>
                      {greenData?.Ecommerce || 0}
                    </span>
                  </Col>
                  <Col>
                    <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                      <span className="border-b mr-3">Total User</span>
                      {greenData?.total || 0}
                    </span>
                  </Col>
                  <Col>
                    <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                      <span className="border-b mr-3">Average</span>
                      {greenData?.average || 0} %
                    </span>
                  </Col> */}
                  </div>
                </div>
                <div className="flex items-center text-[12px]">
                  <div className="w-2 h-2 rounded-full bg-[#F59E0B]" />
                  <div>
                    <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                      <span className="border-b mr-3">Lead Gen</span>
                      {amberData?.['Lead Gen'] || 0}
                    </span>
                  </div>
                </div>
                <div className="flex items-center text-[12px]">
                  <div className="w-2 h-2 rounded-full bg-[#F43F5E]" />
                  <div>
                    <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                      <span className="border-b mr-3">Lead Gen</span>
                      {redData?.['Lead Gen'] || 0}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="flex items-center text-[12px]">
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {greenData?.Ecommerce || 0}
                  </span>
                </div>
                <div className="flex items-center text-[12px]">
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {amberData?.Ecommerce || 0}
                  </span>
                </div>
                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {redData?.Ecommerce || 0}
                  </span>
                </div>
              </Col>
              {/* <Col>
                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Total User</span>
                    {greenData?.total}
                  </span>
                </div>
                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Total User</span>
                    {amberData?.total}
                  </span>
                </div>

                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Total User</span>
                    {redData?.total}
                  </span>
                </div>
              </Col>
              <Col>
                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Average</span>
                    {greenData?.average} %
                  </span>
                </div>
                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Average</span>
                    {amberData?.average} %
                  </span>
                </div>
                <div>
                  <span className="ml-1 rounded text-[12px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Average</span>
                    {redData?.average} %
                  </span>
                </div>
              </Col> */}
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  const userCustomTooltip = (props: CustomTooltip) => {
    const { payload, active, label } = props;
    if (!active || !payload || payload.length === 0) return null;

    const payloadItem = payload[0];
    const seo_google = payloadItem.payload.seo_google;
    const ppc_google = payloadItem.payload.ppc_google;
    const social_meta = payloadItem.payload.social_meta;

    return (
      <div className="rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex space-x-2.5">
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis dark:text-white">
              {label}
            </p>
            <Grid
              numItems={3}
              numItemsSm={3}
              numItemsMd={3}
              numItemsLg={3}
              className={`gap-4`}
            >
              <Col>
                <div className="text-[10px] text-center ml-2 font-bold">
                  Google SEO
                </div>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-between items-center">
                      <div className="w-2 h-2 rounded-full bg-[#22C55E]" />
                      <div className="text-[12px] dark:text-white font-[500] text-left w-[18px]">
                        {formatNumber(seo_google?.green?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(seo_google?.green?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-between items-center">
                      <div className="w-2 h-2 rounded-full bg-[#F59E0B]" />
                      <div className="text-[12px] dark:text-white font-[500] text-left w-[18px]">
                        {formatNumber(seo_google?.amber?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(seo_google?.amber?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-between items-center">
                      <div className="w-2 h-2 rounded-full bg-[#F43F5E]" />
                      <div className="text-[12px] dark:text-white font-[500] text-left w-[18px]">
                        {formatNumber(seo_google?.red?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(seo_google?.red?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
              </Col>
              <Col>
                <div className="text-[10px] text-center font-bold">
                  Google Ads
                </div>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-[12px] dark:text-white font-[500]">
                        {formatNumber(ppc_google?.green?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(ppc_google?.green?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-[12px] dark:text-white font-[500]">
                        {formatNumber(ppc_google?.amber?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(ppc_google?.amber?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-[12px] dark:text-white font-[500]">
                        {formatNumber(ppc_google?.red?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(ppc_google?.red?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
              </Col>
              <Col>
                <div className="text-[10px] text-center font-bold">Meta</div>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-[12px] dark:text-white font-[500]">
                        {formatNumber(social_meta?.green?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(social_meta?.green?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-[12px] dark:text-white font-[500]">
                        {formatNumber(social_meta?.amber?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(social_meta?.amber?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
                <Grid numItems={2} className="gap-2">
                  <Col>
                    <div className="flex justify-center items-center gap-1">
                      <div className="text-[12px] dark:text-white font-[500]">
                        {formatNumber(social_meta?.red?.user) || 0}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-[12px] dark:text-white font-[500]">
                      {formatNumber(social_meta?.red?.average) || 0}%
                    </div>
                  </Col>
                </Grid>
              </Col>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="border-t pt-4">
        <div className="font-[700] text-[18px] text-[#001C44] mb-4 dark:text-gray-300">
          Client Performance by Staff
        </div>
        <Grid
          className="gap-4 mb-4"
          numItems={1}
          numItemsSm={1}
          numItemsMd={3}
          numItemsLg={3}
        >
          <Col numColSpanLg={2}>
            <UserSearch
              setSelectedUserId={setSelectedUserId}
              selectedUserId={selectedUserId}
            />
          </Col>
          <Col numColSpanLg={1}>
            <ReactSelect
              value={insightsStatusService}
              options={[
                { value: '', label: 'All Service' },
                { value: 'seo_google', label: 'Google SEO' },
                { value: 'ppc_google', label: 'Google Ads' },
                { value: 'social_meta', label: 'Meta' },
              ]}
              handleOnChange={(selectedOption: any) => {
                setInsightsStatusService(selectedOption);
              }}
              placeholder="All Service"
            />
          </Col>
        </Grid>
        <MissingIntegrationCards selectedUserId={selectedUserId} />
        {insightsStatus.loading ? (
          <div className="w-full h-[242px] flex flex-col gap-5 justify-center items-center">
            <div className="w-1/2 bg-gray-300 rounded-full overflow-hidden">
              <div className="h-2 bg-blue-500 rounded-full animate-loading-bar" />
            </div>
            <div className="font-bold animate-pulse dark:text-white">
              Loading Data
            </div>
          </div>
        ) : (
          <ComboChart
            data={selectedUserId ? userInsightsStatusArray : statusData}
            index="date"
            showLegend={false}
            barSeries={{
              categories: selectedUserId
                ? ['greenUser', 'amberUser', 'redUser']
                : [],
              colors: ['green', 'amber', 'rose'],
              yAxisWidth: 32,
              valueFormatter: (value) => dataFormatter(value, false, false),
            }}
            lineSeries={{
              categories: selectedUserId
                ? ['greenTotal', 'amberTotal', 'redTotal']
                : ['Green', 'Amber', 'Red'],
              showYAxis: true,
              colors: ['green', 'amber', 'rose'],
              yAxisWidth: 0,
              valueFormatter: (value) => dataFormatter(value, false, false),
            }}
            className="h-[242px]"
            customTooltip={
              selectedUserId ? userCustomTooltip : statusCustomTooltip
            }
          />
          // <LineChart
          //   className="h-[242px]"
          //   data={statusData}
          //   index="date"
          //   categories={['Green', 'Amber', 'Red']}
          //   colors={['green', 'amber', 'rose']}
          //   valueFormatter={dataLineFormatter}
          //   startEndOnly
          //   showAnimation
          //   yAxisWidth={30}
          //   customTooltip={statusCustomTooltip}
          //   onValueChange={(v) => console.log(v)}
          // />
        )}
      </div>
    </>
  );
};

export default PerformanceInsightsStatus;
