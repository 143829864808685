import { useMemo } from 'react';
import {
  processData,
  formatNumberWithCommas,
  formatNumber,
  parseDate,
  processData2,
} from '../../../utils/common';
import { Grid, Col, Card, BadgeDelta } from '@tremor/react';
import AreaCardChart from '../AreaCardChart';
import useGoogleStore from '../../../store/useGoogleStore';
import MobileView from './MobileView';
import DonutBarGroup from '../DonutCardChart';
import AnalyticsTable from '../AnalyticsTable';
import InfoTooltip from '../AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import useClientStore from '../../../store/useClientStore';
import Tooltip from '../../HelpTooltip';
import { useRetryFetchGA4Analytics } from '../Hooks';

interface IProps {
  sliceData?: boolean;
}

const TrafficInsights = ({ sliceData }: IProps) => {
  const { ga4SeoData, landingPages } = useGoogleStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const propertyId = selectedClient?.google_analytics?.ga4?.property_id;
  const newUsers = processData(ga4SeoData, 'newUsers');
  const organicNewUsers = processData(ga4SeoData, 'organic_newUsers');
  const sessions = processData(ga4SeoData, 'sessions');
  const organic_sessions = processData(ga4SeoData, 'organic_sessions');
  const engagedSessions = processData(ga4SeoData, 'engagedSessions');

  const organic_engagedSessions = processData(
    ga4SeoData,
    'organic_engagedSessions'
  );
  const sessionConversionRate = processData(
    ga4SeoData,
    'sessionConversionRate'
  );

  const new_Users = processData(ga4SeoData, 'newUsers');

  const lastMonthnewUsers =
    Array.isArray(new_Users) && new_Users.length > 0
      ? new_Users[new_Users.length - 1]?.value
      : undefined;

  const lastMonthSessionConversionRate =
    Array.isArray(sessionConversionRate) && sessionConversionRate.length > 0
      ? sessionConversionRate[sessionConversionRate.length - 1]?.value
      : undefined;

  const lastMonthEngagedSessions =
    Array.isArray(engagedSessions) && engagedSessions.length > 0
      ? engagedSessions[engagedSessions.length - 1]?.value
      : undefined;

  const lastMonthOrganicEngagedSessions =
    Array.isArray(organic_engagedSessions) && organic_engagedSessions.length > 0
      ? organic_engagedSessions[organic_engagedSessions.length - 1]?.value
      : undefined;

  const lastMonthSessions =
    Array.isArray(sessions) && sessions.length > 0
      ? sessions[sessions.length - 1]?.value
      : undefined;

  const lastMonthOrganicSessions =
    Array.isArray(organic_sessions) && organic_sessions.length > 0
      ? organic_sessions[organic_sessions.length - 1]?.value
      : undefined;

  const lastMonthNewUsers =
    Array.isArray(newUsers) && newUsers.length > 0
      ? newUsers[newUsers.length - 1]?.value
      : undefined;
  const lastMonthOrganicNewUsers =
    Array.isArray(organicNewUsers) && organicNewUsers.length > 0
      ? organicNewUsers[organicNewUsers.length - 1]?.value
      : undefined;

  const top_traffic_sources = ga4SeoData?.top_traffic_sources?.data || [];
  const formattedTopTrafficSources = top_traffic_sources.map((item, index) => {
    return {
      name: item.sessionDefaultChannelGroup,
      value: item.sessions,
    };
  });

  const topTrafficSourcesCategories = top_traffic_sources.map(
    (item) => item.sessionDefaultChannelGroup
  );

  const new_users = ga4SeoData?.new_users?.data || [];

  const formattedNew_Users = new_users.map((item, index) => {
    return {
      name: item.sessionDefaultChannelGroup,
      value: item.sessions,
    };
  });

  const new_UsersCategories = top_traffic_sources.map(
    (item) => item.sessionDefaultChannelGroup
  );

  const landingPageSessions = ga4SeoData?.landingPage?.sessions_desc || [];
  const landingPageConversions = ga4SeoData?.landingPage?.conversions_desc || [];

  const landingPageData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Page',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Page' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Page' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Page' || headerId === 'Page') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'page',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[150px] sm:w-[48vw] pl-4 h-fit">
            <div className="truncate max-w-[40vw] overflow-hidden">
              {props.getValue() === '' ? 'N/A' : props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Sessions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Sessions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Sessions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Sessions' || headerId === 'Sessions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'sessions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[14vw] h-fit">
            <div className="w-[120px] sm:w-[8vw] flex justify-between sm:gap-3">
              <div className="w-fit overflow-hidden">
                {formatNumberWithCommas(props.getValue()?.session)}
              </div>
              <div className="w-fit overflow-hidden">
                {renderValue(props.getValue()?.previous_period_sessions)}
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[14vw] h-fit">
            <div className="w-[120px] sm:w-[8vw] flex justify-between sm:gap-3">
              <div className="w-fit overflow-hidden">
                {formatNumberWithCommas(props.getValue()?.conversion)}
              </div>
              <div className="w-fit overflow-hidden">
                {renderValue(props.getValue()?.previous_period_conversions)}
              </div>
            </div>
          </div>
        ),
      },
    ];

    const mergedData = landingPageSessions.map((sessionItem) => {
      const matchingConversion = landingPageConversions.find(
        (conversionItem) => conversionItem.page === sessionItem.page
      );

      return {
        page: sessionItem.page,
        sessions: {
          session: Number(sessionItem.sessions),
          previous_period_sessions: sessionItem.previous_period,
        },
        conversions: {
          conversion: matchingConversion
            ? Number(matchingConversion?.conversions)
            : 0,
          previous_period_conversions: matchingConversion
            ? matchingConversion?.previous_period
            : 0, // Assuming previous_period is a string
        },
      };
    });

    // Include pages from landingPageConversions that do not have corresponding entries in landingPageSessions
    landingPageConversions.forEach((conversionItem) => {
      const pageExists = mergedData.some(
        (item) => item.page === conversionItem.page
      );
      if (!pageExists) {
        mergedData.push({
          page: conversionItem.page,
          sessions: { session: 0, previous_period_sessions: 0 },
          conversions: {
            conversion: Number(conversionItem.conversions),
            previous_period_conversions: conversionItem.previous_period,
          },
        });
      }
    });

    return {
      tableHeader,
      mergedData,
    };
  }, [landingPageSessions, landingPageConversions]);

  const organicLandingPageSessions = ga4SeoData?.organic_landingPage?.sessions_desc || [];
  const organicLandingPageConversions = ga4SeoData?.organic_landingPage?.conversions_desc || [];

  const organicLandingPageData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Page',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Page' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Page' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Page' || headerId === 'Page') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[48vw] h-fit pl-4">
                <div>Page</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'page',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[150px] sm:w-[48vw] pl-4 h-fit">
            <div className="truncate max-w-[40vw] overflow-hidden">
              {props.getValue() === '' ? 'N/A' : props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Sessions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Sessions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Sessions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Sessions' || headerId === 'Sessions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Sessions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'sessions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[14vw] h-fit">
            <div className="w-[120px] sm:w-[8vw] flex justify-between sm:gap-3">
              <div className="w-fit overflow-hidden">
                {formatNumberWithCommas(props.getValue()?.session)}
              </div>
              <div className="w-fit overflow-hidden">
                {renderValue(props.getValue()?.previous_period_sessions)}
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[14vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[14vw] h-fit">
            <div className="w-[120px] sm:w-[8vw] flex justify-between sm:gap-3">
              <div className="w-fit overflow-hidden">
                {formatNumberWithCommas(props.getValue()?.conversion)}
              </div>
              <div className="w-fit overflow-hidden">
                {renderValue(props.getValue()?.previous_period_conversions)}
              </div>
            </div>
          </div>
        ),
      },
    ];
    const mergedData = organicLandingPageSessions.map((sessionItem) => {
      const matchingConversion = organicLandingPageConversions.find(
        (conversionItem) => conversionItem.page === sessionItem.page
      );

      return {
        page: sessionItem.page,
        sessions: {
          session: Number(sessionItem.sessions),
          previous_period_sessions: sessionItem.previous_period,
        },
        conversions: {
          conversion: matchingConversion
            ? Number(matchingConversion?.conversions)
            : 0,
          previous_period_conversions: matchingConversion
            ? matchingConversion?.previous_period
            : 0, // Assuming previous_period is a string
        },
      };
    });

    organicLandingPageConversions.forEach((conversionItem) => {
      const pageExists = mergedData.some(
        (item) => item.page === conversionItem.page
      );
      if (!pageExists) {
        mergedData.push({
          page: conversionItem.page,
          sessions: { session: 0, previous_period_sessions: 0 },
          conversions: {
            conversion: Number(conversionItem.conversions),
            previous_period_conversions: conversionItem.previous_period,
          },
        });
      }
    });

    return {
      tableHeader,
      mergedData,
    };
  }, [organicLandingPageSessions, organicLandingPageConversions]);

  const renderValue = (value: any) => {
    const isString = typeof value === 'string';
    const valueTrend =
      isString &&
      (value.includes('+') ? true : value.includes('-') ? false : true);
    return valueTrend ? (
      // <div className="trend-up bg-[#22c55e] rounded-3xl mx-0">
      //   <Icon name="trendUp" color="#FFF" />{' '}
      //   <span className="block ml-1 text-[#FFF]">{value}</span>
      // </div>
      <BadgeDelta
        deltaType="moderateIncrease"
        isIncreasePositive={true}
        className="p-1 text-[10px] h-[20px]"
      >
        {value}
      </BadgeDelta>
    ) : (
      // <div className="trend-down rounded-3xl mx-0 dark:text-[#ef4444] dark:bg-gray-900">
      //   <Icon name="trendDown" /> <span className="block ml-1">{value}</span>
      // </div>
      <BadgeDelta
        deltaType="moderateDecrease"
        isIncreasePositive={true}
        className="p-1 text-[10px] h-[20px]"
      >
        {value === 0 ? '0' : value}
      </BadgeDelta>
    );
  };

  // const retryFetchGA4Analytics = () => {
  //   const client = selectedClient;
  //   const data = {
  //     account_email: client?.google_analytics?.ga4?.account_email,
  //     propertyId: propertyId,
  //     startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
  //     reportType: 'seo',
  //     endDate: moment(endDate).format('YYYY-MM-DD'),
  //     resetCache:false,
  //   };
  //   if (data.propertyId && data.account_email) {
  //     fetchGA4SeoData(data).catch((error: unknown) => {
  //       console.log(error, 'error');
  //     });
  //   } else {
  //     resetGoogleStore(['ga4SeoData']);
  //   }
  // };
  const retryFetchGA4Analytics = useRetryFetchGA4Analytics();

  return (
    <div>
      <div id="session">
        <div className="flex justify-start items-center mb-4">
          <h2 className="title-text text-base sm:text-[1.85rem] pb-1 hidden sm:block">
            Traffic Insights
          </h2>
        </div>

        <MobileView retry={retryFetchGA4Analytics} />
        <div className="hidden sm:block">
          <Grid
            numItems={1}
            numItemsSm={1}
            numItemsMd={2}
            numItemsLg={4}
            className="gap-4 mt-4 "
          >
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.sessions?.label
                    ? ga4SeoData?.sessions?.label
                    : 'Sessions'
                }
                chatData={sessions}
                category={['Sessions']}
                info={
                  ga4SeoData?.sessions?.info
                    ? ga4SeoData?.sessions?.info
                    : 'Sessions'
                }
                prevPeriod={ga4SeoData?.sessions?.previous_period}
                previousYear={ga4SeoData?.sessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.organic_sessions?.label
                    ? ga4SeoData?.organic_sessions?.label
                    : 'Organic Sessions'
                }
                chatData={organic_sessions}
                category={['Organic Sessions']}
                info={
                  ga4SeoData?.organic_sessions?.info
                    ? ga4SeoData?.organic_sessions?.info
                    : 'Organic Sessions'
                }
                prevPeriod={ga4SeoData?.organic_sessions?.previous_period}
                previousYear={ga4SeoData?.organic_sessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthOrganicSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.engagedSessions?.label
                    ? ga4SeoData?.engagedSessions?.label
                    : 'Engaged Sessions'
                }
                chatData={engagedSessions}
                category={['Engaged Sessions']}
                info={
                  ga4SeoData?.engagedSessions?.info
                    ? ga4SeoData?.engagedSessions?.info
                    : 'Engaged Sessions'
                }
                prevPeriod={ga4SeoData?.engagedSessions?.previous_period}
                previousYear={ga4SeoData?.engagedSessions?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthEngagedSessions)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.organic_engagedSessions?.label
                    ? ga4SeoData?.organic_engagedSessions?.label
                    : 'Organic Engaged Sessions'
                }
                chatData={organic_engagedSessions}
                category={['Organic Engaged Sessions']}
                info={
                  ga4SeoData?.organic_engagedSessions?.info
                    ? ga4SeoData?.organic_engagedSessions?.info
                    : 'Organic Engaged Sessions'
                }
                prevPeriod={
                  ga4SeoData?.organic_engagedSessions?.previous_period
                }
                previousYear={
                  ga4SeoData?.organic_engagedSessions?.previous_year
                }
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(
                  lastMonthOrganicEngagedSessions
                )}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.newUsers?.label
                    ? ga4SeoData?.newUsers?.label
                    : 'New Users'
                }
                chatData={new_Users}
                category={['New Users']}
                info={
                  ga4SeoData?.newUsers?.info
                    ? ga4SeoData?.newUsers?.info
                    : 'New Users'
                }
                prevPeriod={ga4SeoData?.newUsers?.previous_period}
                previousYear={ga4SeoData?.newUsers?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumber(lastMonthnewUsers)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.organic_newUsers?.label
                    ? ga4SeoData?.organic_newUsers?.label
                    : 'New Users (Organic)'
                }
                chatData={organicNewUsers}
                category={['Organic New Users']}
                info={
                  ga4SeoData?.organic_newUsers?.info
                    ? ga4SeoData?.organic_newUsers?.info
                    : 'New Users'
                }
                prevPeriod={ga4SeoData?.organic_newUsers?.previous_period}
                previousYear={ga4SeoData?.organic_newUsers?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumberWithCommas(lastMonthOrganicNewUsers)}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
            <Col>
              <AreaCardChart
                title={
                  ga4SeoData?.sessionConversionRate?.label
                    ? ga4SeoData?.sessionConversionRate?.label
                    : 'Session Conversion Rate'
                }
                chatData={sessionConversionRate}
                category={['Session Conversion Rate']}
                info={
                  ga4SeoData?.sessionConversionRate?.info
                    ? ga4SeoData?.sessionConversionRate?.info
                    : 'Session Conversion Rate'
                }
                prevPeriod={ga4SeoData?.sessionConversionRate?.previous_period}
                previousYear={ga4SeoData?.sessionConversionRate?.previous_year}
                isLoading={ga4SeoData.loading}
                totalValue={formatNumber(lastMonthSessionConversionRate, true)}
                hasPercent={true}
                error={ga4SeoData.error}
                icon="googleAnalytics"
                propertyId={`Property ID: ${propertyId}`}
                retryMethod={retryFetchGA4Analytics}
              />
            </Col>
          </Grid>
        </div>
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={2}
          numItemsLg={2}
          className="gap-4 my-4"
        >
          <Col>
            <DonutBarGroup
              title={
                ga4SeoData?.top_traffic_sources?.label
                  ? ga4SeoData?.top_traffic_sources.label
                  : `Traffic by Channel`
              }
              data={formattedTopTrafficSources}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={topTrafficSourcesCategories}
              info={
                ga4SeoData?.top_traffic_sources?.info
                  ? ga4SeoData?.top_traffic_sources.info
                  : `Traffic by Channel`
              }
              icon="googleAnalytics"
              propertyId={`Property ID: ${propertyId}`}
              error={ga4SeoData.error}
              retryMethod={retryFetchGA4Analytics}
            />
          </Col>
          <Col>
            <DonutBarGroup
              title={
                ga4SeoData?.new_users?.label
                  ? ga4SeoData?.new_users?.label
                  : `Session By Channel`
              }
              data={formattedNew_Users}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
              customClass="h-[312px]"
              categories={new_UsersCategories}
              info={
                ga4SeoData?.new_users?.length > 0
                  ? ga4SeoData?.new_users?.info
                  : `Session By Channel`
              }
              icon="googleAnalytics"
              propertyId={`Property ID: ${propertyId}`}
              error={ga4SeoData.error}
              retryMethod={retryFetchGA4Analytics}
            />
          </Col>
        </Grid>
        <Card className="lg:mb-4 dark:bg-[#222732] dark:border dark:border-white px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex items-start justify-between mb-1">
            <div className="flex gap-2 items-center mb-4 sm:mb-0">
              <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
                {'Landing Page (All Channels)'}
              </h2>
              <InfoTooltip
                content={
                  ga4SeoData?.landingPage?.info
                    ? ga4SeoData?.landingPage?.info
                    : 'Landing Page'
                }
                position="top"
              >
                <Icon name="InfoCircle" size={14} />
              </InfoTooltip>
            </div>
            <Tooltip
              content={
                propertyId ? `Property ID: ${propertyId}` : `Property ID: None`
              }
              position="top"
              textAlign="text-center"
            >
              <div className="pt-1">
                <Icon name="googleAnalytics" size={20} />
              </div>
            </Tooltip>
          </div>
          <AnalyticsTable
            data={
              landingPageData?.mergedData ? landingPageData?.mergedData : []
            }
            columns={landingPageData?.tableHeader}
            loading={ga4SeoData.loading}
            totalDocs={
              landingPageData?.mergedData
                ? landingPageData?.mergedData.length
                : 'N/A'
            }
            sortByColumnId="Sessions"
            pagination={true}
            error={ga4SeoData?.landingPage?.error}
            retryMethod={retryFetchGA4Analytics}
          />
        </Card>
        <Card className="lg:mb-4 dark:bg-[#222732] dark:border dark:border-white px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
          <div className="flex items-start justify-between mb-1">
            <div className="flex gap-2 items-center mb-4 sm:mb-0">
              <h2 className="text-sm text-[#001C44] dark:text-white font-bold">
                {ga4SeoData?.total_landing_pages_organic?.label
                  ? ga4SeoData?.total_landing_pages_organic?.label
                  : 'Organic Landing Page'}
              </h2>
              <InfoTooltip
                content={
                  ga4SeoData?.total_landing_pages_organic?.info
                    ? ga4SeoData?.total_landing_pages_organic?.info
                    : 'Organic Landing Page'
                }
                position="top"
              >
                <Icon name="InfoCircle" size={14} />
              </InfoTooltip>
            </div>
            <Tooltip
              content={
                propertyId ? `Property ID: ${propertyId}` : `Property ID: None`
              }
              position="top"
              textAlign="text-center"
            >
              <div className="pt-1">
                <Icon name="googleAnalytics" size={20} />
              </div>
            </Tooltip>
          </div>
          <AnalyticsTable
            data={
              organicLandingPageData?.mergedData
                ? organicLandingPageData?.mergedData
                : []
            }
            columns={organicLandingPageData?.tableHeader}
            loading={ga4SeoData.loading}
            totalDocs={
              organicLandingPageData?.mergedData
                ? organicLandingPageData?.mergedData.length
                : 'N/A'
            }
            sortByColumnId="Sessions"
            pagination={true}
            error={ga4SeoData?.landingPage?.error}
            retryMethod={retryFetchGA4Analytics}
          />
        </Card>
      </div>
    </div>
  );
};

export default TrafficInsights;
