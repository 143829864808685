import { requests } from "./ApiService";
import { toQueryString } from "../utils/common";

export const ThirdParty = {
  getUrlRating: (data) => requests.get(`/api/v1/ahrefs/url_rating/${data.id}${toQueryString({
    startDate: data.startDate,
    endDate: data.endDate,
    refreshCache: data.resetCache,
  })}`),
  getAhrefsDomainRating: (data) =>
    requests.get(`/api/v1/ahrefs/domain_rating/${data.id}/${toQueryString({  
      refreshCache: data.resetCache,
      startDate: data.startDate,
      endDate: data.endDate,
    })}`), 
  getAhrefsDomainRatingHistory: (data) =>
    requests.get(`/api/v1/ahrefs/domain_rating_history/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      startDate: data.startDate,
      endDate: data.endDate,
      refreshCache: data.resetCache,
    })}`),
  getAhrefsBacklinks: (data) =>
    requests.get(`/api/v1/ahrefs/backlinks/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      refreshCache: data.resetCache,
    })}`),
  getAhrefsRefDomains: (data) =>
    requests.get(`/api/v1/ahrefs/refdomains/${data.id}${toQueryString({
      startDate: data.startDate,
      endDate: data.endDate,
      refreshCache: data.resetCache,
    })}`),
  getAhrefsTopKeywords: (data) =>
    requests.get(`/api/v1/ahrefs/top_keywords_history/${data.id}${toQueryString({
      startDate: data.startDate,
      endDate: data.endDate,
      refreshCache: data.resetCache,
    })}`),
  // semrush
  getOrgDomainOverview: (data) =>
    requests.get(`/api/v1/semrush/organic_domain_overview/${data.id}`),
  getSemrushCompetitors: (data) =>
    requests.get(`/api/v1/semrush/traffic_competitors/${data.id}`),
  getSemrushBacklinks: (data) =>
    requests.get(`/api/v1/semrush/backlinks/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      refreshCache: data.resetCache,
    })}`),
  getOrgSearchPositions: (data) =>
    requests.get(`/api/v1/semrush/organic_search_positions/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      refreshCache: data.resetCache,
    })}`),
  // Rankranger
    getRankRangerData: (data) =>
      requests.get(
        `/api/v1/rankranger/ranking/${toQueryString({
          campaign_id: data.campaignId,
          domain: data.domain,
          startDate: data.startDate,
          endDate: data.endDate,
          date: data.date,
          refreshCache: data.resetCache,
        })}`),  
  getCampaignsRankRanger: () => requests.get(`/api/v1/rankranger/campaigns`),
  /** Analytics */
  // semrush 
  getOrgDomainOverviewReport: (data) =>
    requests.get(`/analytics/v1/semrush/organic_domain_overview/${data.id}`),
  getSemrushCompetitorsReport: (data) =>
    requests.get(`/analytics/v1/semrush/traffic_competitors/${data.id}`),
  getSemrushBacklinksReport: (data) =>
    requests.get(`/analytics/v1/semrush/backlinks/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      refreshCache: data.resetCache,
    })}`),
  getOrgSearchPositionsReport: (data) =>
    requests.get(`/analytics/v1/semrush/organic_search_positions/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      refreshCache: data.resetCache,
    })}`),
  // Ahrefs
  getAhrefsBacklinksReport: (data) =>
    requests.get(`/analytics/v1/ahrefs/backlinks/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      refreshCache: data.resetCache,
    })}`),
  getUrlRatingReport: (data) => requests.get(`/analytics/v1/ahrefs/url_rating/${data.id}${toQueryString({
    startDate: data.startDate,
    endDate: data.endDate,
    refreshCache: data.resetCache,
    })}`),
  getAhrefsDomainRatingReport: (data) =>
    requests.get(`/analytics/v1/ahrefs/domain_rating/${data.id}/${toQueryString({  
      refreshCache: data.resetCache,
      startDate: data.startDate,
      endDate: data.endDate,
    })}`), 
  getAhrefsDomainRatingHistoryReport: (data) =>
    requests.get(`/analytics/v1/ahrefs/domain_rating_history/${data.id}${toQueryString({
      page: data.page,
      limit: data.limit,
      startDate: data.startDate,
      endDate: data.endDate,
      refreshCache: data.resetCache,
    })}`),
  getAhrefsRefDomainsReport: (data) =>
    requests.get(`/analytics/v1/ahrefs/refdomains/${data.id}${toQueryString({
      startDate: data.startDate,
      endDate: data.endDate,
      refreshCache: data.resetCache,
    })}`),
  getAhrefsTopKeywordsReport: (data) =>
    requests.get(`/analytics/v1/ahrefs/top_keywords_history/${data.id}${toQueryString({
      startDate: data.startDate,
      endDate: data.endDate,
      refreshCache: data.resetCache,
    })}`),
};