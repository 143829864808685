import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export const getStatusMetric = (status) => {
  switch (status) {
    case 'organic_sessions':
      return 'Organic Sessions';
    case 'organic_conversions':
      return 'Organic Conversions';
    case 'organic_totalRevenue':
      return 'Organic Total Revenue';
    case 'totalRevenue':
      return 'Total Revenue';
    case 'transactions':
      return 'Transactions';
    case 'sessions':
      return 'Sessions (All Channels)';
    case 'engagedSessions':
      return 'Engaged Sessions';
    case 'organic_engagedSessions':
      return 'Organic Engaged Sessions';
    case 'newUsers':
      return 'New Users';
    case 'organic_newUsers':
      return 'New Users (Organic)';
    case 'sessionConversionRate':
      return 'Session Conversion Rate';
    case 'landingPage_sessions':
      return 'Landing Page (All Channels) - Sessions';
    case 'landingPage_conversions':
      return 'Landing Page (All Channels) - Conversions';
    case 'landingPage_organic_sessions':
      return 'Organic Landing Page - Sessions';
    case 'landingPage_organic_conversions':
      return 'Organic Landing Page - Conversions';
    case 'clicks':
      return 'Clicks';
    case 'conversion_rate':
      return 'Conversion Rate';
    case 'conversions':
      return 'Conversions';
    case 'conversions_value':
      return 'Conversion Value';
    case 'cost':
      return 'Cost';
    case 'cpc':
      return 'Cost Per Click';
    case 'cost_per_conversion':
      return 'Cost Per Conversion';
    case 'ctr':
      return 'Click-Through Rate';
    case 'impressions':
      return 'Impressions';
    case 'roas':
      return 'Return on Ad Spend';
    case 'position':
      return 'Positions';
    case 'spend':
      return 'Ad Spend';
    case 'revenue':
      return 'Revenue';
    case 'reach':
      return 'Reach';
    case 'link_clicks':
      return 'Link Clicks';
    case 'website_purchases':
      return 'Purchases';
    case 'aov':
      return 'Average Order Value (AOV)';
    case 'cps':
      return 'Cost Per Sale (CPS)';
    case 'refdomains':
      return 'Domain Referring';
    case 'domain_rating':
      return 'Domain Rating';
    case 'Adwords Traffic':
      return 'Estimated Traffic';
    case 'Organic Traffic':
      return 'Estimated Organic Traffic Value';
    case 'Spend':
      return 'Cost';
    case 'ConversionRate':
      return 'Conversion Rate';
    case 'ReturnOnAdSpend':
      return 'Return on Ad Spend';
    case 'costPerConversion':
      return 'Cost Per Conversion';
    default:
      return capitalized(status);
  }
};

export const capitalized = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const metrics = [
  {
    label: 'Organic Sessions',
    value: 'organic_sessions',
  },
  {
    label: 'Organic Conversions',
    value: 'organic_conversions',
  },
  {
    label: 'Organic Revenue',
    value: 'organic_totalRevenue',
  },
  {
    label: 'Total Revenue',
    value: 'totalRevenue',
  },
  {
    label: 'Clicks',
    value: 'clicks',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  },
];

export const metricSourceOptions = [
  {
    label: 'Ahrefs',
    value: 'ahrefs',
  },
  {
    label: 'GA4',
    value: 'ga4',
  },
  {
    label: 'Google Ads',
    value: 'googleAds',
  },
  {
    label: 'GSC',
    value: 'gsc',
  },
  {
    label: 'Meta',
    value: 'meta',
  },
  {
    label: 'Microsoft Ads',
    value: 'bing',
  },
  {
    label: 'Semrush',
    value: 'semrush',
  },
];

// Utility function to get dependent options with label and value
export const getMetricSourceOptions = (selectedSource: string) => {
  const dependentOptions = {
    ahrefs: [
      { label: 'Domain Referring', value: 'refdomains' },
      { label: 'Domain Rating', value: 'domain_rating' },
    ],
    ga4: [
      { label: 'Organic Sessions', value: 'organic_sessions' },
      { label: 'Organic Conversions', value: 'organic_conversions' },
      { label: 'Organic Revenue', value: 'organic_totalRevenue' },
      { label: 'Total Revenue', value: 'totalRevenue' },
      { label: 'Transactions', value: 'transactions' },
      { label: 'Sessions (All Channels)', value: 'sessions' },
      { label: 'Engaged Sessions', value: 'engagedSessions' },
      { label: 'Organic Engaged Sessions', value: 'organic_engagedSessions' },
      { label: 'New users', value: 'newUsers' },
      { label: 'New Users (Organic)', value: 'organic_newUsers' },
      { label: 'Session Conversion Rate', value: 'sessionConversionRate' },
      {
        label: 'Landing Page (All Channels) - Sessions',
        value: 'landingPage_sessions',
      },
      {
        label: 'Landing Page (All Channels) - Conversions',
        value: 'landingPage_conversions',
      },
      {
        label: 'Organic Landing Page - Sessions',
        value: 'landingPage_organic_sessions',
      },
      {
        label: 'Organic Landing Page - Conversions',
        value: 'landingPage_organic_conversions',
      },
      // { label: 'Total Conversions', value: '' },
      // { label: 'Session Conversion Rate', value: '' },
      // { label: 'Organic Conversions', value: '' },
      // { label: 'Organic Conversions Rate', value: '' },
    ],
    googleAds: [
      { label: 'Clicks', value: 'clicks' },
      { label: 'Conversion Rate', value: 'conversion_rate' },
      { label: 'Conversions', value: 'conversions' },
      { label: 'Conversion Value', value: 'conversions_value' },
      { label: 'Cost', value: 'cost' },
      { label: 'Cost Per Click', value: 'cpc' },
      { label: 'Cost Per Conversions', value: 'cost_per_conversion' },
      { label: 'Click-Through Rate', value: 'ctr' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Return on Ad Spend', value: 'roas' },
    ],
    gsc: [
      { label: 'Clicks', value: 'clicks' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'CTR', value: 'ctr' },
      { label: 'Positions', value: 'position' },
    ],
    meta: [
      { label: 'Ad Spend', value: 'spend' },
      { label: 'Revenue', value: 'revenue' },
      { label: 'ROAS', value: 'roas' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Reach', value: 'reach' },
      { label: 'CPM', value: 'cpm' },
      { label: 'Link Clicks', value: 'link_clicks' },
      { label: 'CTR', value: 'ctr' },
      { label: 'CPC', value: 'cpc' },
      { label: 'Purchases', value: 'website_purchases' },
      { label: 'Average Order Value (AOV)', value: 'aov' },
      { label: 'Cost Per Sale (CPS)', value: 'cps' },
      // { label: 'Revenue by Campaign', value: '' },
    ],
    bing: [
      { label: 'Clicks', value: 'Clicks' },
      { label: 'Conversion Rate', value: 'ConversionRate' },
      { label: 'Conversions', value: 'Conversions' },
      { label: 'Conversion Value', value: '' },
      { label: 'Cost', value: 'Spend' },
      { label: 'Cost Per Click', value: 'cpc' },
      { label: 'Cost Per Conversions', value: 'costPerConversion' },
      { label: 'Click-Through Rate', value: 'Ctr' },
      { label: 'Impressions', value: 'Impressions' },
      { label: 'Return on Ad Spend', value: 'ReturnOnAdSpend' },
    ],
    semrush: [
      { label: 'Estimated Traffic', value: 'Adwords Traffic' },
      { label: 'Estimated Organic Traffic Value', value: 'Organic Traffic' },
    ],
  };

  return dependentOptions[selectedSource] || [];
};

export const getMetricSourceIcon = (key: string) => {
  const keywordMappings = {
    ahrefs: {
      label: 'Ahrefs',
      value: 'Ahref',
    },
    ga4: {
      label: 'GA4',
      value: 'googleAnalytics',
    },
    googleAds: {
      label: 'Google Ads',
      value: 'googleAd',
    },
    gsc: {
      label: 'GSC',
      value: 'googleSearch',
    },
    meta: {
      label: 'Meta',
      value: 'Meta',
    },
    bing: {
      label: 'Microsoft Ads',
      value: 'BingAnalyticsTab',
    },
    semrush: {
      label: 'Semrush',
      value: 'semrush',
    },
  };

  return (
    keywordMappings[key] || {
      label: 'Unknown',
      value: 'unknown',
    }
  );
};



export const itemsObject = (itemsArray) => {
  return itemsArray.reduce((acc, item) => {
    acc[item.key] = {...item};
    return acc;
  }, {});
}

export const emptyLayoutCharts = [
  {
    id: uuidv4(),
    key: "1",
    title: "",
    toolTip: "",
    data_config: {
      property: "",
      metrics: "",
      source: "",
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "light",
      themeStyle: "light"
    }
  },
  {
    id: uuidv4(),
    key: "2",
    title: "",
    toolTip: "",
    data_config: {
      property: "",
      metrics: "",
      source: "",
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "light",
      themeStyle: "light"
    }
  },
  {
    id: uuidv4(),
    key: "3",
    title: "",
    toolTip: "",
    report: {
      data: {},
      error: {
        status: 401,
        message: ''
      }
    },
    data_config: {
      property: "",
      metrics: "",
      source: ""
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "light",
      themeStyle: "light"
    }
  }
]

export const addChart = (key) => {
  return {
    id: uuidv4(),
    key: key.toString(),
    title: "",
    toolTip: "",
    data_config: {
      property: "",
      metrics: "",
      source: "",
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "light",
      themeStyle: "light"
    }
  }
}

export const getInitialLayout = () => {
  return {
    lg: [
      { i: "1", x: 0, y: 0, w: 1, h: 1 },
      { i: "2", x: 1, y: 0, w: 1, h: 1 },
      { i: "3", x: 2, y: 0, w: 1, h: 1 }
    ],
    sm: [
      { i: "1", x: 0, y: 0, w: 1, h: 1 },
      { i: "2", x: 1, y: 0, w: 1, h: 1 },
      { i: "3", x: 0, y: 1, w: 1, h: 1 }
    ]
  };
};

export const generateLayout = (data) => {
  return {
    lg: data.map((item, index) => ({
      i: item.key,
      x: index, // Place items in rows of 3
      y: 0, // Math.floor(index / 3),
      w: 1,
      h: 1
    })),
    sm: data.map((item, index) => ({
      i: item.key,
      x: 0, // Stacks items vertically
      y: 0,
      w: 1,
      h: 1
    }))
  };
};

type LayoutItem = {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
  moved: boolean;
  static: boolean;
};

type Layout = LayoutItem[];

export type LayoutData = {
  [breakpoint: string]: Layout;
};

export const addNewColumn = (layoutData: LayoutData, maxColumns: number) => {
  const updatedLayoutData = {};

  for (const [breakpoint, layout] of Object.entries(layoutData)) {
      const newIndex = layout.length + 1;
      const newX = layout.length % maxColumns;
      const newY = Math.floor(layout.length / maxColumns);

      const newItem = {
          w: 1,
          h: 1,
          x: newX,
          y: newY,
          i: newIndex.toString(),
          moved: false,
          static: false
      };
      updatedLayoutData[breakpoint] = [...layout, newItem];
  }

  return updatedLayoutData;
}

export const generateGridLayout = (data, maxColumns) => {
  const layout = data.map((item, index) => {
      const x = index % maxColumns; // Calculate column position
      const y = Math.floor(index / maxColumns); // Calculate row position
      return {
          i: item.key, // Use the key as the unique identifier
          x, // Column position
          y, // Row position
          w: 1, // Width of the item (1 grid unit)
          h: 1, // Height of the item (1 grid unit)
          static: false // Item is movable/resizable
      };
  });
  return layout;
}