import { useMemo } from 'react';
import { Card } from '@tremor/react';
import Icon from '../../../assets/icons/SvgComponent';
import AnalyticsTable from '../AnalyticsTable';
import Tooltip from '../../HelpTooltip';
import InfoTooltip from '../AnalyticsInfoTooltip';
import useClientStore from '../../../store/useClientStore';
import { useRetryFetchRankRanger } from '../Hooks';
interface IProps {
  data: any;
  isLoading?: boolean;
  hideHeader?: boolean;
  error?: any;
}

const Rankings = ({ data, isLoading = false, hideHeader, error }: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const rankingsResponse = data.data || [];
  const rankingsReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Keyword',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Keyword' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit pl-4">
                <div>Keyword</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Keyword' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit pl-4">
                <div>Keyword</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Keyword' || headerId === 'Keyword') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit pl-4">
                <div>Keyword</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'keyword',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[150px] sm:w-[12vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[12vw] overflow-hidden dark:text-white">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Tags',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Tags' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[15vw] h-fit">
                <div>Tags</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Tags' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[15vw] h-fit">
                <div>Tags</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Tags' || headerId === 'Tags') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[15vw] h-fit">
                <div>Tags</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'tags',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[15vw]  h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="text-white bg-[#005C9F] h-6 w-fit p-2 flex justify-center items-center rounded-lg min-w-[15vw] font-[600]">
                {props.getValue() ? props.getValue()?.toUpperCase() : ''}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Rank',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Rank' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Rank</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Rank' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Rank</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Rank' || headerId === 'Rank') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Rank</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'rank',
        cell: (props: any) => (
          <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
            <div className="flex justify-center">
              {renderRanking(props.getValue())}
            </div>
          </div>
        ),
      },
      {
        id: 'Weekly',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Weekly' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Weekly</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Weekly' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Weekly</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Weekly' || headerId === 'Weekly') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Weekly</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'weeklyChange',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            {renderValue(props.getValue()?.toString() ?? 0)}
          </div>
        ),
      },
      {
        id: 'Monthly',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Monthly' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Monthly</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Monthly' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Monthly</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Monthly' || headerId === 'Monthly') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Monthly</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'monthlyChange',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            {renderValue(props.getValue()?.toString() ?? 0)}
          </div>
        ),
      },
      {
        id: 'Volume',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Volume' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Volume</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Volume' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Volume</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Volume' || headerId === 'Volume') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Volume</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'search_volume',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            {renderValue(props.getValue()?.toString() ?? 0)}
          </div>
        ),
      },
    ];

    const data = !rankingsResponse?.error && (rankingsResponse || []).map((item: any) => {
      return {
        keyword: item?.keyword,
        tags: item?.tags[0] || '',
        rank: Number(item?.rank),
        dailyChange: item?.dailyChange,
        weeklyChange: item?.weeklyChange,
        monthlyChange: item?.monthlyChange,
        search_volume: Number(item?.search_volume),
      };
    });

    return { tableHeader, data };
  }, [rankingsResponse]);

  const renderRanking = (value: string | number) => {
    if (Number(value) === 1) {
      return (
        <div className="flex justify-center items-center py-[2px] px-2 bg-[#E2FAF1] rounded-3xl text-[#428B6E] dark:text-[#000]">
          <Icon name="badgeStatus" />{' '}
          <span className="block ml-2">{value}st</span>
        </div>
      );
    }
    if (Number(value) === 2) {
      return (
        <div className="flex justify-center items-center py-[2px] px-2 bg-[#FFF2E8] rounded-3xl text-[#FF7200] dark:text-[#000]">
          <Icon name="badgeStatus" color="#FF7200" />{' '}
          <span className="block ml-2">{value}nd</span>
        </div>
      );
    }
    if (Number(value) === 3) {
      return (
        <div className="flex justify-center items-center py-[2px] px-2 bg-[#F0E8FF] rounded-3xl text-[#8E4DFF] dark:text-[#000]">
          <Icon name="badgeStatus" color="#8E4DFF" />{' '}
          <span className="block ml-2">{value}rd</span>
        </div>
      );
    }
    return value || '---';
  };

  const renderValue = (value: string) => {
    const valueTrend = value?.includes('+')
      ? true
      : value?.includes('-')
        ? false
        : true;
    return valueTrend ? (
      <div className="trend-up bg-[#E6EFF5] rounded-3xl mx-0">
        <Icon name="trendUp" color="#005C9F" />{' '}
        <span className="block ml-1 text-[#005C9F]">{value}</span>
      </div>
    ) : (
      <div className="trend-down rounded-3xl mx-0 dark:text-[#FF775D] dark:bg-gray-900">
        <Icon name="trendDown" /> <span className="block ml-1">{value}</span>
      </div>
    );
  };

  const retryFetchRankRanger = useRetryFetchRankRanger();

  return (
    <Card className="card-item dark:bg-[#222732] dark:border dark:border-white px-3 sm:px-6 shadow-none lg:shadow ring-0 lg:ring-1">
      {hideHeader ? null : (
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-2 items-center ">
            <h2 className="text-[14px] text-[#001C44] font-semibold dark:text-white">
              Rankings Overview
            </h2>
            <InfoTooltip
              content={data?.info ? data?.info : `Rankings Overview`}
              position="top"
            >
              <Icon name="InfoCircle" size={14} />
            </InfoTooltip>
          </div>
          <Tooltip
            content={
              selectedClient.rank_ranger
                ? `Campaign ID: ${selectedClient?.rank_ranger?.campaign_id}`
                : ' '
            }
            position="top"
            textAlign="text-center"
          >
            <div className="pt-1">
              <Icon name="rank" size={25} />
            </div>
          </Tooltip>
        </div>
      )}

      <AnalyticsTable
        data={rankingsReportData?.data ? rankingsReportData?.data : []}
        columns={rankingsReportData?.tableHeader}
        loading={isLoading}
        totalDocs={
          rankingsReportData?.data ? rankingsReportData?.data.length : 'N/A'
        }
        pagination={true}
        error={error}
        sortByColumnId="Tags"
        retryMethod={retryFetchRankRanger}
      />
    </Card>
  );
};

export default Rankings;
