// WeeklyReport.tsx
import React, { useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { Card, DonutChart, List, ListItem, Title } from '@tremor/react';
import { useLocation, useNavigate } from 'react-router-dom';

interface PieChartProps {
  title?: string;
  data?: any;
  loading?: boolean;
}

const PieChart: React.FC<PieChartProps> = ({
  title,
  data,
  loading,
}) => {
  console.log('DATA:::::: ', data)

  const sortedData = data.sort((a, b) => a.kwDifficulty - b.kwDifficulty);

  const amounts = [
    { name: "Position 1", amount: 0, color: 'bg-cyan-500' },
    { name: "Position 2 - 3", amount: 0, color: 'bg-indigo-500' },
    { name: "Position 4 - 5", amount: 0, color: 'bg-blue-500' }, 
    { name: "Position 6 - 10", amount: 0,  color: 'bg-violet-500' },
  ];
  
  // Loop through data and increment the appropriate counts
  sortedData.forEach(({ position }) => {
    if (position === 1) {
      amounts[0].amount++;
    } else if (position >= 2 && position <= 3) {
      amounts[1].amount++;
    } else if (position >= 4 && position <= 5) {
      amounts[2].amount++;
    } else if (position >= 6 && position <= 10) {
      amounts[3].amount++;
    }
  });

  console.log('AMOUNTS ', amounts)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const lastElemet = data?.length ? data[data?.length - 1] : 0;
  const valueFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();

  const location = useLocation();
  const [value, setValue] = useState(null);
  const handleNavigate = (val: any) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('date', val.Month);
    searchParams.set('healthStatus', val.categoryClicked.toLowerCase());
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    const newUrl = `/client-reporting?${updatedSearch}&status=all&all=true`;

    window.open(newUrl, '_blank');
  };

  return (
    <Card className="md:max-w-[32.5%]">
      <Title>{title}</Title>

      {loading ? (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <Icon name="Loading" />
        </div>
      ) : (
        <>
          {data?.length ? (
            <>
            <DonutChart
              className="mt-8"
              data={amounts}
              category="amount"
              index="name"
              valueFormatter={valueFormatter}
              showTooltip={true}
              colors={['cyan', 'blue', 'indigo', 'violet', 'fuchsia']}
            />
            <List className="mt-2">
          {amounts.map((item) => (
            <ListItem key={item.name} className="space-x-6">
              <div className="flex items-center space-x-2.5 truncate">
                <span
                  className={classNames(
                    item.color,
                    'size-2.5 shrink-0 rounded-sm',
                  )}
                  aria-hidden={true}
                />
                <span className="truncate dark:text-dark-tremor-content-emphasis">
                  {item.name}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-medium tabular-nums text-tremor-content-strong dark:text-dark-tremor-content-strong">
                  {valueFormatter(item.amount)}
                </span>
              </div>
            </ListItem>
          ))}
        </List>

            </>
          ) : (
            <div className="w-full h-80 flex flex-col justify-center items-center">
              <p className="mb-4">No Data</p>
              <Icon name="NoData" />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default PieChart;

