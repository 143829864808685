import React, { useState, useEffect } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from '../../ReactSelect';
import { useThemeStore } from '../../../store/useThemeStore';
import { Button } from '@tremor/react';
import { ProgressBar } from '../../ProgressBar';
import useDashboardStore from '../../../store/useDashboardStore';
import useClientStore from '../../../store/useClientStore';

import { Textarea } from '@tremor/react';
import useAuthStore from '../../../store/useAuthStore';
import { emptyLayoutCharts, getInitialLayout } from '../constants';
interface ModalProps {
  onClose?: () => void;
}

const CategorySelection: React.FC<ModalProps> = ({ onClose }) => {
  const {
    fetchDashboardTemplate,
    templates,
    templateLoading,
    fetchDashboardTemplates,
    createDashboardAndIntegrateTemplate,
    createDashboardTemplate,
  } = useDashboardStore((state) => state);
  const { selectedClient } = useClientStore((state) => state);
  const { user } = useAuthStore((state) => state);

  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [selectedCategoryType, setSelectedCategoryType] = useState('');
  const [progressValue, setProgressValue] = useState(50);
  const [nextPage, setNextPage] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const categories = [
    {
      label: 'E Commerce',
      description: `Set up a seamless online store with all the tools you need to sell products and manage transactions.`,
      value: 'Ecommerce',
    },
    {
      label: 'Lead Gen',
      description: `Create a high-converting lead generation page to capture and manage potential customer information effectively.`,
      value: 'LeadGen',
    },
    {
      label: 'Varied',
      description: `Mix and match different features and templates to create a customized dashboard tailored to your unique needs, whether it’s for e-commerce, lead generation, or other business objectives.`,
      value: 'Varied',
    },
    {
      label: 'Create a new Template',
      description: `Start fresh and design a brand new template, giving you full control over every detail.`,
      value: 'Create',
    },
  ];

  const handleSelectedCategory = (value) => {
    setSelectedCategoryType(value);
  };

  const handleFetchGraphs = () => {
    fetchDashboardTemplates();
  };

  useEffect(() => {
    handleFetchGraphs();
  }, []);

  const handleSaveNewTemplate = async () => {
    const data = {
      title: selectedClient?.name,
      description: `${selectedClient?.name} regular template`,
      created_by: user.id,
    };
    await createDashboardAndIntegrateTemplate(selectedCategoryType,selectedClient?.id,data);
    onClose();
    fetchDashboardTemplate(selectedClient?.id);
  };

  const handleCreateNewTemplate = async () => {
    const data = {
      title,
      description,
      created_by: user.id,
      sections: [
        {
          title: 'New Section',
          order: 1,
          contents: [
            {
              status: 'active',
              layout_group: 'string',
              layout: JSON.stringify(getInitialLayout()),
              layout_charts: emptyLayoutCharts,
            },
          ],
        },
      ],
    };
    await createDashboardTemplate(data,selectedClient?.id);
    onClose();
    fetchDashboardTemplate(selectedClient?.id);
  };

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-screen sm:w-[45vw] p-[24px] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-center">
        <div className="flex gap-[8px] items-center">
          <div className="w-[34px] h-[34px]">
            <Icon name="Logo" />
          </div>
        </div>

        <div className="cursor-pointer" onClick={onClose}>
          <Icon
            name="Xclose"
            size={20}
            color={mode === 'dark' ? '#fff' : iconColor}
          />
        </div>
      </div>
      <div className="text-[#001C44] font-[600] text-[18px] font-inter py-4 border-b mb-4">
        How do you want to start?
      </div>
      {templateLoading ? (
        <div>
          <div className="w-full h-4 bg-gray-300 animate-pulse rounded-full mb-6" />
          <div className="flex flex-col justify-between h-full">
            <div className="w-full h-[60dvh] bg-gray-300 animate-pulse rounded-lg p-6">
              <div className="bg-gray-100 h-[5.5rem] animate-pulse rounded-lg w-full" />
              <div className="bg-gray-100 h-[5.5rem] animate-pulse rounded-lg w-full my-3" />
              <div className="bg-gray-100 h-[5.5rem] animate-pulse rounded-lg w-full my-3" />
              <div className="bg-gray-100 h-[5.5rem] animate-pulse rounded-lg w-full" />
            </div>
            <div className="flex gap-4 items-center">
              <button
                className="border-2  font-[600] rounded-lg text-[16px] w-full py-2.5 focus:outline-none"
                onClick={() => setNextPage(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  selectedCategoryType === 'Create'
                    ? handleCreateNewTemplate()
                    : handleSaveNewTemplate();
                }}
                className="text-white bg-[#0029FF] border-2 border-[#0029FF] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-[600] rounded-lg text-[16px] w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mb-6">
            <ProgressBar
              value={progressValue}
              className="mx-auto"
              showAnimation
            />
          </div>
          <div
            className="hidden sm:flex absolute top-[50%] left-[-50px]  bg-[#E6EFF5]  border border-[#E6EFF5] rounded-[8px] py-[8px] px-[14px] transform rotate-[270deg] text-[14px]  text-[#005C9F] gap-[8px] cursor-pointer"
            onClick={onClose}
          >
            <div>Close</div>
            <Icon name="Xclose" size={20} color="#005C9F" />
          </div>
          <div className="flex flex-col justify-between h-[80%]">
            {nextPage ? (
              <div>
                <input
                  type="text"
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Textarea
                  className="mt-4"
                  value={description || ''}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Template description..."
                />
              </div>
            ) : (
              <div className="flex flex-col gap-3 max-h-[65dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                {templates?.length > 0 &&
                  templates.map((category) => (
                    <div
                      className={`cursor-pointer ${category._id === selectedCategoryType ? 'p-4 border-2 border-[#0029FF] bg-[#0029FF1A] rounded-lg ' : 'p-4 border rounded-lg '}`}
                      key={category._id}
                      onClick={() => {
                        handleSelectedCategory(category._id);
                        if (category._id === 'Create') {
                          setProgressValue(50);
                        } else {
                          setProgressValue(100);
                        }
                      }}
                    >
                      <div className="mb-[0.125rem] block min-h-[1.5rem] ps-[1.5rem]">
                        <div
                          className={`relative float-left -ms-[1.5rem] me-1 mt-0.5 h-5 w-5 border border-[#C6C9CB] rounded-full flex items-center justify-center`}
                        >
                          <div
                            className={`${category._id === selectedCategoryType ? 'w-3 h-3 bg-[#0029FF] rounded-full' : ''}`}
                          ></div>
                        </div>
                        <label
                          className={`mt-px inline-block ps-[0.15rem] font-inter font-[400] text-[14px] ${category._id === selectedCategoryType && 'text-[#0029FF]'}`}
                        >
                          {category.title}
                        </label>
                        <div
                          className={`mt-px ps-[0.15rem] font-inter font-[400] text-[14px] text-[#001C44] ${category._id === selectedCategoryType && 'text-[#0029FF]'}`}
                        >
                          {category.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <div className="flex gap-4 items-center">
              {nextPage ? (
                <button
                  className="border-2  font-[600] rounded-lg text-[16px] w-full py-2.5 focus:outline-none"
                  onClick={() => setNextPage(false)}
                >
                  Previous
                </button>
              ) : (
                <button className="border-2  font-[600] rounded-lg text-[16px] w-full py-2.5 focus:outline-none">
                  Cancel
                </button>
              )}

              {selectedCategoryType === 'Create' && !nextPage ? (
                <button
                  onClick={() => {
                    setNextPage(true);
                    setProgressValue(100);
                  }}
                  className="text-white bg-[#0029FF] border-2 border-[#0029FF] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-[600] rounded-lg text-[16px] w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={() => {
                    selectedCategoryType === 'Create'
                      ? handleCreateNewTemplate()
                      : handleSaveNewTemplate();
                  }}
                  className="text-white bg-[#0029FF] border-2 border-[#0029FF] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-[600] rounded-lg text-[16px] w-full py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CategorySelection;
