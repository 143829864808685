// WeeklyReport.tsx
import React, { useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import { BarChart, DonutChart, Card, Title } from '@tremor/react';
import { useLocation, useNavigate } from 'react-router-dom';

interface BarChartsProps {
  title?: string;
  data?: any;
  loading?: boolean;
}

const BarCharts: React.FC<BarChartsProps> = ({
  title,
  data,
  loading,
}) => {
  const sortedData = data.sort((a, b) => a.kwDifficulty - b.kwDifficulty);
  let keywords = []
  let kwDiff = []

  sortedData.map( (d) => {
    keywords.push(d.keyword)
    kwDiff.push(
      {
        [d.keyword]: d.kwDifficulty,
      }
    )
  })

  
  const cats = keywords.slice(0, 20);
  const catData = kwDiff.slice(0, 20);

  const lastElemet = data?.length ? data[data?.length - 1] : 0;
  const valueFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();

  const location = useLocation();
  const [value, setValue] = useState(null);
  const handleNavigate = (val: any) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('date', val.Month);
    searchParams.set('healthStatus', val.categoryClicked.toLowerCase());
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    const newUrl = `/client-reporting?${updatedSearch}&status=all&all=true`;

    window.open(newUrl, '_blank');
  };

  return (
    <Card className="md:max-w-[65.5%]">
      <Title>{title}</Title>

      {loading ? (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <Icon name="Loading" />
        </div>
      ) : (
        <>
          {kwDiff?.length ? (
            <>
            <BarChart
              className="opacity-70"
              data={catData}
              index="service"
              categories={cats}
              colors={['emerald', 'amber', 'red', 'blue', 'yellow', 'sky', 'slate', 'green', 'orange', 'purple', 'teal' ]}
              valueFormatter={valueFormatter}
              yAxisWidth={48}
              stack={true}
              onValueChange={(v) => handleNavigate(v)}
            />
            </>
          ) : (
            <div className="w-full h-80 flex flex-col justify-center items-center">
              <p className="mb-4">No Data</p>
              <Icon name="NoData" />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default BarCharts;

