import React, { useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';

import { motion } from 'framer-motion';
import { mode } from '../../../utils/settings';

type Props = {
  onClose: () => void;
  change?: any;
  setConfirmationModalDecline?: any;
  setConfirmationModalApprove?: any;
};
const ChangeRequestModal: React.FC<Props> = ({
  onClose,
  change,
  setConfirmationModalDecline,
  setConfirmationModalApprove,
}) => {
  return (
    <motion.div
      initial={{ x: '100%' }}
      exit={{ x: '100%' }}
      animate={{ x: 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}
    >
      <div
        className="flex flex-col w-1/2 h-full justify-between px-8 py-6 bg-white dark:bg-gray-800"
        style={{ overflowY: 'auto' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex flex-col w-full gap-y-6">
          <div className="flex items-start justify-between pb-6 border-b border-[#E6EBF0]">
            <div className="flex items-center gap-2">
              <Icon name="arrowLeft" color="gray" />
              <span className="text-2xl font-semibold dark:text-white">
                <span>
                  {change.changeDetails.service === 'seo_google' ? 'Google SEO' : ''}
                  {change.changeDetails.service === 'ppc_google' ? 'Google Ads' : ''}
                  {change.changeDetails.service === 'seo_bing' ? 'Microsoft SEO' : ''}
                  {change.changeDetails.service === 'social_bing' ? 'Microsoft Ads' : ''}
                  {change.changeDetails.service === 'link_boost' ? 'Link Booster' : ''}
                  {change.changeDetails.service === 'display' ? 'Remarketing' : ''}
                  {change.changeDetails.service === 'social_meta' ? 'Meta Ads' : ''}
                  {change.changeDetails.service === 'social_tiktok' ? 'Tiktok Ads' : ''}
                  {change.changeDetails.service === 'social_linkedin' ? 'Linkedin Ads' : ''}
                  {change.changeDetails.service === 'social_pinterest' ? 'Pinterest Ads' : ''}
                  {change.changeDetails.service === 'social_programmatic' ? 'Programmatic Ads' : ''}
                  {change.changeDetails.service === 'social_tactical' ? 'Tactical Ads' : ''}
                  {change.changeDetails.service === 'social_creative' ? 'Creative Ads' : ''}
                  {change.changeDetails.service === 'web_hosting' ? 'Web Hosting' : ''}
                  {change.changeDetails.service === 'web_maintenance' ? 'Web Maintenance' : ''}
                </span>{' '}
                <span style={{ textTransform: 'capitalize' }}>
                - {change.changeDetails.type}
                </span>{' '}
                - {change.domain}
              </span>
            </div>
            <div onClick={onClose}>
              <Icon name="close" size={20} color="gray" />
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <span className="text-[#101828] dark:text-white">
                Date Submitted:
              </span>
              <span className="text-[#475467]">
                {change.createdAt.split('T')[0]}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-[#101828] dark:text-white">
                Submitted by:
              </span>
              <span className="text-[#475467]">{change.submitter}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-[#101828] dark:text-white">
                Assigned to:
              </span>
              <span className="text-[#475467]">{change.gadApprover ? change.gadApprover : change.approvedBy}</span>
            </div>
          </div>
          {/* APPLY CREDIT */}
          {change.changeType === 'apply' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the amount to be credited (EX GST)?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.applyCreditAmount}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the credit?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.applyCreditReason}
                  //onChange={(e) => setReason(e.target.value)}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex items-center justify-between gap-4">
                <div className="flex flex-col gap-1.5 w-full">
                  <span className="text-[#001C44] dark:text-white">
                    Which of the below is the most appropriate bucket for the
                    credit?:
                  </span>
                  <input
                    type="text"
                    disabled
                    value={change.changeDetails.applyCreditBucket}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
                <div className="flex flex-col gap-1.5 w-full">
                  <span className="text-[#001C44] dark:text-white">
                    Length of time as client:
                  </span>
                  <input
                    type="text"
                    value={change.changeDetails.applyCreditTimeAsClient}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    disabled
                  />
                </div>
              </div>
            </>
          ) : ('')}
          {/* CANCEL */}
          {change.changeType === 'cancel' ? (
            <>
              {change.changeDetails.cancelUnder30days
                ? <span style={{padding:'4px 6px', fontSize:'14px'}} className="text-white bg-[#D92D20] border border-[#D92D20] rounded-lg  dark:border-gray-600">Was under 30 days</span>
                : ''}

              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Date of cancel request
                </span>
                <input
                  type="date"
                  value={change.changeDetails.cancelRequestDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  When is the last day?
                </span>
                <input
                  type="date"
                  value={change.changeDetails.cancelEffectDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the cancel?
                </span>
                <input
                  value={change.changeDetails.cancelReason}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the sub reason for the cancel?
                </span>
                <input
                  value={change.changeDetails.cancelSubReason}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Briefly explain your choices above:
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.cancelAmComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  How you tried to save the account?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.cancelSaveComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What did the client say?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.cancelClientComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What do you think we could've done better?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.cancelDoneBetterComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Additional Notes
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.cancelNoteComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When was the start date?
                </span>
                <input
                  type="date"
                  value={change.changeDetails.cancelStartDate}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  How long is left in the contract?
                </span>
                <input
                  type="text"
                  value={change.changeDetails.cancelContractLeftTip}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              {change.serviceLine === 'seo_google' ||  change.serviceLine === 'seo_bing' ?
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When should the client be removed from Rank Ranger?
                </span>
                <input
                  type="date"
                  value={change.changeDetails.cancelRankRanger}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              :''}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Should we attempt to reactivate?
                </span>
                <input
                  type="text"
                  value={change.changeDetails.cancelReactivate}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>

              {change.changeDetails.cancelReactivate === 'Yes' ? (
                <>
                  <div className="flex flex-col gap-1.5 w-full">
                    <span className="text-[#001C44] dark:text-white">
                      When should we reach out to the client?
                    </span>
                    <input
                      type="date"
                      value={change.changeDetails.cancelReactivateDate}
                      disabled
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-[#001C44] dark:text-white">
                      Further reactivation notes:
                    </span>
                    <textarea
                      rows={6}
                      value={change.changeDetails.cancelReactivateNotes}
                      disabled
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Service amount
                </span>
                <input
                  type="text"
                  value={change.changeDetails.cancelBudget}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Contract Finish Date
                </span>
                <input
                  type="date"
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  value={change.changeDetails.contractFinishDate}
                />
              </div>
            </>
          ) : ('')}
          {/* NON_START */}
          {change.changeType === 'nonstart' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Date of non start request
                </span>
                <input
                  type="date"
                  value={change.changeDetails.nonStartRequestDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  When is the last day?
                </span>
                <input
                  type="date"
                  value={change.changeDetails.nonStartEffectDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the non start?
                </span>
                <input
                  value={change.changeDetails.nonStartReason}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the sub reason for the non start?
                </span>
                <input
                  value={change.changeDetails.nonStartSubReason}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Briefly explain your choices above:
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.nonStartAmComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  How you tried to save the account?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.nonStartSaveComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What did the client say?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.nonStartClientComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What do you think we could've done better?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.nonStartDoneBetterComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Additional Notes
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.nonStartNoteComments}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When was the start date?
                </span>
                <input
                  type="date"
                  value={change.changeDetails.nonStartStartDate}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  How long is left in the contract?
                </span>
                <input
                  type="text"
                  value={change.changeDetails.nonStartContractLeftTip}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              {change.serviceLine === 'seo_google' ||  change.serviceLine === 'seo_bing' ?
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  When should the client be removed from Rank Ranger?
                </span>
                <input
                  type="date"
                  value={change.changeDetails.nonStartRankRanger}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              :''}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Should we attempt to reactivate?
                </span>
                <input
                  type="text"
                  value={change.changeDetails.nonStartReactivate}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>

              {change.changeDetails.nonStartReactivate === 'Yes' ? (
                <>
                  <div className="flex flex-col gap-1.5 w-full">
                    <span className="text-[#001C44] dark:text-white">
                      When should we reach out to the client?
                    </span>
                    <input
                      type="date"
                      value={change.changeDetails.nonStartReactivateDate}
                      disabled
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-[#001C44] dark:text-white">
                      Further reactivation notes:
                    </span>
                    <textarea
                      rows={6}
                      value={change.changeDetails.nonStartReactivateNotes}
                      disabled
                      className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Service amount
                </span>
                <input
                  type="text"
                  value={change.changeDetails.nonStartBudget}
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5 w-full">
                <span className="text-[#001C44] dark:text-white">
                  Contract Finish Date
                </span>
                <input
                  type="date"
                  disabled
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  value={change.changeDetails.contractFinishDate}
                />
              </div>
            </>
          ) : ('')}
          {/* PAUSE */}
          {change.changeType === 'pause' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the reason for the pause?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseCategory}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the pause request date?:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.pauseRequestDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the pause effect date?:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.pauseEffectDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the pause recommencement date?:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.pauseRecommenceDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Reason for pause?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.pauseReason}
                  //onChange={(e) => setReason(e.target.value)}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What did the client say?
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.pauseClientComments}
                  //onChange={(e) => setReason(e.target.value)}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the client's contract end date?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseHubspotFinishDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  What is the client's budget?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseClientBudget}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Length of time as client:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.pauseTimeAsClient}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
            </>
          ) : ('')}
          {/* STOP WORK */}
          {change.changeType === 'stop' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Stop work comments?:
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.stopWorkComments}
                  //onChange={(e) => setReason(e.target.value)}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Client's service amount:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.stopWorkBudget}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  The client's contract value?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.stopWorkContractValue}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Stop work request date?:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.stopWorkRequestDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Cluent's start date?:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.stopWorkStartDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                  Length of time as client?:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.stopWorkTimeAsClient}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
            </>
          ) : ('')}
          {/*  DELAY BILLING  */}
          {change.changeType === 'delay' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                Planned Launch Date:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.delayBillingRequestDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                When should invoicing commence:
                </span>
                <input
                  type="date"
                  value={change.changeDetails.delayBillingStartDate}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                {change.changeType} Reason:
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.delayBillingReason}
                  //onChange={(e) => setReason(e.target.value)}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                Additional Information:
                </span>
                <textarea
                  rows={6}
                  value={change.changeDetails.delayBillingComments}
                  //onChange={(e) => setReason(e.target.value)}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                Length of time as Client:
                </span>
                <input
                  type="text"
                  value={change.changeDetails.delayTimeAsClient}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
            </>
          ) : ('')}
          {/*  REDUCE BILLING  */}
          {change.changeType === 'reduce' ? (
            <>
              <div className="flex flex-col gap-1.5">
                <span className="text-[#001C44] dark:text-white">
                What is the new budget? 
                </span>
                <input
                  type="text"
                  value={change.changeDetails.reduceBillingNewBudget}
                  //onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                />
              </div>
              <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Effective Date for Reduced Budget:
                  </span>
                  <input
                    type="date"
                    value={change.changeDetails.reduceBillingStartDate}
                    //onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  What is the reason for the reduction:
                  </span>
                  <textarea
                    rows={6}
                    value={change.changeDetails.reduceBillingReason}
                    //onChange={(e) => setReason(e.target.value)}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Current Budget:
                  </span>
                  <input
                    type="text"
                    value={change.changeDetails.reduceBillingBudget}
                    //onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Length of time as Client:
                  </span>
                  <input
                    type="text"
                    value={change.changeDetails.reduceTimeAsClient}
                    //onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
            </>
          ) : ('')}
          {/*  CASH REFUND  */}
          {change.changeType === 'refund' ? (
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is the amount to refund? (excluding GST):
            </span>
            <input
              type="text"
              value={change.changeDetails.refundAmount}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is the reason for the refund?:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.refundReason}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Current Budget:
            </span>
            <input
              type="text"
              value={change.changeDetails.refundBudget}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          </>
          ):('')}
          {/*  RESTART  */}
          {change.changeType === 'restart' ? (
            <>
            <div className="flex flex-col gap-1.5">
              <span className="text-[#001C44] dark:text-white">
              When does the account turn back on?:
              </span>
              <input
                type="text"
                value={change.changeDetails.restartCategory}
                //onChange={(e) => setAmount(Number(e.target.value))}
                className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
              />
            </div>
            {change.changeDetails.restartCategory == 'Specific Date' ?
              <>
                <div className="flex flex-col gap-1.5">
                  <span className="text-[#001C44] dark:text-white">
                  Specified Restart Date:
                  </span>
                  <input
                    type="date"
                    value={change.changeDetails.restartDate}
                    //onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
                  />
                </div>
              </>
            : ''}
            <div className="flex flex-col gap-1.5">
              <span className="text-[#001C44] dark:text-white">
              What did you do to save the account?:
              </span>
              <textarea
                rows={6}
                value={change.changeDetails.restartSave}
                //onChange={(e) => setReason(e.target.value)}
                className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <span className="text-[#001C44] dark:text-white">
              Additional Information:
              </span>
              <textarea
                rows={6}
                value={change.changeDetails.restartInfo}
                //onChange={(e) => setReason(e.target.value)}
                className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
              />
            </div>
            </>
            
          ):('')}
          {/* FLAG FOR REVIEW */}
          {change.changeType === 'flagged' ? (
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            {change.changeType} Reason:
            </span>
            <input
              type="text"
              value={change.changeDetails.flaggedReason}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          {change.changeDetails.flaggedReason == 'Traffic Drop' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is the extent of the drop (% Deficit):
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedDeficit}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Time Period when traffic was performing as expected:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedTimePeriod}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Top 3 landing pages of concern:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedPages}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          {change.changeDetails.flaggedReason == 'Ranking Drop' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Flagged Keywords (1-5 Highest Priority Declining Keywords):
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedKeywords}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          {change.changeDetails.flaggedReason == 'Work Quality' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Sales Brief / Information Around Quality Decline:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedQuality}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Document that best describes the work quality issue:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedDocuments}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          {change.changeDetails.flaggedReason == 'Performance' ?
          <>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            What is going wrong with the campaign:
            </span>
            <textarea
              rows={6}
              value={change.changeDetails.flaggedNoteComments}
              //onChange={(e) => setReason(e.target.value)}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600"
            />
          </div>
          </>
          :''}
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Length of time as Client:
            </span>
            <input
              type="text"
              value={change.changeDetails.flaggedTimeAsClient}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#001C44] dark:text-white">
            Who flagged the issue:
            </span>
            <input
              type="text"
              value={change.changeDetails.flaggedFrom}
              //onChange={(e) => setAmount(Number(e.target.value))}
              className="w-full border border-[#E6EBF0] rounded-lg p-2 text-[#334969] dark:bg-gray-700 dark:text-white dark:border-gray-600 "
            />
          </div>
          </>
          ):('')}
        </div>
        <div className="flex gap-8 bottom-0  w-full py-6  dark:bg-gray-800">
          <button
            className={` p-3 w-full text-white bg-[#005C9F] border border-[#005C9F] rounded-lg dark:bg-[#005C9F] `}
            onClick={() => setConfirmationModalApprove(true)}
          >
            Approve
          </button>
          <button
            className={`p-3 w-full text-white bg-[#D92D20] border border-[#D92D20] rounded-lg  dark:border-gray-600 `}
            onClick={() => setConfirmationModalDecline(true)}
          >
            Decline
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ChangeRequestModal;
