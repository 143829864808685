import React, { useState, useEffect, Fragment } from 'react';

// SEO REFUND EXPORT
export const SEORefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.seo_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'seo_google',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.seo_google.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">SEO GOOGLE Refund Details</div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={client.seo_google.revenue}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC REFUND EXPORT
export const PPCRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.ppc_google.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'ppc_google',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.ppc_google.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">PPC GOOGLE Refund Details</div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={client.ppc_google.revenue}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SEO REFUND EXPORT
export const SEOBINGRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.seo_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'seo_bing',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.seo_bing.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">Microsoft SEO Refund Details</div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={client.seo_google.revenue}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PPC REFUND EXPORT
export const PPCBINGRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_bing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_bing',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_bing.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">Microsoft Ads Refund Details</div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={client.ppc_google.revenue}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINK BOOST REFUND EXPORT
export const LINKBOOSTRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.link_boost.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'link_boost',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.link_boost.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">Link Booster Refund Details</div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={client.seo_google.revenue}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// REM REFUND EXPORT
export const REMRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.display.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'display',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.display.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Remarketing Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={client.display.revenue}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// META REFUND EXPORT
export const METARefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_meta.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_meta',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_meta.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">META Refund Details</div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PINTEREST REFUND EXPORT
export const PINTERESTRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_pinterest.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_pinterest',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_pinterest.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PINTEREST Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TIKTOK REFUND EXPORT
export const TIKTOKRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_tiktok.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_tiktok',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_tiktok.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TIKTOK Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// LINKEDIN REFUND EXPORT
export const LINKEDINRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_linkedin.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_linkedin',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_linkedin.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        LINKEDIN Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PROGRAMMATIC REFUND EXPORT
export const PROGRAMMATICRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_programmatic.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_programmatic',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_programmatic.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        PROGRAMMATIC Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// TACTICAL REFUND EXPORT
export const TACTICALRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_tactical.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_tactical',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_tactical.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        TACTICAL Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// CREATIVE REFUND EXPORT
export const CREATIVERefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.social_creative.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'social_creative',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.social_creative.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        CREATIVE Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBDEV REFUND EXPORT
export const WEBDEVRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_maintenance.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'web_maintenance',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.web_maintenance.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Maintenance Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEBHOST REFUND EXPORT
export const WEBHOSTRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_hosting.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'web_hosting',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.web_hosting.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Hosting Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// WEB LANDING REFUND EXPORT
export const WEBLANDINGRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_landing.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'web_landing',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.web_landing.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Landing Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};


// WEB LANDING REFUND EXPORT
export const WEBPROJECTRefund = ({ parentCallback, client }) => {
  var contractFinish = new Date(parseInt(client.web_project.end_date));
  var contractMonth = contractFinish.getMonth() + 1;
  const formattedContractDate =
    contractFinish.getDate() +
    '/' +
    contractMonth +
    '/' +
    contractFinish.getFullYear();
  const [refundValues, setValues] = useState({
    type: 'refund',
    service: 'web_project',
    refundAmount: '',
    refundReason: '',
    refundBudget: client.web_project.revenue,
  });

  const { type, service, refundAmount, refundReason, refundBudget } = refundValues;

  const handleChange = (name) => (event) => {
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setValues({ ...refundValues, [name]: value });
  };

  const init = (fv) => {
    parentCallback(fv);
  };

  useEffect(() => {
    init(refundValues);
  }, [refundValues]);

  return (
    <div className="mb-[40px]">
      <div className="mb-6 text-[16px] text-[#005C9F]">
        Web Project Refund Details
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the amount to refund?{`(EX GST)`}
          </p>
          <div className="text-[#7C7E7F]">
            <input
              onInput={handleChange('refundAmount')}
              value={refundAmount}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=""
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            What is the reason for the refund? (Be Detailed)
          </p>
          <div className="text-[#7C7E7F]">
          <textarea
              onInput={handleChange('refundReason')}
              value={refundReason}
              className="bg-white dark:bg-gray-800 border border-gray-300 h-[131px] text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[8px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a description..."
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className="text-[14px] text-[#001C44] dark:text-white mb-4">
            Service amount?
          </p>
          <div>
            <input
              disabled
              value={refundBudget}
              type="text"
              className="bg-white dark:bg-gray-800 border border-gray-300 text-[#525252] font-mundial text-sm rounded-lg block w-full ps-3 p-[9px]  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};