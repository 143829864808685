import { requests } from "./ApiService";

export const Tools = {
  getPositions: (filters) =>
    requests.get(
      `/api/v1/tools/positions?domain=${filters.domain}&database=${filters.database}&excluded=${filters.excluded}`
    ),
  getContacts: (filters) =>
    requests.get(
      `/api/v1/tools/contacts?keyword=${filters.keyword}&database=${filters.database}&excluded=${filters.excluded}`
    ),
  getQuotas: () =>
    requests.get(
      `/api/v1/tools/quotas`
    ),
};
