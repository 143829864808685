import axios, { AxiosError, AxiosResponse } from 'axios';
import { Config } from '../config';

const API_ROOT = Config.API_ROOT;

type ErrorResponseData = {
  message: string;
  errors: ErrorDetail[];
};

interface ErrorResponse {
  status?: number | string;
  errors?: any;
  error?: string;
  response?: {
    data?: {
      errors?: Array<{
        message?: string;
      }>;
      message?: string;
    };
  };
  message?: string;
}

interface ErrorDetail {
  message?: string;
}



const handleErrors = async (error: AxiosError): Promise<ErrorResponse[]> => {
  let result: ErrorResponse[] = [];
  const hasErrors = (data: any): data is ErrorResponseData => {
    return (
      data &&
      Array.isArray(data.errors) &&
      typeof data.message === 'string'
    );
  };

  const status = error?.response?.status;
  let errors: ErrorDetail[] = [];
  let errorMessage = 'Unknown error occurred';
  let message = 'Unknown error occurred';

  if (error.response?.data && hasErrors(error.response.data)) {
    errors = error.response.data.errors;
    errorMessage = errors.length > 0 ? errors[0].message || 'Unknown error occurred' : 'Unknown error occurred';
    message = errors.length > 0 ? error.response?.data?.message : "Unknown error occurred";
  }
  if (error?.response?.status === 401) {
    if(window.location.pathname !== "/client/dashboard") {
      localStorage.removeItem('auth-storage');
      window.location.replace('/');
    }
  }

  return result = [
    {
      status,
      errors,
      error: errorMessage,
      message
    },
  ];
};

const handleResponse = (res: AxiosResponse) => {
  return res && res.data;
};

const createApi = (customType?: string, customURL?: string) => {
  let headers: any = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': customType || 'application/json',
  };

  let AUTH_TOKEN = JSON.parse(localStorage.getItem('auth-storage'))?.state
    ?.token;
  if (AUTH_TOKEN) {
    headers.Authorization = `Bearer ${AUTH_TOKEN}`;
  }
  const api = axios.create({
    baseURL: API_ROOT,
    responseType: 'json',
    headers: headers,
  });

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return api;
};

export const requests = {
  get: (url: string, data?: any, customURL?:string) =>
    createApi('application/json',customURL)
      .get(`${customURL || API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  post: (url: string, data?: any, customType?: any, customURL?:string) =>
    createApi(customType)
      .post(`${customURL || API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  patch: (url: string, data?: any, customURL?:string) =>
    createApi()
      .patch(`${customURL || API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  put: (url: string, data?: any, customURL?:string) =>
    createApi()
      .put(`${customURL || API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  delete: (url: string, data?: any, customURL?:string) =>
    createApi()
      .delete(`${customURL || API_ROOT}${url}`, data && { data: data })
      .then(handleResponse)
      .catch(handleErrors),
};

type Filters = { [key: string]: string | string[] | undefined | null };
export const constructURLv2 = (url: string, filters: Filters) => {
  const queryParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryParams.append(`${key}[]`, item);
      });
    } else if (value !== 'all' && value !== undefined && value !== null) {
      queryParams.append(key, value);
    }
  });

  return requests.get(`${url}?${queryParams.toString()}`);
};
