import React from 'react';

interface IconProps {
  color?: string;
  size?: number;
}

const Hourglass: React.FC<IconProps> = ({ color = 'black', size = 13 }) => (
<svg
  width={size}
  height={size - 1}
  viewBox="0 0 13 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5 9.25H8M3.8 1H9.2C9.48003 1 9.62004 1 9.727 1.0545C9.82108 1.10243 9.89757 1.17892 9.9455 1.273C10 1.37996 10 1.51997 10 1.8V2.83726C10 3.08185 10 3.20414 9.97237 3.31923C9.94787 3.42127 9.90747 3.51881 9.85264 3.60828C9.7908 3.7092 9.70432 3.79568 9.53137 3.96863L8.06569 5.43431C7.86768 5.63232 7.76867 5.73133 7.73158 5.84549C7.69895 5.94591 7.69895 6.05409 7.73158 6.15451C7.76867 6.26867 7.86768 6.36768 8.06568 6.56568L9.53137 8.03137C9.70432 8.20432 9.7908 8.2908 9.85264 8.39172C9.90747 8.48119 9.94787 8.57873 9.97237 8.68077C10 8.79586 10 8.91815 10 9.16274V10.2C10 10.48 10 10.62 9.9455 10.727C9.89757 10.8211 9.82108 10.8976 9.727 10.9455C9.62004 11 9.48003 11 9.2 11H3.8C3.51997 11 3.37996 11 3.273 10.9455C3.17892 10.8976 3.10243 10.8211 3.0545 10.727C3 10.62 3 10.48 3 10.2V9.16274C3 8.91815 3 8.79586 3.02763 8.68077C3.05213 8.57873 3.09253 8.48119 3.14736 8.39172C3.2092 8.2908 3.29568 8.20432 3.46863 8.03137L4.93432 6.56568C5.13232 6.36768 5.23133 6.26867 5.26842 6.15451C5.30105 6.05409 5.30105 5.94591 5.26842 5.84549C5.23133 5.73133 5.13232 5.63232 4.93431 5.43431L3.46863 3.96863C3.29568 3.79568 3.2092 3.7092 3.14736 3.60828C3.09253 3.51881 3.05213 3.42127 3.02763 3.31923C3 3.20414 3 3.08185 3 2.83726V1.8C3 1.51997 3 1.37996 3.0545 1.273C3.10243 1.17892 3.17892 1.10243 3.273 1.0545C3.37996 1 3.51997 1 3.8 1Z"
    stroke={color}
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
)
export default Hourglass;
