import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import ToolTipV2 from '../../TooltipV2';
import Icon from '../../../assets/icons/SvgComponent';

// import { Responsive as ResponsiveGridLayout } from "react-grid-layout";

import GridItem from './GridItem';
import CustomModal from '../../CustomModal';
import WidgetSettings from '../WidgetSettings';
import CategorySelection from '../Modals';

import { addChart, addNewColumn, emptyLayoutCharts, generateGridLayout, generateLayout, getInitialLayout, itemsObject } from '../constants';
import EmptyGridItem from './EmptyGridItem';
import useDashboardStore from '../../../store/useDashboardStore';
import useClientStore from '../../../store/useClientStore';
import useAuthStore from '../../../store/useAuthStore';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import moment from 'moment';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface IProps {
  dashboardPageId: string;
  title: string;
  order: number;
  dashboard?: any;
  viewType?: string;
  startDate?: string;
  endDate?: string;
}

// Dashboard pages
const DynamicPage = ({
  dashboardPageId,
  title,
  order,
  dashboard,
  viewType = 'view',
  startDate,
  endDate,
}: IProps) => {
  const { selectedClient } = useClientStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const {
    sections,
    graphData,
    dashboard_template_id,
    isGraphDataLoading,
    fetchDashboardGraph,
    createDashboardSection,
    createDashboardSectionViaItem,
    updateDashboard,
    updateDashboardLayout,
    updateDashboardPage,
    deleteSection
  } = useDashboardStore((state) => state);

  const [openWidget, setOpenWidget] = useState(false);
  const [error, setError] = useState({});
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);

  const [sectionTitle, setSectionTitle] = useState(title);
  const [isEditingSectionTitle, setIsEditingSectionTitle] = useState(false);

  const [dynamicDashboardData, setDynamicDashboardData] = useState([]);
  const [dashboardId, setDashboardId] = useState('');

  const [layouts, setLayouts] = useState(getInitialLayout());
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('lg');

  // utility function to get formatted date from storage
  const getFormattedDateFromStorage = (key) => {
    const rawDate = localStorage.getItem(key);
    const json = rawDate ? JSON.parse(rawDate) : null;
    return rawDate ? moment(json).format('YYYY-MM-DD') : null;
  };

  const handleFetchGraphs = () => {
    const data = {
      clientId: selectedClient?.id,
      startDate: startDate 
        ? moment(startDate).subtract(1, 'year').format('YYYY-MM-DD') 
        : moment(getFormattedDateFromStorage('seoStartDate')).subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: endDate ? endDate : getFormattedDateFromStorage('seoEndDate')
    };
    fetchDashboardGraph(dashboardPageId, data);
  };

  useEffect(() => {
    handleFetchGraphs();
  }, [sections, dashboardPageId, selectedClient?.id, startDate, endDate]);

  useEffect(() => {
    // if(dashboards?.length > 0 && dashboardPageId) {
    if (dashboard?.layout_charts?.length > 0 && dashboardPageId) {
      
      const genLayout = dashboard?.layout && JSON.parse(dashboard?.layout);
      setDashboardId(dashboard?.id);
      
      if(viewType === "edit") {
        const sectionLayout = Object.keys(genLayout)?.length > 0 
          ? addNewColumn(genLayout, 3) 
          : generateGridLayout(dashboard?.layout_charts,3);
        setLayouts(sectionLayout)
      } else {
        const sectionLayout = Object.keys(genLayout)?.length > 0 
          ? genLayout 
          : generateGridLayout(dashboard?.layout_charts,3);  
        setLayouts(sectionLayout ?? {});
      }
      
      setDynamicDashboardData(
        viewType === "edit" 
          ? [...dashboard?.layout_charts, addChart(dashboard?.layout_charts?.length + 1)]
          : dashboard?.layout_charts
      );
      // console.log('chart: ',[...dashboard?.layout_charts, addChart(dashboard?.layout_charts?.length + 1)])
      setSectionTitle(title);
    } else {
      setDashboardId('');
      setLayouts(getInitialLayout());
      setDynamicDashboardData(emptyLayoutCharts);
    }
  }, [dashboard]);

  const [isDragging, setIsDragging] = useState(false);

  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });


  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const handleDragStart = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    // onLayoutChange(layout);
    setIsDragging(true);
  };

  // This will track when dragging stops
  const handleDragStop = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        [currentBreakpoint]: layout,
      };
      // TODO: save this when save button at header is click
      debouncedUpdateLayout(dashboardPageId, dashboard?.id, {
        layout: JSON.stringify(updatedLayouts),
      });
      return updatedLayouts;
    });
    setIsDragging(false);
  };

  // This will track when resizing stops
  const onResizeStop = (layout) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = {
        ...prevLayouts,
        [currentBreakpoint]: layout,
      };
      // TODO: save this when save button at header is click
      debouncedUpdateLayout(dashboardPageId, dashboard?.id, {
        layout: JSON.stringify(updatedLayouts),
      });
      return updatedLayouts;
    });
  };

  const debouncedUpdateLayout = useCallback(
    debounce((secitonId, dashboardId, data) => {
      updateDashboardLayout(secitonId, dashboardId, data);
    }, 1000),
    [updateDashboardLayout]
  );

  // const onLayoutChange = useCallback((currentLayout: any, allLayouts?: any) => {
  //   if(currentLayout?.length > 0) {
  //     setLayouts(prevLayouts => {
  //         const updatedLayouts = {
  //           ...prevLayouts,
  //           [currentBreakpoint]: currentLayout
  //         };
  //         console.log(updatedLayouts,'updatedLayouts');
  //         // debouncedUpdateLayout(dashboard?.id,{
  //         //   layout: JSON.stringify(updatedLayouts)
  //         // });
  //         saveLayoutToLocalStorage(updatedLayouts); // Save to localStorage
  //         return updatedLayouts;
  //     });
  //   }
  // },[]);

  const renderGridItem = () => {
    const dataToObj = dynamicDashboardData?.length > 0 && itemsObject(dynamicDashboardData);
    // console.log(dataToObj,'dataToObj')
    // console.log(layouts[currentBreakpoint],'shet')
    // Render existing grid items
    const gridItems = layouts[currentBreakpoint]?.map((item) => {
      // console.log(item,'gridItems')
      if(dataToObj[item.i] === undefined) return null;

      return (
        <div
          className={`${item.i} sample-class`}
          key={item.i}
          onClick={(e) => e.stopPropagation()}
        >
          {!dataToObj[item.i]?.title ? (
            <EmptyGridItem
              layoutData={dataToObj[item.i]}
              setOpenWidget={setOpenWidget}
              setSelectedItem={setSelectedItem}
              viewType={viewType}
            />
          ) : (
            <GridItem
              key={item.i}
              layoutData={dataToObj[item.i]}
              setOpenWidget={setOpenWidget}
              setSelectedItem={setSelectedItem}
              viewType={viewType}
              isGraphDataLoading={isGraphDataLoading}
            />
          )}
        </div>
      );
    });
    return gridItems;
  };

  const handleSaveItem = async (data) => {
    console.log(data, 'handleSaveItem');
    if (!data.data_config.metrics && !data.data_config.source) {
      setError({
        error: true,
        message: 'Metrics and Source are required.',
      });
      return false;
    }
    console.log(dynamicDashboardData,'dynamicDashboardData')
    const updatedItems = dynamicDashboardData.map((item) =>
      item.id === data.id ? { ...item, ...data } : item
    );
    setDynamicDashboardData(updatedItems);

    const validKeys = new Set(dynamicDashboardData.map(item => item.key));
    const updatedLayout = {
      lg: layouts.lg.filter(item => validKeys.has(item.i)),
      sm: layouts.sm.filter(item => validKeys.has(item.i))
    };
    console.log(updatedLayout,'updatedLayout')
    console.log(updatedItems,'updatedItems');

    let dashboardData = {
      layout_group: title.replace(/\s+/g, '-').toLowerCase() || '',
      layout: JSON.stringify(updatedLayout),
      layout_charts: updatedItems.filter(f => f.data_config.metrics && f.data_config.source), // filter data that has source and metrics
    };
    console.log(dashboardData, 'dashboardData');

    // edit
    if (dashboard?.id) {
      const editData = {
        ...dashboardData,
        status: 'active',
        updated_by: user.id
      };
      console.log(editData,'data-edit')
      updateDashboard(dashboard?.id, editData);
      
      // generate additional column
      const newDashboardData = [...updatedItems, addChart(updatedItems?.length + 1)];
      console.log(newDashboardData,'newDashboardData')
      setDynamicDashboardData(newDashboardData);
      const newLayout = generateGridLayout(newDashboardData,3);
      console.log(updatedLayout,'updatedLayout')
      console.log(newLayout,'newLayout')
      // setLayouts(newLayout)
      // setError(null);
    } else {
      // create new
      const createNewSectionViaChart = {
        dashboard_template_id,
        title: sectionTitle,
        order: sections.length + 1,
        created_by: user.id,
        contents: [
          {
            status: "active",
            layout_group: title.replace(/\s+/g, '-').toLowerCase() || '',
            layout: JSON.stringify(updatedLayout),
            layout_charts: updatedItems
          }
        ]
      };
      createDashboardSectionViaItem(createNewSectionViaChart);
    }

    setOpenWidget(false);
  };

  const handleDeleteItem = async (data) => {
    const remainingItems = dynamicDashboardData.filter(f => f.id !== data.id);
    const updatedLayout = {
      lg: layouts.lg.filter(item => item.i !== data.key),
      sm: layouts.sm.filter(item => item.i !== data.key)
    };
    setDynamicDashboardData(remainingItems);
    if (dashboard?.id) {
      const deleteData = {
        layout_group: title.replace(/\s+/g, '-').toLowerCase() || '',
        layout: JSON.stringify(updatedLayout),
        layout_charts: remainingItems,
        status: 'active',
        updated_by: user.id
      };
      updateDashboard(dashboard?.id, deleteData);
    }
    setOpenWidget(false);
  }

  const saveDashbboardSection = () => {
    if (dashboardPageId) {
      updateDashboardPage(dashboardPageId, dashboard?.id, {
        title: sectionTitle,
        order: sections.length + 1,
        updated_by: user.id,
        contents: [
          {
            status: 'active',
            layout_group: sectionTitle.replace(/\s+/g, '-').toLowerCase() || '',
            layout: JSON.stringify(layouts),
            layout_charts: dynamicDashboardData,
          },
        ],
      });
    }
    
    // Create dashboard page
    if (!dashboardPageId) {
      createDashboardSection({
        dashboard_template_id,
        title: sectionTitle,
        order: sections.length + 1,
        created_by: user.id,
      });
    }

    setIsEditingSectionTitle(false);
  };

  return (
    <>
      <div
        className="flex justify-between items-center mb-4"
        id={String(order)}
      >
        <div className="flex items-center gap-2">
          {isEditingSectionTitle ? (
            <input
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveDashbboardSection();
                }
              }}
              className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          ) : (
            <h2 className="title-text text-2xl pb-1">{sectionTitle || ''}</h2>
          )}

          {viewType === 'edit' &&
            // save/update section onlick
            (isEditingSectionTitle ? (
              <div
                onClick={() => saveDashbboardSection()}
                className="cursor-pointer min-h-[36px] flex items-center justify-center"
              >
                <div className="h-full flex items-center justify-center">
                  <ToolTipV2 tooltip="Save section name">
                    <Icon name="Save" size={20} color="#0029FF" />
                  </ToolTipV2>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setIsEditingSectionTitle(!isEditingSectionTitle)}
                className="cursor-pointer min-h-[36px] flex items-center justify-center"
              >
                <div className="h-full flex items-center justify-center">
                  <ToolTipV2 tooltip="Edit section name">
                    <Icon name="Edit" size={18} color="#0029FF" />
                  </ToolTipV2>
                </div>
              </div>
            ))}
        </div>
        {viewType === 'edit' && (
          <div className="cursor-pointer flex flex-row items-center ">
            <Icon name="gridMenu" />
            {dashboardPageId &&
              <button
                className="w-full ml-2 text-white bg-red-700 hover:bg-red-800 text-[16px] font-[600] rounded-lg p-2"
                onClick={(e) => deleteSection(dashboardPageId)}
              >
                Delete Section
              </button>
            }
          </div>
        )}
      </div>

      <div className="flex flex-col gap-4">
        <ResponsiveReactGridLayout
          // {...props}
          className="layout"
          layouts={layouts}
          breakpoints={{ lg: 1200, sm: 768 }} // Define breakpoints
          cols={{ lg: 3, sm: 2 }}
          rowHeight={380}
          onBreakpointChange={onBreakpointChange}
          // Disable drag on view
          isDraggable={viewType === 'edit'}
          // Disable resize on view
          isResizable={viewType === 'edit'}
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          onResizeStop={onResizeStop}
        >
          {renderGridItem()}
        </ResponsiveReactGridLayout>

        <CustomModal
          open={openWidget}
          onClose={() => {
            setOpenWidget(false);
          }}
        >
          <WidgetSettings
            selectedItem={selectedItem}
            handleSaveItem={handleSaveItem}
            handleDeleteItem={handleDeleteItem}
            onClose={() => setOpenWidget(false)}
          />
        </CustomModal>
        <CustomModal
          open={openCategoryModal}
          onClose={() => {
            setOpenCategoryModal(false);
          }}
        >
          <CategorySelection onClose={() => setOpenCategoryModal(false)} />
        </CustomModal>
      </div>
    </>
  );
};

export default DynamicPage;
